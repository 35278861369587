import {StyledBadge} from '@components/ui/badge/style.ts'
import {HTMLAttributes} from 'react'

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'neutral'
    size?: 'sm' | 'md' | 'lg'
    shape?: 'roundedRectangle' | 'circle' | 'rectangle' | 'square'
}

export const Badge = ({variant = 'neutral', shape = 'roundedRectangle', size = 'md', ...rest}: BadgeProps) => {
    return <StyledBadge $shape={shape} $variant={variant} $size={size} {...rest} />
}
