import {Footer} from '@/features/services/components/footer/Footer.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Content, HeadingGroup} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {errorHandler} from '@utilities/helpers.ts'
import {
    StyledButton,
    StyledVideo,
    StyledTitle,
    StyledParagraph
} from '@/features/services/components/get-started-step/style.ts'
import Logo from '@/assets/videos/airbnb_logo.mp4'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useUpdateStepToHostExpertiseCareer} from '@/features/services/services/useUpdateStepToHostExpertiseCareer.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const GetStartedStep: FC<{serviceId: number; expertiseId: number}> = ({serviceId, expertiseId}) => {
    const {t} = useTranslation()
    const updateStepToHostExpertiseCareerMutation = useUpdateStepToHostExpertiseCareer({
        serviceId,
        expertiseId,
        options: {onError: errorHandler}
    })

    return (
        <>
            <Content gap={0}>
                <StyledVideo src={Logo} autoPlay muted playsInline />
                <HeadingGroup direction="column" align="center" gap={4}>
                    <StyledTitle>{t('services:step_get_started:title')}</StyledTitle>
                    <StyledParagraph>{t('services:step_get_started:paragraph1')}</StyledParagraph>
                    <StyledParagraph>{t('services:step_get_started:paragraph2')}</StyledParagraph>
                </HeadingGroup>
            </Content>
            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <StyledButton
                        fullWidth
                        size="lg"
                        disabled={updateStepToHostExpertiseCareerMutation.isPending}
                        onClick={() => updateStepToHostExpertiseCareerMutation.mutate({category: 'generic'})}
                    >
                        {t('commons:get_started')}
                        {updateStepToHostExpertiseCareerMutation.isPending && <Spinner />}
                    </StyledButton>
                </Flexbox>
            </Footer>
        </>
    )
}
