import PageHero from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Helmet} from 'react-helmet'
import {useEffect, useMemo, useState} from 'react'
import Container from '@components/ui/container/Container.tsx'
import {generatePath, useOutletContext} from 'react-router-dom'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ButtonLink} from '@components/ui/button-link/ButtonLink'
import {routes} from '@/utilities/constants'
import {AlertTriangleIcon, ChevronLeftIcon, MessageTextSquare02Icon} from '@/components/ui/icon'
import {Button} from '@components/ui/button/Button'
import {useGetCoAlarmRequest} from '@/features/co-alarm/services/useGetCoAlarmRequest'
import {CoAlarmListingsURLParams} from '@/features/co-alarm/components/co-alarm-listings-wrapper/CoAlarmListingsWrapper'
import Spinner from '@components/ui/spinner-legacy/Spinner'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {Steps} from '@/components/commons/steps/Steps'
import {getCoAlarmSteps} from '@/features/co-alarm/utils'
import {CoAlarmListingCard} from '@/features/co-alarm/components/co-alarm-listing-card/CoAlarmListingCard'
import {CoAlarmShippingInfoCard} from '@/features/co-alarm/components/co-alarm-shipping-info-card/CoAlarmShippingInfoCard'
import {CoAlarmPostDeliveryCards} from '@/features/co-alarm/components/co-alarm-post-delivery-cards/CoAlarmPostDeliveryCards'
import {CoAlarmInstallationWarningBox} from '@/features/co-alarm/components/co-alarm-installation-warning-box/CoAlarmInstallationWarningBox'
import {CoAlarmInstallationCompletedBox} from '@/features/co-alarm/components/co-alarm-installation-completed-box/CoAlarmInstallationCompletedBox'
import {StyledCoAlarmRequestDetails, StyledCoAlarmRequestDetailsSection} from '@/pages/co-alarm/request-details/style'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {CoAlarmNotifyProcess} from '@/features/co-alarm/components/co-alarm-notification-process/CoAlarmNotifyProcess.tsx'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {NotificationConsentModal} from '@/features/service/components/notification-consent-modal/NotificationConsentModal.tsx'
import {CoAlarmEditRequestModal} from '@/features/co-alarm/components/co-alarm-edit-request-modal/CoAlarmEditRequestModal'
import dayjs from 'dayjs'
import {CoAlarmPromoBanner} from '@/features/co-alarm/components/co-alarm-promo-banner/CoAlarmPromoBanner'
import {useAuthStore} from '@/store/AuthStore'
import {User} from '@/features/sso/types'
import {formatLocaleDate} from '@/utilities/helpers'

export const CoAlarmRequestDetails = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: CoAlarmListingsURLParams}>()
    const [editModal, showEditModal] = useState(false)
    //service acceptance store
    const coNotifyModalVisible = useServiceAcceptStore(state => state.modalVisible)
    const coNotifyModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    //otp store
    const setOtpCoAlarmRequest = useOtpStore(state => state.setCoAlarmRequest)
    const setNotifyToggleStep = useOtpStore(state => state.setNotifyToggleStep)
    const setOtpStep = useOtpStore(state => state.changeStep)
    const resetOtpStore = useOtpStore(state => state.reset)
    //state
    const [notifyConsentOpen, setNotifyConsentOpen] = useState(false)
    // user store
    const authUser = useAuthStore()
    //utils
    const coAlarmNotifyFlowTriggered = localStorage.getItem('coNotifyModal')

    const {
        data: coAlarmRequestDetails,
        isLoading,
        isError
    } = useGetCoAlarmRequest({
        urlParams: {
            listingId: urlParams.listingId,
            requestId: urlParams.requestId
        },
        params: {
            response_type: 'extended'
        }
    })

    const steps = useMemo(() => {
        if (coAlarmRequestDetails) {
            setOtpCoAlarmRequest(coAlarmRequestDetails)
            return getCoAlarmSteps({
                coAlarmStatus: coAlarmRequestDetails.co_alarm_status,
                requestStatus: coAlarmRequestDetails.status,
                createdAt: coAlarmRequestDetails?.created_at,
                shippedAt: coAlarmRequestDetails?.shipped_at,
                deliveredAt: coAlarmRequestDetails?.delivered_at,
                updatedAt: coAlarmRequestDetails?.updated_at
            })
        }
    }, [coAlarmRequestDetails])

    const hoursPassedSinceRequest = useMemo(
        () => (coAlarmRequestDetails?.created_at ? dayjs().diff(coAlarmRequestDetails.created_at, 'hour') : 0),
        [coAlarmRequestDetails?.created_at]
    )

    const onNotifyCoSettingsClick = () => {
        if (coAlarmRequestDetails) {
            setOtpCoAlarmRequest(coAlarmRequestDetails)
            if (!coAlarmNotifyFlowTriggered) {
                setOtpStep('channel_toggle', true)
                setNotifyToggleStep(true)
                setNotifyConsentOpen(true)
            } else {
                coNotifyModalToggle(undefined, coAlarmRequestDetails)
            }
        }
    }

    const onCloseNotifySettingsModal = () => {
        if (notifyConsentOpen) {
            setNotifyConsentOpen(false)
        } else {
            coNotifyModalToggle()
        }
        resetOtpStore()
    }

    useEffect(() => {
        if (
            coAlarmRequestDetails &&
            !coAlarmRequestDetails.notification_preferences?.send_phone_notification &&
            coAlarmNotifyFlowTriggered
        ) {
            onNotifyCoSettingsClick()
        }
    }, [coAlarmRequestDetails?.notification_preferences?.send_phone_notification])

    // Overwrite the value of co_alarm_requests_count on the initial load
    useEffect(() => {
        if (authUser.user?.co_alarm.requests_count == 0) {
            useAuthStore.setState(() => ({
                ...authUser,
                user: {
                    ...(authUser.user as User),
                    co_alarm: {
                        status: 'ineligible',
                        requests_count: 1
                    }
                }
            }))
        }
    }, [authUser.user])

    if (isLoading) {
        return (
            <Container>
                <Flexbox width="100%" height="100%" justify="center" align="center">
                    <Spinner size={40} />
                </Flexbox>
            </Container>
        )
    }

    if (isError || !coAlarmRequestDetails || !steps) {
        return (
            <Container>
                <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
            </Container>
        )
    }

    return (
        <>
            {coNotifyModalVisible && coAlarmNotifyFlowTriggered && (
                <Modal width={374} onClose={onCloseNotifySettingsModal} onOverlayClick={onCloseNotifySettingsModal}>
                    <CoAlarmNotifyProcess />
                </Modal>
            )}

            {notifyConsentOpen && (
                <Modal width={374} onClose={onCloseNotifySettingsModal} onOverlayClick={onCloseNotifySettingsModal}>
                    <NotificationConsentModal onCloseCb={onCloseNotifySettingsModal} />
                </Modal>
            )}

            <StyledCoAlarmRequestDetails>
                <Helmet title={String(t('coAlarm:request_details:page_title_meta'))} />
                <Container fullHeight={false}>
                    <PageHero
                        title={
                            coAlarmRequestDetails.co_alarm_status == 'eligible' ||
                            coAlarmRequestDetails.co_alarm_status == 'ineligible'
                                ? ''
                                : t(`coAlarm:request_details:status:${coAlarmRequestDetails.co_alarm_status}`)
                        }
                        subtitle={`${t('commons:last_update_on', {
                            dateTime: formatLocaleDate(dayjs(coAlarmRequestDetails.updated_at), 'LLL')
                        })}`}
                        ctaComponent={
                            <Flexbox justify="space-between" align="center" width="100%">
                                <ButtonLink
                                    variant={'tertiary'}
                                    shape="text"
                                    size={'md'}
                                    to={generatePath(routes.CO_ALARM_DASHBOARD.path)}
                                >
                                    <ChevronLeftIcon size={20} />
                                    {t('commons:back')}
                                </ButtonLink>
                                <Button size="sm" variant="secondary" onClick={onNotifyCoSettingsClick}>
                                    <MessageTextSquare02Icon size={20} />
                                    {t('commons:text_notifications', {
                                        value: coAlarmRequestDetails.notification_preferences?.send_phone_notification
                                            ? t('commons:on')
                                            : t('commons:off')
                                    })}
                                </Button>
                            </Flexbox>
                        }
                    />
                </Container>

                <Flexbox direction="column" gap={6}>
                    <Steps stepCardContentDirection="row" steps={steps} hasBorder />

                    {coAlarmRequestDetails.listing &&
                        (coAlarmRequestDetails.co_alarm_status == 'installed' ? (
                            <StyledCoAlarmRequestDetailsSection fullHeight={false}>
                                <CoAlarmInstallationCompletedBox />
                            </StyledCoAlarmRequestDetailsSection>
                        ) : (
                            coAlarmRequestDetails.status == 'delivered' && (
                                <StyledCoAlarmRequestDetailsSection fullHeight={false}>
                                    <CoAlarmInstallationWarningBox />
                                </StyledCoAlarmRequestDetailsSection>
                            )
                        ))}

                    {coAlarmRequestDetails.listing && (
                        <StyledCoAlarmRequestDetailsSection fullHeight={false}>
                            <h3>{t('coAlarm:request_details:ordered_for')}</h3>
                            <CoAlarmListingCard listing={coAlarmRequestDetails.listing} />
                        </StyledCoAlarmRequestDetailsSection>
                    )}

                    <StyledCoAlarmRequestDetailsSection fullHeight={false}>
                        <Flexbox justify="space-between">
                            <h3>{t('coAlarm:request_details:shipping_to')}</h3>
                            {hoursPassedSinceRequest < 1 && coAlarmRequestDetails.status == 'requested' && (
                                <Button onClick={() => showEditModal(true)} variant="tertiary">
                                    {t('commons:edit')}
                                </Button>
                            )}
                        </Flexbox>
                        <CoAlarmShippingInfoCard request={coAlarmRequestDetails} />
                    </StyledCoAlarmRequestDetailsSection>

                    {coAlarmRequestDetails.status != 'delivered' && coAlarmRequestDetails.listing?.id && (
                        <StyledCoAlarmRequestDetailsSection fullHeight={false}>
                            <h3>{t('coAlarm:request_details:post_delivery_cards_before_title')}</h3>
                            <CoAlarmPostDeliveryCards listingId={coAlarmRequestDetails.listing.id} />
                        </StyledCoAlarmRequestDetailsSection>
                    )}

                    <CoAlarmPromoBanner />
                </Flexbox>

                {editModal && (
                    <CoAlarmEditRequestModal
                        coAlarmRequestDetails={coAlarmRequestDetails}
                        hideModal={() => showEditModal(false)}
                    />
                )}
            </StyledCoAlarmRequestDetails>
        </>
    )
}
