import styled, {css, SimpleInterpolation} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

type SizesProps = 'xl' | 'lg' | 'md' | 'sm'

export const AvatarRoot = styled(AvatarPrimitive.Root)<{size: SizesProps}>`
    ${({theme: {palette}}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        border-radius: 100%;
        overflow: hidden;
        background: ${palette.neutral['100']};
        border: 1px ${palette.neutral['300']};
        flex: none;
    `}
    ${({size}) => SizeStyles[size]};
`

export const AvatarImage = styled(AvatarPrimitive.Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
`
export const AvatarFallback = styled(AvatarPrimitive.Fallback)<{size: SizesProps}>`
    ${({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${palette.neutral['200']};
        color: ${palette.neutral['600']};
        font-weight: 500;
    `}
    ${({size}) => SizeStyles[size]};
`

const SizeStyles = {
    sm: css`
        height: 24px;
        width: 24px;
        font-size: 12px;
    `,
    md: css`
        height: 32px;
        width: 32px;
        font-size: 16px;
    `,
    lg: css`
        height: 48px;
        width: 48px;
        font-size: 24px;
    `,
    xl: css`
        height: 64px;
        width: 64px;
        font-size: 24px;
    `
} as const satisfies Partial<Record<SizesProps, SimpleInterpolation>>
