import {HeadingGroup, Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StyledContent} from '@/features/services/components/sent-for-review-step/style.ts'
import {ClockIcon} from '@components/ui/icon'

export const SentForReviewStep = () => {
    const {t} = useTranslation()

    return (
        <>
            <StyledContent gap={6}>
                <span>
                    <ClockIcon />
                </span>
                <HeadingGroup align="center">
                    <Title>{t('services:step_sent_for_review:title')}</Title>
                    <Paragraph>{t('services:step_sent_for_review:paragraph')}</Paragraph>
                </HeadingGroup>
            </StyledContent>
        </>
    )
}
