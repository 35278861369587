import React, {FC} from 'react'
import {DPMonth, DPPropGetter} from '@rehookify/datepicker'
import {StyledCalendarWrapper, StyledCalendarHeader, StyledYearMonthGrid} from '@components/commons/calendar/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {View} from '@components/commons/calendar/Calendar.tsx'
import {capitalize} from '@utilities/helpers.ts'
import dayjs from '@utilities/dayjs.ts'

type MonthSelectorProps = {
    months: DPMonth[]
    monthButton: (month: DPMonth) => DPPropGetter
    currentMonth: string
    setViewState: React.Dispatch<React.SetStateAction<View>>
}

export const MonthSelector: FC<MonthSelectorProps> = ({months, monthButton, currentMonth, setViewState}) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    return (
        <StyledCalendarWrapper>
            <StyledCalendarHeader>
                <h3>{t('commons:months')}</h3>
            </StyledCalendarHeader>
            <StyledYearMonthGrid>
                {months.map(month => (
                    <Button
                        key={month.month}
                        variant={month.month == currentMonth ? 'primary' : 'ghost'}
                        {...monthButton(month)}
                        onClick={e => {
                            monthButton(month).onClick?.(e)
                            setViewState('date')
                        }}
                    >
                        {capitalize(dayjs(month.$date).locale(language).format('MMM'))}
                    </Button>
                ))}
            </StyledYearMonthGrid>
            <Button variant="ghost" onClick={() => setViewState('date')}>
                {t('commons:back')}
            </Button>
        </StyledCalendarWrapper>
    )
}
