import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const WalkthroughConsultantCanceledStatusDescription = () => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:home:photographer_canceled_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:consultant_canceled_paragraph1')}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:consultant_canceled_paragraph2')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

WalkthroughConsultantCanceledStatusDescription.displayName = 'WalkthroughConsultantCanceledStatusDescription'
