import {StyledPhotoStatsBody, StyledPhotoStatsContainer, StyledPhotoStatsWrapper} from './style.ts'
import {ServiceValueProps, ServiceValuePropsBg} from '@/features/in-progress-services/utils.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatsWidget} from '@components/commons/stats-widget/StatsWidget.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const PromoPhotoSectionStats = () => {
    const {t} = useTranslation()

    return (
        <StyledPhotoStatsContainer backgroundImg={ServiceValuePropsBg.home}>
            <StyledPhotoStatsWrapper>
                <Flexbox justify={'space-between'} align={'center'}>
                    <h4>{t('photoshoot_landing:stats:main_title')}</h4>
                    {/*<Button variant={'tertiary'} size={'sm'}>
                        {t('commons:learn_more')}
                    </Button>*/}
                </Flexbox>
                <StyledPhotoStatsBody>
                    {Object.values(ServiceValueProps['dash']).map((item, index) => (
                        <StatsWidget
                            key={index}
                            labelText={t(item.labelText)}
                            data={item.data ? t(item.data) : ''}
                            subtext={item.subtext ? t(item.subtext) : ''}
                            description={t(item.description)}
                        />
                    ))}
                </StyledPhotoStatsBody>
            </StyledPhotoStatsWrapper>
        </StyledPhotoStatsContainer>
    )
}

PromoPhotoSectionStats.displayName = 'PromoPhotoSectionStats'
