import {AlertCircleIcon} from '@/components/ui/icon'
import {ServiceType} from '@/types/commons'
import {FC} from 'react'
import {StyledShareHostDatesSuccessBody, StyledShareHostDatesSuccessFooter} from './style'
import {Button} from '@components/ui/button/Button'
import {useShareHostDatesStore} from '@/features/service/store/ShareHostDatesStore'
import {TKey, useTranslation} from '@/translations/i18n.tsx'

const serviceTypeToDescriptionTKey = {
    home: 'service_details:host_dates_picker:home:success_description',
    experience_photo: 'service_details:host_dates_picker:home:success_description',
    experience_video: 'service_details:host_dates_picker:experience_video:success_description',
    matterport: 'service_details:host_dates_picker:matterport:success_description',
    walkthrough: 'service_details:host_dates_picker:walkthrough:success_description'
} as const satisfies Record<ServiceType, TKey>

interface ServiceTypeProps {
    serviceType: ServiceType
}

export const ShareHostDatesSuccess: FC<ServiceTypeProps> = ({serviceType}) => {
    const {t} = useTranslation()
    const toggleShareHostDatesModal = useShareHostDatesStore(state => state.toggleModal)

    return (
        <>
            <StyledShareHostDatesSuccessBody padding={6} gap={1} align="center" justify="center" direction="column">
                <AlertCircleIcon size={48} />
                <h2>{t('service_details:host_dates_picker:success_title')}</h2>
                <p>{t(serviceTypeToDescriptionTKey[serviceType])}</p>
            </StyledShareHostDatesSuccessBody>

            <StyledShareHostDatesSuccessFooter justify="center">
                <Button variant="tertiary" onClick={() => toggleShareHostDatesModal()}>
                    {t(`commons:okay`)}
                </Button>
            </StyledShareHostDatesSuccessFooter>
        </>
    )
}
