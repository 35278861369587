import {create} from 'zustand'
import {UploadedMedia} from '@/features/services/types.ts'

export type UploadingFile = {
    file: File
    progressValue: number
}

export type ErrorFile = {
    file: File
    error?: string
}
export interface ClipsUploadStore {
    videosetId: number | null
    setVideosetId: (videosetId: number) => void
    uploadingFiles: Array<UploadingFile>
    uploadedFiles: Array<UploadedMedia>
    errorFiles: Array<ErrorFile>
    addUploadingFile: (uploadingFile: File) => void
    addErrorFiles: (errorFiles: ErrorFile[]) => void
    removeUploadedFiles: (uploadedFilesIds: number[]) => void
    removeUploadingFiles: (uploadingFile: File[]) => void
    removeErrorFile: (errorFile: File) => void
    setUploadedFiles: (uploadedFiles: UploadedMedia[]) => void
    addUploadedFile: (uploadedFile: UploadedMedia) => void
    setUploadingFileProgressValue: (fileName: string, progressValue: number) => void
    removeAllErrorFiles: () => void
    videoCurrentInPlayingId: number | null
    setVideoCurrentInPlaying: (videoId: number) => void
}

export const useClipsUpload = create<ClipsUploadStore>((set, getState) => ({
    videosetId: null,
    videoCurrentInPlayingId: null,
    uploadingFiles: [],
    uploadedFiles: [],
    errorFiles: [],
    setVideosetId: (videosetId: number) => {
        set({videosetId: videosetId})
    },
    setUploadingFileProgressValue: (fileName, progressValue) => {
        const prevUploadingFiles = getState().uploadingFiles
        set({
            uploadingFiles: prevUploadingFiles.map(uploadingFile => {
                if (uploadingFile.file.name == fileName) {
                    return {...uploadingFile, progressValue}
                }
                return uploadingFile
            })
        })
    },
    addUploadingFile: uploadingFile => {
        const prevUploadingFiles = getState().uploadingFiles
        set({
            uploadingFiles: [...prevUploadingFiles, {file: uploadingFile, progressValue: 0}]
        })
    },
    setUploadedFiles: uploadedFiles => set({uploadedFiles}),
    addUploadedFile: uploadedFile => {
        const prevUploadedFiles = getState().uploadedFiles
        set({uploadedFiles: [...prevUploadedFiles, uploadedFile]})
    },
    removeUploadedFiles: uploadedFilesIds => {
        const prevUploadedFiles = getState().uploadedFiles
        set({
            uploadedFiles: prevUploadedFiles.filter(uploadedFile => !uploadedFilesIds.includes(uploadedFile.id))
        })
    },
    removeUploadingFiles: uploadingFiles => {
        const prevUploadingFiles = getState().uploadingFiles
        set({
            uploadingFiles: prevUploadingFiles.filter(
                prevFile => !uploadingFiles.some(uploadingFile => uploadingFile == prevFile.file)
            )
        })
    },
    addErrorFiles: errorFile => {
        const prevErrorFile = getState().errorFiles
        set({errorFiles: [...prevErrorFile, ...errorFile]})
    },
    removeErrorFile: errorFile => {
        const prevErrorFiles = getState().errorFiles
        set({errorFiles: prevErrorFiles.filter(file => file.file != errorFile)})
    },
    removeAllErrorFiles: () => {
        set({errorFiles: []})
    },
    setVideoCurrentInPlaying: videoId => {
        set({videoCurrentInPlayingId: videoId})
    }
}))
