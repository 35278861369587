import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/services/utils.tsx'
import {Footer} from '@/features/services/components/footer/Footer.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldDescription,
    FieldTitle,
    Title,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {z} from 'zod'
import {FormProvider, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Divider} from '@components/ui/divider/Divider.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import {MoreDetailsPrimaryLanguageSelect} from '@/features/services/components/more-details-primary-language-select/MoreDetailsPrimaryLanguageSelect.tsx'
import {MoreDetailsSecondaryLanguageSelect} from '@/features/services/components/more-details-secondary-language-select/MoreDetailsSecondaryLanguageSelect.tsx'
import {Service} from '@/features/services/types.ts'
import {StyledGroupSizeGrid} from '@/features/services/components/other-details-more-details-step/style.ts'
import {useUpdateStepToOtherDetailsAboutGuest} from '@/features/services/services/useUpdateStepToOtherDetailsAboutGuest.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToOtherDetailsTerms} from '@/features/services/services/useUpdateStepToOtherDetailsTerms.ts'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {ConfirmSubmitModal} from '@/features/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const SUPPORTED_LANGUAGES = [
    {
        label: 'Català',
        value: 'ca'
    },
    {
        label: 'Deutsch',
        value: 'de'
    },
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Español',
        value: 'es'
    },
    {
        label: 'Français',
        value: 'fr'
    },
    {
        label: 'Italiano',
        value: 'it'
    },
    {
        label: '日本語',
        value: 'ja'
    },
    {
        label: '한국어',
        value: 'ko'
    },
    {
        label: 'Português',
        value: 'pt'
    },
    {
        label: 'Русский',
        value: 'ru'
    },
    {
        label: '中文 (简体)',
        value: 'zh'
    }
] as const

const MoreDetailsValidationSchema = z
    .object({
        main_language: z.string(),
        extra_languages: z.string().array().optional(),
        group_min_size: z.coerce
            .number()
            .min(1, i18n.t('services:step_other_details_more_details:minimum_size_required')),
        group_max_size: z.coerce
            .number()
            .min(1, i18n.t('services:step_other_details_more_details:maximum_size_required')),
        cancellation_policy: z.enum(['7_days', '24_hours'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        })
    })
    .superRefine((values, context) => {
        if (values.group_min_size > values.group_max_size) {
            context.addIssue({
                code: 'custom',
                path: ['group_min_size'],
                message: i18n.t('services:step_other_details_more_details:question2_error')
            })
        }
    })
export type MoreDetailsValidationSchema = z.infer<typeof MoreDetailsValidationSchema>

export const OtherDetailsMoreDetailsStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const mainLanguageFlags = updatesRequiredSteps.moreDetails.find(field => field.questionKeyName == 'main_language')
        ?.flags
    const extraLanguagesFlags = updatesRequiredSteps.moreDetails.find(
        field => field.questionKeyName == 'extra_languages'
    )?.flags
    const groupMinSizeFlags = updatesRequiredSteps.moreDetails.find(field => field.questionKeyName == 'group_min_size')
        ?.flags
    const groupMaxSizeFlags = updatesRequiredSteps.moreDetails.find(field => field.questionKeyName == 'group_max_size')
        ?.flags
    const cancellationPolicyFlags = updatesRequiredSteps.moreDetails.find(
        field => field.questionKeyName == 'cancellation_policy'
    )?.flags
    const flagsCount = updatesRequiredSteps.moreDetails.flatMap(field => field.flags).length
    const updateStepToOtherDetailsAboutGuest = useUpdateStepToOtherDetailsAboutGuest({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsTherms = useUpdateStepToOtherDetailsTerms({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'moreDetails', updatesRequiredSteps}).prev?.()
            : updateStepToOtherDetailsAboutGuest
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'moreDetails'>({
                  service,
                  currentStep: 'moreDetails',
                  updatesRequiredSteps
              }).next?.()
            : updateStepToOtherDetailsTherms

    const form = useForm<MoreDetailsValidationSchema>({
        resolver: zodResolver(MoreDetailsValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce(
            (previousValue, currentValue) => {
                if (currentValue.question_key_name == 'main_language' && currentValue.answer) {
                    return {...previousValue, main_language: currentValue.answer}
                }
                if (currentValue.question_key_name == 'extra_languages' && currentValue.answer) {
                    return {...previousValue, extra_languages: currentValue.answer}
                }
                if (currentValue.question_key_name == 'group_min_size' && currentValue.answer) {
                    return {...previousValue, group_min_size: currentValue.answer}
                }
                if (currentValue.question_key_name == 'group_max_size' && currentValue.answer) {
                    return {...previousValue, group_max_size: currentValue.answer}
                }
                if (currentValue.question_key_name == 'cancellation_policy' && currentValue.answer) {
                    return {...previousValue, cancellation_policy: currentValue.answer}
                }
                return previousValue
            },
            {main_language: 'en'}
        )
    })

    const onSubmit = form.handleSubmit(data => {
        nextStepMutation?.mutate({
            ...data,
            extra_languages: data.extra_languages?.length == 0 ? undefined : data.extra_languages
        })
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_other_details_more_details:title')}</Title>
                    <Paragraph>{t('services:add_details_to_help_guests')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <FormProvider {...form}>
                    <Flexbox direction={'column'} gap={6}>
                        <Flexbox direction={'column'} gap={4} width="100%">
                            <Flexbox direction={'column'} gap={2}>
                                <FieldTitle>{t('services:step_other_details_more_details:question1_label')}</FieldTitle>
                                <p>{t('services:step_other_details_more_details:question1_help_text')}</p>

                                <FlagsAlertText
                                    flags={mainLanguageFlags}
                                    title={t('services:step_other_details_more_details:select_primary_language')}
                                />

                                <FlagsAlertText
                                    flags={extraLanguagesFlags}
                                    title={t('services:step_other_details_more_details:additional_languages')}
                                />
                            </Flexbox>

                            <MoreDetailsPrimaryLanguageSelect />

                            <MoreDetailsSecondaryLanguageSelect />
                        </Flexbox>
                        <Divider direction={'horizontal'} />
                        <Flexbox direction={'column'} gap={4} width="100%">
                            <Flexbox direction={'column'} gap={2} width="100%">
                                <FieldTitle>{t('services:step_other_details_more_details:question2_label')}</FieldTitle>
                                <FieldDescription>
                                    {t('services:step_other_details_more_details:question2_description')}
                                </FieldDescription>
                                <FlagsAlertText
                                    flags={groupMinSizeFlags}
                                    title={t('services:step_other_details_more_details:minimum_size')}
                                />
                                <FlagsAlertText
                                    flags={groupMaxSizeFlags}
                                    title={t('services:step_other_details_more_details:maximum_size')}
                                />
                            </Flexbox>
                            <StyledGroupSizeGrid>
                                <InputText
                                    type={'number'}
                                    inputMode={'numeric'}
                                    label={t('services:step_other_details_more_details:minimum_size')}
                                    errorMessage={form.formState.errors.group_min_size?.message || undefined}
                                    {...form.register('group_min_size')}
                                />
                                <InputText
                                    type={'number'}
                                    inputMode={'numeric'}
                                    label={t('services:step_other_details_more_details:maximum_size')}
                                    errorMessage={form.formState.errors.group_max_size?.message || undefined}
                                    {...form.register('group_max_size')}
                                />
                            </StyledGroupSizeGrid>
                        </Flexbox>
                        <Divider direction={'horizontal'} />
                        <Flexbox direction={'column'} gap={4}>
                            <Flexbox direction={'column'} gap={1.5}>
                                <FieldTitle>
                                    {t('services:step_other_details_more_details:question_3_title')}
                                </FieldTitle>

                                <FlagsAlertText
                                    flags={cancellationPolicyFlags}
                                    title={t('services:step_other_details_more_details:question_3_title')}
                                />
                            </Flexbox>
                            <Radio
                                label={t('services:step_other_details_more_details:7_days')}
                                value={'7_days'}
                                {...form.register('cancellation_policy')}
                            />
                            <Radio
                                label={t('services:step_other_details_more_details:24_hours')}
                                value={'24_hours'}
                                {...form.register('cancellation_policy')}
                            />
                            {form.formState.errors.cancellation_policy && (
                                <InputHelpText error={form.formState.errors.cancellation_policy.message} />
                            )}
                        </Flexbox>
                    </Flexbox>
                </FormProvider>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('more_details')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={nextStepMutation.isPending || prevStepMutation?.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'moreDetails'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{
                        ...form.getValues(),
                        extra_languages:
                            form.getValues().extra_languages?.length == 0 ? undefined : form.getValues().extra_languages
                    }}
                />
            )}
        </>
    )
}
