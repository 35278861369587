import {
    StyledAcceptStepBody,
    StyledAcceptStepDesc,
    StyledAcceptStepFooter,
    StyledAcceptStepHeader,
    StyledAcceptStepHeaderCloseButton
} from '@/features/service-acceptance-flow/components/style.ts'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {useMemo} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {CheckIcon, XCloseIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'

export const ServiceAcceptedStep = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const changeAcceptModalStep = useServiceAcceptStore(state => state.changeStep)
    const service = useServiceAcceptStore(state => state.service)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )

    const onsSubmit = () => {
        if (serviceType == 'experience_photo') {
            return changeAcceptModalStep('photo_preparation_1')
        }
        if (service?.can_see_sms_settings) {
            return changeAcceptModalStep('sms')
        }
        return changeAcceptModalStep('email')
    }

    return (
        <>
            <StyledAcceptStepHeader align={'center'} justify={'center'}>
                <StyledAcceptStepHeaderCloseButton
                    size="lg"
                    shape="square"
                    variant="ghost"
                    onClick={() => acceptServiceModalToggle()}
                >
                    <XCloseIcon />
                </StyledAcceptStepHeaderCloseButton>
                {t(AcceptanceStepsConfigByService['accepted'][serviceType].modal_title)}
            </StyledAcceptStepHeader>
            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />

            <StyledAcceptStepBody direction={'column'} gap={6}>
                <StyledAcceptStepDesc direction={'column'} gap={2}>
                    <IconContainer size={'lg'}>
                        <CheckIcon fill={palette.success['500']} />
                    </IconContainer>
                    <h5>{t(AcceptanceStepsConfigByService['accepted'][serviceType].title)}</h5>
                    <small>{t(AcceptanceStepsConfigByService['accepted'][serviceType].subtitle)}</small>
                </StyledAcceptStepDesc>
            </StyledAcceptStepBody>

            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />
            <StyledAcceptStepFooter>
                <Button fullWidth variant="primary" size="md" onClick={onsSubmit}>
                    {t(AcceptanceStepsConfigByService['accepted'][serviceType].primary_cta)}
                </Button>
            </StyledAcceptStepFooter>
        </>
    )
}

ServiceAcceptedStep.displayName = 'ServiceAcceptedStep'
