import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '../status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper'
import {StyledStatusDescriptionTitle} from '../status-description-title/style'
import {StatusDescriptionWrapper} from '../status-description-wrapper/StatusDescriptionWrapper'
import {StatusDescriptionParagraph} from '../status-description-paragraph/StatusDescriptionParagraph'

export const HomeNoPhotographerStatusDescription = () => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StyledStatusDescriptionTitle>
                {t('service_details:descriptions:home:no_photographer_title')}
            </StyledStatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:no_photographer_paragraph1')}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:no_photographer_paragraph2')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

HomeNoPhotographerStatusDescription.displayName = 'HomeNoPhotographerStatusDescription'
