import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {StyledAnswerContainer, StyledExperiencesHostVideoAnswerInput} from '@components/commons/answer-item/style.ts'

export interface ExperiencesHostVideoAnswerItemProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    className?: string
    label: ReactNode
    type: 'radio' | 'checkbox'
}
export const AnswerItem = forwardRef<HTMLInputElement, ExperiencesHostVideoAnswerItemProps>(
    ({className, label, ...rest}, ref) => {
        return (
            <StyledAnswerContainer $isReadonly={!!rest.readOnly} className={className}>
                {label}
                <StyledExperiencesHostVideoAnswerInput ref={ref} {...rest} />
            </StyledAnswerContainer>
        )
    }
)
