import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import Container from '@components/ui/container/Container.tsx'
import Alert from '@assets/images/inactive_ico.svg'
import {InactiveBox, InactiveWrapper} from './style.ts'
import {Helmet} from 'react-helmet'
import {ServiceSuspendedBanner} from '@components/commons/service-suspended-banner/ServiceSuspendedBanner.tsx'

export const CoAlarmInactive: FC = () => {
    const {t} = useTranslation()
    // const navigate = useNavigate()

    // useEffect(() => {
    //     if (coAlarmHostInfo && coAlarmHostInfo.active_listings !== 0) {
    //         navigate(routes.HOME.path)
    //     }
    // }, [coAlarmHostInfo])

    return (
        <Container>
            <Helmet title={String(t('coAlarm:inactive:page_title'))} />
            <InactiveWrapper>
                <InactiveBox direction={'column'} align={'center'}>
                    <img src={Alert} alt={'inactive'} width={64} />
                    <h4>{t('coAlarm:inactive:title')}</h4>
                    <p>{t('coAlarm:inactive:subtitle')}</p>
                    <a href={'https://www.airbnb.com/manage-your-space'} target={'_blank'}>
                        {t('coAlarm:inactive:cta')}
                    </a>
                    <ServiceSuspendedBanner />
                </InactiveBox>
            </InactiveWrapper>
        </Container>
    )
}

CoAlarmInactive.displayName = 'CoAlarmInactive'
