import Skeleton from '@components/ui/skeleton/Skeleton.tsx'
import {ServiceType} from '@/types/commons.ts'
import {DeclineStep, DeclineStepField} from '@/features/service-decline/types.ts'
import {TKey} from '@/translations/i18n.tsx'

export const ReasonsFormLoadingSkeleton = () => (
    <>
        <Skeleton width={160} height={20} />
        <Skeleton width={160} height={20} />
        <Skeleton width={160} height={20} />
        <Skeleton width={160} height={20} />
        <Skeleton width={160} height={20} />
    </>
)

export const DeclineStepsConfigByService = {
    warning: {
        home: {
            title: 'service_decline:warning:home:title',
            subtitle: 'service_decline:warning:home:subtitle',
            primary_cta: 'service_decline:warning:home:primary_cta',
            secondary_cta: 'commons:close',
            stats: true
        },
        matterport: {
            title: 'service_decline:warning:matterport:title',
            subtitle: 'service_decline:warning:matterport:subtitle',
            primary_cta: 'service_decline:warning:matterport:primary_cta',
            secondary_cta: 'commons:close',
            stats: false
        },
        experience_photo: {
            title: 'service_decline:warning:home:title',
            subtitle: 'service_decline:warning:home:subtitle',
            primary_cta: 'service_decline:warning:home:primary_cta',
            secondary_cta: 'commons:close',
            stats: true
        },
        experience_video: {
            title: 'service_decline:warning:home:title',
            subtitle: 'service_decline:warning:home:subtitle',
            primary_cta: 'service_decline:warning:home:primary_cta',
            secondary_cta: 'commons:close',
            stats: false
        },
        walkthrough: {
            title: 'service_decline:warning:walkthrough:title',
            subtitle: 'service_decline:warning:walkthrough:subtitle',
            primary_cta: 'service_decline:warning:walkthrough:primary_cta',
            secondary_cta: 'commons:close',
            stats: false
        }
    },
    final: {
        home: {
            title: 'service_decline:final:home:title',
            subtitle: 'service_decline:final:home:subtitle',
            reason: 'commons:reason',
            primary_cta: 'service_decline:final:home:primary_cta',
            secondary_cta: 'commons:close'
        },
        experience_photo: {
            title: 'service_decline:final:home:title',
            subtitle: 'service_decline:final:home:subtitle',
            reason: 'commons:reason',
            primary_cta: 'service_decline:final:home:primary_cta',
            secondary_cta: 'commons:close'
        },
        matterport: {
            title: 'service_decline:final:matterport:title',
            subtitle: 'service_decline:final:matterport:subtitle',
            reason: 'commons:reason',
            primary_cta: 'service_decline:final:matterport:primary_cta',
            secondary_cta: 'commons:close'
        },
        experience_video: {
            title: 'service_decline:final:home:title',
            subtitle: 'service_decline:final:home:subtitle',
            reason: 'commons:reason',
            primary_cta: 'service_decline:final:home:primary_cta',
            secondary_cta: 'commons:close'
        },
        walkthrough: {
            title: 'service_decline:final:walkthrough:title',
            subtitle: 'service_decline:final:walkthrough:subtitle',
            reason: 'commons:reason',
            primary_cta: 'service_decline:final:walkthrough:primary_cta',
            secondary_cta: 'commons:close'
        }
    }
} as const satisfies Record<
    DeclineStep,
    Record<ServiceType, Record<Exclude<DeclineStepField, 'stats'>, TKey> | Record<'stats', boolean>>
>
