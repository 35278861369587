import {useTranslation} from '@/translations/i18n.tsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoModalBody,
    ExperiencesHostVideoParagraph
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ProgressBar} from '@/features/experiences-host-video/components/progress-bar/ProgressBar.tsx'
import {FriendOnboardingModalStep} from '@/features/experiences-host-video/components/friend-onboarding-modal/FriendOnboardingModal.tsx'
import {
    StyledFriendRequirementsModalShootlistStep,
    StyledTitle
} from '@/features/experiences-host-video/components/friend-onboarding-modal-checklist-step/style.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useCompleteOnboarding} from '@/features/experiences-host-video/services/useCompleteOnboarding.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {Checklist} from '@/features/experiences-host-video/components/checklist/Checklist.tsx'
import {Question} from '@/features/experiences-host-video/types.ts'

interface FriendRequirementsModalShootListStepProps {
    answers: Question[]
    setStep: Dispatch<SetStateAction<FriendOnboardingModalStep>>
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const FriendOnboardingModalChecklistStep: FC<FriendRequirementsModalShootListStepProps> = ({
    answers,
    setStep,
    setSlidingModalState
}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const completeOnboardingMutation = useCompleteOnboarding({
        params: routeParams,
        mutationOptions: {
            onError: () => toast.error(t('errors:default')),
            onSuccess: () => handleCloseSlidingModal(setSlidingModalState)
        }
    })

    return (
        <StyledFriendRequirementsModalShootlistStep>
            <StyledTitle>{t('experiences_host_video:checklist')}</StyledTitle>
            <ExperiencesHostVideoModalBody>
                <ExperiencesHostVideoParagraph>
                    {t('experiences_host_video:friend_onboarding_modal:checklist_description')}
                </ExperiencesHostVideoParagraph>
                <Checklist answers={answers} />
            </ExperiencesHostVideoModalBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox width="100%" gap={1}>
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                    </Flexbox>
                    <Flexbox direction="column" gap={4.5} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Button variant="tertiary" onClick={() => setStep('perspective')}>
                                {t('commons:back')}
                            </Button>
                            <Button
                                variant="primary"
                                disabled={completeOnboardingMutation.isPending}
                                onClick={() => completeOnboardingMutation.mutate()}
                            >
                                {t('commons:done')}
                                {completeOnboardingMutation.isPending && <Spinner />}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </StyledFriendRequirementsModalShootlistStep>
    )
}
