import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18next from 'i18next'
import {AlertCircleIcon} from 'src/components/ui/icon'
import {NotFound} from '@pages/not-found/NofFound.tsx'
import {TermsOfService} from '@pages/terms-of-service/TermsOfService.tsx'
import {ProPhotographyLanding} from '@pages/public-landings/pro-photography-landing/ProPhotographyLanding.tsx'
import {SsoRedirect} from '@pages/sso-redirect/SsoRedirect.tsx'
import {SsoLogin} from '@pages/sso-login/SsoLogin.tsx'
import {CategoriesSsoLogin} from '@pages/categories-sso-login/CategoriesSsoLogin.tsx'
import {CoAlarmSsoLogin} from '@pages/co-alarm-sso-login/CoAlarmSsoLogin.tsx'
import {Home} from '@pages/home/Home.tsx'
import {Dashboard} from '@pages/dashboard/Dashboard.tsx'
import {ExperiencesHostVideoLogin} from '@pages/experiences-host-video-login/ExperiencesHostVideoLogin.tsx'
import {ExperiencesHostVideoRedirect} from '@pages/experiences-host-video-redirect/ExperiencesHostVideoRedirect.tsx'
import {ExperiencesHostVideoRoute} from '@pages/experiences-host-video/ExperiencesHostVideo.tsx'
import {ExperiencesHostVideoFriendRoute} from '@pages/experiences-host-video-friend/ExperiencesHostVideoFriend.tsx'
import {ServiceDetails} from '@pages/service-details/ServiceDetails.tsx'
import {HostApprovalRequest} from '@pages/host-approval-request/HostApprovalRequest.tsx'
import {PhotoshootRequests} from '@pages/photoshoot-requests/PhotoshootRequests.tsx'
import {Categories} from '@pages/categories/Categories.tsx'
import {CoAlarmDashboard} from '@pages/co-alarm/dashboard/CoAlarmDashboard.tsx'
import {CoAlarmSendRequest} from '@pages/co-alarm/send-request/CoAlarmSendRequest.tsx'
import {CoAlarmRequestDetails} from '@pages/co-alarm/request-details/CoAlarmRequestDetails.tsx'
import {CoAlarmInactive} from '@pages/co-alarm/inactive/CoAlarmInactive.tsx'
import {ServicesLogin} from '@pages/services-login/ServicesLogin.tsx'
import {Services} from '@pages/services/Services.tsx'
import {Service} from '@pages/service/Service.tsx'

export const routes = {
    PHOTOSHOOT_LANDING: {
        path: '/pro-photography',
        element: <ProPhotographyLanding />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:photoshoot_landing'
        }
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <SsoRedirect />
    },
    SSO_LOGIN: {
        path: '/login',
        element: <SsoLogin />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    CATEGORIES_SSO_LOGIN: {
        path: '/categories-login',
        element: <CategoriesSsoLogin />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    CO_ALARM_SSO_LOGIN: {
        path: '/co-alarm-login',
        element: <CoAlarmSsoLogin />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    HOME: {
        path: '/',
        element: <Home />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    DASHBOARD: {
        path: '/dashboard/:hostCode/:hostPassword',
        element: <Dashboard />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    SERVICES_LOGIN: {
        path: '/offerings-login',
        element: <ServicesLogin />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    SERVICES: {
        path: '/offerings',
        element: <Services />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    SERVICE: {
        path: '/offerings/:id',
        element: <Service />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    EXPERIENCE_HOST_VIDEO_LOGIN: {
        path: '/experiences-host-video-login/:airbnbId',
        element: <ExperiencesHostVideoLogin />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    EXPERIENCE_HOST_VIDEO_REDIRECT: {
        path: '/experiences-host-video/:airbnbId',
        element: <ExperiencesHostVideoRedirect />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    EXPERIENCE_HOST_VIDEO: {
        path: '/dashboard/:hostCode/:hostPassword/experiences-host-video/:experiencesHostVideoId/tasks/:taskId',
        element: <ExperiencesHostVideoRoute />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    EXPERIENCE_HOST_VIDEO_FRIEND: {
        path: '/dashboard/:hostCode/:hostPassword/experiences-host-video/:experiencesHostVideoId/tasks/:taskId/friend',
        element: <ExperiencesHostVideoFriendRoute />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    SERVICE_DETAILS: {
        path: '/hosts/:hostCode/:hostPassword/quotes/:quoteId',
        element: <ServiceDetails />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:timeline'
        }
    },
    APPROVAL_REQUEST_DETAILS: {
        path: '/hosts/:hostCode/:hostPassword/approval-request/:requestId',
        element: <HostApprovalRequest />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:timeline'
        }
    },
    PHOTOSHOOT_REQUEST: {
        path: '/photoshoot-requests',
        element: <PhotoshootRequests />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    CATEGORIES: {
        path: '/categories',
        element: <Categories />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    CO_ALARM_DASHBOARD: {
        path: '/co-alarm',
        element: <CoAlarmDashboard />
    },
    CO_ALARM_SEND_REQUEST: {
        path: '/co-alarm/send-request',
        element: <CoAlarmSendRequest />
    },
    CO_ALARM_REQUEST_DETAILS: {
        path: '/co-alarm/:listingId/request/:requestId',
        element: <CoAlarmRequestDetails />
    },
    CO_ALARM_INACTIVE: {
        path: '/co-alarm/inactive',
        element: <CoAlarmInactive />
    },
    TERMS_OF_SERVICE: {
        path: '/terms-of-service/:type',
        element: <TermsOfService />
    },
    NOT_FOUND: {
        path: '*',
        element: <NotFound />
    }
}
