import {useAsync} from '@/hooks/useAsync'
import {debounce} from '@/utilities/helpers'
import {ComboboxProvider, useComboboxStore} from '@ariakit/react'
import {useState} from 'react'
import {Coordinates} from '@/features/services/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Label} from '@/components/ui/label/Label'
import useAddressGeocoding from '@/hooks/useAddressGeocoding'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {MarkerPin01Icon, SearchLgIcon} from '@/components/ui/icon'
import {
    StyledInputAddressAutocompleteComboboxItem,
    StyledInputAddressSelectComboboxList,
    StyledInputAddressSelectComboboxPopover
} from './style'
import {useTheme} from 'styled-components'
import {useTranslation} from '@/translations/i18n'
import {ComboboxInput} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import Skeleton from '@/components/ui/skeleton/Skeleton'

type PredictionType = 'geocode' | 'establishment' | 'address' | `(regions)` | `(cities)`

interface InputAddressAutocompleteProps {
    label?: string
    onChange: (value: google.maps.GeocoderResult | null) => void
    onLatLngFound: (value: Coordinates | null) => void
    placeholder?: string
    country?: string
    province?: string
    errorMessage?: string
    predictionTypes: PredictionType[]
}

export const InputAddressAutocomplete = ({
    label,
    onChange,
    onLatLngFound,
    placeholder,
    errorMessage,
    predictionTypes
}: InputAddressAutocompleteProps) => {
    const {t} = useTranslation()
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[] | null>(null)
    const searchPlace = useAsync()
    const comboboxStore = useComboboxStore()
    const {palette} = useTheme()

    const searchPlaces = debounce(async (value: string) => {
        if (!value) {
            setPredictions(null)
            return
        }

        try {
            const autocomplete = new google.maps.places.AutocompleteService()
            const result = await searchPlace.run(
                autocomplete.getPlacePredictions({
                    input: value,
                    types: predictionTypes
                })
            )
            setPredictions(result?.predictions ?? null)
        } catch (err) {
            console.error(err)
        }
    }, 750)

    //Geocoder's logic and utils
    const {retrieveLatLng, retrieveStructured} = useAddressGeocoding({
        onLatLngFound,
        onStructuredAddressFound: value => {
            onChange(value)
            comboboxStore.setState('value', '')
        }
    })

    // On select value retrieve all the geocoding data
    const handleSelectAddress = async (placeId: string) => {
        if (!predictions) return
        const address = predictions.find(prediction => prediction.place_id == placeId)?.description
        if (address) {
            await retrieveStructured(placeId)
            await retrieveLatLng(address)
        }
    }

    return (
        <ComboboxProvider
            store={comboboxStore}
            setValue={searchPlaces}
            setSelectedValue={placeId => handleSelectAddress(placeId as string)}
            resetValueOnHide
            placement="bottom"
        >
            <Flexbox direction="column" gap={1.5} width="100%">
                {label && <Label>{label}</Label>}
                <ComboboxInput
                    typeIcon={<SearchLgIcon />}
                    hasCloseCombobox
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    inputSize="sm"
                />
                <StyledInputAddressSelectComboboxPopover sameWidth hidden={!comboboxStore.getState().value}>
                    <StyledInputAddressSelectComboboxList>
                        {searchPlace.isLoading ? (
                            <StyledInputAddressAutocompleteComboboxItem disabled>
                                <Flexbox direction="column" gap={1}>
                                    <Skeleton height={40} width="100%" />
                                    <Skeleton height={40} width="80%" />
                                </Flexbox>
                            </StyledInputAddressAutocompleteComboboxItem>
                        ) : searchPlace.isError ? (
                            <StyledInputAddressAutocompleteComboboxItem disabled>
                                <InputHelpText error={t('errors:default')} />
                            </StyledInputAddressAutocompleteComboboxItem>
                        ) : predictions && predictions?.length > 0 ? (
                            predictions.map(prediction => (
                                <StyledInputAddressAutocompleteComboboxItem
                                    key={prediction.place_id}
                                    value={prediction.place_id}
                                >
                                    <Flexbox gap={2} align="center">
                                        <MarkerPin01Icon color={palette.neutral['500']} size={20} />
                                        <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                            {prediction.description}
                                        </span>
                                    </Flexbox>
                                </StyledInputAddressAutocompleteComboboxItem>
                            ))
                        ) : (
                            <StyledInputAddressAutocompleteComboboxItem disabled>
                                <InputHelpText helpText={t('commons:no_results_found')} />
                            </StyledInputAddressAutocompleteComboboxItem>
                        )}
                    </StyledInputAddressSelectComboboxList>
                </StyledInputAddressSelectComboboxPopover>
                {!!errorMessage && <InputHelpText error={errorMessage} />}
            </Flexbox>
        </ComboboxProvider>
    )
}
