import styled, {css} from 'styled-components'

export const StyledGroupSizeGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 100%;
        gap: ${spacing * 4}px;
    `
)
