import {Outlet} from 'react-router-dom'
import {z} from 'zod'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {useTranslation} from '@/translations/i18n.tsx'
import {AlertTriangleIcon} from '@/components/ui/icon'

export const CoAlarmListingsURLParams = z.object({
    listingId: z.coerce.number().int(),
    requestId: z.coerce.number().int()
})
export type CoAlarmListingsURLParams = z.infer<typeof CoAlarmListingsURLParams>

export const CoAlarmListingsWrapper = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(CoAlarmListingsURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}
