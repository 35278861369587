import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import i18next from 'i18next'
import {Trans} from '@/translations/i18n.tsx'
import {StyledChatbotLink} from '@/features/chatbot/components/styles.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Mail01Icon, PhoneIcon} from '@components/ui/icon'
import {Assignment} from '@/features/service/types.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'

export const hp33 = ({freelancer}: {freelancer: Assignment['photographer'] | undefined}): ChatbotStep[] => {
    return [
        {
            id: 'HP33',
            options: [
                {
                    value: 'HP33.1',
                    label: i18next.t('chatbot:HP33:options:label_1'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.1',
                            source: 'User',
                            text: 'I want to reschedule'
                        })
                        return 'HP33.1.1'
                    }
                },
                {
                    value: 'HP33.2',
                    label: i18next.t('chatbot:HP33:options:label_2'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.2',
                            source: 'User',
                            text: 'I need to change my requested dates'
                        })
                        return 'HP33.2.1'
                    }
                },
                {
                    value: 'HP33.3',
                    label: i18next.t('chatbot:HP33:options:label_3'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.3',
                            source: 'User',
                            text: 'Do I need to be present during the walkthrough?'
                        })
                        return 'HP33.3.1'
                    }
                },
                {
                    value: 'HP33.4',
                    label: i18next.t('chatbot:HP33:options:label_4'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.4',
                            source: 'User',
                            text: 'How do I prepare for the walkthrough?'
                        })
                        return 'HP33.4.1'
                    }
                },
                {
                    value: 'HP33.5',
                    label: i18next.t('chatbot:HP33:options:label_5'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP33.5',
                            source: 'User',
                            text: 'How long will the walkthrough last?'
                        })
                        return 'HP33.5.1'
                    }
                }
            ]
        },
        {
            id: 'HP33.1.1',
            message: i18next.t('chatbot:HP33:HP33.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.1.1',
                    source: 'Chatbot',
                    text: 'Please let your home consultant know as soon as possible. If you cancel within 24 hours of the walkthrough, we reserve the right to charge you a $50 late cancellation fee to compensate the home consultant'
                })
                return 'HP33.1.2'
            }
        },
        {
            id: 'HP33.1.2',
            asMessage: true,
            component: (
                <Flexbox direction="column" gap={2}>
                    {freelancer?.phone && (
                        <Flexbox align="center" gap={2} className="contacts">
                            <PhoneIcon size={20} />
                            <a href={freelancer?.phone ? `tel:${freelancer?.phone}` : undefined}>{freelancer?.phone}</a>
                        </Flexbox>
                    )}
                    <Flexbox align="center" gap={2} className="contacts">
                        <Mail01Icon size={20} />
                        <a href={freelancer?.email ? `mailto:${freelancer?.email}` : undefined}>{freelancer?.email}</a>
                    </Flexbox>
                </Flexbox>
            ),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.1.2',
                    source: 'Chatbot',
                    text: 'Contact info'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP33.2.1',
            message: i18next.t('chatbot:HP33:HP33.2_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.2.1',
                    source: 'Chatbot',
                    text: 'Use the Host Action card to select new dates, or let your home consultant know as soon as possible. To select new dates, you need to unselect the old dates.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP33.3.1',
            message: i18next.t('chatbot:HP33:HP33.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.3.1',
                    source: 'Chatbot',
                    text: 'We highly recommend that you or a staff member are present during the walkthrough as the home consultant may have questions regarding the home or amenities and their functionality. If no one is present, you are responsible for ensuring the home consultant has appropriate access details to the home in order to conduct the walkthrough.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP33.4.1',
            message: i18next.t('chatbot:HP33:HP33.4_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.4.1',
                    source: 'Chatbot',
                    text: 'Your home consultant will go through a checklist, take pictures for review, and make sure your home is in overall good condition. Please note that the property should be vacant, tidy and guest-ready at the time of the walkthrough.'
                })
                return 'HP33.4.2'
            }
        },
        {
            id: 'HP33.4.2',
            component: (
                <Trans
                    i18nKey={'chatbot:HP33:HP33.4.1_message'}
                    components={[
                        <StyledChatbotLink
                            to={'https://www.airbnb.com/help/article/3307#section-heading-4-0'}
                            target="_blank"
                        />
                    ]}
                />
            ),
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.4.2',
                    source: 'Chatbot',
                    text: 'To best prepare your home, visit this article:'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP33.5.1',
            message: i18next.t('chatbot:HP33:HP33.5_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP33.5.1',
                    source: 'Chatbot',
                    text: 'Block up to 2 hours to ensure sufficient time for the home consultant to complete the walkthrough'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        }
    ]
}
