import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {FormProvider, useForm} from 'react-hook-form'
import {Button} from '@components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import TextArea from '@/components/commons/textarea/TextArea'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {FeedbackRadioGroup} from '../feedback-radio-group/FeedbackRadioGroup'
import {FeedbackValidationSchema, RadioInput} from '../../types.ts'
import {StyledContainer, StyledSubTitle, StyledTitle, StyledFooter, StyledQuestion, StyledModal} from './style'
import {zodResolver} from '@hookform/resolvers/zod'
import {useSendFeedback} from '@/features/feedback-form/components/queries/useSendFeedback.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import toast from 'react-hot-toast'
import {errorHandler} from '@utilities/helpers.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {useQueryClient} from '@tanstack/react-query'

const questions = [
    {name: 'recommendation', ratingLabel: 'likely', min: 0, max: 10},
    {name: 'delivery_time_satisfaction', ratingLabel: 'satisfied', min: 1, max: 5},
    {name: 'image_quality_satisfaction', ratingLabel: 'satisfied', min: 1, max: 5},
    {name: 'find_page_ease', ratingLabel: 'easy', min: 1, max: 5},
    {name: 'scheduling_ease', ratingLabel: 'easy', min: 1, max: 5}
] as const satisfies ReadonlyArray<{
    name: keyof RadioInput
    ratingLabel: 'likely' | 'satisfied' | 'easy'
    min: number
    max: number
}>

export const FeedbackModal: FC<{onCloseCb: () => void}> = ({onCloseCb}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const mutation = useSendFeedback(urlParams.hostCode, urlParams.hostPassword, urlParams.quoteId, {
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
            onCloseCb()
            toast.success(t('service_details:feedback:success_message'), {duration: 4500})
        },
        onError: errorHandler
    })
    const methods = useForm<FeedbackValidationSchema>({
        resolver: zodResolver(FeedbackValidationSchema)
    })

    const onSubmit = (values: FeedbackValidationSchema) => {
        mutation.mutate(values)
    }

    const {
        formState: {isValid, isSubmitting},
        register,
        handleSubmit
    } = methods

    return (
        <FormProvider {...methods}>
            <StyledModal closeIconOffset="sm" width={674} onClose={onCloseCb}>
                <ScrollArea scrollbar={<Scrollbar orientation="vertical" />}>
                    <StyledContainer direction="column" align="stretch" gap={6}>
                        <Flexbox direction="column" gap={2}>
                            <StyledTitle>{t('service_details:feedback:title')}</StyledTitle>
                            <StyledSubTitle>{t('service_details:feedback:subtitle')}</StyledSubTitle>
                        </Flexbox>

                        {questions.map(question => (
                            <Flexbox key={question.name} direction="column" align="stretch" gap={4}>
                                <StyledQuestion>
                                    {t(`service_details:feedback:questions:${question.name}`)}
                                </StyledQuestion>
                                <Flexbox direction="column" align="stretch" gap={2.5}>
                                    <FeedbackRadioGroup
                                        name={question.name}
                                        minValue={question.min}
                                        maxValue={question.max}
                                    />
                                    <Flexbox justify="space-between">
                                        <span className="rating-label">
                                            {t(`service_details:feedback:rating_label:${question.ratingLabel}:low`)}
                                        </span>
                                        <span className="rating-label">
                                            {t(`service_details:feedback:rating_label:${question.ratingLabel}:high`)}
                                        </span>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        ))}

                        <Flexbox direction="column" align="stretch" gap={4}>
                            <StyledQuestion>{t('service_details:feedback:open_question')}</StyledQuestion>
                            <TextArea
                                className="textarea"
                                rows={5}
                                placeholder={t('service_details:feedback:open_question_placeholder')}
                                {...register('notes')}
                            />
                        </Flexbox>
                    </StyledContainer>
                </ScrollArea>
                <StyledFooter justify="space-between" align="center">
                    <Button variant="tertiary" onClick={onCloseCb}>
                        {t('commons:close')}
                    </Button>
                    <Button
                        disabled={!isValid || isSubmitting || mutation.isPending}
                        onClick={handleSubmit(onSubmit)}
                        variant="primary"
                    >
                        {t('commons:submit')}
                    </Button>
                </StyledFooter>
            </StyledModal>
        </FormProvider>
    )
}
