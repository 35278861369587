import {Footer} from '@/features/services/components/footer/Footer.tsx'
import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldDescription,
    FieldTitle,
    Title,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {useTheme} from 'styled-components'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import {z} from 'zod'
import {useFieldArray, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Service} from '@/features/services/types.ts'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {PlusIcon, Trash01Icon} from '@components/ui/icon'
import {
    StyledInvisibleInputFile,
    StyledLinkField,
    StyledPhoneNumber,
    StyledInputText
} from '@/features/services/components/host-expertise-about-yourself-step/style.ts'
import {errorHandler, raise} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {useChangeProfilePhoto} from '@/features/services/services/useChangeProfilePhoto.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {useUpdateStepToHostExpertiseExpertise} from '@/features/services/services/useUpdateStepToHostExpertiseExpertise.ts'
import {useUpdateStepToLocation} from '@/features/services/services/useUpdateStepToLocation.ts'
import {PhoneModal} from '@/features/services/components/phone-modal/PhoneModal.tsx'
import {ConfirmSubmitModal} from '@/features/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

const ValidationSchema = z.object({
    links: z.array(z.object({value: z.string().url(i18n.t('errors:invalid_http_url'))})).min(1),
    hosting_modality: z.enum(['self', 'team', 'team_services']),
    co_hosts: z
        .string()
        .min(1, {message: i18n.t('errors:field_required')})
        .optional()
})
/*.superRefine((values, context) => {
        if (values.hosting_modality == 'team' && !values.co_hosts) {
            context.addIssue({
                code: 'custom',
                path: ['co_hosts'],
                message: i18n.t('errors:field_required')
            })
        }
    })*/
type ValidationSchema = z.infer<typeof ValidationSchema>

export const HostExpertiseAboutYourselfStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
    const user = useAuthStore(store => store.user)
    const invisibleInputRef = useRef<HTMLInputElement>(null)
    const [canScroll, setCanScroll] = useState(false)
    const question3MoreInfoRef = useRef<HTMLParagraphElement>(null)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const linksFlags = updatesRequiredSteps.aboutYourself.find(field => field.questionKeyName == 'links')?.flags
    const hostingModalityFlags = updatesRequiredSteps.aboutYourself.find(
        field => field.questionKeyName == 'hosting_modality'
    )?.flags
    const profilePhotoFlags = updatesRequiredSteps.aboutYourself.find(field => field.questionKeyName == 'profile_photo')
        ?.flags
    const flagsCount = updatesRequiredSteps.aboutYourself.flatMap(field => field.flags).length
    const updateStepToHostExpertiseExpertiseMutation = useUpdateStepToHostExpertiseExpertise<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToLocationMutation = useUpdateStepToLocation<false>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const changeProfilePhotoMutation = useChangeProfilePhoto({
        serviceId: service.id,
        hostId: user?.id ?? raise('userId is nullish'),
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'aboutYourself', updatesRequiredSteps}).prev?.()
            : updateStepToHostExpertiseExpertiseMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'aboutYourself'>({
                  service,
                  currentStep: 'aboutYourself',
                  updatesRequiredSteps
              }).next?.()
            : updateStepToLocationMutation
    const updateFreelancerProfilePhotoAsync = useAsync()

    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce(
            (previousValue, currentValue) => {
                if (
                    currentValue.question_key_name == 'links' &&
                    Array.isArray(currentValue.answer) &&
                    currentValue.answer.length >= 1
                ) {
                    return {...previousValue, links: currentValue.answer.map(value => ({value}))}
                }
                if (currentValue.question_key_name == 'hosting_modality' && currentValue.answer) {
                    return {...previousValue, hosting_modality: currentValue.answer}
                }
                if (currentValue.question_key_name == 'co_hosts' && currentValue.answer) {
                    return {...previousValue, co_hosts: currentValue.answer}
                }
                return previousValue
            },
            {
                links: [{value: ''}]
            }
        )
    })
    const fieldArray = useFieldArray({control: form.control, name: 'links'})
    const hostingModality = useWatch({control: form.control, name: 'hosting_modality'})

    const onSubmit = form.handleSubmit(formValues =>
        (service.host.profile_photo_url || user?.picture_url) && (service.host.airbnb_verified_phone || user?.phone)
            ? nextStepMutation?.mutate({
                  ...formValues,
                  links: formValues.links.map(link => link.value)
              })
            : undefined
    )

    const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? raise('file is nullish')
        if (file.size > 5_000_000) {
            toast.error(t('errors:upload_limit_exceed', {count: 5}))
        } else {
            const formData = new FormData()
            formData.append('image', file)
            formData.append('type', 'host-profile-photo')
            changeProfilePhotoMutation.mutate(formData)
        }
    }

    useEffect(() => {
        if (canScroll && question3MoreInfoRef.current && hostingModality == 'team_services') {
            question3MoreInfoRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [canScroll, question3MoreInfoRef.current, hostingModality])

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:about_you')}</Title>
                    <Paragraph>{t('services:step_host_expertise_about_yourself:paragraph')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6} align="stretch">
                    <Flexbox direction="column" gap={4}>
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:question1_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:question1_list_title')}
                            </FieldDescription>
                            <ul style={{marginLeft: theme.spacing * 4.5, listStyle: 'unset'}}>
                                <li>
                                    <FieldDescription>
                                        {t('services:step_host_expertise_about_yourself:question1_list1')}
                                    </FieldDescription>
                                </li>
                                <li>
                                    <FieldDescription>
                                        {t('services:step_host_expertise_about_yourself:question1_list2')}
                                    </FieldDescription>
                                </li>
                                <li>
                                    <FieldDescription>
                                        {t('services:step_host_expertise_about_yourself:question1_list3')}
                                    </FieldDescription>
                                </li>
                            </ul>

                            <FlagsAlertText
                                flags={profilePhotoFlags}
                                title={t('services:step_host_expertise_about_yourself:question1_title')}
                            />
                        </Flexbox>
                        <Flexbox align="center">
                            <Avatar
                                name={`${user?.first_name} ${user?.last_name}`}
                                imageUrl={service.host.profile_photo_url ?? user?.picture_url ?? ''}
                                size="xl"
                            />
                            <Button
                                variant="tertiary"
                                onClick={() => invisibleInputRef.current?.click()}
                                disabled={
                                    changeProfilePhotoMutation.isPending || updateFreelancerProfilePhotoAsync.isLoading
                                }
                            >
                                {user?.picture_url ? t('commons:edit') : t('commons:upload')}
                                {changeProfilePhotoMutation.isPending ||
                                    (updateFreelancerProfilePhotoAsync.isLoading && <Spinner />)}
                            </Button>
                        </Flexbox>
                        {form.formState.isSubmitted && !service.host.profile_photo_url && !user?.picture_url && (
                            <InputHelpText error={t('errors:field_required')} />
                        )}
                        <FieldDescription>
                            {t('services:step_host_expertise_about_yourself:upload_description_paragraph_1')}
                        </FieldDescription>
                        <FieldDescription>
                            {t('services:step_host_expertise_about_yourself:upload_description_paragraph_2')}
                        </FieldDescription>
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4}>
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:phone_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:phone_description')}
                            </FieldDescription>
                        </Flexbox>
                        {service.host.airbnb_verified_phone || user?.phone ? (
                            <Flexbox justify="space-between" align="center" gap={4} width="100%">
                                <StyledPhoneNumber>
                                    +{service.host.airbnb_verified_phone ?? user?.phone}
                                </StyledPhoneNumber>
                                <Button variant="tertiary" onClick={() => setIsPhoneModalOpen(true)}>
                                    {t('commons:edit')}
                                </Button>
                            </Flexbox>
                        ) : (
                            <Button variant="tertiary" onClick={() => setIsPhoneModalOpen(true)}>
                                <PlusIcon />
                                {t('services:step_host_expertise_about_yourself:phone_cta')}
                            </Button>
                        )}
                        {!service.host.airbnb_verified_phone && !!user?.phone && (
                            <AlertBanner title={t('services:step_host_expertise_about_yourself:phone_alert')} />
                        )}
                        {form.formState.isSubmitted && !service.host.airbnb_verified_phone && !user?.phone && (
                            <InputHelpText error={t('errors:field_required')} />
                        )}
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5} align="stretch">
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:question2_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:question2_description')}
                            </FieldDescription>

                            <FlagsAlertText
                                flags={linksFlags}
                                title={t('services:step_host_expertise_about_yourself:question2_title')}
                            />
                        </Flexbox>
                        {fieldArray.fields.map((field, index, array) => (
                            <StyledLinkField key={field.id}>
                                <StyledInputText
                                    inputSize="sm"
                                    type="text"
                                    label={t('services:step_host_expertise_about_yourself:question2_label')}
                                    placeholder={t('services:step_host_expertise_about_yourself:question2_placeholder')}
                                    {...form.register(`links.${index}.value`)}
                                />
                                <Button
                                    variant="ghost"
                                    shape="square"
                                    onClick={() => fieldArray.remove(index)}
                                    style={{visibility: array.length == 1 ? 'hidden' : 'visible', transition: 'none'}}
                                >
                                    <Trash01Icon />
                                </Button>
                                {form.formState.errors.links?.[index]?.value && (
                                    <InputHelpText error={form.formState.errors.links[index]?.value?.message} />
                                )}
                            </StyledLinkField>
                        ))}
                        {fieldArray.fields.length < 10 && (
                            <Button
                                variant="tertiary"
                                shape="text"
                                style={{width: 'max-content'}}
                                onClick={() => fieldArray.append({value: ''})}
                            >
                                <PlusIcon />
                                {t('services:step_host_expertise_about_yourself:question2_add')}
                            </Button>
                        )}
                        {form.formState.errors.links?.root && (
                            <InputHelpText error={form.formState.errors.links.root.message} />
                        )}
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:question3_title')}</FieldTitle>

                            <FlagsAlertText
                                flags={hostingModalityFlags}
                                title={t('services:step_host_expertise_about_yourself:question3_title')}
                            />
                        </Flexbox>
                        <Flexbox direction="column" align="stretch" gap={3}>
                            <AnswerItem
                                type="radio"
                                value="self"
                                label={t('services:step_host_expertise_about_yourself:question3_option1')}
                                {...form.register('hosting_modality', {
                                    onChange: () => form.setValue('co_hosts', undefined)
                                })}
                            />
                            <AnswerItem
                                type="radio"
                                value="team"
                                label={t('services:step_host_expertise_about_yourself:question3_option2')}
                                {...form.register('hosting_modality')}
                            />
                            <AnswerItem
                                type="radio"
                                value="team_services"
                                label={t('services:step_host_expertise_about_yourself:question3_option3')}
                                onClick={() => setCanScroll(true)}
                                {...form.register('hosting_modality')}
                            />

                            {hostingModality == 'team_services' && (
                                <FieldDescription ref={question3MoreInfoRef}>
                                    {t('services:step_host_expertise_about_yourself:question3_option_description')}
                                </FieldDescription>
                            )}

                            {form.formState.errors.hosting_modality && (
                                <InputHelpText error={t('services:select_option_error')} />
                            )}
                        </Flexbox>
                        {/*{modeWatch == 'team' && (
                            <TextArea
                                rows={6}
                                label={t('services:step_host_expertise_about_yourself:question3_textarea_label')}
                                placeholder={t(
                                    'services:step_host_expertise_about_yourself:question3_textarea_placeholder'
                                )}
                                errorMessage={form.formState.errors.co_hosts?.message}
                                {...form.register('co_hosts')}
                            />
                        )}*/}
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('about_yourself')}>
                <Button
                    variant="tertiary"
                    disabled={
                        nextStepMutation?.isPending ||
                        prevStepMutation?.isPending ||
                        changeProfilePhotoMutation.isPending
                    }
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button
                        disabled={
                            nextStepMutation.isPending ||
                            prevStepMutation?.isPending ||
                            changeProfilePhotoMutation.isPending
                        }
                        onClick={onSubmit}
                    >
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button
                        disabled={prevStepMutation?.isPending}
                        onClick={() =>
                            (service.host.profile_photo_url || user?.picture_url) &&
                            (service.host.airbnb_verified_phone || user?.phone)
                                ? setIsConfirmSubmitOpen(true)
                                : undefined
                        }
                    >
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            <StyledInvisibleInputFile
                disabled={changeProfilePhotoMutation.isPending || updateFreelancerProfilePhotoAsync.isLoading}
                type="file"
                ref={invisibleInputRef}
                accept="image/*"
                value=""
                onChange={uploadImage}
                multiple={false}
            />
            {isPhoneModalOpen && <PhoneModal serviceId={service.id} onClose={() => setIsPhoneModalOpen(false)} />}
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'aboutYourself'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{
                        ...form.getValues(),
                        links: form.getValues().links.map(link => link.value)
                    }}
                />
            )}
        </>
    )
}
