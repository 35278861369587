import styled, {css} from 'styled-components'

export const StyledProgressbar = styled.div<{$rounded?: boolean}>(
    ({theme: {palette}, $rounded}) => css`
        height: 8px;
        background-color: ${palette.neutral[200]};
        width: 100%;
        ${$rounded &&
        css`
            border-radius: 4px;
        `}
    `
)

export const StyledProgressbarIndicator = styled.div<{$rounded?: boolean}>(
    ({theme: {palette}, $rounded}) => css`
        height: 100%;
        background-color: ${palette.primary[600]};
        ${$rounded &&
        css`
            border-radius: 4px;
        `}
    `
)
