import {useTranslation} from '@/translations/i18n.tsx'
import {FC, useRef} from 'react'
import {
    handleCloseSlidingModal,
    SlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    StyledShortClipModalHeader,
    StyledShortClipExample,
    StyledShortClipExampleWrapper
} from '@/features/experiences-host-video/components/short-clip-modal/style.ts'
import {useVideoController} from '@/features/experiences-host-video/hooks/useVideoController.ts'
import {StyledLoadingVideoWrapper} from '@/features/experiences-host-video/components/video-trailer-controller/style.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'

interface ShortClipExampleButtonProps {
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
    src: string
    previewImgSrc?: string
}
//TODO remove the optional to previewImgSrc

export const ShortClipModal: FC<ShortClipExampleButtonProps> = ({
    slidingModalState,
    setSlidingModalState,
    src,
    previewImgSrc
}) => {
    const {t} = useTranslation()
    const videoRef = useRef<HTMLVideoElement>(null)

    const videoController = useVideoController(videoRef, {initialVideoState: 'playing', defaultMuted: true})

    return (
        <SlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledShortClipModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {t('experiences_host_video:clip_example')}
            </StyledShortClipModalHeader>
            <StyledShortClipExampleWrapper>
                <StyledShortClipExample
                    onEnded={() => handleCloseSlidingModal(setSlidingModalState)}
                    ref={videoRef}
                    poster={previewImgSrc}
                    disableRemotePlayback
                    disablePictureInPicture
                    controls={false}
                    preload={'metadata'}
                    playsInline
                    {...videoController}
                >
                    <source src={src} />
                </StyledShortClipExample>
                {videoController.videoState == 'loading' && (
                    <StyledLoadingVideoWrapper>
                        <Spinner size={30} />
                    </StyledLoadingVideoWrapper>
                )}
            </StyledShortClipExampleWrapper>
        </SlidingModal>
    )
}
