import {ServiceValueProps} from '@/features/in-progress-services/utils.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatsWidget} from '@components/commons/stats-widget/StatsWidget.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledMatterportValuesBody,
    StyledMatterportValuesIconWrapper,
    StyledMatterportValuesWrapper
} from '@/features/promotional-services/components/promo-matterport-section/styles.ts'

export const PromoMatterportSectionValues = () => {
    const {t} = useTranslation()

    return (
        <StyledMatterportValuesWrapper>
            <Flexbox justify={'space-between'} align={'center'}>
                <h4>{t('matterport_landing:stats:main_title')}</h4>
            </Flexbox>
            <StyledMatterportValuesBody>
                {Object.values(ServiceValueProps['matterport']).map((item, index) => (
                    <StatsWidget
                        key={index}
                        icon={
                            <StyledMatterportValuesIconWrapper align={'center'} justify={'center'}>
                                {item.icon}
                            </StyledMatterportValuesIconWrapper>
                        }
                        labelText={t(item.labelText)}
                        description={t(item.description)}
                    />
                ))}
            </StyledMatterportValuesBody>
        </StyledMatterportValuesWrapper>
    )
}

PromoMatterportSectionValues.displayName = 'PromoMatterportSectionValues'
