import styled, {css, CSSProperties} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'

export const StyledContent = styled(Container)<{$gap: number}>(
    ({theme: {palette, spacing}, $gap}) => css`
        padding-top: ${spacing * 4}px;
        padding-bottom: ${spacing * 2}px;
        color: ${palette.neutral[900]};
        display: flex;
        flex-direction: column;
        gap: ${$gap * spacing}px;
        max-width: 800px;
    `
)

export const StyledTitle = styled.h2(
    ({theme: {typography}}) => css`
        ${typography.displaySmFixed};
        font-weight: 500;
    `
)

export const StyledParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        & b {
            font-weight: 500;
        }
    `
)

export const StyledQuestionTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
        & a {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)

export const StyledQuestionDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
    `
)

export const StyledAlertText = styled.p(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        ${typography.textSm};
        color: ${palette.warning[700]};
    `
)

export const StyledAlertBanner = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 1.5}px;
        padding: ${spacing * 3}px;
        border-radius: 8px;
        background-color: ${palette.warning[100]};
        ${typography.textSm};
        & h3 {
            font-weight: 500;
        }
        & svg {
            width: 20px;
            height: 20px;
            fill: ${palette.warning[600]};
        }
    `
)

interface StyledButtonAreaProps {
    $padding?: number
    $borderRadius?: CSSProperties['borderRadius']
    $fullWidth?: boolean
}
export const StyledButtonArea = styled.div<StyledButtonAreaProps>(
    ({theme: {spacing, palette}, $padding = 0, $borderRadius = 0, $fullWidth = false}) => css`
        padding: ${spacing * $padding}px;
        cursor: pointer;
        border: 1px solid ${palette.neutral['300']};
        border-radius: ${typeof $borderRadius == 'number' ? `${$borderRadius}px` : $borderRadius};
        width: ${$fullWidth ? '100%' : 'auto'};
    `
)

export const StyledPreviewImage = styled.img<{$hasLowTransparency?: boolean}>(
    ({theme: {transition}, $hasLowTransparency}) => css`
        opacity: ${$hasLowTransparency ? 0.5 : 1};
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 8px;
        transition: opacity ${transition};
    `
)
