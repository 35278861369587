import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {CalendarCheck01Icon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const MatterportScheduledStatusDescription: FC<{
    assignmentDate: Assignment['to_shoot_at'] | undefined
}> = ({assignmentDate}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <CalendarCheck01Icon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:matterport:scheduled_title', {
                    date: assignmentDate ? formatLocaleDate(assignmentDate, 'LL') : '-',
                    hours: assignmentDate ? formatLocaleDate(assignmentDate, 'LT') : '-'
                })}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:matterport:scheduled_paragraph2')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

MatterportScheduledStatusDescription.displayName = 'MatterportScheduledStatusDescription'
