import {ServiceType} from '@/types/commons.ts'
import {
    CancellationReasonKey,
    CancellationReasonKeyWithPrefix,
    CancelStep,
    CancelStepField
} from '@/features/service-cancel/types.ts'

export const cancelStepsConfigByService = {
    warning: {
        home: {
            modal_title: 'service_cancel:warning:home:modal_title',
            title: 'service_cancel:warning:home:title',
            subtitle: 'service_cancel:warning:home:subtitle',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close',
            stats: true
        },
        experience_photo: {
            modal_title: 'service_cancel:warning:home:modal_title',
            title: 'service_cancel:warning:home:title',
            subtitle: 'service_cancel:warning:home:subtitle',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close',
            stats: true
        },
        matterport: {
            modal_title: 'service_cancel:warning:matterport:modal_title',
            title: 'service_cancel:warning:matterport:title',
            subtitle: 'service_cancel:warning:matterport:subtitle',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close',
            stats: false
        },
        experience_video: {
            modal_title: 'service_cancel:warning:experience_video:modal_title',
            title: 'service_cancel:warning:experience_video:title',
            subtitle: 'service_cancel:warning:experience_video:subtitle',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close',
            stats: false
        },
        walkthrough: {
            modal_title: 'service_cancel:warning:walkthrough:modal_title',
            title: 'service_cancel:warning:walkthrough:title',
            subtitle: 'service_cancel:warning:walkthrough:subtitle',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close',
            stats: false
        }
    },
    final: {
        home: {
            modal_title: 'service_cancel:final:modal_title',
            title: 'service_cancel:final:title',
            subtitle: 'service_cancel:final:subtitle',
            reason: 'commons:reason',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close'
        },
        experience_photo: {
            modal_title: 'service_cancel:final:modal_title',
            title: 'service_cancel:final:title',
            subtitle: 'service_cancel:final:subtitle',
            reason: 'commons:reason',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close'
        },
        matterport: {
            modal_title: 'service_cancel:final:modal_title',
            title: 'service_cancel:final:title',
            subtitle: 'service_cancel:final:subtitle',
            reason: 'commons:reason',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close'
        },
        experience_video: {
            modal_title: 'service_cancel:final:modal_title',
            title: 'service_cancel:final:title',
            subtitle: 'service_cancel:final:subtitle',
            reason: 'commons:reason',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close'
        },
        walkthrough: {
            modal_title: 'service_cancel:final:modal_title',
            title: 'service_cancel:final:title',
            subtitle: 'service_cancel:final:subtitle',
            reason: 'commons:reason',
            primary_cta: 'commons:cancel',
            secondary_cta: 'commons:close'
        }
    }
} as const satisfies Record<CancelStep, Record<ServiceType, Partial<Record<CancelStepField, string | boolean>>>>

export const serviceTypeToCancellationReason = {
    experience_video: {
        accidental_sign_up: 'service_cancel:reasons:home:accidental_sign_up',
        changed_decision: 'service_cancel:reasons:home:changed_decision',
        discomfort_with_assigned_provider: 'service_cancel:reasons:experience_video:discomfort_with_assigned_provider',
        excessive_cost: 'service_cancel:reasons:home:excessive_cost',
        found_alternative_provider: 'service_cancel:reasons:experience_video:found_alternative_provider',
        listing_not_prepared: 'service_cancel:reasons:home:listing_not_prepared',
        lost_interest: 'service_cancel:reasons:home:lost_interest',
        unresponsive_provider: 'service_cancel:reasons:experience_video:unresponsive_provider',
        other: 'service_cancel:reasons:home:other'
    },
    experience_photo: {
        accidental_sign_up: 'service_cancel:reasons:home:accidental_sign_up',
        changed_decision: 'service_cancel:reasons:home:changed_decision',
        discomfort_with_assigned_provider: 'service_cancel:reasons:home:discomfort_with_assigned_provider',
        excessive_cost: 'service_cancel:reasons:home:excessive_cost',
        found_alternative_provider: 'service_cancel:reasons:home:found_alternative_provider',
        listing_not_prepared: 'service_cancel:reasons:home:listing_not_prepared',
        lost_interest: 'service_cancel:reasons:home:lost_interest',
        unresponsive_provider: 'service_cancel:reasons:home:unresponsive_provider',
        other: 'service_cancel:reasons:home:other'
    },
    home: {
        accidental_sign_up: 'service_cancel:reasons:home:accidental_sign_up',
        changed_decision: 'service_cancel:reasons:home:changed_decision',
        discomfort_with_assigned_provider: 'service_cancel:reasons:home:discomfort_with_assigned_provider',
        excessive_cost: 'service_cancel:reasons:home:excessive_cost',
        found_alternative_provider: 'service_cancel:reasons:home:found_alternative_provider',
        listing_not_prepared: 'service_cancel:reasons:home:listing_not_prepared',
        lost_interest: 'service_cancel:reasons:home:lost_interest',
        unresponsive_provider: 'service_cancel:reasons:home:unresponsive_provider',
        other: 'service_cancel:reasons:home:other'
    },
    matterport: {
        accidental_sign_up: 'service_cancel:reasons:home:accidental_sign_up',
        changed_decision: 'service_cancel:reasons:home:changed_decision',
        discomfort_with_assigned_provider: 'service_cancel:reasons:matterport:discomfort_with_assigned_provider',
        excessive_cost: 'service_cancel:reasons:home:excessive_cost',
        found_alternative_provider: 'service_cancel:reasons:matterport:found_alternative_provider',
        listing_not_prepared: 'service_cancel:reasons:home:listing_not_prepared',
        lost_interest: 'service_cancel:reasons:home:lost_interest',
        unresponsive_provider: 'service_cancel:reasons:matterport:unresponsive_provider',
        other: 'service_cancel:reasons:home:other'
    },
    walkthrough: {
        accidental_sign_up: 'service_cancel:reasons:home:accidental_sign_up',
        changed_decision: 'service_cancel:reasons:home:changed_decision',
        discomfort_with_assigned_provider: 'service_cancel:reasons:walkthrough:discomfort_with_assigned_provider',
        listing_not_prepared: 'service_cancel:reasons:home:listing_not_prepared',
        lost_interest: 'service_cancel:reasons:home:lost_interest',
        unresponsive_provider: 'service_cancel:reasons:walkthrough:unresponsive_provider',
        other: 'service_cancel:reasons:home:other',
        excessive_cost: '',
        found_alternative_provider: ''
    }
    /*TODO: improve type*/
} as const satisfies Record<ServiceType, Record<CancellationReasonKey, string>>

export const removeCancellationReasonPrefix = (reason: CancellationReasonKeyWithPrefix): CancellationReasonKey =>
    reason.replace('reasons.', '') as CancellationReasonKey
