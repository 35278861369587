import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {StyledFooterNotes} from '@/features/service/components/footer-notes/style.ts'

interface FooterNotesProps {
    showSup2?: boolean
}

export const FooterNotes = ({showSup2 = false}: FooterNotesProps) => {
    const {t} = useTranslation()

    return (
        <StyledFooterNotes gap={2} direction="column">
            <p>
                <Trans i18nKey="service_details:footer_notes:paragraph1" components={{sup: <sup />}} />
            </p>
            {showSup2 && (
                <p>
                    <Trans i18nKey="service_details:footer_notes:paragraph1_sup2" components={{sup: <sup />}} />
                </p>
            )}
            <p>{t('service_details:footer_notes:paragraph2')}</p>
        </StyledFooterNotes>
    )
}

FooterNotes.diplayName = 'FooterNotes'
