import {debounce} from '@utilities/helpers.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {InProgressServicesSearchParams} from '@/features/in-progress-services/services/inProgress.http.ts'
import {useInProgressServices} from '@/features/in-progress-services/services/queries/useInProgressServices.ts'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'

export const useInProgressServicesTable = (params: DashboardURLParams) => {
    const {searchParams, setSearchParams} = useParsedSearchParams(InProgressServicesSearchParams)
    const inProgressQuery = useInProgressServices({...params, ...searchParams})

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: InProgressServicesSearchParams['orderBy']
        orderDirection: InProgressServicesSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...inProgressQuery,
        search,
        searchValue: searchParams.search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
