import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {AlertTriangleIcon} from 'src/components/ui/icon'

export const TermsOfServiceURLParams = z.object({
    type: z.enum(['photo-video', 'walkthrough'])
})
export type TermsOfServiceURLParams = z.infer<typeof TermsOfServiceURLParams>

const TermsRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(TermsOfServiceURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

export default TermsRoute
