import {getStepProgressPercentage} from '@/features/services/utils.tsx'
import {Footer} from '@/features/services/components/footer/Footer.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useSubmitService} from '@/features/services/services/useSubmitService.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToOtherDetailsMoreDetails} from '@/features/services/services/useUpdateStepToOtherDetailsMoreDetails.ts'
import {Service} from '@/features/services/types.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const OtherDetailsTermsStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const updateStepToOtherDetailsMoreDetails = useUpdateStepToOtherDetailsMoreDetails({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const submitService = useSubmitService({
        serviceId: service.id,
        options: {onError: errorHandler}
    })

    const [isGeneralTermsApproved, setIsGeneralTermsApproved] = useState(false)
    const [isAdditionalTermsApproved, setIsAdditionalTermsApproved] = useState(false)

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_other_details_terms:title')}</Title>
                    <Paragraph>
                        <Trans
                            i18nKey={'services:step_other_details_terms:description'}
                            components={[
                                <a
                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.airbnb.com/help/article/2855"
                                />
                            ]}
                        />
                    </Paragraph>
                </HeadingGroup>
                <Flexbox direction={'column'} gap={6}>
                    <Flexbox direction={'column'} gap={4}>
                        <FieldTitle>{t('services:step_other_details_terms:general_attestation')}</FieldTitle>
                        <Checkbox
                            id={'policy-1'}
                            checked={isGeneralTermsApproved}
                            onChange={event => setIsGeneralTermsApproved(event.currentTarget.checked)}
                            label={
                                <Flexbox direction={'column'} gap={4}>
                                    <p>{t('services:step_other_details_terms:policy_1')}</p>
                                    <p>{t('services:step_other_details_terms:policy_1_paragraph_1')}</p>
                                    <p>{t('services:step_other_details_terms:policy_1_paragraph_2')}</p>
                                    <p>{t('services:step_other_details_terms:policy_1_paragraph_3')}</p>
                                    <p>{t('services:step_other_details_terms:policy_1_paragraph_4')}</p>
                                </Flexbox>
                            }
                        />
                    </Flexbox>

                    <Divider direction={'horizontal'} />

                    <Flexbox direction={'column'} gap={4}>
                        <FieldTitle>{t('services:step_other_details_terms:additional_terms')}</FieldTitle>
                        <Checkbox
                            id={'policy-2'}
                            checked={isAdditionalTermsApproved}
                            onChange={event => setIsAdditionalTermsApproved(event.currentTarget.checked)}
                            label={
                                <Trans
                                    i18nKey={'services:step_other_details_terms:policy_2'}
                                    components={[
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2870"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/1451"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2278"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2908#13"
                                        />
                                    ]}
                                />
                            }
                        />
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('terms')}>
                <Button
                    variant="tertiary"
                    disabled={updateStepToOtherDetailsMoreDetails.isPending}
                    onClick={() => updateStepToOtherDetailsMoreDetails.mutate(undefined)}
                >
                    {t('commons:back')}
                    {updateStepToOtherDetailsMoreDetails.isPending && <Spinner />}
                </Button>
                <Button
                    disabled={submitService.isPending || !isGeneralTermsApproved || !isAdditionalTermsApproved}
                    onClick={() =>
                        submitService.mutate({
                            general_attestation: isGeneralTermsApproved,
                            is_terms_approved: isAdditionalTermsApproved
                        })
                    }
                >
                    {t('services:submit_for_review')}
                    {submitService.isPending && <Spinner />}
                </Button>
            </Footer>
        </>
    )
}
