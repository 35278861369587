import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import InputText from '@/components/commons/input-text/InputText'
import {SlidingModal} from '@/components/commons/sliding-modal/SlidingModal'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledOfferingSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto 1fr auto;
`

export const StyledOfferingFormModalHeader = styled(ModalHeader)(
    ({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
        padding: ${2.5 * spacing}px ${2 * spacing}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)

export const StyledOfferingModalHeaderTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledOfferingForm = styled.form`
    width: 100%;
`

export const StyledOfferingFormContent = styled(Flexbox)`
    & > div {
        width: 100%;
    }
`

export const StyledInputPrice = styled(InputText)(
    ({theme: {spacing}}) => css`
        width: 100%;
        & div {
            gap: ${2.5 * spacing}px;
        }
    `
)

export const StyledAnswerItem = styled(AnswerItem)(
    ({theme: {palette, typography}}) => css`
        display: flex;
        flex-direction: column;

        & span {
            font-weight: 400;
            color: ${palette.neutral[600]};
            ${typography.textSm}
        }
    `
)
