import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import dayjs from '@utilities/dayjs.ts'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const MatterportAssignedUnresponsiveStatusDescription: FC<{
    assignedFreelancer: string | undefined
    hostUnresponsiveAt?: Assignment['host_unresponsive_at']
}> = ({assignedFreelancer, hostUnresponsiveAt}) => {
    const {t} = useTranslation()
    const daysCount = dayjs(hostUnresponsiveAt).add(15, 'days').diff(dayjs(), 'days')

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <AlertCircleIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:matterport:assigned_unresponsive_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:matterport:assigned_unresponsive_paragraph', {
                        daysCount: daysCount < 1 ? 1 : daysCount,
                        freelancer: assignedFreelancer
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

MatterportAssignedUnresponsiveStatusDescription.displayName = 'MatterportAssignedUnresponsiveStatusDescription'
