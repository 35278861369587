import styled, {css} from 'styled-components'
import {Content} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'

export const StyledContent = styled(Content)(
    ({theme: {palette}}) => css`
        justify-content: center;
        align-items: center;
        text-align: center;
        & > span {
            height: 56px;
            width: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: ${palette.blue[50]};
            color: ${palette.blue[600]};
            & svg {
                width: 42px;
                height: 42px;
            }
        }
    `
)
