import Container from '@components/ui/container/Container.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {RedirectBox, RedirectWrapper} from '@pages/sso-redirect/style.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useTheme} from 'styled-components'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {validateSSOUser} from '@/features/sso/services/ssoAuthActions.ts'
import {routes} from '@utilities/constants'

export const SsoRedirect = () => {
    //here we will perform all the logic needed after sso response
    const {palette} = useTheme()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const SSOCode = searchParams.get('code')
    const redirectUrl = localStorage.getItem('elevate-login-redirect-url')

    const performSSORedirect = async () => {
        try {
            await validateSSOUser(SSOCode)
            navigate(redirectUrl ?? routes.PHOTOSHOOT_REQUEST.path, {replace: true})
            localStorage.removeItem('elevate-login-redirect-url')
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        void performSSORedirect()
    }, [])

    return (
        <Container>
            <RedirectWrapper>
                <RedirectBox>
                    <Spinner size={48} color={palette.neutral['500']} />
                    <h3>{t('redirect:title')}</h3>
                    <p>{t('redirect:subtitle')}</p>
                </RedirectBox>
            </RedirectWrapper>
        </Container>
    )
}

SsoRedirect.displayName = 'SsoRedirect'
