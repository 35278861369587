import styled, {css} from 'styled-components'

export const StyledStep = styled.div(
    ({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        width: 100%;
        padding: ${spacing * 4}px 0;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
