import {
    ExperiencesHostVideoModalBody,
    ExperiencesHostVideoPageLayout
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoHeader} from '@/features/experiences-host-video/components/experiences-host-video-header/ExperiencesHostVideoHeader.tsx'
import {ExperiencesHostVideo, ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {FC, useEffect} from 'react'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {IntroStep} from '@/features/experiences-host-video/components/intro-step/IntroStep.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {PreparationIntroStep} from '@/features/experiences-host-video/components/preparation-intro-step/PreparationIntroStep'
import {UploadClipsIntro} from '@/features/experiences-host-video/components/upload-clips-intro/UploadClipsIntro.tsx'
import {UploadClips} from '@/features/experiences-host-video/components/upload-clips/UploadClips.tsx'
import {PreparationRequirePreppingSpaceStep} from '@/features/experiences-host-video/components/preparation-require-prepping-space-step/PreparationRequirePreppingSpaceStep'
import {PreparationExperienceUniqueStep} from '@/features/experiences-host-video/components/preparation-interactions-step/PreparationExperienceUniqueStep'
import {PreparationYouUniqueStep} from '@/features/experiences-host-video/components/preparation-you-unique-step/PreparationYouUniqueStep'
import {PreparationHighlightsStep} from '@/features/experiences-host-video/components/preparation-highlights-step/PreparationHighlightsStep'
import {PreparationTakeAnythingHomeStep} from '@/features/experiences-host-video/components/preparation-take-anything-home-step/PreparationTakeAnythingHomeStep'
import {ReadyToFilmIntroStep} from '@/features/experiences-host-video/components/ready-to-film-intro-step/ReadyToFilmIntroStep.tsx'
import {ReadyToFilmSpecsStep} from '@/features/experiences-host-video/components/ready-to-film-specs-step/ReadyToFilmSpecsStep.tsx'
import {ReadyToFilmPerspectiveStep} from '@/features/experiences-host-video/components/ready-to-film-perspective-step/ReadyToFilmPerspectiveStep.tsx'
import {ReadyToFilmGetHelpStep} from '@/features/experiences-host-video/components/ready-to-film-get-help-step/ReadyToFilmGetHelpStep.tsx'
import {PreparationGetReadyToShootStep} from '@/features/experiences-host-video/components/preparation-get-ready-to-shoot-step/PreparationGetReadyToShootStep'
import {ReadyToFilmScheduleStep} from '@/features/experiences-host-video/components/ready-to-film-schedule-step/ReadyToFilmScheduleStep.tsx'
import {VoiceoverLanguage} from '@/features/experiences-host-video/components/voiceover-language/VoiceoverLanguage.tsx'
import {VoiceoverGuidelines} from '@/features/experiences-host-video/components/voiceover-guidelines/VoiceoverGuidelines.tsx'
import {VoiceoverNameQuickIntro} from '@/features/experiences-host-video/components/voiceover-name-quick-intro/VoiceoverNameQuickIntro.tsx'
import {UniqueQuestsCanDo} from '@/features/experiences-host-video/components/unique-guests-can-do/UniqueQuestsCanDo.tsx'
import {RecordVoiceoverHighlights} from '@/features/experiences-host-video/components/record-voiceover-highlights/RecordVoiceoverHighlights.tsx'
import {CompletedStep} from '@/features/experiences-host-video/components/completed-step/CompletedStep.tsx'

export const ExperiencesHostVideoRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(ExperiencesHostVideoRouteParams)

    if (!urlParams.success) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader hasBackButton />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:invalid_url')} />
            </ExperiencesHostVideoPageLayout>
        )
    }

    return <ExperiencesHostVideoContent urlParams={urlParams.data} />
}

const ExperiencesHostVideoContent: FC<{urlParams: ExperiencesHostVideoRouteParams}> = ({urlParams}) => {
    const {t} = useTranslation()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, [showExperiencesHostVideoQuery.data?.status])

    if (showExperiencesHostVideoQuery.isPending) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader hasBackButton />
                <ExperiencesHostVideoModalBody justify="center">
                    <Spinner size={32} />
                </ExperiencesHostVideoModalBody>
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (showExperiencesHostVideoQuery.isError) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader hasBackButton />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            </ExperiencesHostVideoPageLayout>
        )
    }

    const getStepRender = (
        status: ExperiencesHostVideo['status']['key_name'],
        step: ExperiencesHostVideo['status']['step_key_name']
    ) => {
        switch (status) {
            case 'intro':
                return <IntroStep />
            case 'preparation':
                switch (step) {
                    case 'intro':
                        return <PreparationIntroStep />
                    case 'require_prepping_space':
                        return <PreparationRequirePreppingSpaceStep />
                    case 'you_unique':
                        return <PreparationYouUniqueStep />
                    case 'experience_unique':
                        return <PreparationExperienceUniqueStep />
                    case 'highlights':
                        return <PreparationHighlightsStep />
                    case 'take_anything_home':
                        return <PreparationTakeAnythingHomeStep />
                    case 'get_ready_to_shoot':
                        return <PreparationGetReadyToShootStep />
                    default:
                        throw new Error(`Status ${status} combined to step ${step} not found`)
                }
            case 'ready_to_film':
                switch (step) {
                    case 'intro':
                        return <ReadyToFilmIntroStep />
                    case 'specs':
                        return <ReadyToFilmSpecsStep />
                    case 'perspective':
                        return <ReadyToFilmPerspectiveStep />
                    case 'get_help':
                        return <ReadyToFilmGetHelpStep />
                    case 'schedule':
                        return <ReadyToFilmScheduleStep />
                    default:
                        throw new Error(`Status ${status} combined to step ${step} not found`)
                }
            case 'upload':
                switch (step) {
                    case 'intro':
                        return <UploadClipsIntro />
                    case 'video_upload':
                        return <UploadClips />
                    case 'voiceover_language':
                        return (
                            <VoiceoverLanguage
                                defaultLanguage={showExperiencesHostVideoQuery.data.voice_over_language}
                            />
                        )
                    case 'voiceover_guidelines':
                        return <VoiceoverGuidelines />
                    case 'name_quick_intro':
                        return <VoiceoverNameQuickIntro />
                    case 'unique_guests_can_do':
                        return <UniqueQuestsCanDo />
                    case 'record_voiceover_highlights':
                        return <RecordVoiceoverHighlights />
                    default:
                        throw new Error(`Status ${status} combined to step ${step} not found`)
                }
            case 'completed':
                return <CompletedStep />
            default:
                throw new Error(`Status ${status} combined to step ${step} not found`)
        }
    }

    return (
        <ExperiencesHostVideoPageLayout>
            <ExperiencesHostVideoHeader hasBackButton />
            {getStepRender(
                showExperiencesHostVideoQuery.data.status.key_name,
                showExperiencesHostVideoQuery.data.status.step_key_name
            )}
        </ExperiencesHostVideoPageLayout>
    )
}
