import {z} from 'zod'
import {Countries} from 'country-to-currency'

export const ParentFlag = z.object({
    key_name: z.enum([
        'alcohol_without_license',
        'alcohol_smoking',
        'animal_welfare_violations',
        'black_white',
        'blurry',
        'blurry_profile_picture',
        'cannot_be_cropped_vertically',
        'collages',
        'duplicate',
        'expertise_unrelated',
        'heavy_filters_applied',
        'hosts_experience_unclear',
        'inappropriate_content',
        'insufficient_formal_experience',
        'irrelevant_lacking_context',
        'lack_photo_variety',
        'misleading_inaccurate',
        'missing_career_achievements',
        'multiple_countries_selected',
        'not_enough_online_presence',
        'not_enough_experience_years',
        'passport_or_logo',
        'photo_angle_awkward',
        'policy_violation',
        'poor_lighting_background',
        'reputation_proof_insufficient',
        'selfie_style',
        'service_description_unclear',
        'spelling_grammatical_errors',
        'stock_photos_or_screenshots',
        'stretched_distorted',
        'text_graphics_overlaid',
        'title_needs_update',
        'too_dark'
    ])
})
export type ParentFlag = z.infer<typeof ParentFlag>

export const UploadedMedia = z.object({
    id: z.number(),
    parent_flags: z.array(ParentFlag),
    url: z.string().url()
})
export type UploadedMedia = z.infer<typeof UploadedMedia>

export const GalleryItem = z.object({
    id: z.number().positive().int(),
    progressValue: z.number().optional(),
    blob: z.instanceof(File).optional(),
    src: z.string().url().optional(),
    presignedUrl: z.string().optional(),
    status: z.enum(['pending', 'success', 'error', 'idle']).default('idle'),
    flags: z.array(ParentFlag).optional()
})
export type GalleryItem = z.infer<typeof GalleryItem>

export const OfferingTypes = z.enum(['standard', 'basic', 'luxe', 'exclusive', 'other'])
export type OfferingTypes = z.infer<typeof OfferingTypes>

export const OfferingQuestionKeyName = z.enum([
    'currency',
    'description',
    'duration',
    'price_per_guest',
    'title',
    'main_photo',
    'price_per_group',
    'meeting_point'
])
export type OfferingQuestionKeyName = z.infer<typeof OfferingQuestionKeyName>

export const Offering = z.object({
    id: z.number().positive(),
    main_photo_url: z.string().url().nullable(),
    type: OfferingTypes,
    meeting_point: z.array(z.enum(['they_travel_to_you', 'you_travel_to_them'])).nullable(),
    question_answer: z
        .object({
            id: z.number().positive(),
            answer: z.string(),
            parent_flags: z.array(ParentFlag),
            question_key_name: OfferingQuestionKeyName,
            gallery_image: z
                .object({
                    id: GalleryItem.shape['id'],
                    url: z.string().url()
                })
                .nullable()
        })
        .array()
})
export type Offering = z.infer<typeof Offering>

export const Service = z.object({
    address: z.string().nullable(),
    country: z
        .custom<Countries>(value => {
            return typeof value === 'string'
        })
        .nullable(),
    city: z.string().nullable(),
    submission_cities: z
        .object({
            city: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                country: z.custom<Countries>(value => {
                    return typeof value === 'string'
                }),
                country_iso: z.string(),
                state: z.string().nullable()
            })
        })
        .array(),
    created_at: z.string().min(1),
    geo: z
        .object({
            country: z.custom<Countries>(value => {
                return typeof value === 'string'
            })
        })
        .nullable(),
    zip_code: z.string().nullable(),
    state: z.string().nullable(),
    latitude: z.string().nullable(),
    longitude: z.string().nullable(),
    category: z
        .object({
            key_name: z.enum([
                'photography',
                'private_chef',
                'prepared_meals',
                'catering',
                'personal_training',
                'massage',
                'spa_treatments',
                'haircare',
                'makeup',
                'nails',
                'generic' //TODO remove 'generic' when the category step is enabled again
            ])
        })
        .nullable(),
    expertise: z.object({
        id: z.number().positive(),
        question_answer: z.array(
            z
                .object({
                    parent_flags: z.array(ParentFlag)
                })
                .and(
                    z
                        .object({
                            answer: z.string(),
                            question_key_name: z.enum([
                                'experience_years',
                                'summary',
                                'highlights',
                                'hosting_modality',
                                'co_hosts',
                                'inspiration',
                                'background',
                                'accolades',
                                'category',
                                'career',
                                'expertise',
                                'background',
                                'about_yourself',
                                'location',
                                'location_notes',
                                'age_limit',
                                'profile_photo',
                                'age',
                                'bring_anything',
                                'what_bring',
                                'language',
                                'main_language',
                                'extra_languages',
                                'group_min_size',
                                'group_max_size',
                                'main_language',
                                'group_min_size',
                                'group_max_size'
                            ])
                        })
                        .or(
                            z.object({
                                answer: z.enum(['individual', 'business']),
                                question_key_name: z.literal('business_type')
                            })
                        )
                        .or(
                            z.object({
                                answer: z.string().array(),
                                question_key_name: z.enum(['links', 'extra_languages', 'cities'])
                            })
                        )
                        .or(
                            z.object({
                                answer: z.enum(['24_hours', '7_days']),
                                question_key_name: z.literal('cancellation_policy')
                            })
                        )
                )
        )
    }),
    id: z.number().int().positive(),
    status: z.enum([
        'approved',
        'declined',
        'in_progress',
        'under_review',
        'pending_id_verification',
        'update_required',
        'waitlisted'
    ]),
    step: z
        .object({
            step: z.enum(['get_started', 'offerings', 'service_location', 'gallery', 'suggestions']),
            sub_step: z.null()
        })
        .or(
            z.object({
                step: z.literal('host_expertise'),
                sub_step: z.enum(['category', 'career', 'expertise', 'about_yourself'])
            })
        )
        .or(
            z.object({
                step: z.literal('other_details'),
                sub_step: z.enum(['about_guests', 'more_details', 'terms'])
            })
        ),
    minimum_image_required: z.number().nullable(),
    host: z.object({
        profile_photo_url: z.string().url().nullable(),
        airbnb_verified_phone: z.string().min(1).nullable()
    }),
    offerings: z.array(Offering),
    airbnb_link: z.string().url().nullish(), // todo: it will be used in near feature
    gallery: z
        .object({
            parent_flags: z.array(ParentFlag),
            images: UploadedMedia.array()
        })
        .nullable()
})
export type Service = z.infer<typeof Service>

export type Coordinates = {
    latitude: string
    longitude: string
}

export const City = z.object({
    id: z.number().positive().int(),
    name: z.string(),
    state: z.string().nullable(),
    country: z.string()
})
export type City = z.infer<typeof City>

export const Country = z.object({
    country: z.string(),
    country_iso: z.string()
})
export type Country = z.infer<typeof Country>
