import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '../status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper'
import {StatusDescriptionWrapper} from '../status-description-wrapper/StatusDescriptionWrapper'
import {StatusDescriptionIconWrapper} from '../status-description-icon/StatusDescriptionIconWrapper'
import {StatusDescriptionTitle} from '../status-description-title/StatusDescriptionTitle'
import {XCloseIcon} from '@/components/ui/icon'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {FC} from 'react'

interface WalkthroughAdminCanceledStatusDescriptionProps {
    assignedFreelancer: string | undefined
}

export const WalkthroughAdminCanceledStatusDescription: FC<WalkthroughAdminCanceledStatusDescriptionProps> = ({
    assignedFreelancer
}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <XCloseIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:walkthrough:canceled_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:admin_canceled_paragraph', {
                        freelancer: assignedFreelancer
                    })}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:admin_canceled_paragraph_2')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

WalkthroughAdminCanceledStatusDescription.displayName = 'WalkthroughAdminCanceledStatusDescription'
