import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {CalendarCheck01Icon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {Assignment} from '@/features/service/types.ts'
import {FC} from 'react'
import {formatLocaleDate} from '@utilities/helpers.ts'
import dayjs from '@utilities/dayjs.ts'

export const HomeScheduledStatusDescription: FC<{
    assignedFreelancer: string | undefined
    assignmentDate: Assignment['to_shoot_at'] | undefined
}> = ({assignedFreelancer, assignmentDate}) => {
    const {t} = useTranslation()
    const daysCount = dayjs(assignmentDate).diff(dayjs(), 'days')

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <CalendarCheck01Icon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:home:scheduled_title', {
                    date: assignmentDate ? formatLocaleDate(assignmentDate, 'LL') : '-',
                    hours: assignmentDate ? formatLocaleDate(assignmentDate, 'LT') : '-'
                })}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:scheduled_paragraph1', {
                        freelancer: assignedFreelancer,
                        daysCount: daysCount < 0 ? 0 : daysCount
                    })}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:scheduled_paragraph2')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

HomeScheduledStatusDescription.displayName = 'HomeScheduledStatusDescription'
