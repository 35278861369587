import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-host-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {FC} from 'react'
import {UseQuizAnswerResponse} from '@/features/experiences-host-video/services/useQuizAnswer.ts'
import {raise} from '@utilities/helpers.ts'
import {UseMutationResult} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {HttpCompleteQuizOptions} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'

const optionToTranslationKey = {
    combination: 'experiences_host_video:quiz:elements:combination',
    do_not_move: 'experiences_host_video:quiz:elements:do_not_move',
    do_not_zoom: 'experiences_host_video:quiz:elements:do_not_zoom',
    all: 'experiences_host_video:quiz:elements:all'
} as const satisfies Record<string, TKey>

interface QuizModalElementsStepProps {
    quiz: Quiz
    completePromise: (promiseProps: HttpCompleteQuizOptions) => Promise<AxiosResponse>
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    quizAnswerMutation: UseMutationResult<UseQuizAnswerResponse, unknown, {questionId: number}, unknown>
}
export const QuizModalElementsStep: FC<QuizModalElementsStepProps> = ({
    setSlidingModalState,
    quiz,
    quizAnswerMutation,
    completePromise
}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const completeQuiz = useAsync()
    const combinationQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'thumb_rules' &&
                question.question_answer.answer.key_name == 'combination'
        ) ?? raise('combinationQuestion is missing')
    const doNotMoveQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'thumb_rules' &&
                question.question_answer.answer.key_name == 'steady_camera'
        ) ?? raise('doNotMoveQuestion is missing')
    const doNotZoomQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'thumb_rules' &&
                question.question_answer.answer.key_name == 'no_zoom'
        ) ?? raise('doNotZoomQuestion is missing')
    const allQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'thumb_rules' &&
                question.question_answer.answer.key_name == 'all'
        ) ?? raise('allQuestion is missing')

    const onComplete = async () => {
        try {
            await completeQuiz.run(
                completePromise({
                    urlParams: {...routeParams, quizId: quiz.id},
                    payload: {
                        user_type: quiz.user_type
                    }
                })
            )
            handleCloseSlidingModal(setSlidingModalState)
        } catch (error) {
            toast.error(t('errors:default'))
        } finally {
            quizAnswerMutation.reset()
        }
    }

    return (
        <>
            <ExperiencesHostVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>{t('experiences_host_video:quiz:elements:question')}</QuizModalQuestion>
                    <QuizModalQuestionHint>{t('experiences_host_video:quiz:tap_options')}</QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['combination'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    combinationQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="combination"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: combinationQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != combinationQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == combinationQuestion.question_answer.id}
                    />
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['do_not_move'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    doNotMoveQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="do_not_move"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: doNotMoveQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != doNotMoveQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == doNotMoveQuestion.question_answer.id}
                    />
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['do_not_zoom'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    doNotZoomQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="do_not_zoom"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: doNotZoomQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != doNotZoomQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == doNotZoomQuestion.question_answer.id}
                    />
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['all'])}
                                {quizAnswerMutation.data?.question_answer_id == allQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="all"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: allQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != allQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == allQuestion.question_answer.id}
                    />
                </Flexbox>
                {quizAnswerMutation.isSuccess && (
                    <QuizModalAnswerResponse
                        variant={quizAnswerMutation.data.is_correct ? 'success' : 'danger'}
                        title={t(
                            `experiences_host_video:quiz:${
                                quizAnswerMutation.data.is_correct ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_host_video:quiz:elements:description')}
                    />
                )}
            </ExperiencesHostVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 100, 100]}>
                <Button disabled={!quizAnswerMutation.isSuccess || completeQuiz.isLoading} onClick={onComplete}>
                    {t('commons:done')}
                    {completeQuiz.isLoading && <Spinner />}
                </Button>
            </QuizModalFooter>
        </>
    )
}
