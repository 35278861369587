import {StyledHeader, StyledLayout} from '@pages/service/style.ts'
import {useEffect, useState} from 'react'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {ChangeLangModalTrigger} from '@components/commons/change-lang-modal-trigger/ChangeLangModalTrigger.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {AlertCircleIcon, ChevronLeftIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {routes} from '@utilities/constants'
import {useService} from '@/features/services/services/useService.ts'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {raise} from '@utilities/helpers.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {HostExpertiseCareerStep} from '@/features/services/components/host-expertise-career-step/HostExpertiseCareerStep.tsx'
import {HostExpertiseExpertiseStep} from '@/features/services/components/host-expertise-expertise-step/HostExpertiseExpertiseStep.tsx'
import {HostExpertiseAboutYourselfStep} from '@/features/services/components/host-expertise-about-yourself-step/HostExpertiseAboutYourselfStep.tsx'
import {HostExpertiseCategoryStep} from '@/features/services/components/host-expertise-category-step/HostExpertiseCategoryStep.tsx'
import {OfferingsStep} from '@/features/services/components/offerings-step/OfferingsStep.tsx'
import {ServiceLocationStep} from '@/features/services/components/service-location-step/ServiceLocationStep.tsx'
import {GalleryStep} from '@/features/services/components/gallery-step/GalleryStep.tsx'
import {OtherDetailsMoreDetailsStep} from '@/features/services/components/other-details-more-details-step/OtherDetailsMoreDetailsStep.tsx'
import {OtherDetailsTermsStep} from '@/features/services/components/other-details-terms-step/OtherDetailsTermsStep.tsx'
import {OtherDetailsAboutGuestsStep} from '@/features/services/components/other-details-about-guests-step/OtherDetailsAboutGuestsStep.tsx'
import {SentForReviewStep} from '@/features/services/components/sent-for-review-step/SentForReviewStep.tsx'
import {DeclinedStep} from '@/features/services/components/declined-step/DeclinedStep.tsx'
import {GetStartedStep} from '@/features/services/components/get-started-step/GetStartedStep.tsx'
import {PendingIdVerificationStep} from '@/features/services/components/pending-id-verification-step/PendingIdVerificationStep.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {ExpServiceSupportModal} from '@/features/services/components/exp-service-support-modal/ExpServiceSupportModal.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {SuggestionsStep} from '@/features/services/components/suggestions-step/SuggestionsStep.tsx'

export const Service = () => {
    const {t} = useTranslation()
    const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false)
    const urlParams = useURLParsedParams(z.object({id: z.coerce.number()}))
    const serviceQuery = useService(urlParams.success ? urlParams.data.id : raise('Invalid urlParams'))
    const [supportSlidingModalState, setSupportSlidingModalState] = useState<SlidingModalState>('closed')

    useEffect(() => {
        scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, [serviceQuery.data?.status, serviceQuery.data?.step.step, serviceQuery.data?.step.sub_step])

    const getStepComponent = () => {
        if (serviceQuery.isPending) {
            return <Spinner size={48} style={{alignSelf: 'center'}} />
        }
        if (serviceQuery.isError) {
            return <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
        }
        if (serviceQuery.data.status == 'declined') {
            return <DeclinedStep />
        }
        if (serviceQuery.data.status == 'under_review') {
            return <SentForReviewStep />
        }
        if (serviceQuery.data.status == 'pending_id_verification') {
            return <PendingIdVerificationStep />
        }

        switch (serviceQuery.data.step.step) {
            case 'host_expertise':
                switch (serviceQuery.data.step.sub_step) {
                    case 'career':
                        return <HostExpertiseCareerStep service={serviceQuery.data} />
                    case 'expertise':
                        return <HostExpertiseExpertiseStep service={serviceQuery.data} />
                    case 'about_yourself':
                        return <HostExpertiseAboutYourselfStep service={serviceQuery.data} />
                    default:
                        return (
                            <HostExpertiseCategoryStep
                                serviceId={serviceQuery.data.id}
                                expertiseId={serviceQuery.data.id}
                                serviceCategory={serviceQuery.data.category}
                            />
                        )
                }
            case 'service_location':
                return <ServiceLocationStep service={serviceQuery.data} />
            case 'offerings':
                return <OfferingsStep service={serviceQuery.data} />
            case 'gallery':
                return <GalleryStep service={serviceQuery.data} />
            case 'other_details':
                switch (serviceQuery.data.step.sub_step) {
                    case 'more_details':
                        return <OtherDetailsMoreDetailsStep service={serviceQuery.data} />
                    case 'terms':
                        return <OtherDetailsTermsStep service={serviceQuery.data} />
                    default:
                        return <OtherDetailsAboutGuestsStep service={serviceQuery.data} />
                }
            case 'suggestions':
                return <SuggestionsStep service={serviceQuery.data} />
            default:
                return <GetStartedStep serviceId={serviceQuery.data.id} expertiseId={serviceQuery.data.expertise.id} />
        }
    }

    return (
        <StyledLayout>
            <StyledHeader>
                <ButtonLink to={routes.SERVICES.path} size="sm" variant="tertiary">
                    <ChevronLeftIcon />
                    {t('services:services')}
                </ButtonLink>
                <Flexbox gap={2} align={'center'}>
                    <Button variant={'tertiary'} size={'sm'} onClick={() => setSupportSlidingModalState('open')}>
                        {t('services:need_help:title')}
                    </Button>
                    {supportSlidingModalState != 'closed' && (
                        <ExpServiceSupportModal
                            slidingModalState={supportSlidingModalState}
                            setSlidingModalState={setSupportSlidingModalState}
                        />
                    )}
                    <ChangeLangModalTrigger showCallback={() => setIsLanguageModalVisible(true)} />
                    {isLanguageModalVisible && (
                        <LangSelectionModal onCloseCb={() => setIsLanguageModalVisible(false)} />
                    )}
                </Flexbox>
            </StyledHeader>
            {getStepComponent()}
        </StyledLayout>
    )
}
