import {Label} from '@/components/ui/label/Label'
import {useTranslation} from '@/translations/i18n'
import {StyledSelectTrigger} from './style'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export const CountrySelectTrigger = ({country, errorMessage}: {country?: string; errorMessage?: string}) => {
    const {t} = useTranslation()

    return (
        <>
            <Label>{t('commons:country')}</Label>
            <StyledSelectTrigger
                placeholder={t('services:step_service_location:select_country')}
                fullWidth
                value={country}
                displayValue={() => country}
                $hasError={!!errorMessage}
            />
            {errorMessage && <InputHelpText error={errorMessage} />}
        </>
    )
}
