import {FileX02Icon} from 'src/components/ui/icon'
import {useTheme, CSSProperties} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {FC, ReactNode} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {StyledEmptyResultBox, StyledEmptyResultBoxHeader} from './styled'
import {DefaultNamespace} from 'i18next'

interface EmptyResultsBoxProps {
    onResetCb?: () => void
    buttonLabel?: string | DefaultNamespace
    icon?: ReactNode
    title: string | DefaultNamespace
    subtitle?: string | DefaultNamespace
    customContent?: ReactNode
    width?: CSSProperties['width']
}
export const EmptyResultsBox: FC<EmptyResultsBoxProps> = ({
    onResetCb,
    title,
    subtitle,
    icon,
    buttonLabel,
    customContent,
    width
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <StyledEmptyResultBox direction="column" align="center" gap={6} width={width}>
            {icon ?? <FileX02Icon size={32} fill={theme.palette.neutral['900']} />}
            <StyledEmptyResultBoxHeader direction="column" align="center" gap={1}>
                <h4>{title}</h4>
                {!!subtitle && <p>{subtitle}</p>}
            </StyledEmptyResultBoxHeader>
            {customContent}
            {onResetCb && (
                <Button size="sm" variant="secondary" onClick={onResetCb}>
                    {buttonLabel ?? t('commons:clear_search')}
                </Button>
            )}
        </StyledEmptyResultBox>
    )
}

EmptyResultsBox.displayName = 'EmptyResultsBox'
