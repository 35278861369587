import {GalleryItem, Service} from '@/features/services/types.ts'
import axios from '@/axiosInstance.ts'
import {ResponseType} from '@/types/commons'

export interface HttpGetServicesOptionsProps {
    params: {
        response_type: ResponseType
    }
}

export const httpGetServices = ({params}: HttpGetServicesOptionsProps) =>
    axios.get(`elevate/services`, {
        params
    })

interface HttpGetServiceOptionsProps {
    urlParams: {serviceId: number}
    params: {
        response_type: ResponseType
    }
}
export const httpGetService = ({urlParams, params}: HttpGetServiceOptionsProps) =>
    axios.get(`elevate/services/${urlParams.serviceId}`, {params})

interface HttpCreateServiceOptions {
    payload: {
        salesforce_id?: string
    }
}
export const httpCreateService = ({payload}: HttpCreateServiceOptions) => axios.post('elevate/services', payload)

interface HttpUpdateStepToGetStartedOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToGetStarted = ({urlParams, params}: HttpUpdateStepToGetStartedOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/get-started`,
        undefined,
        {params}
    )

interface HttpUpdateStepToHostExpertiseCategoryOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToHostExpertiseCategory = ({
    urlParams,
    params
}: HttpUpdateStepToHostExpertiseCategoryOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/host-expertise/category`,
        undefined,
        {params}
    )

export interface HttpUpdateStepToHostExpertiseCareerOptions<IsBack extends boolean> {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: IsBack extends true
        ? undefined
        : {
              category: NonNullable<Service['category']>['key_name']
          }
}
export const httpUpdateStepToHostExpertiseCareer = <IsBack extends boolean>({
    urlParams,
    params,
    payload
}: HttpUpdateStepToHostExpertiseCareerOptions<IsBack>) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/host-expertise/career`,
        payload,
        {params}
    )

export interface HttpUpdateStepToHostExpertiseExpertiseOptions<IsBack extends boolean> {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: IsBack extends true
        ? undefined
        : {
              experience_years: string
              business_type: string
              summary: string
              highlights: string
          }
}
export const httpUpdateStepToHostExpertiseExpertise = <IsBack extends boolean>({
    urlParams,
    payload,
    params
}: HttpUpdateStepToHostExpertiseExpertiseOptions<IsBack>) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/host-expertise/expertise`,
        payload,
        {params}
    )

export interface HttpUpdateStepToOtherDetailsAboutGuestOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToOtherDetailsAboutGuest = ({
    urlParams,
    params
}: HttpUpdateStepToOtherDetailsAboutGuestOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/other-details/about-guests`,
        undefined,
        {params}
    )

export interface HttpUpdateStepToOtherDetailsMoreDetailsOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload?: {
        age_limit: 'yes' | 'no'
        age?: number
        bring_anything: 'yes' | 'no'
        what_bring?: string
    }
}

export const httpUpdateStepToOtherDetailsMoreDetails = ({
    urlParams,
    payload,
    params
}: HttpUpdateStepToOtherDetailsMoreDetailsOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/other-details/more-details`,
        payload,
        {params}
    )

export interface HttpUpdateStepToOtherDetailsMoreThermsOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload?: {
        main_language: string
        extra_languages?: string[]
        group_min_size: number
        group_max_size: number
    }
}

export const httpUpdateStepToOtherDetailsTherms = ({
    urlParams,
    payload,
    params
}: HttpUpdateStepToOtherDetailsMoreThermsOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/other-details/terms`,
        payload,
        {params}
    )

export interface HttpUpdateStepToHostExpertiseAboutYourselfOptions<IsBack extends boolean> {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: IsBack extends true
        ? undefined
        : {
              inspiration: string
              background: string
          }
}
export const httpUpdateStepToHostExpertiseAboutYourself = <IsBack extends boolean>({
    urlParams,
    payload,
    params
}: HttpUpdateStepToHostExpertiseAboutYourselfOptions<IsBack>) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/host-expertise/about-yourself`,
        payload,
        {params}
    )

export interface HttpUpdateStepToLocationOptions<IsBack extends boolean> {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: IsBack extends true
        ? undefined
        : {
              hosting_modality: 'self' | 'team' | 'team_services'
              links: string[]
              co_hosts?: string
          }
}
export const httpUpdateStepToLocation = <IsBack extends boolean>({
    urlParams,
    payload,
    params
}: HttpUpdateStepToLocationOptions<IsBack>) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/service-location`,
        payload,
        {params}
    )

interface HttpUpdateStepToOfferingsOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToOfferings = ({urlParams, params}: HttpUpdateStepToOfferingsOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/offerings`,
        undefined,
        {
            params
        }
    )

interface HttpUpdateStepToGalleryOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToGallery = ({urlParams, params}: HttpUpdateStepToGalleryOptions) =>
    axios.patch(
        `elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-step-to/gallery`,
        undefined,
        {
            params
        }
    )

export interface HttpUpdateServiceLocationOptions {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: {
        address: string
        city: string
        country: string
        latitude: string
        longitude: string
        zip_code: string | null
        state: string
        cities: number[]
    }
}
export const httpUpdateServiceLocation = ({urlParams, params, payload}: HttpUpdateServiceLocationOptions) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-location`, payload, {
        params
    })

interface HttpUpdateStepToSuggestionsOptions {
    urlParams: {serviceId: number}
    params: {response_type: ResponseType}
}
export const httpUpdateStepToSuggestions = ({urlParams, params}: HttpUpdateStepToSuggestionsOptions) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/update-step-to/suggestions`, undefined, {params})

export interface UpdateStepAnswers {
    back: undefined
    career: HttpUpdateStepToHostExpertiseExpertiseOptions<false>['payload']
    expertise: HttpUpdateStepToHostExpertiseAboutYourselfOptions<false>['payload']
    aboutYourself: HttpUpdateStepToLocationOptions<false>['payload']
    serviceLocation: HttpUpdateServiceLocationOptions['payload']
    gallery: undefined
    offerings: undefined
    aboutGuests: HttpUpdateStepToOtherDetailsMoreDetailsOptions['payload']
    moreDetails: HttpUpdateStepToOtherDetailsMoreThermsOptions['payload']
}
type Answers<Step extends keyof UpdateStepAnswers> = UpdateStepAnswers[Step]

export interface HttpUpdateStepOptions<Step extends keyof UpdateStepAnswers> {
    urlParams: {serviceId: number; expertiseId: number}
    params: {response_type: ResponseType}
    payload: {
        is_review_completed: boolean
        step?: Service['step']['step']
        sub_step?: Service['step']['sub_step']
        answers: Answers<Step>
    }
}
export const httpUpdateStep = <Step extends keyof UpdateStepAnswers>({
    urlParams,
    params,
    payload
}: HttpUpdateStepOptions<Step>) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}}/update-step`, payload, {
        params
    })

export interface HttpSubmitServiceOptions {
    urlParams: {serviceId: number}
    params: {response_type: ResponseType}
    payload: {general_attestation: boolean; is_terms_approved: boolean}
}
export const httpSubmitService = ({urlParams, params, payload}: HttpSubmitServiceOptions) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/submit`, payload, {params})

export interface HttpAddOfferingOptions {
    urlParams: {serviceId: number}
    params: {response_type: ResponseType}
    payload: {
        title: string
        description: string
        duration: string
        price_per_guest: string
        currency: string
        price_per_group?: string
        main_photo?: GalleryItem['presignedUrl']
    }
}
export const httpAddOffering = ({urlParams, params, payload}: HttpAddOfferingOptions) =>
    axios.post(`elevate/services/${urlParams.serviceId}/offerings`, payload, {params})

export interface HttpUpdateOfferingOptions {
    urlParams: {serviceId: number; offeringId: number}
    params: {response_type: ResponseType}
    payload: {
        title?: string
        description?: string
        duration?: string
        price_per_guest?: string
        currency?: string
        price_per_group?: string
        main_photo?: string
    }
}
export const httpUpdateOffering = ({urlParams, params, payload}: HttpUpdateOfferingOptions) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/offerings/${urlParams.offeringId}`, payload, {params})

export interface HttpDeleteOffering {
    urlParams: {
        serviceId: number
        offeringId: number
    }
}

export const httpDeleteOffering = ({urlParams}: HttpDeleteOffering) =>
    axios.delete(`elevate/services/${urlParams.serviceId}/offerings/${urlParams.offeringId}`)

/*Upload profile photo*/
export interface HttpUploadProfilePhotoOptions {
    urlParams: {hostId: string}
    payload: FormData
    params: {response_type: ResponseType}
}
export const httpUploadProfilePhoto = ({urlParams, payload, params}: HttpUploadProfilePhotoOptions) =>
    axios.post(`elevate/hosts/${urlParams.hostId}/profile-photo`, payload, {
        params,
        headers: {'Content-Type': 'multipart/form-data'}
    })

export interface HttpGetPresignBulkPhotosOptions {
    urlParams: {serviceId: number}
    payload: {medias: string[]}
}
export const httpGetPresignBulkPhotos = ({payload, urlParams}: HttpGetPresignBulkPhotosOptions) =>
    axios.post(`elevate/services/${urlParams.serviceId}/gallery-images/bulk`, payload)

/*Confirm upload photo*/
interface HttpConfirmUploadPhotosOptions {
    mediaId: number
    serviceId: number
}
export const httpConfirmUploadPhoto = (urlParams: HttpConfirmUploadPhotosOptions) =>
    axios.patch(`elevate/services/${urlParams.serviceId}/gallery-images/${urlParams.mediaId}/upload-completed`)

/*Delete photo*/
interface HttpDeletePhotoOptions {
    mediaId: number
    serviceId: number
}
export const httpDeletePhoto = (urlParams: HttpDeletePhotoOptions) =>
    axios.delete(`elevate/services/${urlParams.serviceId}/gallery-images/${urlParams.mediaId}`)

/*Get cities list*/
export type HttpGetCitiesOptions = {
    search?: string
    country?: string
    response_type: ResponseType
    page: number
    per_page: number
}

export const httpGetCities = (params: HttpGetCitiesOptions) => axios.get(`elevate/services/cities`, {params})

/*Get countries list*/
export type HttpGetCountriesOptions = {
    search?: string
    response_type: ResponseType
    page: number
    per_page: number
}
export const httpGetCountries = (params: HttpGetCountriesOptions) =>
    axios.get(`elevate/services/cities/countries`, {params})

/* request support */
interface HttpSendExpServiceSupportRequestOptions {
    urlParams: {
        id?: number
    }
    payload: {notes: string}
}
export const httpSendExpServiceSupportRequest = ({urlParams, payload}: HttpSendExpServiceSupportRequestOptions) =>
    axios.post(`/elevate/services/need-help`, {
        notes: payload.notes,
        submission_id: urlParams.id
    })

export const httpAddPhoneNumber = ({payload}: {payload: {phone: string}}) =>
    axios.post('elevate/services/verification-phone', payload)

export const httpVerifyPhoneNumber = ({payload}: {payload: {verification_code: number}}) =>
    axios.post('elevate/services/verification-phone/verify', payload)

export const httpResendVerificationCode = () => axios.post('elevate/services/verification-phone/resend')
