import {z} from 'zod'

export type Percentage = `${number}%`

export const ResponseType = z.enum(['small', 'regular', 'extended'])
export type ResponseType = z.infer<typeof ResponseType>

export type ServiceType = 'experience_video' | 'experience_photo' | 'home' | 'matterport' | 'walkthrough'

export type SerializedSearchParam = {
    [key: string]: string[] | null
}

export const PaginationParams = z.object({
    current_page: z.number(),
    from: z.number().catch(0),
    last_page: z.number(),
    per_page: z.number(),
    total: z.number(),
    to: z.number().catch(0)
})

export type PaginationParams = z.infer<typeof PaginationParams>

export type ErrorResponseData = {
    message: string
}
