import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledAlertTextWrapper = styled.div<{$variant: 'banner' | 'alert'}>(
    ({theme: {typography, palette, spacing}, $variant}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        ${typography.textSm};
        color: ${palette.warning[700]};

        ${$variant == 'banner' &&
        css`
            padding: ${spacing * 3}px;
            border-radius: 8px;
            background-color: ${palette.warning[100]};
            ${typography.textSm};
            color: ${palette.neutral[900]};
            width: 100%;
            & svg {
                width: 20px;
                height: 20px;
                fill: ${palette.warning[600]};
            }
        `}
    `
)

export const StyledText = styled.span(
    () => css`
        max-height: 40px;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        flex-shrink: 1;

        & a {
            cursor: pointer;
            text-decoration: underline;
        }

        & ul {
            & li {
                &:before {
                    content: '• ';
                }
            }
        }
    `
)

export const StyledTextWrapper = styled(Flexbox)(
    ({theme: {typography}}) => css`
        flex-wrap: nowrap;

        & button {
            white-space: nowrap;
            color: currentColor;
            font-weight: 400;
            ${typography.textSm}
            flex-shrink: 0;
        }
    `
)
