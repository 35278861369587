import {useAuthStore} from '@/store/AuthStore'
import {useCoAlarmListingsTable} from '@/features/co-alarm/services/useCoAlarmListingsTable'
import {
    StyledCoAlarmListingsHeader,
    StyledCoAlarmListingsSearchLabel,
    StyledCoAlarmListingsSearchWrapper,
    StyledCoAlarmListingsWrapper
} from '@/features/co-alarm/components/co-alarm-dashboard-listings/style'
import InputText from '@/components/commons/input-text/InputText'
import {useTranslation} from '@/translations/i18n.tsx'
import {CoAlarmListingsTable} from '@/features/co-alarm/components/co-alarm-listings-table/CoAlarmListingsTable'
import {SearchLgIcon} from '@/components/ui/icon'
import {useRef} from 'react'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {CoAlarmListingsList} from '../co-alarm-listings-list/CoAlarmListingsList'
import {ButtonNavLink} from '@components/ui/button-nav-link/ButtonNavLink.tsx'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'

export const CoAlarmDashboardListings = ({isCoRequestEnabled}: {isCoRequestEnabled: boolean}) => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const user = useAuthStore(state => state.user)
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.l

    //prevent strange behaviour
    if (!user) {
        throw t('errors:default')
    }

    const {
        remappedData: listings,
        isLoading,
        isFetching,
        onSearch,
        isError,
        hasNextPage,
        fetchNextPage,
        searchValue,
        onResetSearch,
        isFetchingNextPage
    } = useCoAlarmListingsTable()

    const isSearchVisible = (!isLoading && listings.length > 0) || !!searchValue
    const isButtonVisible = !isMobile

    return (
        <StyledCoAlarmListingsWrapper id="co-alarm-listings-section" direction="column" gap={8}>
            {(isSearchVisible || isButtonVisible) && (
                <StyledCoAlarmListingsHeader justify="space-between" align="center" width={'100%'}>
                    <StyledCoAlarmListingsSearchWrapper $isHidden={!isSearchVisible} direction="column" gap={6}>
                        <StyledCoAlarmListingsSearchLabel htmlFor="search-listing">
                            {t('commons:your_active_listings')}
                        </StyledCoAlarmListingsSearchLabel>
                        <InputText
                            id="search-listing"
                            ref={searchRef}
                            isLoading={isFetching && !isFetchingNextPage}
                            typeIcon={<SearchLgIcon size={20} />}
                            inputSize="sm"
                            placeholder={t('commons:search_listing_placeholder')}
                            onChange={event => onSearch(event.target.value)}
                            type="text"
                            maxLength={50}
                        />
                    </StyledCoAlarmListingsSearchWrapper>
                    {isButtonVisible && (
                        <Tooltip
                            triggerProps={{style: {flexShrink: 0}}}
                            rootProps={{open: isCoRequestEnabled ? false : undefined}}
                            maxWidth="320px"
                            contentProps={{side: 'top'}}
                            content={
                                <p>
                                    {t('coAlarm:dashboard:not_eligible_title')}
                                    <br />
                                    <br />
                                    <span> - {t('coAlarm:dashboard:not_eligible_title_reason_1')}</span>
                                    <br />
                                    <span> - {t('coAlarm:dashboard:not_eligible_title_reason_2')}</span>
                                    <br />
                                    <span> - {t('coAlarm:dashboard:not_eligible_title_reason_3')}</span>
                                    <br />
                                </p>
                            }
                        >
                            <ButtonNavLink
                                to={generatePath(routes.CO_ALARM_SEND_REQUEST.path)}
                                disabled={!isCoRequestEnabled}
                            >
                                {t('coAlarm:dashboard:cta')}
                            </ButtonNavLink>
                        </Tooltip>
                    )}
                </StyledCoAlarmListingsHeader>
            )}

            {isMobile ? (
                <CoAlarmListingsList
                    data={listings}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    hasNextPage={hasNextPage}
                    isError={isError}
                    isChangingPage={isFetchingNextPage}
                    searchValue={searchValue}
                    searchRef={searchRef}
                    onChangePage={fetchNextPage}
                    onResetSearch={onResetSearch}
                />
            ) : (
                <CoAlarmListingsTable
                    data={listings}
                    isLoading={isLoading}
                    isError={isError}
                    isChangingPage={isFetchingNextPage}
                    searchValue={searchValue}
                    searchRef={searchRef}
                    onChangePage={fetchNextPage}
                    onResetSearch={onResetSearch}
                />
            )}
        </StyledCoAlarmListingsWrapper>
    )
}
