import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useCities} from '@/features/services/services/useCities'
import {ComboboxProvider} from '@ariakit/react'
import {useRef, useState} from 'react'
import {useInputSearch} from '@/hooks/useInputSearch'
import {City, Country} from '@/features/services/types'
import {CitiesMultiSelectPopover} from './CitiesMultiSelectPopover'
import {CitiesMultiSelectInput} from './CitiesMultiSelectInput'
import {CitiesMultiSelectChips} from './CitiesMultiSelectChips'

interface CitiesMultiSelectProps {
    errorMessage?: string
    label?: string
    values?: string[]
    country: Country['country']
    citiesObjects?: City[]
    onChange: (value: City[]) => void
}

export const CitiesMultiSelect = ({
    errorMessage,
    label,
    values,
    country,
    citiesObjects = [],
    onChange
}: CitiesMultiSelectProps) => {
    const [selectedCities, setSelectedCities] = useState(values || [])
    const comboboxInputRef = useRef(null)
    const {searchValue, onSearch} = useInputSearch()

    const citiesQuery = useCities(
        {
            search: searchValue || undefined,
            country,
            response_type: 'small',
            per_page: 50
        },
        {enabled: !!country}
    )

    const handleSelectCity = (currentValue: string[]) => {
        setSelectedCities(currentValue)
        // Concatenate all cities into one array
        const allCities = citiesObjects.concat(citiesQuery.remappedData)
        // Deduplicate by city id using a Map
        const uniqueCitiesMap = new Map(allCities.map(city => [city.id, city]))
        const uniqueCities = Array.from(uniqueCitiesMap.values())
        // Filter cities based on the current selected IDs
        const newCities = uniqueCities.filter(city => currentValue.includes(city.id.toString()))
        // Update value
        onChange(newCities)
    }

    const handleRemoveCity = (cityId: number) => {
        setSelectedCities(prev => prev.filter(currentValue => currentValue != cityId.toString()))
        onChange(citiesObjects.filter(currentValue => currentValue.id != cityId))
    }

    const handleNextPage = () => {
        if (citiesQuery.hasNextPage) {
            citiesQuery.fetchNextPage()
        }
    }

    return (
        <Flexbox gap={4} width="100%" direction="column">
            <ComboboxProvider
                setValue={onSearch}
                selectedValue={selectedCities}
                setSelectedValue={handleSelectCity}
                resetValueOnHide
            >
                <CitiesMultiSelectInput label={label} errorMessage={errorMessage} ref={comboboxInputRef} />
                <CitiesMultiSelectPopover
                    cities={citiesQuery.remappedData}
                    isLoading={citiesQuery.isLoading}
                    isError={citiesQuery.isError}
                    onEndReached={handleNextPage}
                />
            </ComboboxProvider>
            <CitiesMultiSelectChips cities={citiesObjects} remove={handleRemoveCity} />
        </Flexbox>
    )
}
