import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpDeletePhoto} from '@/features/services/services/services.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'

interface UseDeletePhotos {
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                mediaId: number
                serviceId: number
            }
        >,
        'mutationFn'
    >
}

export const useDeletePhotos = (mutationOptions?: UseDeletePhotos) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown, {mediaId: number; serviceId: number}>({
        mutationFn: ({mediaId, serviceId}) => httpDeletePhoto({mediaId, serviceId}),
        ...mutationOptions?.mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_GALLERY]})
            mutationOptions?.mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
