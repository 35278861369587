import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import toast from 'react-hot-toast'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar'
import {useShowExperiencesHostVideo} from '../../services/useShowExperiencesHostVideo'
import {Checklist} from '@/features/experiences-host-video/components/checklist/Checklist.tsx'

export const PreparationGetReadyToShootStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})
    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {
            onError: () => toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesHostVideoTitle>
                        {t('experiences_host_video:get_ready_shoot:title')}
                    </ExperiencesHostVideoTitle>
                    <ExperiencesHostVideoParagraph>
                        {t('experiences_host_video:get_ready_shoot:description')}
                    </ExperiencesHostVideoParagraph>
                </Flexbox>

                {showExperiencesHostVideoQuery.data?.answers && (
                    <Checklist answers={showExperiencesHostVideoQuery.data?.answers} />
                )}
            </ExperiencesHostVideoBody>

            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() =>
                                backUpdateStepMutation.mutate({status: 'preparation', step: 'take-anything-home'})
                            }
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'ready-to-film',
                                    step: 'intro'
                                })
                            }
                            disabled={continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
