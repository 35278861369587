import {useTranslation} from '@/translations/i18n.tsx'
import {ExperiencesHostVideoParagraph} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {RequirementCard} from '@/features/experiences-host-video/components/requirement-card/RequirementCard.tsx'
import {ClockStopwatchIcon, Phone02Icon, SlashCircle01Icon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import doNotPan from '@assets/videos/experiences-host-video/DONOT_PAN.mp4'
import doNotPanPreview from '@assets/images/experiences-host-video/donot_pan_preview.png'
import {StyledDoNotVideo} from '@/features/experiences-host-video/requirements/style.ts'

export const Requirements = () => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={6}>
            <ExperiencesHostVideoParagraph>
                {t('experiences_host_video:requirements:description')}
            </ExperiencesHostVideoParagraph>
            <Flexbox direction="column" gap={4}>
                <RequirementCard icon={<Phone02Icon />} iconWrapperVariant={'success'}>
                    <h3>{t('experiences_host_video:requirements:film_vertically_title')}</h3>
                    <p>{t('experiences_host_video:requirements:film_vertically_description')}</p>
                </RequirementCard>
                <RequirementCard icon={<ClockStopwatchIcon />} iconWrapperVariant={'success'}>
                    <h3>{t('experiences_host_video:requirements:film_seconds_clip_title')}</h3>
                    <p>{t('experiences_host_video:requirements:film_seconds_clip_description')}</p>
                </RequirementCard>
            </Flexbox>

            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />

            <RequirementCard icon={<SlashCircle01Icon />} iconWrapperVariant={'danger'}>
                <h3>{t('experiences_host_video:requirements:prohibited_activity_title')}</h3>
                <p>{t('experiences_host_video:requirements:prohibited_activity_description')}</p>
            </RequirementCard>
            <RequirementCard icon={<SlashCircle01Icon />} iconWrapperVariant={'danger'}>
                <h3>{t('experiences_host_video:requirements:children_title')}</h3>
                <p>{t('experiences_host_video:requirements:children_description')}</p>
            </RequirementCard>
            <RequirementCard icon={<SlashCircle01Icon />} iconWrapperVariant={'danger'}>
                <h3>{t('experiences_host_video:requirements:do_not_move_title')}</h3>
                <p>{t('experiences_host_video:requirements:do_not_move_description')}</p>
                <StyledDoNotVideo autoPlay playsInline loop muted poster={doNotPanPreview}>
                    <source src={doNotPan} />
                </StyledDoNotVideo>
            </RequirementCard>
        </Flexbox>
    )
}
