import styled, {css} from 'styled-components'

export const StyledMoreDetailsAdditionalLanguageCheckboxLabel = styled.label(
    ({theme: {spacing, palette}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        width: 100%;
        color: ${palette.neutral[900]};
        cursor: pointer;
    `
)
