import {z} from 'zod'

const StatusStep = z
    .object({
        status: z.object({
            key_name: z.literal('intro'),
            step_key_name: z.literal('intro')
        })
    })
    .or(
        z.object({
            status: z.object({
                key_name: z.literal('preparation'),
                step_key_name: z.enum([
                    'intro',
                    'require_prepping_space',
                    'you_unique',
                    'experience_unique',
                    'highlights',
                    'take_anything_home',
                    'get_ready_to_shoot'
                ])
            })
        })
    )
    .or(
        z.object({
            status: z.object({
                key_name: z.literal('ready_to_film'),
                step_key_name: z.enum(['intro', 'specs', 'perspective', 'get_help', 'schedule'])
            })
        })
    )
    .or(
        z.object({
            status: z.object({
                key_name: z.literal('upload'),
                step_key_name: z.enum([
                    'intro',
                    'video_upload',
                    'voiceover_language',
                    'voiceover_guidelines',
                    'name_quick_intro',
                    'unique_guests_can_do',
                    'record_voiceover_unique',
                    'record_voiceover_highlights'
                ])
            })
        })
    )
    .or(
        z.object({
            status: z.object({
                key_name: z.literal('completed'),
                step_key_name: z.literal('completed')
            })
        })
    )

const Question = z.object({
    id: z.number(),
    question_answer_id: z.number(),
    task_id: z.number(),
    question_answer: z.object({
        id: z.number(),
        question_id: z.number(),
        answer_id: z.number(),
        type: z.literal('preparation'),
        question: z.object({
            id: z.number(),
            key_name: z.enum([
                'require_prepping_space',
                'you_unique',
                'experience_unique',
                'highlights',
                'take_anything_home',
                'taking_anything_home',
                'get_ready_to_shoot'
            ]),
            type: z.literal('preparation')
        }),
        answer: z.object({
            id: z.number(),
            key_name: z.enum(['yes', 'no', 'string', 'string[]']),
            type: z.literal('preparation')
        })
    }),
    notes: z.array(z.object({task_question_answers_id: z.number(), notes: z.string(), id: z.number()}))
})

export type Question = z.infer<typeof Question>

export const ExperiencesHostVideo = StatusStep.and(
    z.object({
        answers: z.array(Question),
        device_id: z.number().int().positive().nullable(),
        has_friend_completed_onboarding: z.boolean(),
        is_self_filmed: z.boolean(),
        status_id: z.number(),
        questions: z.array(Question),
        scheduled_at: z.string().nullable(),
        voice_over_language: z.string().nullable(),
        host: z
            .object({
                first_name: z.string().nullable()
            })
            .optional(), //TODO remove optional when back implements the host
        videoset: z
            .object({
                id: z.number()
            })
            .nullable()
    })
)
export type ExperiencesHostVideo = z.infer<typeof ExperiencesHostVideo>

export const ExperiencesHostVideoRouteParams = z.object({
    hostCode: z.coerce.number().int().positive(),
    hostPassword: z.string().min(1),
    experiencesHostVideoId: z.coerce.number().int().positive(),
    taskId: z.coerce.number().int().positive()
})
export type ExperiencesHostVideoRouteParams = z.infer<typeof ExperiencesHostVideoRouteParams>

const ExperiencesHostVideoUploadRouteParams = ExperiencesHostVideoRouteParams.merge(
    z.object({videoSetId: z.number().nullable()})
)
export type ExperiencesHostVideoUploadRouteParams = z.infer<typeof ExperiencesHostVideoUploadRouteParams>

export type UploadStepPayload =
    | {
          status: {
              key_name: 'intro'
              step_key_name: 'intro'
          }
      }
    | {
          status: {
              key_name: 'preparation'
              step_key_name:
                  | 'intro'
                  | 'require-prepping-space'
                  | 'you-unique'
                  | 'experience-unique'
                  | 'highlights'
                  | 'take-anything-home'
                  | 'get-ready-to-shoot'
          }
      }
    | {
          status: {
              key_name: 'ready-to-film'
              step_key_name: 'intro' | 'specs' | 'perspective' | 'get-help' | 'schedule'
          }
      }
    | {
          status: {
              key_name: 'upload'
              step_key_name:
                  | 'intro'
                  | 'video-upload'
                  | 'voiceover-language'
                  | 'voiceover-guidelines'
                  | 'name-quick-intro'
                  | 'unique-guests-can-do'
                  | 'record-voiceover-highlights'
          }
      }
    | {
          status: {
              key_name: 'completed'
              step_key_name: 'completed'
          }
      }

export const Media = z.object({
    id: z.number().int().positive(),
    name: z.string().min(1)
})
export type Media = z.infer<typeof Media>

export const Device = z.object({
    id: z.number().int().positive(),
    brand: z.string().min(1),
    model: z.string().min(1),
    release_year: z.number().int().positive(),
    is_approved: z.boolean()
})
export type Device = z.infer<typeof Device>

const QuizQuestion = z.object({
    id: z.number().int().positive(),
    question_answer: z.object({
        id: z.number().int().positive(),
        question: z.object({
            id: z.number().int().positive(),
            key_name: z.enum(['hold_phone', 'what_upload', 'who_film', 'people_in_trailer', 'thumb_rules'])
        }),
        answer: z.object({
            id: z.number().int().positive(),
            key_name: z.enum([
                'vertically',
                'horizontally',
                '1_edited_video',
                '20_40_short_videos',
                '5_photos',
                'my_self',
                'acquaintance',
                'host',
                '3_people',
                'combination',
                'steady_camera',
                'no_zoom',
                'all'
            ])
        })
    })
})
export const Quiz = z.object({
    id: z.number().int().positive(),
    user_type: z.enum(['host', 'friend']),
    questions: z.array(QuizQuestion)
})
export type Quiz = z.infer<typeof Quiz>

/**
 * Host experience proposal response schema
 */
export const HostExperienceProposal = z.object({
    id: z.number().int().positive(),
    name: z.string(),
    address: z.string().nullable(),
    airbnb_id: z.string().min(1),
    experience_task: z
        .object({
            id: z.number().int().positive()
        })
        .nullable()
})
export type HostExperienceProposal = z.infer<typeof HostExperienceProposal>
