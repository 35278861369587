import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {CalendarIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const ExperienceVideoAssignedNoDatesStatusDescription: FC<{
    assignedFreelancer: string | undefined
}> = ({assignedFreelancer}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionParagraphsWrapper>
            <StatusDescriptionIconWrapper>
                <CalendarIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:assigned_shared_dates_title', {
                    freelancer: assignedFreelancer
                })}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:assigned_no_dates_paragraph', {
                        freelancer: assignedFreelancer
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionParagraphsWrapper>
    )
}

ExperienceVideoAssignedNoDatesStatusDescription.displayName = 'ExperienceVideoAssignedNoDatesStatusDescription'
