import {create} from 'zustand'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {AcceptanceStep, NotifyChannels} from '@/features/service-acceptance-flow/types.ts'
import {RequestedPhotoService} from '@/features/service-requests/types.ts'
import {TASK_CATEGORIES} from '@/features/service/utils.tsx'
import {CoAlarmRequest} from '@/features/co-alarm/types.ts'

type ServiceAcceptanceState = {
    coAlarmRequest: CoAlarmRequest | null
    modalVisible: boolean
    manualRequest: RequestedPhotoService | null
    portraitEnabled: boolean
    step: AcceptanceStep
    service: ServiceProposal | null
}

type ServiceAcceptanceActions = {
    acceptServiceModalToggle: (service?: ServiceProposal, coAlarmRequest?: CoAlarmRequest) => void
    changeStep: (nextStep: AcceptanceStep) => void
    setService: (service: ServiceProposal) => void
    setCoAlarmRequest: (coAlarmRequest: CoAlarmRequest) => void
    setManualRequest: (request: RequestedPhotoService) => void
    updateServiceNotifyChannel: (type: NotifyChannels, newChannelValue: string) => void
    updateServiceNotifyConsent: (type: NotifyChannels, newConsentValue: boolean) => void
}

export const useServiceAcceptStore = create<ServiceAcceptanceState & ServiceAcceptanceActions>()(set => ({
    coAlarmRequest: null,
    manualRequest: null,
    modalVisible: false,
    portraitEnabled: false,
    step: 'accept',
    service: null,
    changeStep: nextStep => set({step: nextStep}),
    acceptServiceModalToggle: (service, coAlarmRequest) =>
        set(state => {
            if (state.modalVisible) {
                const conditionalInitialStepClose = state.coAlarmRequest ? 'sms' : 'accept'
                localStorage.removeItem('coNotifyModal')
                return {
                    manualRequest: null,
                    modalVisible: false,
                    portraitEnabled: false,
                    service: null,
                    coAlarmRequest: null,
                    step: conditionalInitialStepClose
                }
            } else {
                const conditionalInitialStepOpen = coAlarmRequest ? 'sms' : 'accept'
                return {
                    modalVisible: true,
                    portraitEnabled: service?.task_category.name == TASK_CATEGORIES.ECH,
                    service: service,
                    coAlarmRequest: coAlarmRequest,
                    step: conditionalInitialStepOpen
                }
            }
        }),
    setManualRequest: request => set({manualRequest: request}),
    setService: service => set({service: service}),
    setCoAlarmRequest: coAlarmRequest => set({coAlarmRequest: coAlarmRequest}),
    updateServiceNotifyChannel: (type, newChannelValue) =>
        set(state => {
            if (type == 'sms') {
                return {service: {...state.service, phone_for_notifications: newChannelValue} as ServiceProposal}
            } else {
                return {service: {...state.service, email_for_notifications: newChannelValue} as ServiceProposal}
            }
        }),
    updateServiceNotifyConsent: (type, newConsentValue) =>
        set(state => {
            if (type == 'sms') {
                return {service: {...state.service, send_notifications_to_phone: newConsentValue} as ServiceProposal}
            } else {
                return {service: {...state.service, send_notifications_to_email: newConsentValue} as ServiceProposal}
            }
        })
}))
