import styled, {css, DefaultTheme} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'
import {Service} from '@/features/services/types.ts'

export const StyledServices = styled(Container)(
    ({theme: {spacing, typography, palette}}) => css`
        padding-top: ${spacing * 7.5}px;
        padding-bottom: ${spacing * 7.5}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 8}px;

        & h2 {
            ${typography.displayXsFixed};
            font-weight: 700;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledEmptyState = styled.div(
    ({theme: {palette, spacing, typography}}) => css`
        width: 100%;
        background-color: ${palette.neutral['50']};
        border-radius: 12px;
        padding: ${spacing * 8}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 6}px;
        color: ${palette.neutral[900]};
        & h3 {
            ${typography.textLg};
            font-weight: 500;
        }
    `
)

export const StyledCard = styled.div(
    ({theme: {palette, spacing, typography}}) => css`
        width: 100%;
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[300]};
        border-radius: 16px;
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: ${spacing * 7}px;
        color: ${palette.neutral[900]};
        & h4 {
            ${typography.textLg};
            font-weight: 500;
            word-break: break-word;
        }
        & p {
            ${typography.textSm};
        }
    `
)

const getStatusColor = (status: Service['status'], palette: DefaultTheme['palette']) => {
    const statusToColors = {
        approved: palette.success['400'],
        declined: palette.danger['400'],
        in_progress: palette.neutral['500'],
        under_review: palette.blue['500'],
        pending_id_verification: palette.warning['500'],
        update_required: palette.warning['500'],
        waitlisted: palette.purple['500']
    } as const satisfies Record<Service['status'], string>

    return statusToColors[status]
}

export const StyledStatusBullet = styled.span<{$status: Service['status']}>(
    ({theme: {palette}, $status}) => css`
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${getStatusColor($status, palette)};
    `
)

export const StyledList = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: ${spacing * 4}px;

        ${mediaQueries.xl} {
            grid-template-columns: 1fr 1fr;
        }
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)
