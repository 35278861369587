import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledLocationBox = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
    `
)
