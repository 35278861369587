import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'
import router from './router.tsx'
import {useEffect} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {userMe} from '@/features/sso/services/ssoAuthActions.ts'

function App() {
    const {
        i18n,
        i18n: {language}
    } = useTranslation()
    const accessToken = useAuthStore(state => state.accessToken)

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [language])

    useEffect(() => {
        if (accessToken) {
            void userMe()
        }
    }, [userMe, accessToken])

    return (
        <ThemeProvider theme={getAppTheme({name: 'light'})}>
            <StyleSheetManager>
                <>
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </>
            </StyleSheetManager>
        </ThemeProvider>
    )
}

export default App
