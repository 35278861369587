import {createBrowserRouter, Outlet} from 'react-router-dom'
import {AppLayout} from '@layouts/app-layout/AppLayout.tsx'
import {routes} from '@utilities/constants'
import ServiceRoute from '@route-guards/service-route/ServiceRoute.tsx'
import DashboardRoute from '@route-guards/dashboard-route/DashboardRoute.tsx'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import TermsRoute from '@route-guards/terms-route/TermsRoute.tsx'
import {CoAlarmListingsWrapper} from '@/features/co-alarm/components/co-alarm-listings-wrapper/CoAlarmListingsWrapper'
import HostApprovalRoute from '@route-guards/host-approval-route/HostApprovalRoute.tsx'
import {Toaster} from 'react-hot-toast'

const router = createBrowserRouter(
    [
        {
            element: (
                <>
                    <Toaster position="top-right" />
                    <Outlet />
                </>
            ),
            children: [
                {...routes.NOT_FOUND},
                {...routes.EXPERIENCE_HOST_VIDEO_LOGIN},
                {...routes.EXPERIENCE_HOST_VIDEO_FRIEND},
                {
                    element: <PrivateRoute />,
                    children: [
                        {
                            element: <AppLayout withHeader />,
                            children: [
                                {...routes.HOME},
                                {...routes.PHOTOSHOOT_REQUEST},
                                {...routes.CATEGORIES},
                                {...routes.CO_ALARM_DASHBOARD},
                                {...routes.CO_ALARM_INACTIVE},
                                {...routes.CO_ALARM_SEND_REQUEST},
                                {
                                    element: <CoAlarmListingsWrapper />,
                                    children: [{...routes.CO_ALARM_REQUEST_DETAILS}]
                                },
                                {...routes.SERVICES}
                            ]
                        },
                        {...routes.EXPERIENCE_HOST_VIDEO_REDIRECT},
                        {...routes.EXPERIENCE_HOST_VIDEO},
                        {...routes.SERVICE}
                    ]
                },
                {
                    element: <AppLayout withHeader />,
                    children: [
                        {...routes.SSO_REDIRECT},
                        {...routes.PHOTOSHOOT_LANDING},
                        {...routes.SSO_LOGIN},
                        {...routes.CATEGORIES_SSO_LOGIN},
                        {...routes.CO_ALARM_SSO_LOGIN},
                        {...routes.SERVICES_LOGIN},
                        {
                            element: <HostApprovalRoute />,
                            children: [{...routes.APPROVAL_REQUEST_DETAILS}]
                        },
                        {
                            element: <ServiceRoute />,
                            children: [{...routes.SERVICE_DETAILS}]
                        },
                        {
                            element: <DashboardRoute />,
                            children: [{...routes.DASHBOARD}]
                        },
                        {
                            element: <TermsRoute />,
                            children: [{...routes.TERMS_OF_SERVICE}]
                        }
                    ]
                }
                /* {
                    element: (
                        <>
                            <Outlet />
                        </>
                    ),
                    children: [
                        {
                            element: <PublicLayout />,
                            children: [{...routes.COOKIE_POLICY}]
                        }
                    ]
                }*/
            ]
        }
    ],
    {
        basename: '/'
    }
)

export default router
