import {handleCloseSlidingModal, SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {
    StyledAnswerItem,
    StyledInputPrice,
    StyledOfferingForm,
    StyledOfferingFormContent,
    StyledOfferingFormModalHeader,
    StyledOfferingModalHeaderTitle,
    StyledOfferingSlidingModal
} from './style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon'
import i18n, {useTranslation} from '@/translations/i18n'
import {ModalBody, ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import InputText from '@/components/commons/input-text/InputText'
import {zodResolver} from '@hookform/resolvers/zod'
import TextArea from '@/components/commons/textarea/TextArea'
import {OfferingDurationSelector} from '../offering-duration-selector/OfferingDurationSelector'
import {Divider} from '@/components/ui/divider/Divider'
import {FieldDescription, FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Toggle} from '@/components/ui/toggle/Toggle'
import {Label} from '@/components/ui/label/Label'
import {OfferingPhotoUploader} from '../offering-photo-uploader/OfferingPhotoUploader'
import {GalleryItem, Offering, OfferingTypes, Service} from '@/features/services/types'
import {useAddOffering} from '@/features/services/services/useAddOffering'
import Spinner from '@components/ui/spinner-legacy/Spinner'
import {formatCurrency} from '@/utilities/helpers'
import countryToCurrency, {Countries} from 'country-to-currency'
import {useUpdateOffering} from '@/features/services/services/useUpdateOffering'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import toast from 'react-hot-toast'
import {useState} from 'react'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {FlagsAlertText} from '../flags-alert-text/FlagsAlertText'

// limits
const TITLE_MAX_LENGTH = 25
const DESCRIPTION_MIN_LENGTH = 34
const DESCRIPTION_MAX_LENGTH = 200
const PRICE_LIMIT = 999999

// types
const OfferingFormValidationSchema = z
    .object({
        title: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')})
            .max(TITLE_MAX_LENGTH),
        description: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(DESCRIPTION_MIN_LENGTH, {
                message: i18n.t('errors:field_min_character', {count: DESCRIPTION_MIN_LENGTH})
            })
            .max(DESCRIPTION_MAX_LENGTH),
        duration: z.string({required_error: i18n.t('errors:field_required')}),
        price_per_guest: z
            .number({required_error: i18n.t('errors:field_required')})
            .positive()
            .max(PRICE_LIMIT, {
                message: i18n.t('services:step_offerings:price_limit', {value: formatCurrency(PRICE_LIMIT)})
            })
            .multipleOf(0.01, {message: i18n.t('services:step_offerings:price_decimal_error')}),
        currency: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')}),
        group_pricing_enabled: z.boolean().default(false),
        price_per_group: z
            .number({required_error: i18n.t('errors:field_required')})
            .positive()
            .max(PRICE_LIMIT, {
                message: i18n.t('services:step_offerings:price_limit', {value: formatCurrency(PRICE_LIMIT)})
            })
            .multipleOf(0.01, {message: i18n.t('services:step_offerings:price_decimal_error')})
            .optional(),
        main_photo: GalleryItem.nullish(),
        type: OfferingTypes.default(OfferingTypes.Enum.other),
        meeting_point: z
            .array(z.enum(['they_travel_to_you', 'you_travel_to_them']))
            .min(1, {message: i18n.t('errors:field_required')})
    })
    .superRefine((values, context) => {
        if (values.group_pricing_enabled && !values.price_per_group) {
            context.addIssue({
                code: 'custom',
                path: ['price_per_group'],
                message: i18n.t('errors:field_required')
            })
        }
    })
export type OfferingFormValidationSchema = z.infer<typeof OfferingFormValidationSchema>

interface OfferingFormModalProps {
    serviceId: Service['id']
    serviceAddress: Service['address']
    submissionCities: Service['submission_cities']
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
    country: Countries
    defaultValues?: Partial<Offering>
    selectedOfferingType: OfferingTypes
}

export const OfferingFormModal = ({
    serviceId,
    serviceAddress,
    submissionCities,
    country,
    setSlidingModalState,
    slidingModalState,
    defaultValues,
    selectedOfferingType
}: OfferingFormModalProps) => {
    const {t} = useTranslation()
    const uploadStatusState = useState<'idle' | 'pending' | 'success' | 'error'>('idle')

    // methods
    const form = useForm<OfferingFormValidationSchema>({
        resolver: zodResolver(OfferingFormValidationSchema),
        defaultValues: defaultValues?.question_answer
            ? defaultValues.question_answer.reduce(
                  (acc, val) => {
                      if (val.question_key_name == 'title') {
                          return {...acc, title: val.answer}
                      }
                      if (val.question_key_name == 'description') {
                          return {...acc, description: val.answer}
                      }
                      if (val.question_key_name == 'currency') {
                          return {...acc, currency: val.answer}
                      }
                      if (val.question_key_name == 'price_per_guest') {
                          return {...acc, price_per_guest: parseFloat(val.answer)}
                      }
                      if (val.question_key_name == 'price_per_group') {
                          return {...acc, price_per_group: parseFloat(val.answer)}
                      }
                      if (val.question_key_name == 'duration') {
                          return {...acc, duration: val.answer}
                      }
                      if (val.question_key_name == 'main_photo') {
                          return val.gallery_image?.id
                              ? {
                                    ...acc,
                                    main_photo: {
                                        id: val.gallery_image.id,
                                        src: val.answer
                                    }
                                }
                              : acc
                      }

                      return acc
                  },
                  {
                      title: '',
                      currency: '',
                      duration: '',
                      meeting_point: defaultValues?.meeting_point ?? [],
                      group_pricing_enabled: defaultValues.question_answer.some(
                          question => question.question_key_name == 'price_per_group' && question.answer != '0'
                      )
                  }
              )
            : {
                  currency: countryToCurrency[country]
              }
    })

    const addOfferingMutation = useAddOffering(serviceId, {
        onSuccess: () => {
            handleCloseSlidingModal(setSlidingModalState)
        },
        onError: () => {
            handleCloseSlidingModal(setSlidingModalState)
            toast.error(t('errors:default'))
        }
    })

    const updateOfferingMutation = useUpdateOffering({
        serviceId,
        offeringId: defaultValues?.id,
        options: {
            onSuccess: () => {
                void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES, serviceId]})
                handleCloseSlidingModal(setSlidingModalState)
            },
            onError: () => toast.error(t('errors:default'))
        }
    })

    // observer
    const title = useWatch({control: form.control, name: 'title'})
    const description = useWatch({control: form.control, name: 'description'})
    const groupPricingEnabled = useWatch({control: form.control, name: 'group_pricing_enabled'})
    const currency = useWatch({control: form.control, name: 'currency'})
    const meetingPoint = useWatch({control: form.control, name: 'meeting_point'}) ?? []

    // actions
    const handleSubmit = form.handleSubmit(formValues => {
        const payload = {
            title: formValues.title,
            description: formValues.description,
            duration: formValues.duration.toString(),
            price_per_guest: formValues.price_per_guest.toString(),
            currency: formValues.currency,
            price_per_group: formValues.price_per_group ? formValues.price_per_group?.toString() : undefined,
            main_photo: formValues.main_photo?.src,
            type: selectedOfferingType,
            meeting_point: formValues.meeting_point
        }

        return defaultValues ? updateOfferingMutation.mutate(payload) : addOfferingMutation.mutate(payload)
    })

    const titleFlags = defaultValues?.question_answer?.find(field => field.question_key_name == 'title')?.parent_flags
    const descriptionFlags = defaultValues?.question_answer?.find(field => field.question_key_name == 'description')
        ?.parent_flags
    const durationFlags = defaultValues?.question_answer?.find(field => field.question_key_name == 'duration')
        ?.parent_flags
    const guestPricingFlags = defaultValues?.question_answer?.find(
        field => field.question_key_name == 'price_per_guest'
    )?.parent_flags
    const groupPricingFlags = defaultValues?.question_answer?.find(
        field => field.question_key_name == 'price_per_group'
    )?.parent_flags
    const mainPhotoFlags = defaultValues?.question_answer?.find(field => field.question_key_name == 'main_photo')
        ?.parent_flags
    const meetingPointFlags = defaultValues?.question_answer?.find(field => field.question_key_name == 'meeting_point')
        ?.parent_flags

    return (
        <StyledOfferingSlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledOfferingFormModalHeader>
                <Button variant="ghost" shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                    <XCloseIcon size={36} />
                </Button>
                <StyledOfferingModalHeaderTitle>
                    {t(`services:step_offerings:offering_type_title:${selectedOfferingType}`)}
                </StyledOfferingModalHeaderTitle>
            </StyledOfferingFormModalHeader>
            <ModalBody>
                <StyledOfferingForm
                    onSubmit={e => {
                        e.preventDefault()
                    }}
                >
                    <Flexbox direction="column" gap={4} width="100%">
                        <StyledOfferingFormContent direction="column" gap={2} width="100%">
                            <InputText
                                type="text"
                                label={t('services:step_offerings:offering_title_label')}
                                maxLength={TITLE_MAX_LENGTH}
                                helpText={t('services:x_characters_available', {
                                    count: TITLE_MAX_LENGTH - (title?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.title?.message}
                                {...form.register('title')}
                            />

                            <FlagsAlertText
                                flags={titleFlags}
                                title={t('services:step_offerings:offering_title_label')}
                                detailLevel="offering_title"
                            />
                        </StyledOfferingFormContent>

                        <StyledOfferingFormContent direction="column" gap={2} width="100%">
                            <TextArea
                                rows={3}
                                label={t('services:step_offerings:offering_description_label')}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                helpText={t('services:x_characters_available', {
                                    count: DESCRIPTION_MAX_LENGTH - (description?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.description?.message}
                                {...form.register('description')}
                            />

                            <FlagsAlertText
                                flags={descriptionFlags}
                                title={t('services:step_offerings:offering_description_label')}
                                detailLevel="offering_description"
                            />
                        </StyledOfferingFormContent>

                        <StyledOfferingFormContent direction="column" gap={2} width="100%">
                            <Controller
                                control={form.control}
                                name="duration"
                                render={({field}) => (
                                    <OfferingDurationSelector
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={form.formState.errors.duration?.message}
                                        ref={field.ref}
                                    />
                                )}
                            />

                            <FlagsAlertText
                                flags={durationFlags}
                                title={t('services:step_offerings:select_duration_label')}
                            />
                        </StyledOfferingFormContent>

                        <Divider direction="horizontal" endSpacing={2} startSpacing={2} />
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_offerings:guest_pricing')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_offerings:guest_pricing_description')}
                            </FieldDescription>
                        </Flexbox>
                        <Flexbox direction="column" gap={1.5} width="100%">
                            <StyledInputPrice
                                type="number"
                                placeholder={t('services:step_offerings:price_placeholder')}
                                label={t('services:step_offerings:price_per_guest_label')}
                                errorMessage={form.formState.errors.price_per_guest?.message}
                                typeIcon={<span>{currency}</span>}
                                inputMode="decimal"
                                step="0.01"
                                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                {...form.register('price_per_guest', {
                                    setValueAs: value =>
                                        value == '' || value == 0 || value == null ? undefined : parseFloat(value)
                                })}
                            />
                            <Flexbox direction="column" gap={2} width="100%">
                                <InputHelpText helpText={t('services:step_offerings:price_per_guest_hint')} />

                                <FlagsAlertText
                                    flags={guestPricingFlags}
                                    title={t('services:step_offerings:price_per_guest_label')}
                                />
                            </Flexbox>
                        </Flexbox>
                        <Flexbox gap={3}>
                            <Controller
                                control={form.control}
                                name="group_pricing_enabled"
                                render={({field}) => (
                                    <Toggle
                                        value={field.value}
                                        role="switch"
                                        aria-checked={field.value}
                                        aria-label={t('services:step_offerings:group_pricing')}
                                        onChange={event => {
                                            field.onChange(event)
                                            form.resetField('price_per_group', undefined)
                                            form.setValue('price_per_group', undefined)
                                            form.clearErrors(['price_per_group'])
                                        }}
                                    />
                                )}
                            />
                            <Label>{t('services:step_offerings:group_pricing')}</Label>
                        </Flexbox>
                        {groupPricingEnabled && (
                            <Flexbox direction="column" gap={1.5}>
                                <StyledInputPrice
                                    type="number"
                                    step="0.01"
                                    placeholder={t('services:step_offerings:price_placeholder')}
                                    label={t('services:step_offerings:price_per_group')}
                                    errorMessage={form.formState.errors.price_per_group?.message}
                                    inputMode="decimal"
                                    typeIcon={<span>{currency}</span>}
                                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                    {...form.register('price_per_group', {
                                        setValueAs: value =>
                                            value == '' || value == 0 || value == null ? undefined : parseFloat(value)
                                    })}
                                />
                                <Flexbox direction="column" gap={2}>
                                    <InputHelpText helpText={t('services:step_offerings:minimum_price_hint')} />

                                    <FlagsAlertText
                                        flags={groupPricingFlags}
                                        title={t('services:step_offerings:price_per_group')}
                                    />
                                </Flexbox>
                            </Flexbox>
                        )}
                        <Divider direction="horizontal" endSpacing={2} startSpacing={2} />

                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_offerings:where_meet_guests_question:title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_offerings:where_meet_guests_question:description')}
                            </FieldDescription>
                        </Flexbox>

                        <Flexbox direction="column" align="stretch" gap={3} width="100%">
                            <StyledAnswerItem
                                type="checkbox"
                                value="they_travel_to_you"
                                label={
                                    <>
                                        {t('services:step_offerings:where_meet_guests_question:answer1_label')}
                                        <span>{serviceAddress}</span>
                                    </>
                                }
                                checked={meetingPoint.includes('they_travel_to_you')}
                                {...form.register('meeting_point')}
                            />

                            <StyledAnswerItem
                                type="checkbox"
                                value="you_travel_to_them"
                                label={
                                    <>
                                        {t('services:step_offerings:where_meet_guests_question:answer2_label')}
                                        <div>
                                            {submissionCities.map(({city}, index) => {
                                                const isLast = index == submissionCities.length - 1
                                                return (
                                                    <span key={city.name}>{`${city.name} ${!isLast ? ', ' : ''}`}</span>
                                                )
                                            })}
                                        </div>
                                    </>
                                }
                                checked={meetingPoint.includes('you_travel_to_them')}
                                {...form.register('meeting_point')}
                            />

                            <InputHelpText error={form.formState.errors.meeting_point?.message} />

                            <FlagsAlertText
                                flags={meetingPointFlags}
                                title={t('services:step_offerings:where_meet_guests_question:title')}
                            />
                        </Flexbox>

                        <Divider direction="horizontal" endSpacing={2} startSpacing={2} />
                        <Controller
                            control={form.control}
                            name="main_photo"
                            render={({field}) => (
                                <OfferingPhotoUploader
                                    serviceId={serviceId}
                                    value={field.value ?? null}
                                    onChange={field.onChange}
                                    uploadStatusState={uploadStatusState}
                                    mainPhotoFlags={mainPhotoFlags}
                                />
                            )}
                        />
                    </Flexbox>
                </StyledOfferingForm>
            </ModalBody>
            <ModalFooter>
                <Flexbox align="center" justify="space-between" width="100%">
                    <Button variant="tertiary" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={
                            addOfferingMutation.isPending ||
                            updateOfferingMutation.isPending ||
                            uploadStatusState[0] == 'pending'
                        }
                    >
                        {t('commons:confirm')}
                        {(addOfferingMutation.isPending || updateOfferingMutation.isPending) && <Spinner />}
                    </Button>
                </Flexbox>
            </ModalFooter>
        </StyledOfferingSlidingModal>
    )
}
