import {ComboboxItem, ComboboxPopover} from '@ariakit/react'
import styled, {css} from 'styled-components'
import {makeSelectItemStyle, makeSelectPopoverStyle} from '@/components/ui/select-atoms/style'
import {makeInputStyle} from '@/components/commons/input-text/style'

export const StyledComboboxPopover = styled(ComboboxPopover)(
    ({theme}) => css`
        ${makeSelectPopoverStyle(theme)}
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${theme.spacing}px;
        max-height: min(var(--popover-available-height, 300px), 300px);
        overflow: auto;
        overscroll-behavior: contain;
    `
)

export const StyledComboboxInputWrapper = styled.div(
    ({theme: {palette}}) => css`
        position: relative;
        width: 100%;
        & svg {
            width: 20px;
            height: 20px;
            fill: ${palette.neutral['500']};
        }
    `
)

export const StyledComboboxInputSideComponent = styled.div<{$side: 'left' | 'right'}>(
    ({theme: {spacing}, $side}) => css`
        position: absolute;
        display: flex;
        top: 50%;
        transform: translateY(-50%);

        ${$side == 'left' &&
        css`
            left: ${spacing * 2}px;
        `}

        ${$side == 'right' &&
        css`
            right: ${spacing * 2}px;
        `}
    `
)

export const StyledComboboxItem = styled(ComboboxItem)(
    ({theme}) => css`
        ${makeSelectItemStyle(theme)}
        ${theme.typography.textMd};

        &[aria-selected='true'] {
            font-weight: 400;
            background-color: ${theme.palette.neutral[100]};
        }

        &:hover:not([data-active-item='true']) {
            background-color: ${theme.palette.neutral[100]};
        }
    `
)

type StyledComboboxInputTextProps = {
    $size: 'sm' | 'md' | 'lg'
    $hasTypeIcon: boolean
    $hasCloseCombobox: boolean
    $hasError: boolean
}

export const makeComboboxInputSizeStyle = {
    sm: css`
        height: 40px;
    `,
    md: css`
        height: 44px;
    `,
    lg: css`
        height: 48px;
    `
}

export const StyledComboboxInputText = styled.input<StyledComboboxInputTextProps>(
    ({theme, $hasCloseCombobox, $hasTypeIcon, $hasError, $size}) => css`
        ${makeInputStyle(theme)}
        ${makeComboboxInputSizeStyle[$size]};
        background: ${theme.palette.neutral.white};
        border: 1px solid ${theme.palette.neutral['300']};
        border-radius: 8px;
        width: 100%;
        transition: ${theme.transition};
        cursor: pointer;
        border-radius: 8px;
        &:hover {
            box-shadow: none;
        }

        &:not(&:has(input:disabled)):hover,
        &:not(&:has(input:disabled)):focus {
            border: 1px solid ${$hasError ? theme.palette.danger['300'] : theme.palette.primary['300']};
            box-shadow: ${`${theme.shadows.xs}, 0px 0px 0px 4px ${
                $hasError ? theme.palette.danger['100'] : theme.palette.primary['100']
            }`};
        }

        ${$hasCloseCombobox &&
        css`
            padding-right: ${theme.spacing * 8.5}px;
        `}

        ${$hasTypeIcon
            ? css`
                  padding-left: ${theme.spacing * 8.5}px;
              `
            : css`
                  padding: ${theme.spacing * 2}px ${theme.spacing * 1.5}px;
              `}

        ${$hasError &&
        css`
            border: 1px solid ${theme.palette.danger['300']};
        `};
    `
)
