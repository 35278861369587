import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StyledOtpStepBody, StyledOtpStepHeader, StyledOtpStepWrapper} from '@/features/otp-flow/components/style.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {useServicesProposals} from '@/features/promotional-services/services/queries/useServicesProposals.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import {getNotificationPreferences} from '@/features/otp-flow/utils'
import {User} from '@/features/sso/types'
import {Host} from '@/features/service/types'

export const OtpEmailBaseStep = () => {
    const {t} = useTranslation()
    //host params store
    const hostCode = useHostParamsStore(state => state.hostCode)
    const hostPassword = useHostParamsStore(state => state.hostPassword)
    const {targetedHost} = useServicesProposals({
        hostCode: Number(hostCode),
        hostPassword: String(hostPassword)
    })
    //otp store
    const otpChangeStep = useOtpStore(state => state.changeStep)
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)

    //auth store
    const user = useAuthStore(state => state.user)
    //email to show
    const {email} = getNotificationPreferences({
        servicePreferences: service?.notification_preferences,
        coAlarmPreferences: coAlarmRequest?.notification_preferences,
        user: user as User,
        currentHost: targetedHost as Host
    })

    return (
        <StyledOtpStepWrapper direction={'column'} justify={'stretch'} gap={1}>
            <StyledOtpStepHeader justify={'space-between'} align={'center'}>
                <h2>{t(AcceptanceStepsConfigByService['email'].generic.base.title)}</h2>
                <Button variant={'tertiary'} size={'md'} onClick={() => otpChangeStep('edit', true)}>
                    {t('commons:edit')}
                </Button>
            </StyledOtpStepHeader>
            <StyledOtpStepBody direction={'column'} gap={6}>
                <InputText width={300} type={'text'} disabled={true} value={email ? `${email}` : '-'} />
            </StyledOtpStepBody>
        </StyledOtpStepWrapper>
    )
}

OtpEmailBaseStep.displayName = 'OtpEmailBaseStep'
