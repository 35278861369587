import styled, {css} from 'styled-components'

export const StyledButtonAreaCta = styled.span(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        display: block;
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledButtonAreaDescription = styled.span(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['700']};
    `
)
