import {z} from 'zod'

/**
 * Actions schema and Types
 */
export const ServiceAction = z.object({
    id: z.number().int().min(1).optional(),
    key_name: z.enum([
        '3_key_elements_video',
        'block_date',
        'check_accessibility_features',
        'host_portrait',
        'important_video_info',
        'important_experience_photo_info',
        'listing_status',
        'set_date',
        'text_notification',
        'update_listing_photos'
    ]),
    link: z.string().optional(),
    value: z.boolean().optional()
})
export type ServiceAction = z.infer<typeof ServiceAction>
