import {ComboboxInput} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import {SelectComboboxPopover, SelectItem, SelectTrigger} from '@/components/ui/select-atoms/SelectAtoms'
import styled, {css} from 'styled-components'

export const StyledSelectComboboxInput = styled(ComboboxInput)`
    box-shadow: none !important;
`
export const StyledCountrySelectItem = styled(SelectItem)(
    ({theme: {palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &[aria-selected='true'] {
            background-color: ${palette.neutral[100]};
        }
    `
)

export const StyledSelectTrigger = styled(SelectTrigger)<{$hasError?: boolean}>(
    ({theme: {palette, typography}, $hasError}) => css`
        ${typography.textMd};
        font-weight: 500;
        height: 40px;
        ${$hasError &&
        css`
            border-color: ${palette.danger['300']};
        `}
    `
)

export const StyledCountryComboboxPopover = styled(SelectComboboxPopover)(
    ({theme: {zIndex}}) => css`
        z-index: ${zIndex.modal};
    `
)
