import {Label} from '@/components/ui/label/Label'
import {SelectItem, SelectList} from '@/components/ui/select-atoms/SelectAtoms'
import {useTranslation} from '@/translations/i18n'
import {SelectProvider} from '@ariakit/react'
import {
    StyledOfferingDurationSelectPopover,
    StyledOfferingDurationSelectPopoverWrapper,
    StyledSelectDurationSelectorTrigger
} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {forwardRef} from 'react'

interface OfferingDurationSelectorProps {
    errorMessage?: string
    onChange?: (value: string) => void
    value?: string
}

export const OfferingDurationSelector = forwardRef<HTMLSelectElement, OfferingDurationSelectorProps>(
    ({errorMessage, onChange, value}, ref) => {
        const {t} = useTranslation()

        return (
            <Flexbox direction="column" gap={1.5}>
                <SelectProvider value={value ?? ''} setValue={onChange}>
                    <Label>{t('services:step_offerings:select_duration_label')}</Label>
                    <StyledSelectDurationSelectorTrigger
                        fullWidth
                        placeholder={t('services:step_offerings:select_duration_placeholder')}
                        displayValue={value => t('commons:minutes', {minutes: value, count: parseInt(value as string)})}
                        $hasError={!!errorMessage}
                        ref={ref}
                    />
                    <StyledOfferingDurationSelectPopoverWrapper>
                        <StyledOfferingDurationSelectPopover portal={false}>
                            <SelectList>
                                {Array.from({length: 8}).map((_, index) => {
                                    const minutes = (index + 1) * 30
                                    return (
                                        <SelectItem key={index} value={minutes.toString()}>
                                            {t('commons:minutes', {minutes: minutes, count: minutes})}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </StyledOfferingDurationSelectPopover>
                    </StyledOfferingDurationSelectPopoverWrapper>
                </SelectProvider>
                {errorMessage && <InputHelpText error={errorMessage} />}
            </Flexbox>
        )
    }
)
