import {StyledMatterportIntroFooter} from './style'
import {Button} from '@components/ui/button/Button'
import {useTranslation} from '@/translations/i18n.tsx'

export const MatterportIntroFooter = ({onClose}: {onClose: () => void}) => {
    const {t} = useTranslation()

    return (
        <StyledMatterportIntroFooter align="center" justify="space-between" width={'100%'}>
            <Button variant="tertiary" onClick={onClose}>
                {t('commons:close')}
            </Button>
            <Button variant="primary" onClick={onClose}>
                {t('commons:continue')}
            </Button>
        </StyledMatterportIntroFooter>
    )
}
