import {
    handleCloseSlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {Dispatch, FC, SetStateAction, useState} from 'react'
import {
    StyledModalHeader,
    StyledQuizModal,
    StyledSuccessIconContainer,
    StyledSuccessTexts,
    StyledTextarea
} from '@/features/experiences-host-video/components/support-modal/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    ExperiencesHostVideoModalBody,
    ExperiencesHostVideoParagraph
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import toast from 'react-hot-toast'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {ModalFooter} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {CheckIcon} from '@components/ui/icon'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {httpSendExpServiceSupportRequest} from '@/features/services/services/services.http.ts'

const TEXTAREA_LIMIT = 450

export const ExpServiceSupportModal: FC<{
    slidingModalState: SlidingModalState
    setSlidingModalState: Dispatch<SetStateAction<SlidingModalState>>
}> = ({slidingModalState, setSlidingModalState}) => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(z.object({id: z.coerce.number()}))
    const askSupport = useAsync()
    const [textareaValue, setTextareaValue] = useState('')

    const onSend = async () => {
        try {
            await askSupport.run(
                httpSendExpServiceSupportRequest({
                    urlParams: {id: urlParams.success ? urlParams.data.id : undefined},
                    payload: {notes: textareaValue}
                })
            )
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <StyledQuizModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {t('services:need_help:title')}
            </StyledModalHeader>
            {askSupport.isSuccess ? (
                <ExperiencesHostVideoModalBody justify="center" align="center">
                    <StyledSuccessIconContainer>
                        <CheckIcon size={36} />
                    </StyledSuccessIconContainer>
                    <StyledSuccessTexts>
                        <h3>{t('experiences_host_video:support_modal:success_title')}</h3>
                        <ExperiencesHostVideoParagraph>
                            {t('experiences_host_video:support_modal:success_description')}
                        </ExperiencesHostVideoParagraph>
                    </StyledSuccessTexts>
                </ExperiencesHostVideoModalBody>
            ) : (
                <ExperiencesHostVideoModalBody>
                    <ExperiencesHostVideoParagraph>{t('services:need_help:description')}</ExperiencesHostVideoParagraph>
                    <Flexbox direction="column" align="flex-end" gap={2.5} width="100%">
                        <StyledTextarea
                            onChange={event => setTextareaValue(event.target.value)}
                            rows={6}
                            maxLength={TEXTAREA_LIMIT}
                            label={t('services:need_help:label')}
                            placeholder={t('services:need_help:placeholder')}
                            helpText={t('services:x_characters_available', {
                                count: TEXTAREA_LIMIT - textareaValue.length
                            })}
                        />
                        <Button disabled={!textareaValue || askSupport.isLoading} onClick={onSend}>
                            {t('services:need_help:send')}
                            {askSupport.isLoading && <Spinner />}
                        </Button>
                    </Flexbox>
                </ExperiencesHostVideoModalBody>
            )}
            {askSupport.isSuccess && (
                <ModalFooter>
                    <Button fullWidth onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                        {t('commons:close')}
                    </Button>
                </ModalFooter>
            )}
        </StyledQuizModal>
    )
}
