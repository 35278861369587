import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {Service} from '@/features/services/types.ts'
import {httpUpdateStep, HttpUpdateStepOptions, UpdateStepAnswers} from '@/features/services/services/services.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'

export const useUpdateStep = <Step extends keyof UpdateStepAnswers, IsSubmit extends boolean>({
    serviceId,
    step,
    subStep,
    expertiseId,
    isSubmit,
    options
}: {
    serviceId: number
    step: IsSubmit extends true ? undefined : Service['step']['step']
    subStep: IsSubmit extends true ? undefined : Service['step']['sub_step']
    expertiseId: number
    isSubmit: boolean
    options?: Omit<
        UseMutationOptions<Service, unknown, HttpUpdateStepOptions<Step>['payload']['answers']>,
        'mutationFn'
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: answers =>
            parseAxiosPromise({
                axiosPromise: httpUpdateStep<Step>({
                    urlParams: {serviceId, expertiseId},
                    params: {response_type: 'extended'},
                    payload: {
                        answers,
                        step: step ?? undefined,
                        sub_step: subStep ?? undefined,
                        is_review_completed: isSubmit
                    }
                }),
                responseShape: Service,
                onZodError: captureException
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE, serviceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
