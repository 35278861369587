import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {CalendarPlus02Icon} from '@components/ui/icon'
import {ShareHostDatesProcess} from '@/features/service/components/host-date-picker/ShareHostDatesProcess.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {useShareHostDatesStore} from '@/features/service/store/ShareHostDatesStore.tsx'
import {FC} from 'react'
import {ServiceType} from '@/types/commons.ts'
import {HostAvailabilityDate, Quote} from '@/features/service/types.ts'
import {httpClickAction} from '@/features/service-actions/services/actions.http.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'

const serviceTypeToTitleTKey = {
    home: 'service_details:actions:share_dates:home:title',
    experience_photo: 'service_details:actions:share_dates:home:title',
    experience_video: 'service_details:actions:share_dates:experience_video:title',
    matterport: 'service_details:actions:share_dates:matterport:title',
    walkthrough: 'service_details:actions:share_dates:walkthrough:title'
} as const satisfies Record<ServiceType, TKey>
const serviceTypeToParagraphTKey = {
    home: 'service_details:actions:share_dates:home:paragraph',
    experience_photo: 'service_details:actions:share_dates:home:paragraph',
    experience_video: 'service_details:actions:share_dates:experience_video:paragraph',
    matterport: 'service_details:actions:share_dates:matterport:paragraph',
    walkthrough: 'service_details:actions:share_dates:walkthrough:paragraph'
} as const satisfies Record<ServiceType, TKey>

interface HostDatesActionProps {
    actionId: ServiceAction['id']
    hostAvailabilityDates: [HostAvailabilityDate, HostAvailabilityDate] | []
    isCompleted: boolean
    quoteId: Quote['id']
    serviceType: ServiceType
}
export const HostDatesAction: FC<HostDatesActionProps> = ({
    actionId,
    hostAvailabilityDates,
    isCompleted,
    serviceType
}) => {
    const {t} = useTranslation()
    const isHostDatePickerModalVisible = useShareHostDatesStore(state => state.modalVisible)
    const toggleShareHostDatesModal = useShareHostDatesStore(state => state.toggleModal)
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()

    const clickAction = () => {
        toggleShareHostDatesModal()
        void httpClickAction({actionId, ...urlParams})
    }

    return (
        <>
            <ActionCard
                isCompleted={isCompleted}
                icon={CalendarPlus02Icon}
                paragraph={t(serviceTypeToParagraphTKey[serviceType])}
                title={t(serviceTypeToTitleTKey[serviceType])}
                onClick={clickAction}
            />
            {isHostDatePickerModalVisible && (
                <ShareHostDatesProcess
                    hostAvailabilityDates={hostAvailabilityDates}
                    urlParams={urlParams}
                    serviceType={serviceType}
                    onClose={toggleShareHostDatesModal}
                />
            )}
        </>
    )
}

HostDatesAction.displayName = 'HostDatesAction'
