import {FC} from 'react'
import {AccessibilityImage, Amenity, Room} from '../types'
import {
    StyledMatterportAccessibilityCard,
    StyledMatterportAccessibilityCardButton,
    StyledMatterportAccessibilityCardButtonsWrapper
} from './style'
import {useTranslation} from '@/translations/i18n.tsx'
import {CheckIcon, XCloseIcon} from '@/components/ui/icon'
import {useMatterportStore} from '../store/MatterportStore'

interface MatterportAccessibilityCardProps {
    room: Room
    amenity: Amenity
    image: AccessibilityImage
}

export const MatterportAccessibilityCard: FC<MatterportAccessibilityCardProps> = ({image}) => {
    const toReviewList = useMatterportStore(state => state.toReviewList)
    const updateReview = useMatterportStore(state => state.updateReview)
    const {t} = useTranslation()

    return (
        <StyledMatterportAccessibilityCard direction="column" gap={4}>
            <img id={image.id.toString()} src={image.url} alt={image.name} loading="lazy" />
            <StyledMatterportAccessibilityCardButtonsWrapper gap={2} width="100%">
                <StyledMatterportAccessibilityCardButton
                    variant={toReviewList?.[image.id].accepted ? 'primary' : 'secondary'}
                    fullWidth
                    onClick={() => updateReview('add', image.id)}
                >
                    <CheckIcon size={20} />
                    {t('matterport:review:add_btn')}
                </StyledMatterportAccessibilityCardButton>
                <StyledMatterportAccessibilityCardButton
                    variant={toReviewList?.[image.id].removed ? 'primary' : 'secondary'}
                    fullWidth
                    onClick={() => updateReview('not_add', image.id)}
                >
                    <XCloseIcon size={20} />
                    {t('matterport:review:remove_btn')}
                </StyledMatterportAccessibilityCardButton>
            </StyledMatterportAccessibilityCardButtonsWrapper>
        </StyledMatterportAccessibilityCard>
    )
}
