import {handleCloseSlidingModal, SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {StyledHeaderTitle, StyledList, StyledModalHeader, StyledSlidingModal, StyledTitle} from './style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {ModalBody} from '@/components/ui/modal-atoms/ModalAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ParentFlag} from '@/features/services/types'
import {flagToDescription} from '@/features/services/utils'
import {ReactNode} from 'react'

interface FlagsModalProps {
    flags: ParentFlag[]
    title: ReactNode
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
    detailLevel?: 'default' | 'offering_title' | 'offering_description'
}

export const FlagsModal = ({
    flags,
    title,
    setSlidingModalState,
    slidingModalState,
    detailLevel = 'default'
}: FlagsModalProps) => {
    const {t} = useTranslation()

    return (
        <StyledSlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledModalHeader>
                <Button variant="ghost" shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                    <XCloseIcon size={36} />
                </Button>
                <StyledHeaderTitle>{title}</StyledHeaderTitle>
            </StyledModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={4}>
                    <StyledTitle>{t('services:flag_alert_text')}</StyledTitle>
                    <StyledList>
                        {flags.map(flag => {
                            const description = flagToDescription(flag.key_name, detailLevel)
                            return !!description && <li key={flag.key_name}>{description}</li>
                        })}
                    </StyledList>
                </Flexbox>
            </ModalBody>
        </StyledSlidingModal>
    )
}
