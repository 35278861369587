import styled, {css} from 'styled-components'
import {spacing} from '@/theme'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledGalleryItemImg = styled.img(
    () => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `
)

export const StyledGalleryItemContainer = styled.div(
    ({theme: {palette, opacities}}) => css`
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        background: ${palette.neutral['600']}${opacities[30]};
        aspect-ratio: 1;
        width: 100%;
    `
)

export const StyledGalleryActionButton = styled(Button)(
    ({theme}) => css`
        position: absolute;
        background: ${theme.palette.neutral.white}${theme.opacities[80]};
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        z-index: 1;
    `
)

export const StyledGalleryItemLoaderContainer = styled.div(
    ({theme: {palette, typography, opacities}}) => css`
        position: absolute;
        background: ${palette.neutral['600']}${opacities[30]};
        color: ${palette.neutral.white};
        font-weight: 500;
        ${typography.textSm}
        backdrop-filter: blur(3px);
        border-radius: 8px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    `
)
