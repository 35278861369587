import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {StyledActions, StyledActionsTitles} from '@/features/service-actions/components/actions/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {MatterportImagesAction} from '@/features/service-actions/components/matterport-images-action/MatterportImagesActions.tsx'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {FC, ReactElement, useState} from 'react'
import {BlockCalendarDateAction} from '@/features/service-actions/components/block-calendar-date-action/BlockCalendarDateAction.tsx'
import {CheckListingStatusAction} from '@/features/service-actions/components/check-listing-status-action/CheckListingStatusAction.tsx'
import {ProfilePhotoAction} from '@/features/service-actions/components/profile-photo-action/ProfilePhotoAction.tsx'
import {HostDatesAction} from '@/features/service-actions/components/host-dates-action/HostDatesAction.tsx'
import {TextNotificationAction} from '@/features/service-actions/components/text-notifications-action/TextNotificationAction.tsx'
import {HttpGetServiceResponse} from '@/features/service/services/service.http.ts'
import {ImportantVideoInfoAction} from '@/features/service-actions/components/important-video-info-action/ImportantVideoInfoAction.tsx'
import {KeyElementsVideoAction} from '@/features/service-actions/components/key-elements-video-action/KeyElementsVideoAction.tsx'
import {UpdateListingPhotosAction} from '@/features/service-actions/components/update-listing-photos-actions/UpdateListingPhotosAction.tsx'
import {ServiceType} from '@/types/commons.ts'
import {ImportantExperiencePhotoInfoAction} from '@/features/service-actions/components/important_experience_photo_info-action/ImportantExperiencePhotoInfoAction.tsx'

const serviceTypeToTitleTKey = {
    home: 'service_details:actions:title:home',
    experience_photo: 'service_details:actions:title:home',
    experience_video: 'service_details:actions:title:experience_video',
    matterport: 'service_details:actions:title:matterport',
    walkthrough: 'service_details:actions:title:walkthrough'
} as const satisfies Record<ServiceType, TKey>

export const Actions: FC<{service: HttpGetServiceResponse}> = ({service}) => {
    const {t} = useTranslation()
    const [pendingActionsCount, setPendingActionsCount] = useState(
        service.actions.filter(action => !action.value).length
    )
    const serviceType = getServiceType(service.task_media.name, service.task_category.name)

    return (
        <StyledActions direction="column" gap={6}>
            <StyledActionsTitles>
                <h3>{t(serviceTypeToTitleTKey[serviceType])}</h3>
                {pendingActionsCount >= 1 && (
                    <h4>{t('service_details:actions:subtitle', {count: pendingActionsCount})}</h4>
                )}
            </StyledActionsTitles>
            <Flexbox className="actions-list" align="stretch" gap={6}>
                {service.actions.map(action => {
                    const actionNameToComponent = {
                        '3_key_elements_video': (
                            <KeyElementsVideoAction
                                actionId={action.id}
                                isCompleted={!!action.value}
                                key={action.id}
                                freelancerEmail={service.assignment?.photographer?.email}
                            />
                        ),
                        block_date: (
                            <BlockCalendarDateAction
                                setPendingActionsCount={setPendingActionsCount}
                                actionId={action.id}
                                isCompleted={!!action.value}
                                link={action.link}
                                quoteId={service.quote.id}
                                serviceType={serviceType}
                                key={action.id}
                            />
                        ),
                        check_accessibility_features: (
                            <MatterportImagesAction actionId={action.id} isCompleted={!!action.value} key={action.id} />
                        ),
                        host_portrait: (
                            <ProfilePhotoAction actionId={action.id} isCompleted={!!action.value} key={action.id} />
                        ),
                        important_experience_photo_info: (
                            <ImportantExperiencePhotoInfoAction
                                isCompleted={!!action.value}
                                key={action.id}
                                actionId={action.id}
                            />
                        ),
                        important_video_info: (
                            <ImportantVideoInfoAction
                                isCompleted={!!action.value}
                                key={action.id}
                                actionId={action.id}
                            />
                        ),
                        listing_status: (
                            <CheckListingStatusAction
                                setPendingActionsCount={setPendingActionsCount}
                                actionId={action.id}
                                isCompleted={!!action.value}
                                link={action.link}
                                key={action.id}
                            />
                        ),
                        set_date: (
                            <HostDatesAction
                                actionId={action.id}
                                hostAvailabilityDates={service.host_availability_dates}
                                isCompleted={!!action.value}
                                quoteId={service.quote.id}
                                serviceType={serviceType}
                                key={action.id}
                            />
                        ),
                        text_notification: (
                            <TextNotificationAction isCompleted={!!action.value} actionId={action.id} key={action.id} />
                        ),
                        update_listing_photos: (
                            <UpdateListingPhotosAction
                                setPendingActionsCount={setPendingActionsCount}
                                actionId={action.id}
                                isCompleted={!!action.value}
                                link={action.link}
                                quoteId={service.quote.id}
                                key={action.id}
                            />
                        )
                    } as const satisfies Record<ServiceAction['key_name'], ReactElement>

                    return actionNameToComponent[action.key_name]
                })}
            </Flexbox>
        </StyledActions>
    )
}

Actions.displayName = 'Actions'
