import {
    StyledAcceptStepBody,
    StyledAcceptStepDesc,
    StyledAcceptStepFooter,
    StyledAcceptStepHeader
} from '@/features/service-acceptance-flow/components/style.ts'
import {SearchLgIcon} from '@components/ui/icon'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {useTheme} from 'styled-components'

export const PhotoPreparation1Step = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const changeStep = useServiceAcceptStore(state => state.changeStep)

    return (
        <>
            <StyledAcceptStepHeader align="center" justify="center">
                {t('service_accept:common:prepare_for_photoshoot')}
            </StyledAcceptStepHeader>
            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />

            <StyledAcceptStepBody direction={'column'} gap={6}>
                <StyledAcceptStepDesc direction={'column'} gap={2}>
                    <IconContainer size={'lg'}>
                        <SearchLgIcon fill={theme.palette.primary['600']} />
                    </IconContainer>
                    <h5>{t('service_accept:photo_preparation_1:title')}</h5>
                    <small>{t('service_accept:photo_preparation_1:paragraph')}</small>
                </StyledAcceptStepDesc>
            </StyledAcceptStepBody>

            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />
            <StyledAcceptStepFooter>
                <Button fullWidth variant="primary" size="md" onClick={() => changeStep('photo_preparation_2')}>
                    {t('commons:continue')}
                </Button>
            </StyledAcceptStepFooter>
        </>
    )
}
