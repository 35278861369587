import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpGetServices, HttpGetServicesOptionsProps} from '@/features/services/services/services.http.ts'
import {z} from 'zod'
import {Service} from '@/features/services/types.ts'

export const useServices = (params: HttpGetServicesOptionsProps) =>
    useQuery({
        queryKey: [QUERY_KEYS.SERVICES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServices(params),
                responseShape: z.array(Service),
                onZodError: captureException
            })
    })
