import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import Container from '@components/ui/container/Container.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {HostExperienceProposal} from '@/features/experiences-host-video/types.ts'
import {
    StyledServicesSectionBody,
    StyledServicesSectionHeader,
    StyledServicesSectionWrapper
} from '@/features/promotional-services/components/promo-services-section/style.ts'
import {HostExpProposalsSectionCard} from '@/features/experiences-host-video/components/host-experience-proposals-section-card/HostExpPromoSectionCard.tsx'

export interface HostExpProposalsSectionProps {
    hostExpProposals: HostExperienceProposal[]
}

export const HostExpProposalsSection: FC<HostExpProposalsSectionProps> = ({hostExpProposals}) => {
    const {t} = useTranslation()

    return (
        <Container fullHeight={false}>
            <StyledServicesSectionWrapper id={'host-exp'}>
                <StyledServicesSectionHeader>
                    <Flexbox justify={'space-between'} align={'start'}>
                        <div>
                            <h4>{t('experiences_host_video:dash:title')}</h4>
                            <p>{t('experiences_host_video:dash:subtitle')}</p>
                        </div>
                    </Flexbox>
                </StyledServicesSectionHeader>
                <StyledServicesSectionBody>
                    {hostExpProposals.map(experience => (
                        <HostExpProposalsSectionCard experience={experience} key={experience.id} />
                    ))}
                </StyledServicesSectionBody>
            </StyledServicesSectionWrapper>
        </Container>
    )
}

HostExpProposalsSection.displayName = 'HostExpProposalsSection'
