import {matchRoutes, Navigate, Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useEffect, useState} from 'react'
import {LoadingScreen} from '@components/commons/loading-screen/LoadingScreen.tsx'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {mixpanelInit} from '@utilities/mixpanel/mixpanelEventCenter.ts'
import router from '@/router.tsx'

const PrivateRoute = () => {
    const user = useAuthStore(state => state.user)
    const accessToken = useAuthStore(state => state.accessToken)
    const setAccessToken = useAuthStore(state => state.setAccessToken)
    const reset = useAuthStore(state => state.reset)
    const [guardCondition, setGuardCondition] = useState(false)
    const location = useLocation()

    // Intercept the impersonate token from Navigate
    const [searchParams, setSearchParams] = useSearchParams()
    const impersonateAccessToken = searchParams.get('impersonate')
    const impersonateService = searchParams.get('service')
    const navigate = useNavigate()

    useEffect(() => {
        if (impersonateAccessToken && impersonateService) {
            reset()
            setAccessToken(impersonateAccessToken)
            searchParams.delete('impersonate')
            setSearchParams(searchParams)
            navigate(impersonateService)
        }
    }, [impersonateAccessToken])

    useEffect(() => {
        if (user) {
            //TODO: write here the necessaries checks
            setGuardCondition(true)
            //Set mixpanel user globally
            mixpanelInit(user.id)
        }
    }, [user])

    const loginRedirectPath = () => {
        const matchedRoutes = matchRoutes(router.routes, location) ?? []
        const currentRoutePath = matchedRoutes.at(-1)?.route.path

        if (currentRoutePath == routes.CATEGORIES.path) {
            return routes.CATEGORIES_SSO_LOGIN.path
        }
        if (currentRoutePath == routes.SERVICES.path || currentRoutePath == routes.SERVICE.path) {
            return routes.SERVICES_LOGIN.path
        }
        if (
            currentRoutePath == routes.CO_ALARM_DASHBOARD.path ||
            currentRoutePath == routes.CO_ALARM_INACTIVE.path ||
            currentRoutePath == routes.CO_ALARM_SEND_REQUEST.path ||
            currentRoutePath == routes.CO_ALARM_REQUEST_DETAILS.path
        ) {
            return routes.CO_ALARM_SSO_LOGIN.path
        }

        return routes.SSO_LOGIN.path
    }

    return (
        <>
            {!accessToken && <Navigate to={loginRedirectPath()} />}
            {user && guardCondition ? <Outlet /> : <LoadingScreen />}
        </>
    )
}

export default PrivateRoute
