import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {parseAxiosPromise} from '@/utilities/helpers'
import {PaginationParams} from '@/types/commons'
import {httpGetCountries, HttpGetCountriesOptions} from '@/features/services/services/services.http'
import {Country} from '@/features/services/types'

export const HttpGetCountriesResponse = z.object({
    data: z.array(Country),
    ...PaginationParams.shape
})
export type HttpGetCountriesResponse = z.infer<typeof HttpGetCountriesResponse>

export const useCountries = (
    promiseArgs: Omit<HttpGetCountriesOptions, 'page'>,
    options?: Pick<UseInfiniteQueryOptions<HttpGetCountriesResponse>, 'enabled'>
) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.COUNTRIES, promiseArgs],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetCountries({
                    response_type: promiseArgs.response_type,
                    search: promiseArgs.search,
                    page: pageParam,
                    per_page: promiseArgs.per_page
                }),
                responseShape: HttpGetCountriesResponse,
                onZodError: captureException
            }),
        ...options,
        initialPageParam: 1,
        getNextPageParam: lastPage => (lastPage.current_page > lastPage.last_page ? lastPage.current_page + 1 : null)
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    }
}
