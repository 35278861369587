import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {CoAlarmSendRequestInfoText} from './style'

export const CoAlarmSendRequestInfo = () => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={4}>
            <CoAlarmSendRequestInfoText>{t('coAlarm:form:shipping_times_info')}</CoAlarmSendRequestInfoText>
            <CoAlarmSendRequestInfoText>
                <Trans
                    i18nKey="coAlarm:form:policies"
                    components={[
                        <a href={'https://www.airbnb.com/help/article/1128'} target={'_blank'} />,
                        <a href={'https://www.airbnb.com/help/article/2855/'} target={'_blank'} />
                    ]}
                />
            </CoAlarmSendRequestInfoText>
        </Flexbox>
    )
}
