import styled, {css, DefaultTheme} from 'styled-components'

type StyledInputProps = {
    touched: boolean
    hasError: boolean
    disabled?: boolean
    visibilityToggle?: boolean
    width?: number
    size: 'sm' | 'md' | 'lg'
}

export const StyledInputContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
    `}
`

export const StyledInputGrid = styled.div`
    ${() => css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `}
`

export const StyledInputWrapper = styled.div<StyledInputProps>`
    ${({width, disabled, visibilityToggle, hasError, theme: {spacing, palette, shadows}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: ${visibilityToggle ? '8px 0 0 8px' : '8px'};
        width: ${width ? `${width}px` : `100%`};
        transition: ease-in-out 200ms;
        cursor: pointer;

        ${hasError &&
        css`
            border: 1px solid ${palette.danger['300']};
        `};

        ${disabled &&
        css`
            background: ${palette.neutral['100']};
            color: ${palette.neutral['500']};
            cursor: not-allowed;
        `};

        ${!disabled &&
        css`
            &:not(:disabled):hover {
                border: 1px solid ${hasError ? palette.danger['300'] : palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${
                    hasError ? palette.danger['100'] : palette.primary['100']
                }`};
            }
        `}
    `}
    ${({size, theme}) => InputSizeStyles(theme)[size]};
`

const InputSizeStyles = ({spacing}: DefaultTheme) => ({
    sm: css`
        height: 40px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3}px; // check ui
    `,
    md: css`
        height: 44px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3.5}px;
    `,
    lg: css`
        height: 48px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 4}px;
    `
})

export const makeInputStyle = (theme: DefaultTheme) => css`
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: ${theme.palette.neutral['900']};
    ${theme.typography.textMd}

    &:focus {
        outline: none;
    }
    &::placeholder {
        color: ${theme.palette.neutral['400']};
    }
    &:disabled {
        cursor: not-allowed;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`

export const StyledInput = styled.input`
    ${({theme}) => css`
        ${makeInputStyle(theme)}
    `}
`

type StyledVisibleToggleProps = {
    disabled?: boolean
    size: 'sm' | 'md' | 'lg'
}
export const StyledVisibleToggle = styled.div<StyledVisibleToggleProps>`
    ${({disabled, theme: {palette}}) => css`
        color: ${palette.neutral['700']};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 0 8px 8px 0;
        border-left: none;
    `}
    ${({size, theme}) => VisibleToggleSizeStyles(theme)[size]};
`

const VisibleToggleSizeStyles = ({spacing}: DefaultTheme) => ({
    sm: css`
        height: 40px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `,
    md: css`
        height: 44px;
        padding: ${spacing * 2.5}px ${spacing * 3}px;
    `,
    lg: css`
        height: 48px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `
})
