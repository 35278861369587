import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {AlertTriangleIcon} from 'src/components/ui/icon'

export const ServiceDetailsURLParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string(),
    quoteId: z.coerce.number().int()
})
export type ServiceDetailsURLParams = z.infer<typeof ServiceDetailsURLParams>

export const ServiceDetailsSearchParams = z.object({
    isTextNotificationsDefaultOpen: z.coerce.boolean()
})
export type ServiceDetailsSearchParams = z.infer<typeof ServiceDetailsSearchParams>

const ServiceRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(ServiceDetailsURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

export default ServiceRoute
