import {StyledContent} from '@/features/services/components/pending-id-verification-step/style.ts'
import {AirbnbIcon, PassportIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {Paragraph} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const PendingIdVerificationStep = () => {
    const {t} = useTranslation()

    return (
        <StyledContent gap={6}>
            <PassportIcon />
            <Flexbox direction="column" align="center" gap={2}>
                <h3>{t('services:step_pending_id_verification:title')}</h3>
                <Paragraph>{t('services:step_pending_id_verification:paragraph')}</Paragraph>
            </Flexbox>
            <ButtonLink to="https://www.airbnb.com/verify" size="lg" fullWidth>
                <AirbnbIcon />
                {t('services:step_pending_id_verification:cta')}
            </ButtonLink>
            <small>{t('services:step_pending_id_verification:description')}</small>
        </StyledContent>
    )
}
