import {OfferingQuestionKeyName, ParentFlag, Service} from '@/features/services/types.ts'
import {
    AlertTriangleIcon,
    CateringIcon,
    HairIcon,
    MakeupIcon,
    MassageIcon,
    NailsIcon,
    PersonalTrainingIcon,
    PhotographyIcon,
    PreparedMealsIcon,
    PrivateChefIcon,
    SpaTreatmentsIcon
} from '@components/ui/icon'
import countryToCurrency, {Countries} from 'country-to-currency'
import {ReactElement, ReactNode} from 'react'
import i18n, {TKey, Trans} from '@/translations/i18n.tsx'
import {ObjectEntries} from '@utilities/helpers.ts'
import {useUpdateStep} from '@/features/services/services/useUpdateStep.ts'
import {UpdateStepAnswers} from '@/features/services/services/services.http.ts'

type ProgressStep =
    | Exclude<Service['step']['step'], 'host_expertise' | 'other_details' | 'declined'>
    | NonNullable<Service['step']['sub_step']>

const progressSteps = [
    'get_started',
    'category',
    'career',
    'expertise',
    'about_yourself',
    'service_location',
    'offerings',
    'gallery',
    'about_guests',
    'more_details',
    'terms'
] as const satisfies ReadonlyArray<ProgressStep>

export const getStepProgressPercentage = (stepName: ProgressStep) => {
    const stepIndex = progressSteps.findIndex(progressStep => progressStep == stepName)
    return ((stepIndex + 1) / progressSteps.length) * 100
}

export const categoryToLabel = {
    photography: 'services:category_photography',
    haircare: 'services:category_hair_styling',
    makeup: 'services:category_makeup',
    massage: 'services:category_massage',
    nails: 'services:category_nails',
    private_chef: 'services:category_chef',
    prepared_meals: 'services:category_prepared_meals',
    personal_training: 'services:category_personal_training',
    catering: 'services:category_catering',
    spa_treatments: 'services:category_spa_treatments',
    generic: 'services:no_category' //TODO remove 'generic' when the category step is enabled again
} as const satisfies Record<NonNullable<Service['category']>['key_name'], TKey>

export const categoryToIcon = {
    photography: <PhotographyIcon />,
    private_chef: <PrivateChefIcon />,
    prepared_meals: <PreparedMealsIcon />,
    catering: <CateringIcon />,
    personal_training: <PersonalTrainingIcon />,
    massage: <MassageIcon />,
    spa_treatments: <SpaTreatmentsIcon />,
    haircare: <HairIcon />,
    makeup: <MakeupIcon />,
    nails: <NailsIcon />,
    generic: <AlertTriangleIcon /> //TODO remove 'generic' when the category step is enabled again
} as const satisfies Record<NonNullable<Service['category']>['key_name'], ReactElement>

const isCountry = (value: string): value is Countries => {
    return Object.keys(countryToCurrency).includes(value)
}

// Define types in separate declarations for better reusability
type GeocoderAddressComponentType =
    | 'street_address'
    | 'route'
    | 'intersection'
    | 'political'
    | 'country'
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'
    | 'administrative_area_level_3'
    | 'administrative_area_level_4'
    | 'administrative_area_level_5'
    | 'administrative_area_level_6'
    | 'administrative_area_level_7'
    | 'colloquial_area'
    | 'locality'
    | 'sublocality'
    | 'sublocality_level_1'
    | 'sublocality_level_2'
    | 'sublocality_level_3'
    | 'sublocality_level_4'
    | 'sublocality_level_5'
    | 'neighborhood'
    | 'premise'
    | 'subpremise'
    | 'plus_code'
    | 'postal_code'
    | 'natural_feature'
    | 'airport'
    | 'park'
    | 'point_of_interest'

type AddressComponent = {
    longName: string
    shortName: string
}

type AddressResult = {
    address: string
    city: string
    country: string
    zip_code: string | null
    state: string
}

type GeoLookup = Partial<Record<GeocoderAddressComponentType, AddressComponent>>

// Gets city name based on priority order of different address types
const getCityFromLookup = (lookup: GeoLookup): string => {
    return (
        lookup.administrative_area_level_3?.shortName || lookup.locality?.longName || lookup.sublocality?.longName || ''
    )
}

// Validates and returns country code
const getValidCountry = (country: string): string => {
    return isCountry(country) ? country : ''
}

/**
 + * Transforms Google Maps Geocoder address components into a structured address object
 + * @param addressComponents - Array of Google Maps Geocoder address components
 + * @returns Structured address object
 + */
export const retrieveAddressDataFromComponents = (
    addressComponents: google.maps.GeocoderAddressComponent[]
): AddressResult => {
    // Transform address components into lookup object
    const lookup = addressComponents.reduce<GeoLookup>((addressValue, component) => {
        component.types.forEach(type => {
            addressValue[type as GeocoderAddressComponentType] = {
                longName: component.long_name,
                shortName: component.short_name
            }
        })
        return addressValue
    }, {})
    // Extract address components with priority order
    const address = lookup.route?.shortName ?? ''
    const city = getCityFromLookup(lookup)
    const state = lookup.administrative_area_level_1?.longName ?? ''
    const zip_code = lookup.postal_code?.shortName ?? null
    const country = getValidCountry(lookup.country?.shortName ?? '')
    return {
        address,
        city,
        country,
        zip_code,
        state
    }
}

type UpdatesRequiredStep = Array<{
    questionKeyName: Service['expertise']['question_answer'][number]['question_key_name'] | OfferingQuestionKeyName
    flags: ParentFlag[]
}>
type UpdatesRequiredSteps = {
    career: UpdatesRequiredStep
    expertise: UpdatesRequiredStep
    aboutYourself: UpdatesRequiredStep
    serviceLocation: UpdatesRequiredStep
    offerings: UpdatesRequiredStep[]
    gallery: {
        id: number
        flags: ParentFlag[]
    }[]
    aboutGuests: UpdatesRequiredStep
    moreDetails: UpdatesRequiredStep
}

export const getUpdatesRequiredSteps = (service: Service): UpdatesRequiredSteps => {
    const expertises = service.expertise.question_answer.reduce(
        (result: Omit<UpdatesRequiredSteps, 'aboutGuests' | 'moreDetails'>, value) => {
            if (value.parent_flags.length >= 1) {
                if (['business_type', 'experience_years', 'summary', 'highlights'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        career: [
                            ...result.career,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['inspiration', 'background', 'accolades'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        expertise: [
                            ...result.expertise,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['links', 'hosting_modality', 'co_hosts', 'profile_photo'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        aboutYourself: [
                            ...result.aboutYourself,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['location', 'cities'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        serviceLocation: [
                            ...result.serviceLocation,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                return result
            }
            return result
        },
        {
            career: [],
            expertise: [],
            aboutYourself: [],
            serviceLocation: [],
            offerings: [],
            gallery: []
        }
    )

    const offerings = service.offerings.reduce((result: UpdatesRequiredSteps['offerings'], offering) => {
        return [
            ...result,
            offering.question_answer.reduce((acc: UpdatesRequiredSteps['offerings'][number], value) => {
                if (value.parent_flags.length >= 1) {
                    if (OfferingQuestionKeyName.options.includes(value.question_key_name)) {
                        return [...acc, {questionKeyName: value.question_key_name, flags: value.parent_flags}]
                    }
                    return acc
                }
                return acc
            }, [])
        ]
    }, [])

    const gallery =
        service.gallery?.images.reduce((result: UpdatesRequiredSteps['gallery'], value) => {
            if (value.parent_flags.length >= 1) {
                return [...result, {id: value.id, flags: value.parent_flags}]
            }
            return result
        }, []) ?? []

    const other = service.expertise.question_answer.reduce(
        (result: Pick<UpdatesRequiredSteps, 'aboutGuests' | 'moreDetails'>, value) => {
            if (value.parent_flags.length >= 1) {
                if (['age_limit', 'age', 'bring_anything', 'what_bring'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        aboutGuests: [
                            ...result.aboutGuests,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (
                    [
                        'main_language',
                        'extra_languages',
                        'group_min_size',
                        'group_max_size',
                        'cancellation_policy'
                    ].includes(value.question_key_name)
                ) {
                    return {
                        ...result,
                        moreDetails: [
                            ...result.moreDetails,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                return result
            }
            return result
        },
        {
            aboutGuests: [],
            moreDetails: []
        }
    )

    return {
        ...expertises,
        offerings,
        gallery,
        ...other
    }
}

export const getPrevNextStepMutations = <Step extends keyof UpdateStepAnswers>({
    service,
    updatesRequiredSteps,
    currentStep
}: {
    service: Service
    updatesRequiredSteps: UpdatesRequiredSteps
    currentStep: keyof UpdatesRequiredSteps | 'suggestions'
}): {
    prev: (() => ReturnType<typeof useUpdateStep<Step, false>>) | null
    next: (() => ReturnType<typeof useUpdateStep<Step, false>>) | null
} => {
    const flaggedSteps = [
        'suggestions' as const,
        ...ObjectEntries(updatesRequiredSteps)
            .filter(([key, value]) => {
                if (key == 'offerings') {
                    return value.flat().length >= 1
                }
                return value.length >= 1
            })
            .map(([key]) => key)
    ]
    const currentStepIndex = flaggedSteps.findIndex(step => step == currentStep)

    const stepToStepSubStep = {
        suggestions: {step: 'suggestions', subStep: null},
        expertise: {step: 'host_expertise', subStep: 'expertise'},
        career: {step: 'host_expertise', subStep: 'career'},
        aboutYourself: {step: 'host_expertise', subStep: 'about_yourself'},
        gallery: {step: 'gallery', subStep: null},
        offerings: {step: 'offerings', subStep: null},
        serviceLocation: {step: 'service_location', subStep: null},
        aboutGuests: {step: 'other_details', subStep: 'about_guests'},
        moreDetails: {step: 'other_details', subStep: 'more_details'}
    } as const satisfies Record<
        typeof currentStep,
        {step: Service['step']['step']; subStep: Service['step']['sub_step']}
    >

    return {
        prev: flaggedSteps[currentStepIndex - 1]
            ? () =>
                  useUpdateStep<Step, false>({
                      serviceId: service.id,
                      expertiseId: service.expertise.id,
                      isSubmit: false,
                      ...stepToStepSubStep[flaggedSteps[currentStepIndex - 1]]
                  })
            : null,
        next: flaggedSteps[currentStepIndex + 1]
            ? () =>
                  useUpdateStep<Step, false>({
                      serviceId: service.id,
                      expertiseId: service.expertise.id,
                      isSubmit: false,
                      ...stepToStepSubStep[flaggedSteps[currentStepIndex + 1]]
                  })
            : null
    }
}

export const flagToDescription = (
    flag: ParentFlag['key_name'],
    detailLevel: 'default' | 'offering_title' | 'offering_description' = 'default'
): ReactNode =>
    (
        ({
            alcohol_smoking: i18n.t('services:flags:alcohol_smoking_title'),
            alcohol_without_license: i18n.t('services:flags:alcohol_without_license_title'),
            animal_welfare_violations: i18n.t('services:flags:animal_welfare_violations_title'),
            black_white: i18n.t('services:flags:black_white_title'),
            blurry: i18n.t('services:flags:blurry_title'),
            blurry_profile_picture: i18n.t('services:flags:blurry_profile_picture_description'),
            cannot_be_cropped_vertically: i18n.t('services:flags:cannot_be_cropped_vertically_title'),
            collages: i18n.t('services:flags:collages_title'),
            duplicate: i18n.t('services:flags:duplicate_title'),
            expertise_unrelated: i18n.t('services:flags:expertise_unrelated_description'),
            heavy_filters_applied: i18n.t('services:flags:heavy_filters_applied_title'),
            hosts_experience_unclear: i18n.t('services:flags:hosts_experience_unclear_description'),
            inappropriate_content: i18n.t('services:flags:inappropriate_content_title'),
            insufficient_formal_experience: i18n.t('services:flags:insufficient_formal_experience_description'),
            irrelevant_lacking_context: i18n.t('services:flags:irrelevant_lacking_context_title'),
            lack_photo_variety: i18n.t('services:flags:lack_photo_variety_description'),
            misleading_inaccurate: i18n.t('services:flags:misleading_inaccurate_title'),
            missing_career_achievements: i18n.t('services:flags:insufficient_formal_experience_description'),
            multiple_countries_selected: i18n.t('services:flags:multiple_countries_selected_description'),
            not_enough_experience_years: undefined,
            not_enough_online_presence: undefined,
            passport_or_logo: i18n.t('services:flags:multiple_countries_selected_description'),
            photo_angle_awkward: i18n.t('services:flags:multiple_countries_selected_description'),
            policy_violation: (
                <Trans
                    i18nKey="services:flags:policy_violation_description"
                    components={{a: <a href={'https://www.airbnb.com/help/article/1451'} />}}
                />
            ),
            poor_lighting_background: i18n.t('services:flags:multiple_countries_selected_description'),
            reputation_proof_insufficient: i18n.t('services:flags:reputation_proof_insufficient_description'),
            selfie_style: i18n.t('services:flags:multiple_countries_selected_description'),
            service_description_unclear: (
                <Trans
                    i18nKey="services:flags:service_description_unclear_description"
                    components={{ul: <ul />, li: <li />}}
                />
            ),
            spelling_grammatical_errors:
                detailLevel == 'offering_description' ? (
                    i18n.t('services:flags:spelling_grammatical_errors_offering_description')
                ) : detailLevel == 'offering_title' ? (
                    <Trans
                        i18nKey="services:flags:spelling_grammatical_errors_offering_title"
                        components={{ul: <ul />, li: <li />}}
                    />
                ) : (
                    i18n.t('services:flags:spelling_grammatical_errors_description')
                ),
            stock_photos_or_screenshots: i18n.t('services:flags:stock_photos_or_screenshots_title'),
            stretched_distorted: i18n.t('services:flags:stretched_distorted_title'),
            text_graphics_overlaid: i18n.t('services:flags:text_graphics_overlaid_title'),
            title_needs_update: i18n.t('services:flags:title_needs_update_description'),
            too_dark: i18n.t('services:flags:too_dark_title')
        }) as const satisfies Record<typeof flag, ReactNode>
    )[flag]
