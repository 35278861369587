import {ComboboxItem, ComboboxPopover} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCitiesMultiSelectComboboxItem = styled(ComboboxItem)(
    ({theme: {spacing}}) => css`
        display: flex;
        background-color: transparent;
        font-weight: 400;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: ${spacing * 2}px ${spacing * 2.5}px;
    `
)

export const StyledCitiesMultiSelectPopover = styled(ComboboxPopover)(
    ({theme: {zIndex}}) => css`
        z-index: ${zIndex.modal};
    `
)

export const StyledCityChip = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        font-weight: 500;
        color: ${palette.neutral['600']};
        padding: ${spacing * 1.5}px ${spacing * 4}px;
        border: 1px solid ${palette.neutral['200']};
        border-radius: 20px;
    `
)

export const StyledCityChips = styled(Flexbox)`
    flex-wrap: wrap;
`
