import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledList = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: ${spacing * 3}px;
    `
)
export const StyledInputContainer = styled(Flexbox)(
    () => css`
        & > div {
            width: 100%;
        }
    `
)
