import axios from '@/axiosInstance.ts'
import {ExperiencesHostVideoRouteParams, Quiz, UploadStepPayload} from '@/features/experiences-host-video/types.ts'
import {ResponseType} from '@/types/commons.ts'

/**
 * httpHostExperienceProposals
 * Here we will fetch the list of the experiences trailers proposals
 */
export interface HttpGetHostExperienceProposalsOptions {
    urlParams: {
        hostCode: number
        hostPassword: string
    }
    params: {
        response_type: ResponseType
    }
}
export const httpGetHostExperienceProposals = ({urlParams, params}: HttpGetHostExperienceProposalsOptions) => {
    return axios.get(`/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video`, {
        params
    })
}

interface HttpChangeStepOptions {
    urlParams: ExperiencesHostVideoRouteParams & {
        status: UploadStepPayload['status']['key_name']
        step: UploadStepPayload['status']['step_key_name']
    }
    params: {
        response_type: ResponseType
    }
}
export const httpUpdateStep = ({urlParams, params}: HttpChangeStepOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/update-step/${urlParams.status}/${urlParams.step}`,
        undefined,
        {params}
    )
export const httpUpdateFriendStep = ({urlParams, params}: HttpChangeStepOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/update-step/${urlParams.status}/${urlParams.step}`,
        undefined,
        {params}
    )

interface HttpShowExperiencesHostVideoOptions {
    urlParams: ExperiencesHostVideoRouteParams
    params: {
        response_type: ResponseType
    }
}
export const httpShowExperiencesHostVideo = ({urlParams, params}: HttpShowExperiencesHostVideoOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}`,
        {params}
    )
export const httpShowExperiencesHostVideoFriend = ({urlParams, params}: HttpShowExperiencesHostVideoOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend`,
        {params}
    )

interface HttpShowExperiencesHostVideoRedirectOptions {
    urlParams: {airbnbId: string; hostCode: number; hostPassword: string}
    params: {
        task_type: 'video'
        response_type: ResponseType
    }
}
export const httpShowExperiencesHostVideoRedirect = ({
    urlParams,
    params
}: HttpShowExperiencesHostVideoRedirectOptions) =>
    axios.get(`/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences/${urlParams.airbnbId}`, {
        params
    })

interface HttpUpdateExperiencesHostVideoOptions {
    urlParams: ExperiencesHostVideoRouteParams
    params: {
        response_type: ResponseType
    }
    payload: {
        is_self_filmed?: boolean
        scheduled_at?: string
        device_id?: number | null
    }
}
export const httpUpdateExperiencesHostVideo = ({urlParams, params, payload}: HttpUpdateExperiencesHostVideoOptions) =>
    axios.patch(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}`,
        payload,
        {params}
    )

export interface ExperiencesHostVideoUploadRouteParams extends ExperiencesHostVideoRouteParams {
    videoSetId: number
}
/*Presign bulk clips*/
interface HttpGetPresignBulkClipsOptions {
    urlParams: ExperiencesHostVideoUploadRouteParams
    payload: {medias: string[]}
}
export const httpGetPresignBulkClips = ({urlParams, payload}: HttpGetPresignBulkClipsOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-videos/presigned-bulk`,
        payload
    )

/*Confirm upload clips*/
interface HttpConfirmUploadClipsOptions extends ExperiencesHostVideoUploadRouteParams {
    mediaId: number
}
export const httpConfirmUploadClips = (urlParams: HttpConfirmUploadClipsOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-videos/${urlParams.mediaId}/upload-completed`
    )

/*Delete uploaded images bulk*/
interface HttpBulkDeleteUploadedClipsOptions {
    urlParams: ExperiencesHostVideoUploadRouteParams
}
export const httpBulkDeleteUploadedClips = ({urlParams}: HttpBulkDeleteUploadedClipsOptions) =>
    axios.delete(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-videos/bulk`
    )
/*Delete uploaded images*/
interface HttpDeleteUploadedClipsOptions extends ExperiencesHostVideoUploadRouteParams {
    mediaId: number
}
export const httpDeleteUploadedClips = (urlParams: HttpDeleteUploadedClipsOptions) =>
    axios.delete(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-videos/${urlParams.mediaId}`
    )

export interface HttpStoreHostExperienceOptions {
    urlParams: {
        hostCode: number
        hostPassword: string
        experiencesHostVideoId: number
    }
}
export const httpStoreHostExperience = ({urlParams}: HttpStoreHostExperienceOptions) => {
    return axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks`
    )
}

export interface HttpShowQuizOptions {
    urlParams: ExperiencesHostVideoRouteParams & {quizId: number}
    params: {response_type: ResponseType}
}
export const httpShowQuiz = ({urlParams, params}: HttpShowQuizOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/quiz/${urlParams.quizId}`,
        {params}
    )

export const httpShowFriendQuiz = ({urlParams, params}: HttpShowQuizOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/quiz/${urlParams.quizId}`,
        {params}
    )

export interface HttpQuizAnswerOptions {
    urlParams: ExperiencesHostVideoRouteParams & {quizId: number; questionId: number}
}
export const httpQuizAnswer = ({urlParams}: HttpQuizAnswerOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/quiz/${urlParams.quizId}/question-answer/${urlParams.questionId}`
    )

export const httpFriendQuizAnswer = ({urlParams}: HttpQuizAnswerOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/quiz/${urlParams.quizId}/question-answer/${urlParams.questionId}`
    )

export interface HttpCompleteQuizOptions {
    urlParams: ExperiencesHostVideoRouteParams & {quizId: number}
    payload: {user_type: 'friend' | 'host'}
}
export const httpCompleteQuiz = ({urlParams, payload}: HttpCompleteQuizOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/quiz/${urlParams.quizId}/completed`,
        payload
    )
export const httpCompleteFriendQuiz = ({urlParams, payload}: HttpCompleteQuizOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/quiz/${urlParams.quizId}/completed`,
        payload
    )

export interface HttpStoreQuizOptions {
    urlParams: ExperiencesHostVideoRouteParams
    params: {user_type: Quiz['user_type']; response_type: ResponseType}
}
export const httpStoreQuiz = ({urlParams, params}: HttpStoreQuizOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/quiz`,
        undefined,
        {params}
    )

export const httpStoreFriendQuiz = ({urlParams, params}: HttpStoreQuizOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/quiz`,
        undefined,
        {params}
    )

export interface HttpSelectQuestionAnswerOptions {
    urlParams: ExperiencesHostVideoRouteParams
    payload: {
        questionAnswerId: number
        notes?: string[]
    }
}
export const httpSelectQuestionAnswer = ({urlParams, payload}: HttpSelectQuestionAnswerOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/question-answer/${payload.questionAnswerId}`,
        payload
    )

export interface HttpUpdateQuestionAnswerNote {
    urlParams: ExperiencesHostVideoRouteParams
    payload: {
        questionAnswerId: number
        notes: string
        noteId: number
    }
}
export const httpUpdateQuestionAnswerNote = ({urlParams, payload}: HttpUpdateQuestionAnswerNote) =>
    axios.patch(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/question-answer/${payload.questionAnswerId}/notes/${payload.noteId}`,
        payload
    )

export interface HttpDeleteQuestionAnswerNote {
    urlParams: ExperiencesHostVideoRouteParams
    payload: {
        questionAnswerId: number
        noteId: number
    }
}

export const httpDeleteQuestionAnswerNote = ({urlParams, payload}: HttpDeleteQuestionAnswerNote) =>
    axios.delete(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/question-answer/${payload.questionAnswerId}/notes/${payload.noteId}`
    )
export const httpCompleteOnboarding = ({
    urlParams,
    params
}: {
    urlParams: ExperiencesHostVideoRouteParams
    params: {response_type: ResponseType}
}) =>
    axios.patch(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/friend/onboarding-completed`,
        undefined,
        {params}
    )

interface HttpGetVideoOptions {
    urlParams: ExperiencesHostVideoUploadRouteParams
}

export const httpGetUploadedVideo = ({urlParams}: HttpGetVideoOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-videos`
    )

interface HttpGetPresignBulkVoiceoverOptions {
    urlParams: ExperiencesHostVideoUploadRouteParams
    payload: {media: string}
}
export const httpGetPresignBulkVoiceover = ({urlParams, payload}: HttpGetPresignBulkVoiceoverOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-voiceovers/presigned`,
        payload
    )

interface HttpConfirmUploadVoiceoverOptions extends ExperiencesHostVideoUploadRouteParams {
    audioId: number
}
export const httpConfirmUploadVoiceover = (urlParams: HttpConfirmUploadVoiceoverOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-voiceovers/${urlParams.audioId}/upload-completed`
    )

interface HttpSendSupportRequestOptions {
    urlParams: ExperiencesHostVideoRouteParams
    payload: {notes: string}
}
export const httpSendSupportRequest = ({urlParams, payload}: HttpSendSupportRequestOptions) =>
    axios.post(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/need-help`,
        payload
    )

interface HttpGetDevicesOptions {
    urlParams: ExperiencesHostVideoRouteParams
    params: {
        limit: number
        page: number
        search?: string
    }
}
export const httpGetDevices = ({urlParams, params}: HttpGetDevicesOptions) =>
    axios.get(`/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/devices`, {params})

interface HttpUpdateLanguageOptions {
    urlParams: ExperiencesHostVideoRouteParams
    payload: {language: string}
}
export const httpUpdateLanguage = ({urlParams, payload}: HttpUpdateLanguageOptions) =>
    axios.put(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/update-language`,
        payload
    )

interface HttpGetVoiceoverOptions {
    urlParams: ExperiencesHostVideoUploadRouteParams
}
export const httpGetVoiceover = ({urlParams}: HttpGetVoiceoverOptions) =>
    axios.get(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-voiceovers`
    )

interface HttpDeleteVoiceoverOptions extends ExperiencesHostVideoUploadRouteParams {
    mediaId: number
}
export const httpDeleteVoiceover = (urlParams: HttpDeleteVoiceoverOptions) =>
    axios.delete(
        `/elevate/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences-video/${urlParams.experiencesHostVideoId}/tasks/${urlParams.taskId}/luminate-videosets/${urlParams.videoSetId}/luminate-voiceovers/${urlParams.mediaId}`
    )
