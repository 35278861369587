import {forwardRef} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledStepCard} from '@/components/commons/step-card/style'
import {AlertTriangleIcon, CheckIcon} from 'src/components/ui/icon'
import {CSSProperties} from 'styled-components'
import {useTranslation} from '@/translations/i18n.tsx'

export type StepStatus = 'active' | 'inactive' | 'completed' | 'error'
export type StepCardContentDirection = 'row' | 'column'
export interface StepCardProps {
    number: number
    status: StepStatus
    title: string
    subtitle?: string
    stepCardContentDirection?: StepCardContentDirection
    align?: CSSProperties['alignItems']
    gap?: number
}

export const StepCard = forwardRef<HTMLDivElement, StepCardProps>(
    ({number, status, title, subtitle, stepCardContentDirection}, ref) => {
        const {t} = useTranslation()

        return (
            <StyledStepCard
                ref={ref}
                status={status}
                padding={4}
                stepCardContentDirection={stepCardContentDirection}
                aria-current={status == 'active'}
            >
                <Flexbox className="number" width="36px" height="36px" justify="center" align="center">
                    {status == 'completed' ? (
                        <CheckIcon role="img" aria-label={t('coAlarm:steps:screen_reader:success')} size={20} />
                    ) : status == 'error' ? (
                        <AlertTriangleIcon role="img" aria-label={t('coAlarm:steps:screen_reader:failed')} size={20} />
                    ) : (
                        number
                    )}
                </Flexbox>
                <Flexbox direction="column" gap={1}>
                    <p>{title}</p>
                    <small>{subtitle}</small>
                </Flexbox>
            </StyledStepCard>
        )
    }
)
