import {Viewport} from '@radix-ui/react-scroll-area'
import styled from 'styled-components'

export const StyledViewport = styled(Viewport)`
    height: 100%;
    /* This was added to enable text overflow, which seems to be prevented by using display: table */
    & > div {
        display: block !important;
    }
`
