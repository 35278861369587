import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {ServiceToAcceptStep} from '@/features/service-acceptance-flow/components/ServiceToAcceptStep.tsx'
import {ServiceAcceptedStep} from '@/features/service-acceptance-flow/components/ServiceAcceptedStep.tsx'
import {ServiceSmsNotifyStep} from '@/features/service-acceptance-flow/components/ServiceSmsNotifyStep.tsx'
import {ServiceConfirmEmailStep} from '@/features/service-acceptance-flow/components/ServiceConfirmEmailStep.tsx'
import {ServiceAcceptPortraitStep} from '@/features/service-acceptance-flow/components/ServiceAcceptPortraitStep.tsx'
import toast from 'react-hot-toast'
import {AcceptanceFlowCompletedMessage} from '@/features/service-acceptance-flow/components/AcceptanceFlowCompleted.tsx'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {AcceptManualRequestStep} from '@/features/service-acceptance-flow/components/AcceptManualRequestStep.tsx'
import {AcceptanceStep} from '@/features/service-acceptance-flow/types.ts'
import {ReactNode} from 'react'
import {PhotoPreparation1Step} from '@/features/service-acceptance-flow/components/PhotoPreparation1Step.tsx'
import {PhotoPreparation2Step} from '@/features/service-acceptance-flow/components/PhotoPreparation2Step.tsx'

export const ServiceAcceptanceProcess = () => {
    const acceptanceActiveStep = useServiceAcceptStore(state => state.step)
    const acceptModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const portraitEnabled = useServiceAcceptStore(state => state.portraitEnabled)
    const otpFlowReset = useOtpStore(state => state.reset)

    const onConfigurationCompleted = () => {
        toast.success(<AcceptanceFlowCompletedMessage />, {duration: 8000})
        acceptModalToggle()
        otpFlowReset()
    }

    const stepToComponent = {
        acceptManualRequest: <AcceptManualRequestStep />,
        accept: <ServiceToAcceptStep />,
        accepted: <ServiceAcceptedStep />,
        photo_preparation_1: <PhotoPreparation1Step />,
        photo_preparation_2: <PhotoPreparation2Step />,
        sms: <ServiceSmsNotifyStep isAcceptanceFlow />,
        email: <ServiceConfirmEmailStep />,
        portrait: (
            <ServiceAcceptPortraitStep
                onPortraitAcceptedCb={onConfigurationCompleted}
                portraitEnabled={portraitEnabled}
            />
        )
    } as const satisfies Record<AcceptanceStep, ReactNode>

    return stepToComponent[acceptanceActiveStep]
}

ServiceAcceptanceProcess.displayName = 'ServiceAcceptanceProcess'
