import {StyledAcceptanceFlowCompleted} from '@/features/service-acceptance-flow/components/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {generatePath} from 'react-router-dom'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {routes} from '@utilities/constants'

export const AcceptanceFlowCompletedMessage = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const linkToInProgressSection = user?.id
        ? generatePath(`${routes.DASHBOARD.path}`, {
              hostCode: user.code,
              hostPassword: user.password
          }).concat('#in-progress-section')
        : '#in-progress-section'

    return (
        <StyledAcceptanceFlowCompleted direction={'column'} gap={1}>
            <h6>{t('service_accept:common:flow_success_title')}</h6>
            <p>{t('service_accept:common:flow_success_subtitle')}</p>
            <ButtonLink to={linkToInProgressSection} size={'sm'} variant={'tertiary'}>
                {t('commons:view')}
            </ButtonLink>
        </StyledAcceptanceFlowCompleted>
    )
}

AcceptanceFlowCompletedMessage.displayName = 'AcceptanceFlowCompletedMessage'
