import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/modal/Modal.tsx'

export const StyledTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
    `
)

export const StyledParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)

export const StyledModal = styled(Modal)`
    overflow: visible;
`

export const StyledPhoneInputWrapper = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;

        .react-tel-input > input {
            ${typography.textMd}
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing}px;
            border: 1px solid ${palette.neutral['300']};
            width: 100%;
            transition: ease-in-out 200ms;
            cursor: pointer;
            height: 44px;
            padding: 0 ${spacing * 9}px 0 ${spacing * 12}px;

            &:not(:disabled):hover {
                border: 1px solid ${palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['25']}`};
            }

            &:focus {
                outline: none;
                border-color: ${palette.neutral['300']};
                box-shadow: unset;
            }

            &::placeholder {
                color: ${palette.neutral['400']};
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        .react-tel-input .country-list {
            width: 260px;
            position: fixed;
            max-height: 150px;
            margin: 0 auto;
            border-radius: 8px;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: ${palette.neutral[50]};
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${palette.neutral[300]};
                border-radius: 3px;

                &:hover {
                    background-color: ${palette.neutral[400]};
                }
            }
        }
    `}
`
