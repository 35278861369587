import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpDeleteOffering} from './services.http'
import {Service} from '../types'

export const useDeleteOffering = ({
    serviceId,
    offeringId,
    options
}: {
    serviceId: number
    offeringId: number
    options?: Omit<UseMutationOptions, 'mutationFn'>
}) => {
    const queryKey = [QUERY_KEYS.SERVICE, serviceId]

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_OFFERING],
        mutationFn: () => httpDeleteOffering({urlParams: {offeringId, serviceId}}),
        onMutate: async () => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})
            const previousService = queryClient.getQueryData<Service>(queryKey)
            return {previousService}
        },
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData(
                {queryKey: [QUERY_KEYS.SERVICE, serviceId]},
                {
                    ...context?.previousService,
                    offerings: context?.previousService
                        ? context?.previousService.offerings.filter(offering => offering.id != offeringId)
                        : []
                }
            )

            options?.onSuccess?.(data, variables, context)
        }
    })
}
