import {
    StyledAcceptStepBody,
    StyledAcceptStepDesc,
    StyledAcceptStepFooter,
    StyledAcceptStepHeader,
    StyledAcceptStepHeaderCloseButton
} from '@/features/service-acceptance-flow/components/style.ts'
import {AcceptanceStepsConfigByService, serviceTypeToTermsOfService} from '@/features/service-acceptance-flow/utils.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {useMemo, useState} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {errorHandler, formatCurrency, getServiceType} from '@utilities/helpers.ts'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import toast from 'react-hot-toast'
import {useAcceptService} from '@/features/service-acceptance-flow/services/queries/useAcceptService.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {generatePath, Link} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {XCloseIcon} from '@components/ui/icon'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'

export const ServiceToAcceptStep = () => {
    const {t} = useTranslation()
    //host params store
    const hostCode = useHostParamsStore(state => state.hostCode)
    const hostPassword = useHostParamsStore(state => state.hostPassword)
    //otp store
    const setOtpService = useOtpStore(state => state.setCurrentService)
    //accept store
    const changeAcceptModalStep = useServiceAcceptStore(state => state.changeStep)
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const service = useServiceAcceptStore(state => state.service)
    //state and utils
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    const [termsAccepted, setTermsAccepted] = useState(false)
    //Accept quote mutation
    const {mutate: acceptQuote, isPending} = useAcceptService({
        onSuccess: serviceAccepted => {
            changeAcceptModalStep('accepted')
            setOtpService(serviceAccepted)
        },
        onError: errorHandler
    })

    const onAcceptQuote = () => {
        if (service?.id && hostPassword && hostCode) {
            acceptQuote({
                hostCode: hostCode,
                hostPassword: hostPassword,
                quoteId: service.id
            })
        } else {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <StyledAcceptStepHeader align={'center'} justify={'center'}>
                <StyledAcceptStepHeaderCloseButton
                    disabled={isPending}
                    size="lg"
                    shape="square"
                    variant="ghost"
                    onClick={() => acceptServiceModalToggle()}
                >
                    <XCloseIcon />
                </StyledAcceptStepHeaderCloseButton>
                {t(AcceptanceStepsConfigByService['accept'][serviceType].modal_title)}
            </StyledAcceptStepHeader>
            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />
            <StyledAcceptStepBody direction={'column'} gap={6}>
                <StyledAcceptStepDesc direction={'column'} gap={2}>
                    <h5>{t(AcceptanceStepsConfigByService['accept'][serviceType].title)}</h5>
                    {service && service.amount ? (
                        <>
                            <small>
                                {t(AcceptanceStepsConfigByService['accept'][serviceType].subtitle, {
                                    cost: formatCurrency(service.amount)
                                })}
                            </small>
                            <small>{t('service_accept:common:payments_future')}</small>
                        </>
                    ) : (
                        <small>{t('service_accept:common:free_service')}</small>
                    )}
                </StyledAcceptStepDesc>
                <Checkbox
                    id={'terms'}
                    label={
                        <Trans
                            i18nKey={`service_accept:common:terms_cond`}
                            components={[
                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    to={generatePath(routes.TERMS_OF_SERVICE.path, {
                                        type: serviceTypeToTermsOfService[serviceType]
                                    })}
                                />
                            ]}
                        />
                    }
                    checked={termsAccepted}
                    onChange={e => setTermsAccepted(e.target.checked)}
                />
            </StyledAcceptStepBody>

            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />
            <StyledAcceptStepFooter>
                <Button
                    fullWidth={true}
                    variant={'primary'}
                    size={'md'}
                    disabled={!termsAccepted || isPending}
                    onClick={onAcceptQuote}
                >
                    {isPending && <Spinner size={20} />}
                    {!isPending && t(AcceptanceStepsConfigByService['accept'][serviceType].primary_cta)}
                </Button>
            </StyledAcceptStepFooter>
        </>
    )
}

ServiceToAcceptStep.displayName = 'ServiceToAcceptStep'
