import {ComboboxInput} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {SearchLgIcon} from '@/components/ui/icon'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import InputStatusIcon from '@/components/ui/input-status-icon/Input-status-icon'
import {Label} from '@/components/ui/label/Label'
import {useTranslation} from '@/translations/i18n'
import {forwardRef} from 'react'

interface CitiesMultiSelectInputProps {
    label?: string
    errorMessage?: string
}

export const CitiesMultiSelectInput = forwardRef<HTMLInputElement, CitiesMultiSelectInputProps>(
    ({label, errorMessage}, ref) => {
        const {t} = useTranslation()

        return (
            <Flexbox direction="column" gap={1.5} width="100%">
                {label && <Label>{label}</Label>}
                <Flexbox direction="column" gap={1.5} width="100%">
                    <ComboboxInput
                        hasCloseCombobox
                        errorMessage={errorMessage}
                        ref={ref}
                        typeIcon={<SearchLgIcon size={18} />}
                        style={{height: 40}}
                        placeholder={t('services:step_service_location:cities_multiselect_placeholder')}
                    />
                    <Flexbox align="center" gap={1.5}>
                        <InputStatusIcon hasError={!!errorMessage} />
                        <InputHelpText
                            helpText={t('services:step_service_location:select_areas_hint')}
                            error={errorMessage}
                        />
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        )
    }
)
