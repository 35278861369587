import {
    Combobox,
    ComboboxCancel,
    ComboboxItemProps,
    ComboboxPopoverProps,
    ComboboxProps,
    useComboboxContext
} from '@ariakit/react'
import {
    StyledComboboxInputSideComponent,
    StyledComboboxInputText,
    StyledComboboxInputWrapper,
    StyledComboboxItem,
    StyledComboboxPopover
} from './style'
import {InputProps} from '@/components/commons/input-text/InputText'
import {forwardRef, ReactNode} from 'react'
import {raise} from '@/utilities/helpers'

export const ComboboxPopover = (props: ComboboxPopoverProps) => (
    <StyledComboboxPopover gutter={6} sameWidth {...props} />
)

export const ComboboxItem = (props: ComboboxItemProps) => <StyledComboboxItem {...props} />

export type ComboboxInputProps = ComboboxProps &
    Omit<InputProps, 'type' | 'touched' | 'visibilityToggle' | 'typeIcon'> & {
        onClear?: () => void
        hasCloseCombobox?: boolean
        typeIcon?: ReactNode
        size?: 'sm' | 'md' | 'lg'
    }

export const ComboboxInput = forwardRef<HTMLInputElement, ComboboxInputProps>(
    ({typeIcon, hasCloseCombobox, onClear, ...props}, ref) => {
        const comboboxContext = useComboboxContext() ?? raise('Combobox must be in a ComboboxProvider')
        const value = comboboxContext.useState('value')

        return (
            <StyledComboboxInputWrapper>
                {typeIcon && (
                    <StyledComboboxInputSideComponent $side="left">{typeIcon}</StyledComboboxInputSideComponent>
                )}
                <Combobox
                    autoSelect
                    render={
                        <StyledComboboxInputText
                            type="text"
                            ref={ref}
                            $size={props.inputSize ?? 'md'}
                            $hasTypeIcon={!!typeIcon}
                            $hasCloseCombobox={!!hasCloseCombobox}
                            $hasError={!!props.errorMessage}
                        />
                    }
                    {...props}
                />
                {hasCloseCombobox && value && (
                    <StyledComboboxInputSideComponent $side="right">
                        <ComboboxCancel onClick={onClear} />
                    </StyledComboboxInputSideComponent>
                )}
            </StyledComboboxInputWrapper>
        )
    }
)
