import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Button} from '@components/ui/button/Button.tsx'
import {ManualAddressFormSchema} from '@/features/co-alarm/components/co-alarm-send-request-form/manual-address-form/CoManualAddressFormModel.ts'
import {useTranslation} from 'react-i18next'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {envVars} from '@/envVars.ts'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import InputText from '@components/commons/input-text/InputText.tsx'
import {customSelectStyles} from '@components/commons/select/style.ts'
import {useTheme} from 'styled-components'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {customComponents} from '@components/commons/select/commons.tsx'
import {Option} from 'react-google-places-autocomplete/build/types'
import {Map} from '@/features/co-alarm/components/co-alarm-send-request-form/map/Map'
import {generatePath, useNavigate} from 'react-router-dom'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import toast from 'react-hot-toast'
import PhoneInput, {CountryData} from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import useAddressGeocoding from '@/features/co-alarm/hooks/useAddressGeocoding.ts'
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {SelectInstance} from 'react-select'
import {CoAlarmListingRemappedRequest, ParsedManualAddress} from '@/features/co-alarm/types.ts'
import {debounce} from '@/utilities/helpers.ts'
import {Flexbox} from '@/components/ui/flexbox/FlexBox.tsx'
import {routes} from '@/utilities/constants/routes.tsx'
import {
    GooglePlacesAutocompleteWrapper,
    StyledCoAlarmRequestModal,
    StyledCoAlarmRequestSentMessage,
    StyledCoAlarmSendRequestInputsWrapper,
    StyledFormattedAddress,
    StyledFullWidthInputText,
    StyledPhoneInputWrapper,
    StyledSelectCountry
} from '@/features/co-alarm/components/co-alarm-send-request-form/style.ts'
import {CoManualAddressForm} from '@/features/co-alarm/components/co-alarm-send-request-form/manual-address-form/CoManualAddressForm.tsx'
import {
    CO_ALARM_SEND_REQUEST_FORM_MODEL,
    CoAlarmSendRequestFormSchema,
    CoAlarmSendRequestSchema
} from '@/features/co-alarm/components/co-alarm-send-request-form/form/CoAlarmSendRequestFormModel.ts'
import {useSendCoAlarmRequest} from '@/features/co-alarm/services/useSendCoAlarmRequest.ts'
import {parseManualAddressForm} from '@/features/co-alarm/utils.tsx'
import {HttpSendCoAlarmRequestOptions} from '@/features/co-alarm/services/coAlarm.http'
import {captureException} from '@sentry/react'

export const CoAlarmSendRequestForm = ({
    selectedListing,
    selectedCountry,
    setSelectedCountry
}: {
    selectedListing: CoAlarmListingRemappedRequest
    selectedCountry: string | null
    setSelectedCountry: Dispatch<SetStateAction<string | null>>
}) => {
    const [emptyPredictionsCount, setEmptyPredictionsCount] = useState(0)
    const [manualAddressModal, setManualAddressModal] = useState(false)
    const [manualAddress, setManualAddress] = useState<ParsedManualAddress | null>(null)
    const inputCountry = selectedCountry ?? selectedListing.country_code.toLowerCase()
    const theme = useTheme()
    const navigate = useNavigate()
    const inputAddressRef = useRef<SelectInstance<Option | null>>(null)

    const {mutate: requestCoAlarm, isPending: isPendingRequestCoAlarm} = useSendCoAlarmRequest({
        onSuccess: requestResponse => {
            toast.success(
                <StyledCoAlarmRequestSentMessage gap={1} direction="column">
                    <h4>{t('coAlarm:form:sent_successfully_toast:title')}</h4>
                    <p>{t('coAlarm:form:sent_successfully_toast:paragraph')}</p>
                </StyledCoAlarmRequestSentMessage>,
                {
                    position: 'top-right'
                }
            )

            localStorage.setItem('coNotifyModal', 'true')

            navigate(
                generatePath(routes.CO_ALARM_REQUEST_DETAILS.path, {
                    listingId: selectedListing.id,
                    requestId: requestResponse.id
                })
            )
        },
        onError: error => {
            captureException({error, ...getValues()})
            toast.error(t('coAlarm:smokeForm:address_info_error'), {
                position: 'top-right'
            })

            setManualAddressModal(true)
            setError('address', {message: String(t('coAlarm:form:address_error'))})
        }
    })

    /*TODO: replace with i18n.ts useTranslation*/
    const {
        t,
        i18n: {language}
    } = useTranslation()

    //hook form setup
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        resetField,
        getValues,
        reset,
        formState: {errors, isSubmitting, isValid}
    } = useForm<CoAlarmSendRequestFormSchema>({
        mode: 'onChange',
        shouldFocusError: true,
        resolver: zodResolver(CoAlarmSendRequestFormSchema),
        defaultValues: {
            sensors_requested: 1,
            manual_address: false,
            listing: {
                label: selectedListing?.name,
                value: selectedListing?.name.toLowerCase()
            },
            country: {
                value: t(`coAlarm:countries:${inputCountry}`),
                code: inputCountry,
                label: t(`coAlarm:countries:${inputCountry}`),
                icon: (
                    <img
                        src={`https://flagcdn.com/w20/${inputCountry}.png`}
                        width="20"
                        height="14"
                        alt={inputCountry}
                        aria-hidden={true}
                    />
                )
            }
        }
    })

    //Geocoder's logic and utils
    const {
        addressLatLng,
        shippingAddressFormat,
        countriesLocalizedOptions,
        retrieveLatLng,
        retrieveStructured,
        resetGeocoding
    } = useAddressGeocoding(language)

    // Watch form changes
    const isManualAddress = watch('manual_address')
    const addressSetted = watch('address')

    // Handles the input change event for the input address field
    const onInputAddressChange = (inputValue: string) => {
        if (inputValue) {
            const options = inputAddressRef.current?.props.options

            if (manualAddress) {
                setManualAddress(null)
                setValue('manual_address', false)
            }

            if (options && options.length > 0) {
                // If options are available, reset empty predictions count
                setEmptyPredictionsCount(0)
            } else {
                // If no options available, increment empty predictions count up to 2
                setEmptyPredictionsCount(prev => Math.min(prev + 1, 2))
            }
        }
    }

    // Function to handle setting manual address
    const onSetManualAddress = (manualFormValues: ManualAddressFormSchema) => {
        // Reset the count of empty predictions
        setEmptyPredictionsCount(0)
        setValue('manual_address', true)

        // Format the manual address
        const formattedManualAddress = parseManualAddressForm(manualFormValues, countrySetted)
        setManualAddress(formattedManualAddress)
        setValue(
            'address',
            {
                label: formattedManualAddress.formatted_address,
                value: formattedManualAddress.address
            },
            // Validate the field after setting the value
            {shouldValidate: true}
        )
    }

    const onSubmit = handleSubmit(data => {
        const address =
            isManualAddress && manualAddress
                ? {
                      address: manualAddress.address,
                      formatted_address: manualAddress.formatted_address
                  }
                : {
                      address: shippingAddressFormat?.address_components,
                      formatted_address: shippingAddressFormat?.formatted_address
                  }

        requestCoAlarm({
            urlParams: {listingId: selectedListing.id},
            payload: HttpSendCoAlarmRequestOptions.shape['payload'].parse({
                ...data,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...(address as google.maps.GeocoderResult),
                country: countrySetted.code
            })
        })
    })

    // Check the number of times the google api search fails and shows the manual address modal
    useEffect(() => {
        if (emptyPredictionsCount === 2) {
            inputAddressRef.current?.blur()
            setManualAddressModal(true)
        }
    }, [emptyPredictionsCount])

    //  Reset all the fields when the listing changes
    useEffect(() => {
        reset()
        setValue('country', {
            value: t(`coAlarm:countries:${inputCountry}`),
            code: inputCountry,
            label: t(`coAlarm:countries:${inputCountry}`),
            icon: (
                <img
                    src={`https://flagcdn.com/w20/${inputCountry}.png`}
                    width="20"
                    height="14"
                    alt={inputCountry}
                    aria-hidden={true}
                />
            )
        })
    }, [selectedListing])

    // Watch changes on country field
    const countrySetted: CoAlarmSendRequestSchema['country'] = useWatch({control, name: 'country'})

    // Reset fields
    const resetLocationFields = (countryValue: CoAlarmSendRequestSchema['country']) => {
        // remove formatted address
        resetGeocoding()
        setManualAddress(null)
        setEmptyPredictionsCount(0)
        setSelectedCountry(countryValue.code)
        resetField('phone')
        setValue('address', {label: '', value: ''})
        resetField('address_extras')
    }

    return (
        <>
            <Flexbox direction="column" as="form" gap={8} onSubmit={onSubmit} width="100%">
                <StyledCoAlarmSendRequestInputsWrapper>
                    <InputText
                        inputSize="sm"
                        label={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.firstName.label)}
                        type={'text'}
                        errorMessage={t(errors.first_name?.message || '')}
                        placeholder={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.firstName.placeholder).toString()}
                        {...register(t(CO_ALARM_SEND_REQUEST_FORM_MODEL.firstName.name))}
                        helpText={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.firstName.helpText)}
                        maxLength={50}
                    />
                    <InputText
                        inputSize="sm"
                        label={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.lastName.label)}
                        type={'text'}
                        errorMessage={t(errors.last_name?.message || '')}
                        placeholder={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.lastName.placeholder).toString()}
                        {...register(t(CO_ALARM_SEND_REQUEST_FORM_MODEL.lastName.name))}
                        helpText={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.lastName.helpText)}
                        maxLength={50}
                    />
                </StyledCoAlarmSendRequestInputsWrapper>

                <Flexbox direction="column" gap={1} width="100%">
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <StyledSelectCountry
                                key={`lang-${language}`}
                                value={value as SelectValue}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                    resetLocationFields(newValue as CoAlarmSendRequestSchema['country'])
                                }}
                                size={'medium'}
                                name={CO_ALARM_SEND_REQUEST_FORM_MODEL.country.name}
                                label={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.country.label)}
                                isSearchable={true}
                                errorMessage={t(errors.country?.message || '')}
                                placeholder={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.country.placeholder)}
                                options={countriesLocalizedOptions}
                                selectAriaLabel={t('coAlarm:form:country_placeholder')}
                            />
                        )}
                        control={control}
                        name={'country'}
                    />
                    <InputHelpText
                        helpText={
                            countrySetted.code == 'us'
                                ? t('coAlarm:form:country_usa_help_text')
                                : t(CO_ALARM_SEND_REQUEST_FORM_MODEL.country.helpText)
                        }
                    />
                </Flexbox>

                {countrySetted.code == 'br' && (
                    <Flexbox direction="column" gap={1} width="100%">
                        <InputText
                            label={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.vat_number.label)}
                            type={'text'}
                            errorMessage={t(errors.last_name?.message || '')}
                            placeholder={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.vat_number.placeholder).toString()}
                            {...register(t(CO_ALARM_SEND_REQUEST_FORM_MODEL.vat_number.name))}
                            helpText={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.vat_number.helpText)}
                            maxLength={50}
                        />
                    </Flexbox>
                )}

                <Controller
                    render={({field: {onChange, value}}) => (
                        <StyledPhoneInputWrapper direction="column" gap={2}>
                            <Label htmlFor={CO_ALARM_SEND_REQUEST_FORM_MODEL.phone.name}>
                                {t(CO_ALARM_SEND_REQUEST_FORM_MODEL.phone.label)}
                            </Label>
                            <PhoneInput
                                country={countrySetted.code}
                                enableAreaCodes={false}
                                countryCodeEditable={false}
                                value={value}
                                onChange={(value: string, country: CountryData, event) =>
                                    onChange(value, country, event)
                                }
                            />
                            <InputHelpText error={t(errors.phone?.message || '')} />
                        </StyledPhoneInputWrapper>
                    )}
                    control={control}
                    name={CO_ALARM_SEND_REQUEST_FORM_MODEL.phone.name}
                />

                {countrySetted && (
                    <>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <GooglePlacesAutocompleteWrapper direction="column" gap={1.5} width="100%">
                                    <Label htmlFor={CO_ALARM_SEND_REQUEST_FORM_MODEL.address.name}>
                                        {t(CO_ALARM_SEND_REQUEST_FORM_MODEL.address.label)}
                                    </Label>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            ref: inputAddressRef,
                                            placeholder: t(CO_ALARM_SEND_REQUEST_FORM_MODEL.address.placeholder),
                                            name: CO_ALARM_SEND_REQUEST_FORM_MODEL.address.name,
                                            isClearable: !!value?.value,
                                            value: value as Option,
                                            onInputChange: debounce(onInputAddressChange, 750),
                                            'aria-live': 'polite',
                                            'aria-label': t(CO_ALARM_SEND_REQUEST_FORM_MODEL.address.label),
                                            onChange: newValue => {
                                                onChange(newValue as SelectValue)
                                                newValue?.label && retrieveLatLng(newValue.label)
                                                newValue?.value && retrieveStructured(newValue.value.place_id)
                                            },
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            components: {...customComponents, DropdownIndicator: () => null},
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            styles: customSelectStyles({theme, isError: !!errors.address?.message})
                                        }}
                                        autocompletionRequest={{
                                            types: ['address'],
                                            componentRestrictions: {
                                                country: countrySetted.code
                                            }
                                        }}
                                        apiOptions={{language: 'en', region: countrySetted.code}}
                                        apiKey={envVars.VITE_GOOGLE_API_KEY}
                                    />
                                    {errors?.address && <InputHelpText error={t('coAlarm:form:address_error')} />}

                                    {emptyPredictionsCount === 2 && (
                                        <Flexbox justify="end" width="100%">
                                            <Button
                                                variant={'ghost'}
                                                size={'sm'}
                                                onClick={() => setManualAddressModal(true)}
                                            >
                                                {t('coAlarm:form:manual_hint')}
                                            </Button>
                                        </Flexbox>
                                    )}

                                    {(shippingAddressFormat || manualAddress) && addressSetted && (
                                        <StyledFormattedAddress>
                                            <strong>{t('coAlarm:form:formatted_address')}</strong>
                                            <span>
                                                {!manualAddress?.formatted_address
                                                    ? shippingAddressFormat?.formatted_address
                                                    : manualAddress?.formatted_address}
                                            </span>
                                        </StyledFormattedAddress>
                                    )}
                                </GooglePlacesAutocompleteWrapper>
                            )}
                            control={control}
                            name={CO_ALARM_SEND_REQUEST_FORM_MODEL.address.name}
                        />

                        <StyledFullWidthInputText
                            inputSize="sm"
                            type="text"
                            label={t(CO_ALARM_SEND_REQUEST_FORM_MODEL.addressExtras.label)}
                            errorMessage={t(errors.address_extras?.message ?? '')}
                            {...register(CO_ALARM_SEND_REQUEST_FORM_MODEL.addressExtras.name)}
                            maxLength={150}
                        />

                        {addressLatLng && !isManualAddress && addressSetted && (
                            <Map center={addressLatLng} markerPosition={addressLatLng} />
                        )}
                    </>
                )}

                <Button
                    type={'submit'}
                    variant={'primary'}
                    size={'md'}
                    onClick={onSubmit}
                    disabled={!isValid || isSubmitting || isPendingRequestCoAlarm || !addressSetted?.value}
                >
                    {t('commons:submit')}
                    {isSubmitting || (isPendingRequestCoAlarm && <Spinner size={12} />)}
                </Button>
            </Flexbox>

            {manualAddressModal && (
                <StyledCoAlarmRequestModal width={540} onOverlayClick={() => setManualAddressModal(false)}>
                    <CoManualAddressForm
                        closeModal={() => setManualAddressModal(false)}
                        onSetManualAddress={onSetManualAddress}
                    />
                </StyledCoAlarmRequestModal>
            )}
        </>
    )
}
