import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {Film01Icon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import dayjs from 'dayjs'

export const ExperienceVideoVideoshootCompletedStatusDescription: FC<{
    assignmentDate: Assignment['to_shoot_at'] | undefined
}> = ({assignmentDate}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <Film01Icon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:videoshoot_completed_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:videoshoot_completed_paragraph', {
                        uploadDate: formatLocaleDate(dayjs(assignmentDate).add(10, 'days'))
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

ExperienceVideoVideoshootCompletedStatusDescription.displayName = 'ExperienceVideoVideoshootCompletedStatusDescription'
