import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {ImageCheckIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {FeedbackModal} from '@/features/feedback-form/components/feedback-modal/FeedbackModal.tsx'
import {FC, useState} from 'react'

interface HomeCompletedStatusDescriptionProps {
    isFeedbackSent: boolean
}

export const HomeCompletedStatusDescription: FC<HomeCompletedStatusDescriptionProps> = ({isFeedbackSent}) => {
    const {t} = useTranslation()
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)

    return (
        <Flexbox gap={2} direction={'column'} align="center" width="100%">
            <StatusDescriptionWrapper centered>
                <StatusDescriptionIconWrapper>
                    <ImageCheckIcon />
                </StatusDescriptionIconWrapper>
                <StatusDescriptionTitle>
                    {t('service_details:descriptions:home:completed_title')}
                </StatusDescriptionTitle>
                <StatusDescriptionParagraphsWrapper>
                    <StatusDescriptionParagraph>
                        {t('service_details:descriptions:home:completed_paragraph')}
                    </StatusDescriptionParagraph>
                </StatusDescriptionParagraphsWrapper>
            </StatusDescriptionWrapper>
            <Button disabled={isFeedbackSent} onClick={() => setShowFeedbackModal(true)}>
                {isFeedbackSent ? t('service_details:feedback_shared') : t('service_details:share_feedback')}
            </Button>
            {showFeedbackModal ? <FeedbackModal onCloseCb={() => setShowFeedbackModal(false)} /> : null}
        </Flexbox>
    )
}

HomeCompletedStatusDescription.displayName = 'HomeCompletedStatusDescription'
