import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {ClockIcon} from '@components/ui/icon'
import {VoiceoverRecorder} from '@/features/experiences-host-video/components/voiceover-recorder/VoiceoverRecorder.tsx'
import {UsingYourAnswer} from '@/features/experiences-host-video/components/using-your-answer/UsingYourAnswer.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {useShowVoiceovers} from '@/features/experiences-host-video/services/useShowVoicovers.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'

export const UniqueQuestsCanDo = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})

    const answer =
        showExperiencesHostVideoQuery.data?.answers.find(
            answer => answer.question_answer.question.key_name === 'experience_unique'
        )?.notes || []

    const voiceoversQuery = useShowVoiceovers({
        urlParams: {...routeParams, videoSetId: showExperiencesHostVideoQuery.data?.videoset?.id || null},
        queryOptions: {enabled: !!showExperiencesHostVideoQuery.data?.videoset?.id}
    })
    const currentAudio = voiceoversQuery.data?.find(
        audio => audio.experience_task_status_id == showExperiencesHostVideoQuery.data?.status_id
    )

    return (
        <>
            {voiceoversQuery.isPending ? (
                <Flexbox width={'100%'} align={'center'} justify={'center'}>
                    <Spinner size={32} />
                </Flexbox>
            ) : (
                <>
                    <ExperiencesHostVideoBody gap={6}>
                        <Flexbox direction="column" gap={2}>
                            <ExperiencesHostVideoTitle>
                                {t('experiences_host_video:unique_quests_can_do:title')}
                            </ExperiencesHostVideoTitle>
                            <ExperiencesHostVideoParagraph>
                                {t('experiences_host_video:unique_quests_can_do:description')}
                            </ExperiencesHostVideoParagraph>
                        </Flexbox>
                        <Flexbox gap={2} align={'center'}>
                            <ClockIcon size={20} />
                            <p>{t('experiences_host_video:try_to_record_minimum_of_20_seconds')}</p>
                        </Flexbox>
                        {answer[0]?.notes && (
                            <UsingYourAnswer
                                label={t('experiences_host_video:unique_quests_can_do:using_your_answer')}
                                answer={`"${answer[0].notes}"`}
                            />
                        )}
                    </ExperiencesHostVideoBody>
                    <ExperiencesHostVideoFooter>
                        <VoiceoverRecorder
                            uploadedAudio={currentAudio}
                            nextStatus={'upload'}
                            nextStep={'record-voiceover-highlights'}
                            prevStatus={'upload'}
                            prevStep={'name-quick-intro'}
                        />
                    </ExperiencesHostVideoFooter>
                </>
            )}
        </>
    )
}
