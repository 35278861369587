import axiosOriginalInstance, {AxiosProgressEvent} from 'axios'

interface HttpUploadFileOptions {
    url: string
    payload: File | Blob
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export const httpUploadFile = ({url, payload, onUploadProgress}: HttpUploadFileOptions) =>
    axiosOriginalInstance.put(url, payload, {onUploadProgress, headers: {'Content-Type': 'multipart/form-data'}})
