import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {StyledMatterportTitle, StyledMatterportIntroCopy, StyledMatterportModal} from './style'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ReactNode} from 'react'

export const MatterportIntroModal = ({onClose, endSlot}: {onClose: () => void; endSlot?: ReactNode}) => {
    const {t} = useTranslation()
    return (
        <StyledMatterportModal width={560} onClose={onClose}>
            <StyledMatterportTitle>{t('matterport:accessibility_features:title')}</StyledMatterportTitle>

            <ScrollArea scrollbar={<Scrollbar />}>
                <StyledMatterportIntroCopy direction="column" gap={2}>
                    <h3>{t('matterport:accessibility_features:intro:title')}</h3>
                    <p>{t('matterport:accessibility_features:intro:description_top_1')}</p>
                    <p>{t('matterport:accessibility_features:intro:description_top_2')}</p>
                    <ul>
                        <li>{t('matterport:accessibility_features:intro:description_list_1')}</li>
                        <li>{t('matterport:accessibility_features:intro:description_list_2')}</li>
                    </ul>
                    <p>
                        <Trans
                            i18nKey="matterport:accessibility_features:intro:description_bottom"
                            components={{a: <a href="https://www.airbnb.com/help/article/1961" target="_blank" />}}
                        />
                    </p>
                </StyledMatterportIntroCopy>
            </ScrollArea>
            {endSlot}
        </StyledMatterportModal>
    )
}
