import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC} from 'react'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {useTheme} from 'styled-components'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'

interface AcceptanceModalStepsIndicatorProps {
    portraitEnabled: boolean
}

export const AcceptanceModalStepsIndicator: FC<AcceptanceModalStepsIndicatorProps> = ({portraitEnabled}) => {
    const {palette} = useTheme()
    const currentStep = useServiceAcceptStore(state => state.step)

    return (
        <Flexbox gap={1}>
            <Divider
                startSpacing={0}
                endSpacing={0}
                direction="horizontal"
                height={portraitEnabled ? 2 : 1}
                background={portraitEnabled ? palette.neutral['900'] : palette.neutral['300']}
            />
            {portraitEnabled && (
                <Divider
                    startSpacing={0}
                    endSpacing={0}
                    direction="horizontal"
                    height={portraitEnabled ? 2 : 1}
                    background={currentStep === 'portrait' ? palette.neutral['900'] : palette.neutral['300']}
                />
            )}
        </Flexbox>
    )
}

AcceptanceModalStepsIndicator.displayName = 'AcceptanceModalStepsIndicator'
