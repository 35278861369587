import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import {FC, ReactNode} from 'react'
import {ScrollAreaProps as RadixScrollAreaProps, ScrollAreaViewportProps} from '@radix-ui/react-scroll-area'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import * as React from 'react'
import {StyledViewport} from './style'

type ScrollAreaProps = {
    children: ReactNode
    scrollbar: ReturnType<typeof Scrollbar>
    viewportProps?: ScrollAreaViewportProps & React.RefAttributes<HTMLDivElement>
} & RadixScrollAreaProps

export const ScrollArea: FC<ScrollAreaProps> = ({children, scrollbar, viewportProps, ...rest}) => {
    return (
        <RadixScrollArea.Root scrollHideDelay={500} {...rest}>
            <StyledViewport style={{height: '100%'}} {...viewportProps}>
                {children}
            </StyledViewport>
            {scrollbar}
        </RadixScrollArea.Root>
    )
}
