import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useInputSearch} from '@/hooks/useInputSearch'
import {ComboboxProvider, SelectProvider} from '@ariakit/react'
import {useRef} from 'react'
import {useCountries} from '@/features/services/services/useCountries'
import {Country} from '@/features/services/types'
import {CountrySelectTrigger} from './CountrySelectTrigger'
import {CountrySelectPopover} from './CountrySelectPopover'

export const CountrySelect = ({
    value,
    onChange,
    errorMessage
}: {
    value?: Country | null
    errorMessage?: string
    onChange: (value: Country | null) => void
}) => {
    const {searchValue, onSearch, onResetSearch} = useInputSearch()
    const inputCountryRef = useRef(null)
    const countriesQuery = useCountries({
        search: searchValue || undefined,
        response_type: 'small',
        per_page: 100
    })

    return (
        <ComboboxProvider setValue={onSearch} resetValueOnHide>
            <SelectProvider
                value={value?.country ?? ''}
                setValue={value =>
                    onChange(countriesQuery.remappedData.find(country => value == country.country) ?? null)
                }
                setOpen={() => onResetSearch(inputCountryRef)}
            >
                <Flexbox direction="column" gap={1.5} width="100%">
                    <CountrySelectTrigger country={value?.country} errorMessage={errorMessage} />
                    <CountrySelectPopover
                        isLoading={countriesQuery.isLoading}
                        isError={countriesQuery.isError}
                        countries={countriesQuery.remappedData}
                    />
                </Flexbox>
            </SelectProvider>
        </ComboboxProvider>
    )
}
