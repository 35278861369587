import {SlidingModal} from '@/components/commons/sliding-modal/SlidingModal'
import {ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto 1fr;
`

export const StyledModalHeader = styled(ModalHeader)(
    ({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        gap: ${spacing * 2}px;
        align-items: center;
        justify-items: center;
        padding: ${2.5 * spacing}px ${2 * spacing}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)

export const StyledHeaderTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledList = styled.ul(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        margin-left: ${spacing * 5}px;
        & a {
            cursor: pointer;
            text-decoration: underline;
        }

        & li {
            &:before {
                content: '• ';
                margin-left: ${-spacing * 1.5}px;
            }
            & li {
                margin-left: ${spacing * 2}px;
            }
        }
    `
)
