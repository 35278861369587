import styled, {css} from 'styled-components'

export const StyledAnswerContainer = styled.label<{$isReadonly: boolean}>(
    ({theme: {palette, shadows, spacing, typography, transition}, $isReadonly}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral[700]};
        transition: ${transition};
        padding: ${spacing * 3}px ${spacing * 4}px;
        box-shadow: ${`${shadows.xs}, 0px 0px 0px 1px ${palette.neutral[300]}`};
        border-radius: 12px;

        ${!$isReadonly &&
        css`
            cursor: pointer;
        `}

        &:has(input:checked) {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 3px ${palette.neutral[700]}`};
        }
        &:has(input:disabled) {
            background-color: ${palette.neutral[100]};
            cursor: not-allowed;
            color: ${palette.neutral[500]};
        }
    `
)

export const StyledExperiencesHostVideoAnswerInput = styled.input`
    display: none;
`
