import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {MessageTextSquare02Icon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {NotificationConsentModal} from '@/features/service/components/notification-consent-modal/NotificationConsentModal.tsx'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {FC, useState} from 'react'
import {httpClickAction} from '@/features/service-actions/services/actions.http.ts'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const TextNotificationAction: FC<{
    actionId: ServiceAction['id']
    isCompleted: boolean
}> = ({actionId, isCompleted}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const [showModal, setShowModal] = useState(false)

    const clickAction = () => {
        setShowModal(true)
        httpClickAction({actionId, ...urlParams})
    }

    return (
        <>
            <ActionCard
                isCompleted={isCompleted}
                onClick={clickAction}
                icon={MessageTextSquare02Icon}
                paragraph={t('service_details:actions:text_notifications:paragraph')}
                title={t('commons:enable_text_notifications')}
            />
            {showModal && (
                <Modal width={374} onClose={() => setShowModal(false)} onOverlayClick={() => setShowModal(false)}>
                    <NotificationConsentModal onCloseCb={() => setShowModal(false)} />
                </Modal>
            )}
        </>
    )
}

TextNotificationAction.displayName = 'TextNotificationAction'
