import {ButtonLink} from '@components/ui/button-link/ButtonLink'
import {File02Icon} from '@/components/ui/icon'
import {
    StyledCoAlarmPostDeliveryCard,
    StyledCoAlarmPostDeliveryCardContent,
    StyledCoAlarmPostDeliveryCards,
    StyledCoAlarmInstallationIconContainer
} from '@/features/co-alarm/components/co-alarm-post-delivery-cards/style'
import {useTranslation} from '@/translations/i18n.tsx'
import {CoAlarmListing} from '@/features/co-alarm/types'

export const CoAlarmPostDeliveryCards = ({listingId}: {listingId: CoAlarmListing['id']}) => {
    const {t} = useTranslation()

    return (
        <StyledCoAlarmPostDeliveryCards>
            <StyledCoAlarmPostDeliveryCard gap={4} width="100%">
                <StyledCoAlarmInstallationIconContainer>
                    <File02Icon />
                </StyledCoAlarmInstallationIconContainer>
                <StyledCoAlarmPostDeliveryCardContent direction="column" gap={2.5}>
                    <p>{t('coAlarm:installation_cards:update_listing_paragraph')}</p>
                    <ButtonLink
                        to={`https://www.airbnb.com/hosting/listings/editor/${listingId}/details/guest-safety`}
                        size="lg"
                        variant="tertiary"
                        shape="text"
                        target="_blank"
                    >
                        {t('coAlarm:installation_cards:update_listing_cta')}
                    </ButtonLink>
                </StyledCoAlarmPostDeliveryCardContent>
            </StyledCoAlarmPostDeliveryCard>
        </StyledCoAlarmPostDeliveryCards>
    )
}
