import {Footer} from '@/features/services/components/footer/Footer.tsx'
import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps,
    retrieveAddressDataFromComponents
} from '@/features/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldDescription,
    FieldTitle,
    Title,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {InputAddressAutocomplete} from '@/features/services/components/input-address-autocomplete/InputAddressAutocomplete'
import {City, Coordinates, Country, Service} from '@/features/services/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {errorHandler, ObjectKeys} from '@/utilities/helpers'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {useUpdateLocation} from '@/features/services/services/useUpdateLocation'
import {useUpdateStepToHostExpertiseAboutYourself} from '@/features/services/services/useUpdateStepToHostExpertiseAboutYourself'
import {CountrySelect} from '../country-select/CountrySelect'
import {StyledLocationBox} from './style'
import {CitiesMultiSelect} from '../cities-multi-select/CitiesMultiSelect'
import {ConfirmSubmitModal} from '@/features/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

const ServiceFormValidationSchema = z.object({
    address: z.string().min(1),
    city: z.string().min(1),
    country: z.string().min(1),
    latitude: z.string().min(1),
    longitude: z.string().min(1),
    zip_code: z.string().nullable(),
    state: z.string().min(1),
    country_filter: z.object(Country.shape, {required_error: i18n.t('services:step_service_location:select_country')}),
    cities: z
        .array(City, {required_error: i18n.t('services:step_service_location:select_one_area')})
        .min(1, {message: i18n.t('services:step_service_location:select_one_area')})
})
type ServiceFormValidationSchema = z.infer<typeof ServiceFormValidationSchema>

export const ServiceLocationStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const locationFlags = updatesRequiredSteps.serviceLocation.find(field => field.questionKeyName == 'location')?.flags
    const citiesFlags = updatesRequiredSteps.serviceLocation.find(field => field.questionKeyName == 'cities')?.flags
    const flagsCount = updatesRequiredSteps.serviceLocation.flatMap(field => field.flags).length
    const updateStepToHostExpertiseAboutYourselfMutation = useUpdateStepToHostExpertiseAboutYourself<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateLocationMutation = useUpdateLocation({serviceId: service.id, expertiseId: service.expertise.id})
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'serviceLocation', updatesRequiredSteps}).prev?.()
            : updateStepToHostExpertiseAboutYourselfMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'serviceLocation'>({
                  service,
                  currentStep: 'serviceLocation',
                  updatesRequiredSteps
              }).next?.()
            : updateLocationMutation
    const form = useForm<ServiceFormValidationSchema>({
        resolver: zodResolver(ServiceFormValidationSchema),
        defaultValues: {
            address: service.address ?? undefined,
            country: service.country ?? undefined,
            city: service.city ?? undefined,
            zip_code: service.zip_code ?? undefined,
            state: service.state ?? undefined,
            latitude: service.latitude ?? undefined,
            longitude: service.longitude ?? undefined,
            country_filter: service.submission_cities?.[0]?.city.country
                ? {
                      country: service.submission_cities?.[0]?.city.country,
                      country_iso: service.submission_cities?.[0]?.city.country_iso
                  }
                : undefined,
            cities:
                service.submission_cities.length > 0
                    ? service.submission_cities.map(({city}) => ({
                          id: city.id,
                          country: city.country,
                          name: city.name,
                          state: city?.state || null
                      }))
                    : []
        }
    })

    // values
    const formattedAddress = useWatch({control: form.control, name: 'address'})
    const latitude = useWatch({control: form.control, name: 'latitude'})
    const longitude = useWatch({control: form.control, name: 'longitude'})
    const countryFilter = useWatch({control: form.control, name: 'country_filter'})

    // actions
    const onLatLngFound = (coordinates: Coordinates | null) => {
        form.setValue('latitude', coordinates?.latitude ?? '')
        form.setValue('longitude', coordinates?.longitude ?? '')
        form.clearErrors(['address', 'city', 'country', 'state', 'zip_code', 'latitude', 'longitude'])
    }

    const onChangeAddress = (value: google.maps.GeocoderResult | null) => {
        if (!value) return
        const {city, country, zip_code, state} = retrieveAddressDataFromComponents(value.address_components)
        form.setValue('address', value.formatted_address, {shouldValidate: true})
        form.setValue('city', city)
        form.setValue('country', country)
        form.setValue('state', state)
        form.setValue('zip_code', zip_code)
    }

    const onSubmit = form.handleSubmit(
        formValues =>
            nextStepMutation?.mutate({
                ...formValues,
                cities: formValues.cities.map(city => city.id)
            })
    )

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_service_location:title')}</Title>
                    <Paragraph>{t('services:step_service_location:paragraph')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:service_location_improvement_title')}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={8}>
                    <InputAddressAutocomplete
                        placeholder={
                            formattedAddress
                                ? t('services:step_service_location:search_another_address_placeholder')
                                : t('services:step_service_location:search_address_placeholder')
                        }
                        onLatLngFound={onLatLngFound}
                        errorMessage={
                            form.formState.errors.address?.message
                                ? t('services:step_service_location:search_address_warning')
                                : ''
                        }
                        onChange={onChangeAddress}
                        predictionTypes={['address']}
                    />
                    {formattedAddress && latitude && longitude && (
                        <>
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <StyledLocationBox direction="column" gap={1.5} width="100%">
                                    <FieldDescription>
                                        {t('services:step_service_location:offering_address')}
                                    </FieldDescription>
                                    <FieldTitle>{formattedAddress}</FieldTitle>
                                </StyledLocationBox>
                                <FieldDescription>
                                    {t('services:step_service_location:service_location_hint')}
                                </FieldDescription>
                                {ObjectKeys(form.formState.errors).filter(
                                    error => error != 'country_filter' && error != 'cities'
                                ).length ? (
                                    <InputHelpText
                                        error={t('services:step_service_location:address_missing_data_error')}
                                    />
                                ) : null}

                                <FlagsAlertText
                                    flags={locationFlags}
                                    title={t('services:step_service_location:offering_address')}
                                />
                            </Flexbox>

                            <Flexbox direction="column" gap={4} width="100%">
                                <Flexbox direction="column" gap={1.5}>
                                    <FieldTitle>{t('services:step_service_location:country_title')}</FieldTitle>

                                    <FlagsAlertText
                                        flags={citiesFlags}
                                        title={t('services:step_service_location:country_title')}
                                    />
                                </Flexbox>
                                {formattedAddress && (
                                    <Controller
                                        control={form.control}
                                        name="country_filter"
                                        render={({field, fieldState}) => (
                                            <CountrySelect
                                                value={field.value}
                                                onChange={value => field.onChange(value)}
                                                errorMessage={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                )}

                                {countryFilter && (
                                    <Flexbox direction="column" width="100%" gap={4}>
                                        <Controller
                                            control={form.control}
                                            name="cities"
                                            render={({field, fieldState}) => (
                                                <CitiesMultiSelect
                                                    country={countryFilter.country}
                                                    onChange={cities => field.onChange(cities)}
                                                    values={field.value.map(city => city.id.toString())}
                                                    citiesObjects={field.value}
                                                    errorMessage={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    </Flexbox>
                                )}
                            </Flexbox>
                        </>
                    )}
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('service_location')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={nextStepMutation.isPending || prevStepMutation?.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'serviceLocation'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{
                        ...form.getValues(),
                        cities: form.getValues().cities.map(city => city.id)
                    }}
                />
            )}
        </>
    )
}
