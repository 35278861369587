import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {Paragraph} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'

export const StyledVideo = styled.video(
    () => css`
        height: 120px;
        width: 120px;
        object-fit: contain;
        margin: 0 auto;
    `
)

export const StyledButton = styled(Button)(
    () => css`
        max-width: 800px;
    `
)

export const StyledTitle = styled.h1(
    ({theme: {typography}}) => css`
        ${typography.displayMd};
        font-weight: 500;
        text-align: center;
    `
)

export const StyledParagraph = styled(Paragraph)(
    () => css`
        text-align: center;
    `
)
