import {useAsync} from '@hooks/useAsync.ts'
import {useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {Columns01Icon, PlayIcon, Trash01Icon} from '@components/ui/icon'
import {httpDeleteUploadedClips} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {errorHandler, raise} from '@utilities/helpers.ts'
import {
    StyledPreviewUploadedClips,
    StyledPreviewUploadedClipsPlayButton,
    StyledPreviewUploadedClipsRemoveButton,
    StyledPreviewUploadedClipsWrapper
} from '@/features/experiences-host-video/components/uploaded-clip-item/style.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {UploadedMedia} from '@/features/services/types.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useEffect, useRef} from 'react'
import {useVideoController} from '@/features/experiences-host-video/hooks/useVideoController.ts'
export const UploadedClipItem = (uploadedFile: UploadedMedia) => {
    const removeFile = useAsync()
    const removeUploadedFiles = useClipsUpload(store => store.removeUploadedFiles)
    const videosetId = useClipsUpload(store => store.videosetId)
    const videoCurrentInPlayingId = useClipsUpload(store => store.videoCurrentInPlayingId)
    const setVideoCurrentInPlaying = useClipsUpload(store => store.setVideoCurrentInPlaying)
    const routeParams = useExperiencesHostVideoRouteParams()
    const videoRef = useRef<HTMLVideoElement>(null)
    const onRemove = async () => {
        try {
            await removeFile.run(
                httpDeleteUploadedClips({
                    ...routeParams,
                    videoSetId: videosetId ?? raise('videosetId is undefined'),
                    mediaId: uploadedFile.id
                })
            )
            removeUploadedFiles([uploadedFile.id])
        } catch (error) {
            errorHandler(error)
        }
    }

    const videoController = useVideoController(videoRef, {defaultMuted: true, initialVideoState: 'pause'})

    useEffect(() => {
        if (videoCurrentInPlayingId != uploadedFile.id) {
            videoRef.current?.pause()
        }
    }, [videoCurrentInPlayingId])

    return (
        <StyledPreviewUploadedClipsWrapper>
            <StyledPreviewUploadedClips
                controls={false}
                preload="metadata"
                playsInline
                ref={videoRef}
                src={`${uploadedFile.url}#t=0.001`}
                {...videoController}
                onPlay={() => {
                    setVideoCurrentInPlaying(uploadedFile.id)
                    videoController.onPlay()
                }}
            />
            <StyledPreviewUploadedClipsRemoveButton onClick={onRemove} disabled={removeFile.isLoading}>
                {removeFile.isLoading ? <Spinner size={17} /> : <Trash01Icon size={17} />}
            </StyledPreviewUploadedClipsRemoveButton>
            <StyledPreviewUploadedClipsPlayButton
                onClick={videoController.playPauseToggle}
                disabled={removeFile.isLoading || videoController.videoState == 'loading'}
            >
                {videoController.videoState == 'playing' ? (
                    <Columns01Icon size={17} />
                ) : videoController.videoState == 'pause' ? (
                    <PlayIcon size={17} />
                ) : (
                    <Spinner size={17} />
                )}
            </StyledPreviewUploadedClipsPlayButton>
        </StyledPreviewUploadedClipsWrapper>
    )
}
