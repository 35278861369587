import {AddressType, CoAlarmStatus, CoAlarmRequest, ParsedManualAddress} from './types'
import {
    AnnotationAlertIcon,
    CheckCircleBrokenIcon,
    FileCheck02Icon,
    ShieldTickIcon,
    Truck02Icon
} from '@/components/ui/icon'
import {ReactElement} from 'react'
import {palette} from '@/theme/palette'
import i18next from 'i18next'
import {StepCardProps, StepStatus} from '@/components/commons/step-card/StepCard'
import dayjs from 'dayjs'
import {CoAlarmSendRequestSchema} from './components/co-alarm-send-request-form/form/CoAlarmSendRequestFormModel'
import {ManualAddressFormSchema} from './components/co-alarm-send-request-form/manual-address-form/CoManualAddressFormModel'
import {TKey} from '@/translations/i18n.tsx'

export type SantizedFormData = Omit<ManualAddressFormSchema, 'address_line_2' | 'street_number'> & {
    street_number?: string
    address_line_optional?: string
}

const addressTypeReference: Record<keyof SantizedFormData, AddressType> = {
    address_line_1: AddressType.Enum.route,
    address_line_optional: AddressType.Enum.sublocality_level_1,
    city: AddressType.Enum.locality,
    state: AddressType.Enum.administrative_area_level_1,
    postal_code: AddressType.Enum.postal_code,
    street_number: AddressType.Enum.street_number
}

function sanitizeData(data: SantizedFormData) {
    const obj = {...data}

    for (const key in obj) {
        if (!obj[key as keyof SantizedFormData]) {
            delete obj[key as keyof SantizedFormData]
        }
    }

    return obj
}

export const parseManualAddressForm = (
    formData: ManualAddressFormSchema & {street_number?: string},
    country: CoAlarmSendRequestSchema['country']
): ParsedManualAddress => {
    const sanitizedFormData = sanitizeData({
        address_line_1: formData.address_line_1,
        address_line_optional: [formData.address_line_2].filter(Boolean).join(','),
        city: formData.city,
        state: formData.state,
        postal_code: formData.postal_code,
        street_number: formData.street_number
    })

    // When in edit mode, the street number will not be added because it is already included in address_line_1.
    let formattedAddress = formData.address_line_1
    formattedAddress += `, ${formData.city}, ${formData.postal_code}, ${formData.state}, ${country.value}`

    return {
        formatted_address: formattedAddress,
        address: (Object.keys(sanitizedFormData) as Array<keyof SantizedFormData>)
            .map(addressKey => ({
                long_name: sanitizedFormData[addressKey] as string,
                short_name: sanitizedFormData[addressKey] as string,
                types: [addressTypeReference[addressKey]]
            }))
            .concat({
                long_name: country.value,
                short_name: country.code.toUpperCase(),
                types: [AddressType.Enum.country]
            })
    }
}

export const coAlarmStatusToIconLabel = {
    eligible: {
        label: 'coAlarm:dashboard:status:eligible',
        icon: <AnnotationAlertIcon size={20} color={palette.light.warning['600']} />
    },
    requested: {
        label: 'coAlarm:dashboard:status:requested',
        icon: <FileCheck02Icon size={20} color={palette.light.blue['600']} />
    },
    shipped: {
        label: 'coAlarm:dashboard:status:shipped',
        icon: <Truck02Icon size={20} color={palette.light.blue['600']} />
    },
    delivered: {
        label: 'coAlarm:dashboard:status:delivered',
        icon: <CheckCircleBrokenIcon size={20} color={palette.light.blue['600']} />
    },
    to_install: {
        label: 'coAlarm:dashboard:status:to_install',
        icon: <AnnotationAlertIcon size={20} color={palette.light.warning['600']} />
    },
    installed: {
        label: 'coAlarm:dashboard:status:installed',
        icon: <ShieldTickIcon size={20} color={palette.light.success['600']} />
    },
    ineligible: {
        label: 'coAlarm:dashboard:status:eligible',
        icon: <AnnotationAlertIcon size={20} color={palette.light.warning['600']} />
    }
} as const satisfies Record<CoAlarmStatus | CoAlarmRequest['status'], {icon: ReactElement; label: string}>

export const EXISTING_CO_ALARM_STEPS = [
    {
        title: 'coAlarm:existing_co_alarm_modal:steps:step_1_title',
        link: `https://www.airbnb.com/hosting/listings`
    },
    {
        title: 'coAlarm:existing_co_alarm_modal:steps:step_2_title',
        link: null
    },
    {
        title: 'coAlarm:existing_co_alarm_modal:steps:step_3_title',
        link: null
    },
    {
        title: 'coAlarm:existing_co_alarm_modal:steps:step_4_title',
        link: null
    }
] as const satisfies ReadonlyArray<{title: TKey; link: string | null}>

export const coRequestStatusToLabelDescription = {
    requested: {
        label: 'co_alarm:request:requested_label',
        description: 'co_alarm:request:requested_description'
    },
    shipped: {
        label: 'co_alarm:request:shipped_label',
        description: 'co_alarm:request:shipped_description'
    },
    delivered: {
        label: 'co_alarm:request:delivered_label',
        description: 'co_alarm:request:delivered_description'
    },
    to_install: {
        label: 'co_alarm:request:to_install_label',
        description: 'co_alarm:request:to_install_description'
    }
}

export const coAlarmStepsTitlesTKeys = {
    requested: {
        step1: {
            completed: 'coAlarm:steps:requested:title'
        },
        step2: {
            active: 'coAlarm:steps:shipped:in_progress_title'
        },
        step3: {
            inactive: 'coAlarm:steps:delivered:title'
        },
        step4: {
            inactive: 'coAlarm:steps:completed:title'
        }
    },
    shipped: {
        step1: {
            completed: 'coAlarm:steps:requested:title'
        },
        step2: {
            completed: 'coAlarm:steps:shipped:title'
        },
        step3: {
            active: 'coAlarm:steps:delivered:in_progress_title'
        },
        step4: {
            inactive: 'coAlarm:steps:completed:title'
        }
    },
    delivered: {
        step1: {
            completed: 'coAlarm:steps:requested:title'
        },
        step2: {
            completed: 'coAlarm:steps:shipped:title'
        },
        step3: {
            completed: 'coAlarm:steps:delivered:title'
        },
        step4: {
            active: 'coAlarm:steps:completed:in_progress_title'
        }
    },
    completed: {
        step1: {
            completed: 'coAlarm:steps:requested:title'
        },
        step2: {
            completed: 'coAlarm:steps:shipped:title'
        },
        step3: {
            completed: 'coAlarm:steps:delivered:title'
        },
        step4: {
            completed: 'coAlarm:steps:completed:title'
        }
    }
} as const satisfies Record<
    CoAlarmRequest['status'] & 'completed',
    Record<'step1' | 'step2' | 'step3' | 'step4', Partial<Record<StepStatus, string>>>
>

export const getCoAlarmSteps = ({
    requestStatus,
    coAlarmStatus,
    createdAt,
    shippedAt,
    deliveredAt,
    updatedAt
}: {
    requestStatus: CoAlarmRequest['status']
    coAlarmStatus: CoAlarmRequest['co_alarm_status']
    createdAt: CoAlarmRequest['created_at']
    shippedAt: CoAlarmRequest['shipped_at']
    deliveredAt: CoAlarmRequest['delivered_at']
    updatedAt: CoAlarmRequest['updated_at']
}): Array<StepCardProps & {isCurrent: boolean}> => {
    if (coAlarmStatus == 'installed') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys['completed'].step1.completed),
                subtitle: dayjs(createdAt).format('ll')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys['completed'].step2.completed),
                subtitle: dayjs(shippedAt).format('ll')
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys['completed'].step3.completed),
                subtitle: dayjs(deliveredAt).format('ll')
            },
            {
                number: 4,
                isCurrent: true,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys['completed'].step4.completed),
                subtitle: dayjs(updatedAt).format('ll')
            }
        ]
    }

    if (requestStatus == 'delivered') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step1.completed),
                subtitle: dayjs(createdAt).format('ll')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step2.completed),
                subtitle: dayjs(shippedAt).format('ll')
            },
            {
                number: 3,
                isCurrent: true,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step3.completed),
                subtitle: dayjs(deliveredAt).format('ll')
            },
            {
                number: 4,
                isCurrent: false,
                status: 'active',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step4.active)
            }
        ]
    }

    if (requestStatus == 'shipped') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step1.completed),
                subtitle: dayjs(createdAt).format('ll')
            },
            {
                number: 2,
                isCurrent: true,
                status: 'completed',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step2.completed),
                subtitle: dayjs(shippedAt).format('ll')
            },
            {
                number: 3,
                isCurrent: false,
                status: 'active',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step3.active)
            },
            {
                number: 4,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step4.inactive)
            }
        ]
    }

    return [
        {
            number: 1,
            isCurrent: true,
            status: 'completed',
            title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step1.completed),
            subtitle: dayjs(createdAt).format('ll')
        },
        {
            number: 2,
            isCurrent: false,
            status: 'active',
            title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step2.active)
        },
        {
            number: 3,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step3.inactive)
        },
        {
            number: 4,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(coAlarmStepsTitlesTKeys[requestStatus].step4.inactive)
        }
    ]
}
