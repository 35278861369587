import React, {FC} from 'react'
import {StyledCalendarHeader} from '@components/commons/calendar/style.ts'
import {DPPropGetter, DPYear} from '@rehookify/datepicker'
import {ChevronLeftIcon, ChevronRightIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {View} from '@components/commons/calendar/Calendar.tsx'
import dayjs from '@utilities/dayjs.ts'
import {
    HostCalendarWrapper,
    StyledHostBackButton,
    StyledHostYearMonthGrid
} from '@/features/service/components/host-calendar/style.ts'

type YearSelectorProps = {
    years: DPYear[]
    yearButton: (year: DPYear) => DPPropGetter
    nextYearsButton: () => DPPropGetter
    previousYearsButton: () => DPPropGetter
    currentYear: string
    setViewState: React.Dispatch<React.SetStateAction<View>>
}
export const HostYearSelector: FC<YearSelectorProps> = ({
    years,
    yearButton,
    nextYearsButton,
    previousYearsButton,
    currentYear,
    setViewState
}) => {
    const {t} = useTranslation()

    return (
        <HostCalendarWrapper width={`100%`} height={'100%'} direction="column" gap={6}>
            <StyledCalendarHeader>
                <Button shape="square" variant="ghost" {...previousYearsButton()}>
                    <ChevronLeftIcon />
                </Button>
                <h3>{t('commons:years')}</h3>
                <Button shape="square" variant="ghost" {...nextYearsButton()}>
                    <ChevronRightIcon />
                </Button>
            </StyledCalendarHeader>
            <StyledHostYearMonthGrid>
                {years.map(year => (
                    <Button
                        key={year.year}
                        {...yearButton(year)}
                        variant={year.year.toString() == currentYear ? 'primary' : 'ghost'}
                    >
                        {dayjs(year.$date).format('YYYY')}
                    </Button>
                ))}
            </StyledHostYearMonthGrid>
            <StyledHostBackButton variant="ghost" onClick={() => setViewState('date')}>
                {t('commons:back')}
            </StyledHostBackButton>
        </HostCalendarWrapper>
    )
}
