import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {generatePath} from 'react-router-dom'
import {FC} from 'react'
import {routes} from '@utilities/constants'
import {PromoServicesSection} from '@/features/promotional-services/components/promo-services-section/PromoPhotoshootsSection.tsx'
import {StyledTermsLink} from '@/features/promotional-services/components/promo-services-section/style.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import Container from '@components/ui/container/Container.tsx'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {PromoWalkthroughExplain} from '@/features/promotional-services/components/promo-walkthrough-section/PromoWalkthroughExplain.tsx'
import acceptImg from '@assets/images/photo-landing/steps/sign_quote.svg'
import pickDatesImg from '@assets/images/photo-landing/steps/shoot_date.svg'
import haveWalkthrough from '@assets/images/photo-landing/steps/walk_lens.svg'
import feedback from '@assets/images/photo-landing/steps/walk_feedback.svg'
import confirmWalkthrough from '@assets/images/photo-landing/steps/accept_walkthrough.svg'
import {ServiceSteps} from '@/features/landing/components/service-steps/ServiceSteps.tsx'

export interface PromoWalkthroughSectionProps {
    walkthroughServices: ServiceProposal[]
    showFullServicesInfo: boolean
}

export const PromoWalkthroughSection: FC<PromoWalkthroughSectionProps> = ({
    walkthroughServices,
    showFullServicesInfo
}) => {
    const {t} = useTranslation()
    //TODO: temporary onboarding type check
    const isStandardOnboarding = walkthroughServices[0].task_category.name == 'Onboarding'

    return (
        <>
            <Container fullHeight={false}>
                {showFullServicesInfo && (
                    <StyledPageHeaderContainer>
                        <StyledPageHeaderText direction={'column'} gap={3}>
                            <h1>
                                {isStandardOnboarding
                                    ? t('dashboard:promo_walkthrough:landing_mode_title_onboarding')
                                    : t('dashboard:promo_walkthrough:landing_mode_title_onboarding_alt')}
                            </h1>
                        </StyledPageHeaderText>
                    </StyledPageHeaderContainer>
                )}

                <PromoServicesSection
                    id={'walkthrough'}
                    title={showFullServicesInfo ? t('commons:your_quotes') : t('dashboard:promo_walkthrough:title')}
                    subtitle={
                        <Trans
                            i18nKey={'dashboard:promo_walkthrough:subtitle'}
                            components={[
                                <StyledTermsLink
                                    to={generatePath(routes.TERMS_OF_SERVICE.path, {type: 'walkthrough'})}
                                    target={'_blank'}
                                />
                            ]}
                        />
                    }
                    services={walkthroughServices}
                />
            </Container>

            {showFullServicesInfo && <PromoWalkthroughExplain />}
            {showFullServicesInfo && (
                <Container>
                    <ServiceSteps
                        title={t('photoshoot_landing:service_steps:title')}
                        subtitle={t('photoshoot_landing:service_steps:subtitle')}
                        steps={[
                            {
                                title: t('walkthrough_landing:service_steps:label_1'),
                                subtitle: t('walkthrough_landing:service_steps:desc_1'),
                                image: acceptImg
                            },
                            {
                                title: t('walkthrough_landing:service_steps:label_2'),
                                subtitle: t('walkthrough_landing:service_steps:desc_2'),
                                image: pickDatesImg
                            },
                            {
                                title: t('walkthrough_landing:service_steps:label_3'),
                                subtitle: t('walkthrough_landing:service_steps:desc_3'),
                                image: haveWalkthrough
                            },
                            {
                                title: t('walkthrough_landing:service_steps:label_4'),
                                subtitle: t('walkthrough_landing:service_steps:desc_4'),
                                image: feedback
                            },
                            {
                                title: t('walkthrough_landing:service_steps:label_5'),
                                subtitle: t('walkthrough_landing:service_steps:desc_5'),
                                image: confirmWalkthrough
                            }
                        ]}
                    />
                </Container>
            )}
        </>
    )
}

PromoWalkthroughSection.displayName = 'PromoWalkthroughSection'
