import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {httpUploadProfilePhoto, HttpUploadProfilePhotoOptions} from '@/features/services/services/services.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {z} from 'zod'
import {Service} from '@/features/services/types.ts'

export const useChangeProfilePhoto = ({
    hostId,
    serviceId,
    options
}: {
    serviceId: number
    hostId: string
    options?: UseMutationOptions<{url: string}, unknown, Omit<HttpUploadProfilePhotoOptions['payload'], 'mutationFn'>>
}) => {
    const queryClient = useQueryClient()

    return useMutation<{url: string}, unknown, HttpUploadProfilePhotoOptions['payload']>({
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpUploadProfilePhoto({
                    payload,
                    urlParams: {hostId},
                    params: {response_type: 'extended'}
                }),
                responseShape: z.object({url: z.string().url()}),
                onZodError: captureException
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData<Service>({queryKey: [QUERY_KEYS.SERVICE, serviceId]}, prevData =>
                prevData
                    ? {
                          ...prevData,
                          host: {
                              ...prevData.host,
                              profile_photo_url: data.url
                          }
                      }
                    : undefined
            )
            options?.onSuccess?.(data, variables, context)
        }
    })
}
