import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {errorHandler} from '@utilities/helpers.ts'
import {httpReassignTask} from '@/features/chatbot/services/reassignTask.http.ts'
import {useTranslation} from '@/translations/i18n.tsx'

export const ReassignTask = () => {
    const {run} = useAsync()
    const service = useChatbotStore(state => state.service)
    const {t} = useTranslation()

    useEffect(() => {
        onReassignTask()
    }, [])

    const onReassignTask = async () => {
        try {
            await run(
                httpReassignTask({
                    code: String(service?.entity.code),
                    password: String(service?.entity.password),
                    media: Number(service?.task_media?.id)
                })
            )
        } catch (e) {
            errorHandler(e as AxiosError<{message: string}>)
        }
    }

    return (
        <div>
            <p>{t('chatbot:HP21:HP21.2.1.4_send_component')}</p>
        </div>
    )
}

ReassignTask.displayName = 'ReassignTask'
