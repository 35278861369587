import {TableColumn, TableSorter} from '@components/commons/table/Table.tsx'
import {
    StyledBody,
    StyledButton,
    StyledHead,
    StyledSorterModal
} from '@components/commons/table/MobileSorterModal/style.ts'
import {CheckIcon} from 'src/components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'

interface MobileSorterModalProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    onClose: () => void
    sorter: TableSorter<TSortName>
}

const MobileSorterModal = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    onClose,
    sorter
}: MobileSorterModalProps<TData, TSortName>) => {
    const {t} = useTranslation()

    return (
        <StyledSorterModal onClose={onClose} width={320} overlayVariant="gradient" closeIconOffset="sm">
            <StyledHead>
                <h3>{t('commons:sort_the_table')}</h3>
            </StyledHead>
            <StyledBody>
                {columns
                    .filter(column => column.sortName)
                    .map(column => (
                        <StyledButton
                            size="sm"
                            variant="ghost"
                            key={column.sortName}
                            onClick={() => {
                                sorter.onSort({orderBy: column.sortName, orderDirection: 'desc'})
                                onClose()
                            }}
                            fullWidth
                        >
                            {column.label}
                            {sorter.orderBy == column.sortName && <CheckIcon />}
                        </StyledButton>
                    ))}
            </StyledBody>
        </StyledSorterModal>
    )
}

MobileSorterModal.displayName = 'MobileSorterModal'
export default MobileSorterModal
