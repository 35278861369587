import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {generatePath} from 'react-router-dom'
import {FC} from 'react'
import {routes} from '@utilities/constants'
import {PromoServicesSection} from '@/features/promotional-services/components/promo-services-section/PromoPhotoshootsSection.tsx'
import {StyledTermsLink} from '@/features/promotional-services/components/promo-services-section/style.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {FooterNotes} from '@/features/service/components/footer-notes/FooterNotes.tsx'
import {PromoPhotoSectionStats} from '@/features/promotional-services/components/promo-photo-section/PromoPhotoSectionStats.tsx'
import join1Img from '@assets/images/photo-landing/join1.webp'
import join2Img from '@assets/images/photo-landing/join2.webp'
import join3Img from '@assets/images/photo-landing/join3.webp'
import join4Img from '@assets/images/photo-landing/join4.webp'
import join5Img from '@assets/images/photo-landing/join5.webp'
import join1LuxeImg from '@assets/images/photo-landing/join1Luxe.jpg'
import join2LuxeImg from '@assets/images/photo-landing/join2Luxe.jpg'
import join3LuxeImg from '@assets/images/photo-landing/join3Luxe.jpg'
import join4LuxeImg from '@assets/images/photo-landing/join4Luxe.jpg'
import join5LuxeImg from '@assets/images/photo-landing/join5Luxe.jpg'
import {PhotoMosaic} from '@/features/landing/components/photo-mosaic/PhotoMosaic.tsx'
import {ServiceSteps} from '@/features/landing/components/service-steps/ServiceSteps.tsx'
import acceptImg from '@assets/images/photo-landing/steps/sign_quote.svg'
import pickDatesImg from '@assets/images/photo-landing/steps/shoot_date.svg'
import havePhotoshootImg from '@assets/images/photo-landing/steps/meet_photographer.svg'
import reviewImg from '@assets/images/photo-landing/steps/review.svg'
import enjoyImg from '@assets/images/photo-landing/steps/enjoy.svg'
import Container from '@components/ui/container/Container.tsx'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'

export interface PromoPhotoSectionProps {
    onPhotoShootRequestClick: () => void
    photoServices: ServiceProposal[]
    showFullServicesInfo: boolean
}

const homeImages = [join2Img, join3Img, join4Img, join5Img]
const luxeImages = [join2LuxeImg, join3LuxeImg, join4LuxeImg, join5LuxeImg]

export const PromoPhotoSection: FC<PromoPhotoSectionProps> = ({
    onPhotoShootRequestClick,
    photoServices,
    showFullServicesInfo
}) => {
    const {t} = useTranslation()
    //TODO: temporary check
    const isLuxe = photoServices[0].task_category.name == 'Luxe' || photoServices[0].task_category.name == 'Luxe Drone'
    const isExp =
        photoServices[0].task_category.name == 'Experience' || photoServices[0].task_category.name == 'M13-Photo'

    return (
        <Container>
            {showFullServicesInfo && (
                <StyledPageHeaderContainer>
                    <StyledPageHeaderText direction={'column'} gap={3}>
                        <h1>{t('dashboard:promotional_photo:landing_mode_title')}</h1>
                    </StyledPageHeaderText>
                </StyledPageHeaderContainer>
            )}

            <PromoServicesSection
                id={'photoshoots'}
                title={showFullServicesInfo ? t('commons:your_quotes') : t('dashboard:promotional_photo:title')}
                subtitle={
                    <Trans
                        i18nKey={
                            isExp ? 'dashboard:promotional_photo:subtitle_ext' : 'dashboard:promotional_photo:subtitle'
                        }
                        components={[
                            <StyledTermsLink
                                target="_blank"
                                to={generatePath(routes.TERMS_OF_SERVICE.path, {type: 'photo-video'})}
                            />
                        ]}
                    />
                }
                services={photoServices}
                showRequestBtn={showFullServicesInfo && !isLuxe}
                requestServiceModalCb={onPhotoShootRequestClick}
                requestServiceCtaLabel={t('routeNames:request_a_photoshoot')}
            />

            {showFullServicesInfo && (
                <>
                    {!isLuxe && <PromoPhotoSectionStats />}
                    <PhotoMosaic
                        title={t('photoshoot_landing:join:title')}
                        subtitle={t('photoshoot_landing:join:subtitle')}
                        mainImage={isLuxe ? join1LuxeImg : join1Img}
                        sideImages={isLuxe ? luxeImages : homeImages}
                    />
                    <ServiceSteps
                        title={t('photoshoot_landing:service_steps:title')}
                        subtitle={t('photoshoot_landing:service_steps:subtitle')}
                        steps={[
                            {
                                title: t('photoshoot_landing:service_steps:label_1'),
                                subtitle: t('photoshoot_landing:service_steps:description_1'),
                                image: acceptImg
                            },
                            {
                                title: t('photoshoot_landing:service_steps:label_2'),
                                subtitle: t('photoshoot_landing:service_steps:description_2'),
                                image: pickDatesImg
                            },
                            {
                                title: t('photoshoot_landing:service_steps:label_3'),
                                subtitle: t('photoshoot_landing:service_steps:description_3'),
                                image: havePhotoshootImg
                            },
                            {
                                title: t('photoshoot_landing:service_steps:label_4'),
                                subtitle: t('photoshoot_landing:service_steps:description_4'),
                                image: reviewImg
                            },
                            {
                                title: t('photoshoot_landing:service_steps:label_5'),
                                subtitle: t('photoshoot_landing:service_steps:description_5'),
                                image: enjoyImg
                            }
                        ]}
                    />
                    <FooterNotes showSup2={true} />
                </>
            )}
        </Container>
    )
}

PromoPhotoSection.displayName = 'PromoPhotoSection'
