import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {useState} from 'react'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {Button} from '@/components/ui/button/Button'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {IntroStepsTimeline} from '../intro-steps-timeline/IntroStepsTimeline'
import {StyledViewSomeExampleButton} from './style'
import planYourShootImage from '@assets/images/experiences-host-video/planyourshoot.png'
import inviteYourCrewImage from '@assets/images/experiences-host-video/inviteyourcrew.png'
import uploadClipsImage from '@assets/images/experiences-host-video/uploadclips.png'
import {TrailerExampleModal} from '../trailer-example-modal/TrailerExampleModal'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import toast from 'react-hot-toast'

export const IntroStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const updateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction={'column'} gap={4}>
                    <ExperiencesHostVideoTitle>{t('experiences_host_video:intro:title')}</ExperiencesHostVideoTitle>
                    <ExperiencesHostVideoParagraph>
                        <Trans
                            i18nKey={'experiences_host_video:intro:description'}
                            components={[<StyledViewSomeExampleButton onClick={() => setSlidingModalState('open')} />]}
                        />
                    </ExperiencesHostVideoParagraph>
                </Flexbox>
                <Flexbox direction={'column'} gap={6} align={'stretch'} width="100%">
                    <IntroStepsTimeline
                        number={1}
                        title={t('experiences_host_video:plan_shoot')}
                        paragraph={t('experiences_host_video:intro:plan_shoot_description')}
                        image={planYourShootImage}
                    />
                    <IntroStepsTimeline
                        number={2}
                        title={t('experiences_host_video:invite_crew')}
                        paragraph={t('experiences_host_video:intro:invite_crew_description')}
                        image={inviteYourCrewImage}
                    />
                    <IntroStepsTimeline
                        number={3}
                        title={t('experiences_host_video:upload_video_clips')}
                        paragraph={t('experiences_host_video:intro:upload_description')}
                        image={uploadClipsImage}
                    />
                </Flexbox>
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Button
                    onClick={() => updateStepMutation.mutate({status: 'preparation', step: 'intro'})}
                    disabled={updateStepMutation.isPending}
                    fullWidth
                >
                    {t('commons:get_started')}

                    {updateStepMutation.isPending && (
                        <Flexbox>
                            <Spinner />
                        </Flexbox>
                    )}
                </Button>
            </ExperiencesHostVideoFooter>

            {slidingModalState != 'closed' && (
                <TrailerExampleModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                />
            )}
        </>
    )
}
