import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalFooter, ModalHeader, ModalParagraph, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {StyledBody, StyledTitle} from '@/features/experiences-host-video/components/confirm-self-filming-modal/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useUpdateExperiencesHostVideo} from '@/features/experiences-host-video/services/useUpdateExperiencesHostVideo.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'

export const ConfirmSelfFilmingModal: FC<{onClose: () => void}> = ({onClose}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const updateIsSelfFilmedMutation = useUpdateExperiencesHostVideo({
        params: routeParams,
        mutationOptions: {
            onError: () => toast.error(t('errors:default')),
            onSuccess: onClose
        }
    })

    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <StyledBody>
                <StyledTitle>
                    {t('experiences_host_video:ready_to_film_get_help:self_filming_confirm_title')}
                </StyledTitle>
                <ModalParagraph>
                    {t('experiences_host_video:ready_to_film_get_help:self_filming_confirm_description')}
                </ModalParagraph>
            </StyledBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" disabled={updateIsSelfFilmedMutation.isPending} onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button
                    disabled={updateIsSelfFilmedMutation.isPending}
                    variant="primary"
                    onClick={() => updateIsSelfFilmedMutation.mutate({is_self_filmed: true})}
                >
                    {t('experiences_host_video:ready_to_film_get_help:self_filming_confirm_cta')}
                    {updateIsSelfFilmedMutation.isPending && <Spinner size={12} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
