import {FC, useRef, useState} from 'react'
import {
    StyledAudioPlayerContainer,
    StyledAudioPlayerWrapper,
    StyledAudioPlayPauseButton,
    StyledAudioProgressInput,
    StyledCustomSliderTruck,
    StyledCustomSlideThumb,
    StyledProgressBarWrapper
} from '@/features/experiences-host-video/components/audio-player/style.ts'
import {Columns01Icon, PlayIcon} from '@components/ui/icon'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import dayjs from '@utilities/dayjs.ts'
import duration from 'dayjs/plugin/duration'

interface AudioPlayerProps {
    src: string
}
dayjs.extend(duration)
export const AudioPlayer: FC<AudioPlayerProps> = ({src}) => {
    const audioRef = useRef<HTMLAudioElement>(null)
    const [duration, setDuration] = useState(0)
    const [isReady, setIsReady] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentProgress, setCurrentProgress] = useState(0)
    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current?.pause()
            setIsPlaying(false)
        } else {
            audioRef.current?.play()
            setIsPlaying(true)
        }
    }
    return (
        <StyledAudioPlayerWrapper>
            <audio
                ref={audioRef}
                preload="auto"
                onLoadedMetadata={() => setIsReady(true)}
                onDurationChange={e => setDuration(e.currentTarget.duration)}
                onCanPlay={() => {
                    setIsReady(true)
                }}
                onPlaying={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onTimeUpdate={e => {
                    setCurrentProgress(e.currentTarget.currentTime)
                }}
            >
                <source src={src} />
            </audio>
            <StyledAudioPlayerContainer>
                <StyledAudioPlayPauseButton disabled={!isReady} variant={'secondary'} onClick={togglePlayPause}>
                    {!isReady ? <Spinner /> : isPlaying ? <Columns01Icon /> : <PlayIcon />}
                </StyledAudioPlayPauseButton>
                <Flexbox direction={'column'} gap={2} width={'100%'}>
                    <Flexbox justify={'space-between'} width={'100%'}>
                        <p>{dayjs.duration(currentProgress, 'seconds').format('mm:ss')}</p>
                        <p>{dayjs.duration(duration != Infinity ? duration : 0, 'seconds').format('mm:ss')}</p>
                    </Flexbox>
                    <StyledProgressBarWrapper>
                        <StyledCustomSliderTruck />
                        <StyledCustomSlideThumb $width={`${(currentProgress / duration) * 100}%`} />
                        <StyledAudioProgressInput
                            type="range"
                            min={0}
                            max={duration}
                            step={0.01}
                            value={currentProgress}
                            onChange={e => {
                                if (!audioRef.current) return
                                audioRef.current.currentTime = e.currentTarget.valueAsNumber
                                setCurrentProgress(e.currentTarget.valueAsNumber)
                            }}
                        />
                    </StyledProgressBarWrapper>
                </Flexbox>
            </StyledAudioPlayerContainer>
        </StyledAudioPlayerWrapper>
    )
}
