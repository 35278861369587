import {Button} from '@components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledListingCategoriesCard = styled.div(
    ({theme: {shadows, palette, spacing}}) => css`
        overflow: hidden;
        border-radius: 16px;
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.md};
        width: 100%;
    `
)

export const StyledListigCategoriesHeader = styled(Flexbox)(
    ({theme: {spacing, mediaQueries}}) => css`
        position: relative;
        margin-bottom: ${spacing * 3}px;

        ${mediaQueries.xs} {
            flex-direction: column-reverse;
        }
    `
)

export const StyledCategoriesRequestsWarning = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
        background: ${palette.warning[50]};
        color: ${palette.warning[700]};
        border-radius: 12px;
        padding: ${spacing * 3}px ${spacing * 4}px;

        svg {
            flex-shrink: 0;
            width: 20px;
        }
    `
)

export const StyledListingCategoriesSkeletonButton = styled.div(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            right: 0;
            position: absolute;
        }

        ${mediaQueries.xs} {
            position: relative;
            margin-left: auto;
        }
    `
)

export const StyledListingCategoriesButton = styled(Button)(
    ({theme: {mediaQueries}}) => css`
        width: fit-content;
        height: fit-content;
        white-space: nowrap;

        ${mediaQueries.m} {
            right: 0;
            position: absolute;
        }

        ${mediaQueries.xs} {
            position: relative;
            margin-left: auto;
        }

        svg {
            height: 20px;
        }
    `
)

export const StyledListingCategoriesHeaderContainer = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        flex: 1;
        max-width: 100%;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `
)

export const StyledListingCategoriesHeaderText = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        flex-grow: 1;
        width: 0px;

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledListingCategoriesBodyHeading = styled.div(
    ({theme: {spacing}}) => css`
        margin-top: ${spacing * 3}px;
        width: 100%;
    `
)

export const StyledListingCategoriesBody = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        width: 100%;
        color: ${palette.neutral[600]};

        p {
            color: ${palette.neutral[500]};
            ${typography.textSm};
        }
    `
)

export const StyledListingCategoriesChips = styled(Flexbox)(
    ({theme: {typography, spacing}}) => css`
        padding: ${spacing * 4}px 0;
        ${typography.textMd};
        font-weight: 500;
        flex-wrap: wrap;
    `
)

export const StyledListingCategoriesFooter = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 3}px 0;
        width: 100%;

        ${mediaQueries.m} {
            margin-top: ${spacing * 3}px;
        }
    `
)
