import {
    StyledAlertBanner,
    StyledAlertText,
    StyledButtonArea,
    StyledContent,
    StyledParagraph,
    StyledPreviewImage,
    StyledQuestionDescription,
    StyledQuestionTitle,
    StyledTitle
} from '@/components/ui/multi-step-form-atoms/style.ts'
import {forwardRef, HTMLAttributes, ImgHTMLAttributes, ReactNode} from 'react'
import {Flexbox, FlexBoxCustomProps} from '@components/ui/flexbox/FlexBox.tsx'
import {CSSProperties} from 'styled-components'
import {ContainerProps} from '@components/ui/container/Container.tsx'
import {AlertCircleIcon} from '@components/ui/icon'

export const Content = ({children, gap = 8, ...rest}: ContainerProps & {gap?: number}) => (
    <StyledContent $gap={gap} children={children} {...rest} />
)

export const HeadingGroup = (props: FlexBoxCustomProps) => <Flexbox direction="column" gap={4} {...props} />

export const Title = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledTitle {...props} />

export const Paragraph = (props: HTMLAttributes<HTMLParagraphElement>) => <StyledParagraph {...props} />

export const FieldTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledQuestionTitle {...props} />

export const FieldDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>((props, ref) => (
    <StyledQuestionDescription {...props} ref={ref} />
))

export const AlertText = forwardRef<
    HTMLParagraphElement,
    HTMLAttributes<Omit<HTMLParagraphElement, 'children'>> & {text: ReactNode}
>(({text, ...rest}, ref) => (
    <StyledAlertText {...rest} ref={ref}>
        <AlertCircleIcon size={20} />
        {text}
    </StyledAlertText>
))

export const AlertBanner = forwardRef<
    HTMLDivElement,
    HTMLAttributes<Omit<HTMLDivElement, 'children'>> & {title: ReactNode; paragraph?: ReactNode}
>(({paragraph, title, ...rest}, ref) => (
    <StyledAlertBanner {...rest} ref={ref}>
        <AlertCircleIcon size={20} />
        <Flexbox direction="column" gap={1}>
            <h3>{title}</h3>
            {!!paragraph && <p>{paragraph}</p>}
        </Flexbox>
    </StyledAlertBanner>
))

export const ButtonArea = ({
    padding,
    borderRadius,
    fullWidth,
    ...props
}: HTMLAttributes<HTMLDivElement> & {
    padding?: number
    fullWidth?: boolean
    borderRadius?: CSSProperties['borderRadius']
}) => <StyledButtonArea {...props} $padding={padding} $borderRadius={borderRadius} $fullWidth={fullWidth} />

export const PreviewImage = ({
    hasLowTransparency,
    ...props
}: ImgHTMLAttributes<HTMLImageElement> & {hasLowTransparency?: boolean}) => (
    <StyledPreviewImage {...props} $hasLowTransparency={hasLowTransparency} />
)
