import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {parseAxiosPromise} from '@/utilities/helpers'
import {captureException} from '@sentry/react'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpAddOffering, HttpAddOfferingOptions} from './services.http'
import {Offering, Service} from '@/features/services/types'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

export const useAddOffering = (
    serviceId: number,
    options?: Omit<UseMutationOptions<Offering, unknown, HttpAddOfferingOptions['payload']>, 'mutationFn'>
) => {
    const queryKey = [QUERY_KEYS.SERVICE, serviceId]

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ADD_OFFERING],
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpAddOffering({
                    urlParams: {serviceId},
                    payload,
                    params: {response_type: 'extended'}
                }),
                onZodError: captureException,
                responseShape: Offering
            }),
        onMutate: async () => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})
            // Snapshot the previous value
            const previousSubmission = queryClient.getQueryData<Service>(queryKey)
            // Return a context object with the snapshot value
            return {previousSubmission}
        },
        onSuccess: (data, variables, context) => {
            queryClient.setQueriesData(
                {queryKey: queryKey},
                {
                    ...context?.previousSubmission,
                    offerings: context?.previousSubmission?.offerings.concat(data)
                }
            )
            options?.onSuccess?.(data, variables, context)
        },
        onError: (error: AxiosError<ErrorResponseData>, vars, context) => {
            options?.onError?.(error, vars, context)
            queryClient.setQueriesData({queryKey: queryKey}, context?.previousSubmission)
        }
    })
}
