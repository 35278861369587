import styled, {css} from 'styled-components'

export const StyledLayout = styled.div(
    () => css`
        display: grid;
        grid-template-rows: auto 1fr auto;
        min-height: 100%;
    `
)

export const StyledHeader = styled.div(
    ({theme: {palette, spacing}}) => css`
        position: sticky;
        top: 0;
        height: 40px;
        padding: 0 ${spacing * 4}px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${palette.neutral.white};
        z-index: 2;
        & a {
            padding-left: 0;
            padding-right: 0;
        }
    `
)
