import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {formatLocaleDate} from '@utilities/helpers.ts'
import i18next from 'i18next'
import {Trans} from '@/translations/i18n.tsx'

export const hp3 = ({expirationDate}: {expirationDate?: Date}) => [
    {
        id: 'HP3',
        options: [
            {
                value: 'HP3.1',
                label: i18next.t('chatbot:HP3:HP3.1_label'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP3',
                        source: 'User',
                        text: 'What does the expiration date mean?'
                    })
                    return 'HP3.1'
                }
            },
            {
                value: 'start',
                label: i18next.t('commons:other'),
                trigger: () => {
                    useChatbotStore.getState().addStepToHistory({
                        id: 'HP3',
                        source: 'User',
                        text: 'Other'
                    })
                    return 'start'
                }
            }
        ]
    },
    {
        id: 'HP3.1',
        component: (
            <p>
                <Trans
                    i18nKey={'chatbot:HP3:HP3.1_component'}
                    components={[<b></b>]}
                    values={{expirationDate: formatLocaleDate(expirationDate ?? '-')}}
                />
            </p>
        ),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP3.1',
                source: 'Chatbot',
                text: `After ${formatLocaleDate(
                    expirationDate ?? '-'
                )} your quote will expire and you will not be able to sign it.`
            })
            return '3.1.2'
        },
        asMessage: true
    },
    {
        id: '3.1.2',
        message: i18next.t('chatbot:HP3:HP3.1.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: '3.1.2',
                source: 'Chatbot',
                text: 'If your quote has already expired please click the "Start a New Quote" button. Please note that the price of the new quote might be different from the original price quote.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    },
    {
        id: 'HP3.2',
        message: i18next.t('chatbot:HP3:HP3.2_message'),
        trigger: () => {
            useChatbotStore.getState().addStepToHistory({
                id: 'HP3.2',
                source: 'Chatbot',
                text: 'If you are not ready to sign your quote today, you can schedule a reminder by clicking the “Schedule a Reminder” option at the bottom of the price quote page. We will automatically extend your quote expiration date to five days after the reminder date. Our team will send you a reminder on the selected date.'
            })
            return 'oq_message'
        } // Final trigger of the flow's branch
    }
]
