import {StyledFooter} from '@/features/services/components/footer/style.ts'
import {ProgressBar} from '@/features/experiences-host-video/components/progress-bar/ProgressBar.tsx'
import {FC, ReactNode} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const Footer: FC<{progressPercentage?: number; children: ReactNode}> = ({progressPercentage, children}) => {
    return (
        <StyledFooter>
            {progressPercentage != null && <ProgressBar rounded percentage={progressPercentage} />}
            <Flexbox justify="space-between">{children}</Flexbox>
        </StyledFooter>
    )
}
