import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '../status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper'
import {StatusDescriptionWrapper} from '../status-description-wrapper/StatusDescriptionWrapper'
import {StatusDescriptionIconWrapper} from '../status-description-icon/StatusDescriptionIconWrapper'
import {StatusDescriptionTitle} from '../status-description-title/StatusDescriptionTitle'
import {XCloseIcon} from '@/components/ui/icon'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'

export const ExperienceVideoAdminCanceledStatusDescription = () => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <XCloseIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:canceled_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:admin_canceled_paragraph')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

ExperienceVideoAdminCanceledStatusDescription.displayName = 'ExperienceVideoAdminCanceledStatusDescription'
