import styled, {css} from 'styled-components'
import {Content} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'

export const StyledContent = styled(Content)(
    ({theme: {typography, palette}}) => css`
        justify-content: center;
        align-items: center;
        text-align: center;
        & > svg {
            width: 48px;
            height: 48px;
            fill: ${palette.neutral[700]};
        }
        & h3 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
        & small {
            ${typography.textSm};
            color: ${palette.neutral[900]};
        }
    `
)
