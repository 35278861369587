import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {CalendarIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {FC} from 'react'

export const WalkthroughAssignedDeclinedDatesStatusDescription: FC<{
    assignedFreelancer: string | undefined
}> = ({assignedFreelancer}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <CalendarIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:walkthrough:assigned_dates_declined_title', {
                    freelancer: assignedFreelancer
                })}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:assigned_dates_declined_paragraph')}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

WalkthroughAssignedDeclinedDatesStatusDescription.displayName = 'WalkthroughAssignedDeclinedDatesStatusDescription'
