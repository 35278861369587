import {useEffect, useRef} from 'react'
import {useInView} from '@/hooks/useInView'
import {ComboboxItemCheck, ComboboxList} from '@ariakit/react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Skeleton from '@/components/ui/skeleton/Skeleton'
import {ComboboxItem} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {StyledCitiesMultiSelectComboboxItem, StyledCitiesMultiSelectPopover} from './style'
import {useTranslation} from '@/translations/i18n'
import {City} from '@/features/services/types'

interface CitiesMultiSelectPopoverProps {
    cities: City[]
    isLoading: boolean
    isError: boolean
    onEndReached: () => void | false
}
export const CitiesMultiSelectPopover = ({cities, isLoading, isError, onEndReached}: CitiesMultiSelectPopoverProps) => {
    const {t} = useTranslation()

    const lastElementRef = useRef<HTMLSpanElement | null>(null)
    const isInView = useInView(lastElementRef, {threshold: 0.5})

    useEffect(() => {
        if (isInView && onEndReached) {
            onEndReached()
        }
    }, [isInView, onEndReached])

    return (
        <StyledCitiesMultiSelectPopover flip="bottom left">
            <ComboboxList>
                {cities.length > 0 ? (
                    cities.map((item, itemIndex) => (
                        <StyledCitiesMultiSelectComboboxItem key={itemIndex} value={item.id.toString()}>
                            {item.state ? `${item.name}, ${item.state}` : item.name}
                            <ComboboxItemCheck />
                        </StyledCitiesMultiSelectComboboxItem>
                    ))
                ) : isLoading ? (
                    <Flexbox direction="column" gap={2} width="100%">
                        {Array.from({length: 3}, (_, index) => (
                            <Flexbox key={index} gap={2} width="100%">
                                <Skeleton width="100%" height={40} />
                            </Flexbox>
                        ))}
                    </Flexbox>
                ) : isError ? (
                    <ComboboxItem disabled>
                        <InputHelpText error={t('errors:default')} />
                    </ComboboxItem>
                ) : (
                    <ComboboxItem disabled>
                        <InputHelpText helpText={t('commons:no_results_found')} />
                    </ComboboxItem>
                )}
                <span ref={lastElementRef} />
            </ComboboxList>
        </StyledCitiesMultiSelectPopover>
    )
}
