import {useTranslation} from '@/translations/i18n.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {ParentFlag} from '../../types'
import {flagToDescription} from '../../utils'
import {ReactNode, useEffect, useRef, useState} from 'react'
import {StyledAlertTextWrapper, StyledText, StyledTextWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {FlagsModal} from '../flags-modal/FlagsModal'

export const FlagsAlertText = ({
    flags = [],
    title,
    variant = 'alert',
    detailLevel = 'default'
}: {
    flags?: ParentFlag[]
    title: ReactNode
    variant?: 'banner' | 'alert'
    detailLevel?: 'default' | 'offering_title' | 'offering_description'
}) => {
    const {t} = useTranslation()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const [isTruncated, setIsTruncated] = useState(false)
    const textRef = useRef<HTMLSpanElement>(null)
    const firstDescription = flags
        .map(flag => flagToDescription(flag?.key_name, detailLevel))
        .find(description => !!description)

    useEffect(() => {
        const element = textRef.current
        if (element) {
            setIsTruncated(element.scrollHeight > element.offsetHeight)
        }
    }, [flags])

    if (!flags.length || !firstDescription) return

    return (
        <>
            <StyledAlertTextWrapper $variant={variant}>
                <AlertCircleIcon size={20} />
                <StyledTextWrapper gap={1} width="100%" direction="column">
                    <StyledText ref={textRef}>{firstDescription}</StyledText>

                    {(isTruncated || flags.length > 1) && (
                        <Button variant="tertiary" shape="text" onClick={() => setSlidingModalState('open')}>
                            {t('commons:learn_more')}
                        </Button>
                    )}
                </StyledTextWrapper>
            </StyledAlertTextWrapper>

            {slidingModalState != 'closed' && (
                <FlagsModal
                    flags={flags}
                    title={title}
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    detailLevel={detailLevel}
                />
            )}
        </>
    )
}
