import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledOfferingItem = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 48px 1fr;
        column-gap: ${spacing * 4}px;
        align-items: start;
        width: 100%;
    `
)

export const StyledImagePlaceholderBox = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.neutral['100']};
        height: 48px;
        border-radius: 8px;

        & > svg {
            fill: ${palette.neutral['400']};
        }
    `
)

export const StyledOfferingItemTextWrapper = styled(Flexbox)`
    overflow: hidden;
    & > h3 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const StyledOfferingType = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm}
        font-weight: 500;
        color: ${palette.neutral[600]};
    `
)
export const StyledOfferingTitle = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textXlFixed}
        font-weight: 500;
    `
)
