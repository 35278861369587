import {Footer} from '@/features/services/components/footer/Footer.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/services/utils.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    AlertBanner,
    ButtonArea,
    Content,
    HeadingGroup,
    Paragraph,
    Title
} from '@components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Fragment, useEffect, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckIcon, PlusIcon} from '@/components/ui/icon'
import {StyledButtonAreaCta, StyledButtonAreaDescription} from './style'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {OfferingFormModal} from '@/features/services/components/offering-form-modal/OfferingFormModal'
import {Offerings} from '@/features/services/components/offerings/Offerings'
import {Offering, OfferingTypes, Service} from '@/features/services/types'
import {ObjectEntries, raise} from '@utilities/helpers.ts'
import {useUpdateStepToGallery} from '@/features/services/services/useUpdateStepToGallery'
import {useUpdateStepToLocation} from '@/features/services/services/useUpdateStepToLocation'
import {ConfirmSubmitModal} from '@/features/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {Trans} from 'react-i18next'
import {useTheme} from 'styled-components'
import {Badge} from '@/components/ui/badge/Badge'
import {Divider} from '@/components/ui/divider/Divider'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

type CategorizedOfferings = Record<OfferingTypes, Offering[]>

const MIN_OFFERINGS_COUNT = 3
export const mandatoryOfferingTypes: OfferingTypes[] = [OfferingTypes.Enum.standard]
export const OfferingsStep = ({service}: {service: Service}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [selectedOffering, setSelectedOffering] = useState<null | Offering>(null)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const flagsCount = updatesRequiredSteps.offerings.flatMap(offering => offering.flatMap(field => field.flags)).length
    const [isConfirmEnabled, setIsConfirmEnabled] = useState(false)
    const [selectedOfferingType, setSelectedOfferingType] = useState<OfferingTypes | null>(null)

    const updateStepToLocationMutation = useUpdateStepToLocation<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id
    })
    const updateStepToGalleryMutation = useUpdateStepToGallery({
        serviceId: service.id,
        expertiseId: service.expertise.id
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'offerings', updatesRequiredSteps}).prev?.()
            : updateStepToLocationMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'offerings'>({service, currentStep: 'offerings', updatesRequiredSteps}).next?.()
            : updateStepToGalleryMutation

    useEffect(() => {
        if (selectedOffering && slidingModalState == 'closed') {
            setSelectedOfferingType(null)
            setSelectedOffering(null)
        }
    }, [selectedOffering, slidingModalState, selectedOfferingType])

    const categorizedOfferings = service.offerings.reduce<CategorizedOfferings>(
        (acc, offering) => {
            switch (offering.type) {
                case OfferingTypes.Enum.standard:
                    acc.standard.push(offering)
                    break
                case OfferingTypes.Enum.basic:
                    acc.basic.push(offering)
                    break
                case OfferingTypes.Enum.luxe:
                    acc.luxe.push(offering)
                    break
                case OfferingTypes.Enum.exclusive:
                    acc.exclusive.push(offering)
                    break
                default:
                    acc.other.push(offering)
                    break
            }

            return acc
        },
        {
            [OfferingTypes.Enum.standard]: [],
            [OfferingTypes.Enum.basic]: [],
            [OfferingTypes.Enum.luxe]: [],
            [OfferingTypes.Enum.exclusive]: [],
            [OfferingTypes.Enum.other]: []
        }
    )

    const hasAllRequiredType = mandatoryOfferingTypes.every(key => categorizedOfferings[key].length > 0)
    const hasMinOfferingCount = service.offerings.length >= MIN_OFFERINGS_COUNT
    useEffect(() => {
        if (hasAllRequiredType && hasMinOfferingCount) {
            setIsConfirmEnabled(true)
        } else {
            setIsConfirmEnabled(false)
        }
    }, [categorizedOfferings])

    const getCorrectOfferingDescription = () => {
        if (service.offerings.length == 0) {
            return 'services:step_offerings:need_offering_count_description'
        } else if (!hasAllRequiredType) {
            return 'services:step_offerings:offering_count_description_standard'
        } else if (hasAllRequiredType && hasMinOfferingCount) {
            return 'services:step_offerings:offering_count_description_min'
        } else {
            return 'services:step_offerings:offering_count_description_need_more'
        }
    }

    return (
        <>
            <Content gap={6}>
                <HeadingGroup>
                    <Title>{t('services:step_offerings:title')}</Title>
                    <Paragraph>{t('services:step_offerings:paragraph')}</Paragraph>

                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:offerings_improvement_title')}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}

                    <Flexbox gap={2}>
                        {hasMinOfferingCount && hasAllRequiredType && <CheckIcon fill={palette.success[600]} />}
                        <Paragraph>
                            <Trans
                                i18nKey={getCorrectOfferingDescription()}
                                components={{strong: <strong />}}
                                values={{
                                    min_offering_count: MIN_OFFERINGS_COUNT,
                                    add_more_count:
                                        MIN_OFFERINGS_COUNT - service.offerings.length > 0
                                            ? MIN_OFFERINGS_COUNT - service.offerings.length
                                            : '',
                                    count: service.offerings.length
                                }}
                            />
                        </Paragraph>
                    </Flexbox>
                </HeadingGroup>

                <Flexbox gap={4} direction="column" width="100%">
                    {ObjectEntries(categorizedOfferings).map(([key, value]) => (
                        <Fragment key={key}>
                            {value.length == 0 ? (
                                <ButtonArea
                                    fullWidth
                                    padding={7}
                                    borderRadius="16px"
                                    onClick={() => {
                                        setSelectedOfferingType(key)
                                        setSlidingModalState('open')
                                    }}
                                >
                                    <Flexbox gap={1} align="center" justify="space-between">
                                        <Flexbox gap={2} direction="column">
                                            <Flexbox gap={1} direction="column">
                                                <StyledButtonAreaCta>
                                                    {t(`services:step_offerings:offering_type_title:${key}`)}
                                                </StyledButtonAreaCta>
                                                {key != OfferingTypes.enum.other && (
                                                    <StyledButtonAreaDescription>
                                                        {t(`services:step_offerings:add_offering_description:${key}`)}
                                                    </StyledButtonAreaDescription>
                                                )}
                                            </Flexbox>
                                            {mandatoryOfferingTypes.includes(key) && (
                                                <Badge shape="roundedRectangle" size="lg">
                                                    {t(`services:step_offerings:mandatory`)}
                                                </Badge>
                                            )}
                                        </Flexbox>
                                        <PlusIcon size={32} fill={palette.neutral[600]} />
                                    </Flexbox>
                                </ButtonArea>
                            ) : (
                                service && (
                                    <Offerings
                                        serviceId={service.id}
                                        offerings={value}
                                        country={service.country ?? raise('country is nullish')}
                                        openOfferingModal={() => {
                                            setSelectedOfferingType(key)
                                            setSlidingModalState('open')
                                        }}
                                        onSelect={offering => {
                                            setSelectedOfferingType(key)
                                            setSelectedOffering(offering)
                                            setSlidingModalState('open')
                                        }}
                                        isOtherOfferings={key == OfferingTypes.enum.other}
                                        hasAllRequiredType={hasAllRequiredType}
                                        offeringsLength={service.offerings.length}
                                    />
                                )
                            )}
                            {(key == OfferingTypes.enum.standard || key == OfferingTypes.enum.luxe) && (
                                <Divider direction="horizontal" background={palette.neutral[300]} />
                            )}
                        </Fragment>
                    ))}
                </Flexbox>
            </Content>

            <Footer progressPercentage={getStepProgressPercentage('offerings')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button
                        disabled={nextStepMutation.isPending || prevStepMutation?.isPending || !isConfirmEnabled}
                        onClick={() => nextStepMutation.mutate(undefined)}
                    >
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {slidingModalState != 'closed' && service.geo?.country && !!selectedOfferingType && (
                <OfferingFormModal
                    serviceId={service.id}
                    serviceAddress={service.address}
                    submissionCities={service.submission_cities}
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    country={service.geo?.country}
                    defaultValues={selectedOffering ?? undefined}
                    selectedOfferingType={selectedOfferingType}
                />
            )}
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'offerings'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={undefined}
                />
            )}
        </>
    )
}
