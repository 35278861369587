import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {httpRetrievePpStatus} from '@/features/chatbot/services/retrievePpStatus.http.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useTranslation} from '@/translations/i18n.tsx'

export const GetPpStatus = () => {
    const {run} = useAsync()
    const service = useChatbotStore(state => state.service)
    const chatbotRef = useChatbotStore(state => state.chatbotRef)
    const {t} = useTranslation()

    useEffect(() => {
        onGetShootStatus()
    }, [])

    const onGetShootStatus = async () => {
        try {
            const res = await run(
                httpRetrievePpStatus({
                    code: String(service?.entity.code),
                    password: String(service?.entity.password),
                    media: Number(service?.task_media?.id)
                })
            )
            useChatbotStore.getState().addStepToHistory({
                id: 'HP8',
                source: 'Chatbot',
                text: "I'm checking your status..."
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            chatbotRef?.current?.triggerNextStep({
                value: res.data?.post_production_status,
                trigger:
                    res.data?.post_production_status === 'Upload Issue'
                        ? 'HP8_2_send'
                        : res.data?.post_production_status === 'Post in Progress'
                        ? 'HP8.1'
                        : res.data?.post_production_status === 'Late'
                        ? 'HP8_3_send'
                        : 'HP8_4_send'
            })
        } catch (e) {
            errorHandler(e as AxiosError<{message: string}>)
        }
    }

    return (
        <div>
            <p>{t('chatbot:HP8:HP8_component')}</p>
        </div>
    )
}

GetPpStatus.displayName = 'GetPpStatus'
