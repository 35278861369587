import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import dayjs from '@utilities/dayjs.ts'

export const ExperienceVideoAssignedUnresponsiveStatusDescription: FC<{
    assignedFreelancer: string | undefined
    hostUnresponsiveAt?: Assignment['host_unresponsive_at']
}> = ({assignedFreelancer, hostUnresponsiveAt}) => {
    const {t} = useTranslation()
    const daysCount = dayjs(hostUnresponsiveAt).add(15, 'days').diff(dayjs(), 'days')

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <AlertCircleIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:experience_video:assigned_unresponsive_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:assigned_unresponsive_paragraph1', {
                        freelancer: assignedFreelancer
                    })}
                </StatusDescriptionParagraph>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:experience_video:assigned_unresponsive_paragraph2', {
                        daysCount: daysCount < 1 ? 1 : daysCount
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

ExperienceVideoAssignedUnresponsiveStatusDescription.displayName =
    'ExperienceVideoAssignedUnresponsiveStatusDescription'
