import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Microphone01Icon, StopIcon} from '@components/ui/icon'
import {useAudioRecorder} from 'react-audio-voice-recorder'
import toast from 'react-hot-toast'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {BlinkingDot} from '@/features/experiences-host-video/components/voiceover-recorder/style.ts'
import dayjs from '@utilities/dayjs.ts'
import duration from 'dayjs/plugin/duration'
import {useEffect, useState} from 'react'
import {AudioPlayer} from '@/features/experiences-host-video/components/audio-player/AudioPlayer.tsx'
import {
    httpConfirmUploadVoiceover,
    httpGetPresignBulkVoiceover
} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {z} from 'zod'
import {Media, UploadStepPayload} from '@/features/experiences-host-video/types.ts'
import {UploadedMedia} from '@/features/services/types.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {raise} from '@utilities/helpers.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import {RecordAgainConfirmationModal} from '@/features/experiences-host-video/components/record-again-confirmation-modal/RecordAgainConfirmationModal.tsx'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useDeleteVoiceover} from '@/features/experiences-host-video/services/useDeleteVoicovers.ts'
import {httpUploadFile} from '@services/upload.http.ts'

dayjs.extend(duration)

export const VoiceoverRecorder = ({
    uploadedAudio,
    nextStatus,
    nextStep,
    prevStep,
    prevStatus
}: {
    uploadedAudio?: UploadedMedia
    nextStatus: UploadStepPayload['status']['key_name']
    nextStep: UploadStepPayload['status']['step_key_name']
    prevStatus: UploadStepPayload['status']['key_name']
    prevStep: UploadStepPayload['status']['step_key_name']
}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})
    const onNotAllowedOrFound = () => {
        toast.error(t('experiences_host_video:audio_permission_denied'))
    }
    const recorderControls = useAudioRecorder({}, onNotAllowedOrFound, {mimeType: 'audio/mp4'})
    const uploadAudio = useAsync()
    const [isUploading, setIsUploading] = useState(false)
    const [showRetryModal, setShowRetryModal] = useState(false)
    const [audioUrl, setAudioUrl] = useState(uploadedAudio?.url ?? '')
    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const deleteVoiceoverMutation = useDeleteVoiceover({
        params: {
            ...routeParams,
            videoSetId: showExperiencesHostVideoQuery.data?.videoset?.id || raise('VideosetId is undefined')
        },
        mutationOptions: {
            onSuccess: () => {
                setAudioUrl('')
                setShowRetryModal(false)
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_VOICEOVER]})
            }
        }
    })

    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {
            onError: () => toast.error(t('errors:default'))
        }
    })
    const onRetry = () => {
        if (uploadedAudio) {
            deleteVoiceoverMutation.mutate({mediaId: uploadedAudio.id})
        } else {
            setAudioUrl('')
            URL.revokeObjectURL(audioUrl)
            setShowRetryModal(false)
        }
    }

    useEffect(() => {
        if (recorderControls.recordingBlob) {
            setAudioUrl(URL.createObjectURL(recorderControls.recordingBlob))
        }
    }, [recorderControls.recordingBlob])

    const onCLickSave = async () => {
        if (recorderControls.recordingBlob) {
            try {
                setIsUploading(true)
                const response = await httpGetPresignBulkVoiceover({
                    payload: {media: `${showExperiencesHostVideoQuery.data?.status.step_key_name}voiceover.mp4`},
                    urlParams: {
                        ...routeParams,
                        videoSetId: showExperiencesHostVideoQuery.data?.videoset?.id || raise('VideosetId is undefined')
                    }
                })
                const parsedResponseData = z
                    .object({media: Media, presigned_url: z.string().url()})
                    .parse(response.data)

                await uploadAudio.run(
                    httpUploadFile({url: parsedResponseData.presigned_url, payload: recorderControls.recordingBlob})
                )
                await httpConfirmUploadVoiceover({
                    ...routeParams,
                    videoSetId: showExperiencesHostVideoQuery.data?.videoset?.id || raise('VideosetId is undefined'),
                    audioId: parsedResponseData.media.id
                })
                await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_VOICEOVER]})

                continueUpdateStepMutation.mutate({status: nextStatus, step: nextStep})
            } catch (err) {
                toast.error(t('errors:default'))
            } finally {
                setIsUploading(false)
            }
        } else {
            continueUpdateStepMutation.mutate({status: nextStatus, step: nextStep})
        }
    }
    return (
        <Flexbox direction="column" gap={7} width="100%">
            {showRetryModal && (
                <RecordAgainConfirmationModal onContinue={onRetry} onClose={() => setShowRetryModal(false)} />
            )}
            {!audioUrl ? (
                <>
                    <Flexbox direction="column" gap={2} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <div>
                                {recorderControls.isRecording && (
                                    <Flexbox align="center" gap={2}>
                                        {t('experiences_host_video:rec')}
                                        <BlinkingDot />
                                    </Flexbox>
                                )}
                            </div>
                            <p>{dayjs.duration(recorderControls.recordingTime, 'seconds').format('mm:ss')}</p>
                        </Flexbox>
                        <Divider height="6px" startSpacing={0} endSpacing={0} direction="horizontal" />
                    </Flexbox>
                    {recorderControls.isRecording ? (
                        <Button variant={'secondary'} fullWidth onClick={recorderControls.stopRecording}>
                            <StopIcon size={20} />
                            {t('experiences_host_video:stop_record')}
                        </Button>
                    ) : (
                        <Flexbox width="100%" justify="space-between">
                            <Button
                                onClick={() => backUpdateStepMutation.mutate({status: prevStatus, step: prevStep})}
                                variant={'tertiary'}
                                disabled={backUpdateStepMutation.isPending}
                            >
                                {t('commons:back')}
                                {backUpdateStepMutation.isPending && <Spinner />}
                            </Button>
                            <Button
                                onClick={recorderControls.startRecording}
                                disabled={backUpdateStepMutation.isPending}
                            >
                                <Microphone01Icon size={20} />
                                {t('experiences_host_video:record')}
                            </Button>
                        </Flexbox>
                    )}
                </>
            ) : (
                <>
                    <AudioPlayer src={audioUrl} />
                    <Flexbox justify={'space-between'} width={'100%'}>
                        <Button
                            variant={'tertiary'}
                            onClick={() => setShowRetryModal(true)}
                            disabled={isUploading || continueUpdateStepMutation.isPending}
                        >
                            {t('experiences_host_video:record_again')}
                        </Button>
                        <Button
                            onClick={onCLickSave}
                            disabled={
                                isUploading || continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending
                            }
                        >
                            {t('experiences_host_video:save_continue')}{' '}
                            {(isUploading || continueUpdateStepMutation.isPending) && <Spinner />}
                        </Button>
                    </Flexbox>
                </>
            )}
        </Flexbox>
    )
}
