import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpGetService} from '@/features/services/services/services.http.ts'
import {Service} from '@/features/services/types.ts'

export const useService = (serviceId: number, options?: Omit<UseQueryOptions<Service>, 'queryFn' | 'queryKey'>) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICE, serviceId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetService({
                    urlParams: {serviceId},
                    params: {response_type: 'extended'}
                }),
                responseShape: Service,
                onZodError: captureException
            })
    })
