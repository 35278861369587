import {Button} from '@components/ui/button/Button.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {
    StyledOtpStepBody,
    StyledOtpStepForm,
    StyledOtpStepHeader,
    StyledOtpStepWrapper
} from '@/features/otp-flow/components/style.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import 'react-phone-input-2/lib/bootstrap.css'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {SubmitHandler, useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useAuthStore} from '@/store/AuthStore.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import InputText from '@components/commons/input-text/InputText.tsx'
import toast from 'react-hot-toast'
import {useNotificationPreferences} from '@/features/otp-flow/services/queries/useNotificationPreferences.ts'

const EmailOtpFlowSchema = z.object({
    email: z.string().email({message: 'service_accept:sms:editing:phone_error'})
})
export type EmailValidationSchema = z.infer<typeof EmailOtpFlowSchema>

export const OtpEmailEditStep = () => {
    const {t} = useTranslation()
    //otp store step
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const otpChangeStep = useOtpStore(state => state.changeStep)
    const isEditing = useOtpStore(state => state.isEditing)
    const onUpdateSendTo = useOtpStore(state => state.updateSendTo)
    const service = useOtpStore(state => state.service)
    //auth store
    const user = useAuthStore(state => state.user)
    //email to show
    const email = coAlarmRequest?.notification_preferences
        ? coAlarmRequest.notification_preferences.email
        : service?.notification_preferences?.email
        ? service?.notification_preferences?.email
        : user?.email
    //request code mutation
    const {mutate: requestVerificationCode, isPending} = useNotificationPreferences({
        onSuccess: () => {
            onUpdateSendTo(getValues().email)
            otpChangeStep('pending_confirmation', true)
        },
        onError: error => errorHandler(error)
    })

    //form structure
    const {
        handleSubmit,
        getValues,
        register,
        formState: {errors, isValid}
    } = useForm<EmailValidationSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(EmailOtpFlowSchema),
        defaultValues: {
            email: email ?? '-'
        }
    })

    const onSubmit: SubmitHandler<EmailValidationSchema> = data => {
        if (coAlarmRequest && coAlarmRequest.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: coAlarmRequest.notification_preferences.id
                },
                params: {
                    email: data.email
                }
            })
        } else if (service && service.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: service.notification_preferences.id
                },
                params: {
                    email: data.email
                }
            })
        } else {
            toast.error('errors:default')
        }
    }

    return (
        <StyledOtpStepWrapper direction={'column'} justify={'stretch'} gap={1}>
            <StyledOtpStepHeader justify={'space-between'} align={'center'}>
                <h2>{t(AcceptanceStepsConfigByService['email'].generic.editing.title)}</h2>
                {isEditing && (
                    <Button variant={'tertiary'} size={'md'} onClick={() => otpChangeStep('base', false)}>
                        {t('commons:cancel')}
                    </Button>
                )}
            </StyledOtpStepHeader>

            <StyledOtpStepBody direction={'column'} gap={6}>
                <p>{t(AcceptanceStepsConfigByService['email'].generic.editing.subtitle)}</p>
                <StyledOtpStepForm onSubmit={handleSubmit(onSubmit)}>
                    <InputText
                        width={300}
                        {...register('email')}
                        label={t(AcceptanceStepsConfigByService['email'].generic.editing.field_label)}
                        type={'email'}
                        placeholder={t('service_accept:email:editing:email_placeholder')}
                        /*TODO: remove as TKey*/
                        helpText={errors.email?.message ? t(errors.email.message as TKey) : ''}
                    />
                    <Button type={'submit'} size={'sm'} variant={'secondary'} disabled={!isValid || isPending}>
                        {t('commons:submit')}
                        {isPending && <Spinner size={16} />}
                    </Button>
                </StyledOtpStepForm>
            </StyledOtpStepBody>
        </StyledOtpStepWrapper>
    )
}

OtpEmailEditStep.displayName = 'OtpEmailEditStep'
