import {Dispatch, SetStateAction, useEffect} from 'react'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useTranslation} from 'react-i18next'
import {SelectValue} from '@/components/commons/select/Select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CoAlarmListingRemappedRequest} from '@/features/co-alarm/types'
import InputText from '@/components/commons/input-text/InputText'
import {
    StyledCoAlarmSendRequestInputsWrapper,
    StyledFullWidthInputText,
    StyledPhoneInputWrapper,
    StyledSelectCountry
} from '@/features/co-alarm/components/co-alarm-send-request-form/style'
import {
    CO_ALARM_SEND_REQUEST_EXTENDED_MODEL,
    CoAlarmSendRequestExtendedSchema
} from '@/features/co-alarm/components/co-alarm-send-request-form/extended-form/CoAlarmSendRequestExtendedFormModel'
import useAddressGeocoding from '@/features/co-alarm/hooks/useAddressGeocoding'
import {Button} from '@components/ui/button/Button'
import {Label} from '@/components/ui/label/Label'
import PhoneInput from 'react-phone-input-2'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {parseManualAddressForm} from '@/features/co-alarm/utils'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {StyledCoAlarmRequestSentMessage} from '@/features/co-alarm/components/co-alarm-send-request-form-wrapper/style'
import {useSendCoAlarmRequest} from '@/features/co-alarm/services/useSendCoAlarmRequest'
import toast from 'react-hot-toast'
import {HttpSendCoAlarmRequestOptions} from '@/features/co-alarm/services/coAlarm.http'
import Spinner from '@components/ui/spinner-legacy/Spinner'
import {CoAlarmSendRequestSchema} from '../form/CoAlarmSendRequestFormModel'

export const CoAlarmSendRequestExtendedForm = ({
    selectedListing,
    selectedCountry,
    setSelectedCountry
}: {
    selectedListing: CoAlarmListingRemappedRequest
    selectedCountry: string | null
    setSelectedCountry: Dispatch<SetStateAction<string | null>>
}) => {
    /*TODO: replace with i18n.ts useTranslation*/
    const {
        t,
        i18n: {language}
    } = useTranslation()
    const inputCountry = selectedCountry ?? selectedListing.country_code.toLowerCase()
    const navigate = useNavigate()

    const {mutate: requestCoAlarm, isPending: isPendingRequestCoAlarm} = useSendCoAlarmRequest({
        onSuccess: requestResponse => {
            toast.success(
                <StyledCoAlarmRequestSentMessage gap={1} direction="column">
                    <h4>{t('coAlarm:form:sent_successfully_toast:title')}</h4>
                    <p>{t('coAlarm:form:sent_successfully_toast:paragraph')}</p>
                </StyledCoAlarmRequestSentMessage>,
                {
                    position: 'top-right'
                }
            )

            localStorage.setItem('coNotifyModal', 'true')

            navigate(
                generatePath(routes.CO_ALARM_REQUEST_DETAILS.path, {
                    listingId: selectedListing.id,
                    requestId: requestResponse.id
                })
            )
        },
        onError: error => {
            console.error(error)
            toast.error(t('errors:default'), {
                position: 'top-right'
            })
        }
    })

    // Hook form setup
    const {
        control,
        handleSubmit,
        register,
        setValue,
        reset,
        resetField,
        formState: {errors, isValid, isSubmitting}
    } = useForm<CoAlarmSendRequestExtendedSchema>({
        mode: 'onChange',
        shouldFocusError: true,
        resolver: zodResolver(CoAlarmSendRequestExtendedSchema),
        defaultValues: {
            sensors_requested: 1,
            manual_address: true,
            listing: {
                label: selectedListing?.name,
                value: selectedListing?.name.toLowerCase()
            },
            country: {
                value: t(`coAlarm:countries:${inputCountry}`),
                code: inputCountry,
                label: t(`coAlarm:countries:${inputCountry}`),
                icon: (
                    <img
                        src={`https://flagcdn.com/w20/${inputCountry}.png`}
                        width="20"
                        height="14"
                        alt={selectedListing.country_code}
                        aria-hidden={true}
                    />
                )
            }
        }
    })

    //Geocoder's utilities
    const {countriesLocalizedOptions} = useAddressGeocoding(language)

    //  Reset all the fields when the listing changes
    useEffect(() => {
        reset()
        setValue('country', {
            value: t(`coAlarm:countries:${inputCountry}`),
            code: inputCountry,
            label: t(`coAlarm:countries:${inputCountry}`),
            icon: (
                <img
                    src={`https://flagcdn.com/w20/${inputCountry}.png`}
                    width="20"
                    height="14"
                    alt={selectedListing.country_code}
                    aria-hidden={true}
                />
            )
        })
    }, [selectedListing])

    // Watch changes on country field
    const countrySetted: CoAlarmSendRequestSchema['country'] = useWatch({control, name: 'country'})

    // Form submit
    const onSubmit = handleSubmit(data => {
        requestCoAlarm({
            urlParams: {listingId: selectedListing.id},
            payload: HttpSendCoAlarmRequestOptions.shape['payload'].parse({
                ...data,
                ...parseManualAddressForm(data, countrySetted),
                manual_address: true,
                country: countrySetted.code
            })
        })
    })

    // Reset the shipment fields when the country changes
    const resetLocationFields = (countryValue: CoAlarmSendRequestSchema['country']) => {
        if (countryValue && inputCountry != countryValue.code) {
            setSelectedCountry(countryValue.code.toLowerCase())
            resetField('phone')
            resetField('address_line_1')
            resetField('address_line_2')
            resetField('address_extras')
            resetField('postal_code')
            resetField('state')
            resetField('city')
        }
    }

    return (
        <Flexbox direction="column" as="form" gap={8} onSubmit={onSubmit} width="100%">
            <StyledCoAlarmSendRequestInputsWrapper>
                <InputText
                    label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstName.label)}
                    type={'text'}
                    inputSize="sm"
                    errorMessage={t(errors.first_name?.message || '')}
                    placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstName.placeholder).toString()}
                    {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstName.name))}
                    helpText={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstName.helpText)}
                    maxLength={50}
                />
                <InputText
                    label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.lastName.label)}
                    type={'text'}
                    inputSize="sm"
                    errorMessage={t(errors.last_name?.message || '')}
                    placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.lastName.placeholder).toString()}
                    {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.lastName.name))}
                    helpText={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.lastName.helpText)}
                    maxLength={50}
                />
            </StyledCoAlarmSendRequestInputsWrapper>

            <Flexbox direction="column" gap={1} width="100%">
                <Controller
                    render={({field: {onChange, value}}) => (
                        <StyledSelectCountry
                            key={`lang-${language}`}
                            value={value as SelectValue}
                            selectAriaLabel={t('coAlarm:form:country_placeholder')}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                                resetLocationFields(newValue as CoAlarmSendRequestSchema['country'])
                            }}
                            size={'medium'}
                            name={CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.country.name}
                            label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.country.label)}
                            isSearchable={true}
                            errorMessage={t(errors.country?.message || '')}
                            placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.country.placeholder)}
                            options={countriesLocalizedOptions}
                        />
                    )}
                    control={control}
                    name={'country'}
                />
                <InputHelpText helpText={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.country.helpText)} />
            </Flexbox>

            <Controller
                render={({field: {onChange, value}}) => (
                    <StyledPhoneInputWrapper direction="column" gap={2}>
                        <Label htmlFor={CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.phone.name}>
                            {t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.phone.label)}
                        </Label>
                        <PhoneInput
                            country={countrySetted.code}
                            countryCodeEditable={false}
                            placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.phone.placeholder)}
                            value={value}
                            onChange={(prefix: string) => onChange(prefix)}
                            enableAreaCodes={false}
                        />
                        <InputHelpText error={t(errors.phone?.message || '')} />
                    </StyledPhoneInputWrapper>
                )}
                control={control}
                name={'phone'}
            />

            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstAddressLine.label)}
                type={'text'}
                errorMessage={t(errors.address_line_1?.message || '')}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstAddressLine.placeholder).toString()}
                maxLength={100}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.firstAddressLine.name))}
            />

            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.secondAddressLine.label)}
                type={'text'}
                errorMessage={t(errors.address_line_2?.message || '')}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.secondAddressLine.placeholder).toString()}
                maxLength={100}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.secondAddressLine.name))}
            />
            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.city.label)}
                type={'text'}
                errorMessage={t(errors.city?.message || '')}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.city.placeholder).toString()}
                maxLength={100}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.city.name))}
            />
            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.state.label)}
                type={'text'}
                errorMessage={t(errors.state?.message || '')}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.state.placeholder).toString()}
                maxLength={100}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.state.name))}
            />
            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.postalCode.label)}
                type={'text'}
                errorMessage={t(errors.postal_code?.message || '')}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.postalCode.placeholder).toString()}
                maxLength={20}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.postalCode.name))}
            />
            <StyledFullWidthInputText
                inputSize="sm"
                label={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.addressExtras.label)}
                type={'text'}
                placeholder={t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.addressExtras.placeholder).toString()}
                maxLength={100}
                {...register(t(CO_ALARM_SEND_REQUEST_EXTENDED_MODEL.addressExtras.name))}
            />
            <Button
                type="submit"
                variant={'primary'}
                size={'md'}
                disabled={!isValid || isSubmitting || isPendingRequestCoAlarm}
            >
                {t('commons:submit')}
                {isSubmitting || (isPendingRequestCoAlarm && <Spinner size={12} />)}
            </Button>
        </Flexbox>
    )
}
