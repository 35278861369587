import {HeadingGroup, Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {XCloseIcon} from '@components/ui/icon'
import {StyledContent} from '@/features/services/components/declined-step/style.ts'

export const DeclinedStep = () => {
    const {t} = useTranslation()

    return (
        <>
            <StyledContent gap={6}>
                <span>
                    <XCloseIcon />
                </span>
                <HeadingGroup align="center">
                    <Title>{t('services:step_declined:title')}</Title>
                    <Paragraph>{t('services:step_declined:paragraph')}</Paragraph>
                </HeadingGroup>
            </StyledContent>
        </>
    )
}
