import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import toast from 'react-hot-toast'
import {HttpUpdateStepOptions, UpdateStepAnswers} from '@/features/services/services/services.http.ts'
import {useUpdateStep} from '@/features/services/services/useUpdateStep.ts'

interface ConfirmSubmitModalProps<Step extends keyof UpdateStepAnswers> {
    onClose: () => void
    serviceId: number
    expertiseId: number
    payload: HttpUpdateStepOptions<Step>['payload']['answers']
}
export const ConfirmSubmitModal = <Step extends keyof UpdateStepAnswers>({
    onClose,
    serviceId,
    expertiseId,
    payload
}: ConfirmSubmitModalProps<Step>) => {
    const {t} = useTranslation()
    const mutation = useUpdateStep<Step, true>({
        serviceId,
        expertiseId,
        isSubmit: true,
        step: undefined,
        subStep: undefined,
        options: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody gap={8}>
                <ModalTitle>{t('services:confirm_submit_modal_title')}</ModalTitle>
                <ModalParagraph>{t('services:confirm_submit_modal_paragraph')}</ModalParagraph>
            </ModalBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" disabled={mutation.isPending} onClick={onClose}>
                    {t('commons:close')}
                </Button>
                <Button disabled={mutation.isPending} variant="primary" onClick={() => mutation.mutate(payload)}>
                    {t('services:submit_for_review')}
                    {mutation.isPending && <Spinner size={12} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
