import {
    StyledProgressbarIndicator,
    StyledProgressbar
} from '@/features/experiences-host-video/components/progress-bar/style.ts'
import {FC} from 'react'

export const ProgressBar: FC<{percentage: number; rounded?: boolean}> = ({percentage, rounded}) => (
    <StyledProgressbar $rounded={rounded}>
        <StyledProgressbarIndicator $rounded={rounded} style={{width: `${percentage}%`}} />
    </StyledProgressbar>
)
