import {useTranslation} from '@/translations/i18n.tsx'
import {useOutletContext} from 'react-router-dom'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {useServicesProposals} from '@/features/promotional-services/services/queries/useServicesProposals.ts'
import {useState} from 'react'
import {useInProgressServices} from '@/features/in-progress-services/services/queries/useInProgressServices.ts'
import {StyledDashTabs, StyledDashTabsWrapper} from '@pages/dashboard/style.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'

type TabID = '#photoshoots' | '#walkthrough' | '#matterport' | '#in-progress-section'
export const DashboardMobileTabs = () => {
    const {t} = useTranslation()
    //prams and host params store
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    //services proposal query
    const {
        walkthroughServices,
        photoServices,
        matterportServices,
        isPending: loadingProposals
    } = useServicesProposals(urlParams)
    const {remappedData: inProgressServices, isPending: loadingInProgress} = useInProgressServices(urlParams)
    //State
    const [activeTab, setActiveTab] = useState<TabID>('#photoshoots')

    return (
        <StyledDashTabsWrapper gap={2} align={loadingProposals && loadingInProgress ? 'center' : 'end'}>
            {photoServices.length > 0 && (
                <StyledDashTabs
                    $isActive={activeTab === '#photoshoots'}
                    to={'#photoshoots'}
                    onClick={() => setActiveTab('#photoshoots')}
                >
                    {t('commons:photoshoots')}
                </StyledDashTabs>
            )}
            {walkthroughServices.length > 0 && (
                <StyledDashTabs
                    $isActive={activeTab === '#walkthrough'}
                    to={'#walkthrough'}
                    onClick={() => setActiveTab('#walkthrough')}
                >
                    {t('commons:walkthrough')}
                </StyledDashTabs>
            )}
            {matterportServices.length > 0 && (
                <StyledDashTabs
                    $isActive={activeTab === '#matterport'}
                    to={'#matterport'}
                    onClick={() => setActiveTab('#matterport')}
                >
                    {t('commons:matterport')}
                </StyledDashTabs>
            )}
            <StyledDashTabs
                $isActive={activeTab === '#in-progress-section'}
                to={'#in-progress-section'}
                onClick={() => setActiveTab('#in-progress-section')}
            >
                {t('dashboard:in_progress:title')}
                <span>{loadingInProgress ? <Spinner size={12} /> : inProgressServices.length}</span>
            </StyledDashTabs>
        </StyledDashTabsWrapper>
    )
}

DashboardMobileTabs.displayName = 'DashboardMobileTabs'
