import {
    StyledAcceptStepBody,
    StyledAcceptStepDesc,
    StyledAcceptStepFooter,
    StyledAcceptStepHeader
} from '@/features/service-acceptance-flow/components/style.ts'
import {Camera01Icon} from '@components/ui/icon'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {useTheme} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const PhotoPreparation2Step = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const changeStep = useServiceAcceptStore(state => state.changeStep)
    const service = useServiceAcceptStore(state => state.service)

    return (
        <>
            <StyledAcceptStepHeader align="center" justify="center">
                {t('service_accept:common:prepare_for_photoshoot')}
            </StyledAcceptStepHeader>
            <Divider direction="horizontal" />

            <StyledAcceptStepBody direction={'column'} gap={6}>
                <StyledAcceptStepDesc direction={'column'} gap={2}>
                    <IconContainer size={'lg'}>
                        <Camera01Icon fill={theme.palette.primary['600']} />
                    </IconContainer>
                    <h5>{t('service_accept:photo_preparation_2:title')}</h5>
                    <small>{t('service_accept:photo_preparation_2:description')}</small>

                    <Flexbox direction="column" gap={1}>
                        <p>{t('service_accept:photo_preparation_2:subtitle_1')}</p>
                        <small>{t('service_accept:photo_preparation_2:paragraph_1')}</small>
                    </Flexbox>

                    <Flexbox direction="column" gap={1}>
                        <p>{t('service_accept:photo_preparation_2:subtitle_2')}</p>
                        <small>{t('service_accept:photo_preparation_2:paragraph_2')}</small>
                    </Flexbox>

                    <Flexbox direction="column" gap={1}>
                        <p>{t('service_accept:photo_preparation_2:subtitle_3')}</p>
                        <small>{t('service_accept:photo_preparation_2:paragraph_3')}</small>
                    </Flexbox>
                </StyledAcceptStepDesc>
            </StyledAcceptStepBody>

            <Divider direction="horizontal" />
            <StyledAcceptStepFooter>
                <Button
                    fullWidth
                    variant="primary"
                    size="md"
                    onClick={() => changeStep(service?.can_see_sms_settings ? 'sms' : 'email')}
                >
                    {t('commons:continue')}
                </Button>
            </StyledAcceptStepFooter>
        </>
    )
}
