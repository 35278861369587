import {SlidingModalCloseButton, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FC, useState} from 'react'
import {
    StyledHeader,
    StyledSlidingModal,
    StyledTab
} from '@/features/experiences-host-video/components/requirements-perspective-modal/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Perspective} from '@/features/experiences-host-video/perspective/Perspective.tsx'
import {Requirements} from '@/features/experiences-host-video/requirements/Requirements.tsx'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {AnnotationQuestionIcon} from '@components/ui/icon'
import {ModalFooter} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {QuizModal} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'
import {
    httpCompleteQuiz,
    httpStoreQuiz
} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {useShowQuiz} from '@/features/experiences-host-video/services/useShowQuiz.ts'
import {useQuizAnswer} from '@/features/experiences-host-video/services/useQuizAnswer.ts'

interface RequirementsPerspectiveModalProps {
    userType: Quiz['user_type']
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const RequirementsPerspectiveModal: FC<RequirementsPerspectiveModalProps> = ({
    userType,
    slidingModalState,
    setSlidingModalState
}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const [activeTab, setActiveTab] = useState<'requirements' | 'perspective'>('requirements')
    const [quizSlidingModalState, setQuizSlidingModalState] = useState<SlidingModalState>('closed')
    const [quizId, setQuizId] = useState<number>()
    const storeQuiz = useAsync()
    const quizQuery = useShowQuiz({
        urlParams: {...routeParams, quizId: quizId},
        queryOptions: {enabled: !!quizId && quizSlidingModalState != 'closed'}
    })
    const startQuiz = async () => {
        try {
            const response = await storeQuiz.run(
                httpStoreQuiz({
                    urlParams: routeParams,
                    params: {user_type: userType, response_type: 'extended'}
                })
            )
            setQuizId(response.data.id)
            setQuizSlidingModalState('open')
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    const quizAnswerMutation = useQuizAnswer({
        params: {...routeParams, quizId: quizId},
        mutationOptions: {
            onError: () => toast.error(t('errors:default'))
        }
    })

    return (
        <StyledSlidingModal slidingModalState={slidingModalState}>
            <StyledHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                <Flexbox>
                    <StyledTab $isActive={activeTab == 'requirements'} onClick={() => setActiveTab('requirements')}>
                        {t('experiences_host_video:requirements:title')}
                    </StyledTab>
                    <StyledTab $isActive={activeTab == 'perspective'} onClick={() => setActiveTab('perspective')}>
                        {t('experiences_host_video:perspective:title')}
                    </StyledTab>
                </Flexbox>
            </StyledHeader>
            <ScrollArea scrollbar={<Scrollbar />}>
                <ExperiencesHostVideoModalBody>
                    {activeTab == 'requirements' ? <Requirements /> : <Perspective />}
                </ExperiencesHostVideoModalBody>
            </ScrollArea>
            {userType == 'host' && (
                <ModalFooter>
                    <Button fullWidth disabled={storeQuiz.isLoading} onClick={startQuiz}>
                        <AnnotationQuestionIcon />
                        {t('experiences_host_video:quiz:title')}
                        {storeQuiz.isLoading && <Spinner style={{margin: 0}} />}
                    </Button>
                </ModalFooter>
            )}

            {!!quizId && quizSlidingModalState != 'closed' && (
                <QuizModal
                    completePromise={httpCompleteQuiz}
                    quizAnswerMutation={quizAnswerMutation}
                    quizQuery={quizQuery}
                    slidingModalState={quizSlidingModalState}
                    setSlidingModalState={setQuizSlidingModalState}
                />
            )}
        </StyledSlidingModal>
    )
}
