import {useState, useEffect, useCallback} from 'react'

type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error'

export const useLoadScript = (src: string, options = {removeOnUnmount: false}): ScriptStatus => {
    const {removeOnUnmount} = options
    const [status, setStatus] = useState<ScriptStatus>('idle')

    const loadScript = useCallback(() => {
        if (!src) {
            setStatus('idle')
            return
        }

        // Check if any script with a similar source URL is already present
        const existingScript = Array.from(document.querySelectorAll('script')).find(script =>
            script.src.includes('maps.googleapis.com/maps/api/js')
        )

        if (!existingScript) {
            const script = document.createElement('script')
            script.src = src
            script.async = true
            script.defer = true
            script.type = 'text/javascript'
            script.setAttribute('loading', 'async')

            setStatus('loading')

            script.onload = () => setStatus('ready')
            script.onerror = () => setStatus('error')

            document.head.appendChild(script)

            return () => {
                if (removeOnUnmount && script) {
                    document.head.removeChild(script)
                    setStatus('idle')
                }
            }
        } else {
            // Script is already loaded
            setStatus('ready')
        }
    }, [])

    useEffect(() => {
        loadScript()
    }, [src, removeOnUnmount])

    return status
}
