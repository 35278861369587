import {SelectComboboxList} from '@/components/ui/select-atoms/SelectAtoms'
import {StyledCountryComboboxPopover, StyledCountrySelectItem, StyledSelectComboboxInput} from './style'
import {Country} from '../../types'
import {forwardRef} from 'react'
import Skeleton from '@/components/ui/skeleton/Skeleton'
import {useTranslation} from '@/translations/i18n'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {SelectItemCheck} from '@ariakit/react'

interface CountrySelectPopoverProps {
    isLoading: boolean
    isError: boolean
    countries: Country[]
}

export const CountrySelectPopover = forwardRef<HTMLInputElement, CountrySelectPopoverProps>(
    ({isLoading, isError, countries}, ref) => {
        const {t} = useTranslation()

        return (
            <StyledCountryComboboxPopover sameWidth flip="bottom left">
                <StyledSelectComboboxInput
                    ref={ref}
                    placeholder={t('services:step_service_location:country_search_placeholder')}
                    style={{height: 40}}
                />
                <SelectComboboxList>
                    {isLoading ? (
                        <>
                            {Array.from({length: 3}, (_, index) => (
                                <Skeleton key={index} height={40} />
                            ))}
                        </>
                    ) : isError ? (
                        <InputHelpText error={t('errors:default')} />
                    ) : countries.length > 0 ? (
                        countries.map((option, index) => (
                            <StyledCountrySelectItem key={index} value={option.country}>
                                {option.country}
                                <SelectItemCheck />
                            </StyledCountrySelectItem>
                        ))
                    ) : (
                        <InputHelpText helpText={t('commons:no_results_found')} />
                    )}
                </SelectComboboxList>
            </StyledCountryComboboxPopover>
        )
    }
)
