import {Button} from '@/components/ui/button/Button'
import {City} from '../../types'
import {XCloseIcon} from '@/components/ui/icon'
import {StyledCityChip, StyledCityChips} from './style'

export const CitiesMultiSelectChips = ({cities, remove}: {cities: City[]; remove: (cityId: number) => void}) => {
    return (
        <StyledCityChips gap={2} width="100%">
            {cities.map(city => (
                <StyledCityChip align="center" gap={2} key={city.id}>
                    {city.state ? `${city.name}, ${city.state}` : city.name}
                    <Button size="sm" variant="ghost" shape="text" onClick={() => remove(city.id)}>
                        <XCloseIcon />
                    </Button>
                </StyledCityChip>
            ))}
        </StyledCityChips>
    )
}
