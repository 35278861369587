import {useTranslation} from '@/translations/i18n.tsx'
import {ServiceResource} from '@/features/service-resources/types.ts'
import {StyledServiceResources, StyledServiceResourcesTitle} from '@/features/service-resources/components/style.ts'
import {FC} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ServiceResourceCard} from '@/features/service-resources/components/ServiceResourceCard.tsx'

export const ServiceResources: FC<{resources: ServiceResource[]}> = ({resources}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={6}>
            <StyledServiceResourcesTitle>
                <h3>{t(`service_details:resources_title`)}</h3>
            </StyledServiceResourcesTitle>
            <StyledServiceResources align="stretch" gap={8}>
                {resources.map(resource => (
                    <ServiceResourceCard key={resource.id} resource={resource} />
                ))}
            </StyledServiceResources>
        </Flexbox>
    )
}

ServiceResources.displayName = 'ServiceResources'
