import {z} from 'zod'
import {TKey} from '@/translations/i18n.tsx'

export const MANUAL_ADDRESS_FORM_MODEL = {
    firstAddressLine: {
        name: 'address_line_1',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_1',
        defaultValue: '',
        placeholder: ''
    },
    secondAddressLine: {
        name: 'address_line_2',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_2',
        defaultValue: '',
        placeholder: ''
    },
    city: {
        name: 'city',
        label: 'coAlarm:smokeForm:manual_address_modal:city',
        defaultValue: '',
        placeholder: ''
    },
    state: {
        name: 'state',
        label: 'coAlarm:smokeForm:manual_address_modal:state',
        defaultValue: '',
        placeholder: ''
    },
    postalCode: {
        name: 'postal_code',
        label: 'coAlarm:smokeForm:manual_address_modal:postal_code',
        defaultValue: '',
        placeholder: ''
    }
} as const satisfies Record<string, {name: string; label: TKey; defaultValue: string; placeholder: string}>

export const ManualAddressFormSchema = z.object({
    address_line_1: z.string().min(3, {message: 'coAlarm:smokeForm:manual_address_modal:address_line_1_error'}),
    address_line_2: z.string().nullish(),
    city: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:city_error'}),
    state: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:state_error'}),
    postal_code: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:postal_code_error'})
})

export type ManualAddressFormSchema = z.infer<typeof ManualAddressFormSchema>
