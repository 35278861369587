import i18next, {Namespace, ParseKeys, TOptions} from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next, TransProps, useTranslation as i18nUseTranslation, Trans as I18nTrans} from 'react-i18next'
//translations
import en from './en/en.json'
import it from './it/it.json'
import de from './de/de.json'
import fr from './fr/fr.json'
import es from './es/es.json'
import ja from './ja/ja.json'
import ko from './ko/ko.json'
import nl from './nl/nl.json'
import pt from './pt/pt.json'
import ru from './ru/ru.json'
import zh from './zh/zh.json'

//dayjs locales
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'
import {HTMLProps} from 'react'
import {_DefaultNamespace} from 'react-i18next/TransWithoutContext'

export const APP_LANGUAGES = [
    {label: 'English (EN)', value: 'en', flagCode: 'gb', json: en},
    {label: 'Español (ES)', value: 'es', flagCode: 'es', json: es},
    {label: 'Deutsch (DE)', value: 'de', flagCode: 'de', json: de},
    {label: 'Français (FR)', value: 'fr', flagCode: 'fr', json: fr},
    {label: 'Italiano (IT)', value: 'it', flagCode: 'it', json: it},
    {label: '日本語 (JP)', value: 'ja', flagCode: 'jp', json: ja},
    {label: '한국어 (KO)', value: 'ko', flagCode: 'kr', json: ko},
    {label: 'Nederlands (NL)', value: 'nl', flagCode: 'nl', json: nl},
    {label: 'Português (PT)', value: 'pt', flagCode: 'pt', json: pt},
    {label: 'Русский (RU)', value: 'ru', flagCode: 'ru', json: ru},
    {label: '中文 (ZH)', value: 'zh', flagCode: 'cn', json: zh}
]

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(APP_LANGUAGES.map(language => [language.value, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

/*These types are needed to get the TKey list*/
type RemoveSuffix<S extends string> = S extends `${infer P}_other` | `${infer P}_one` | `${infer P}_zero` ? P : S
type FlattenObjectKeys<T, Prefix extends string = ''> = {
    [K in keyof T & string]: T[K] extends object
        ? FlattenObjectKeys<T[K], `${Prefix}${K}:`>
        : RemoveSuffix<`${Prefix}${K}`>
}[keyof T & string]

export type TKey = RemoveSuffix<FlattenObjectKeys<typeof en>>

export const useTranslation = () => {
    const translation = i18nUseTranslation()

    return {
        ...translation,
        t: (key: TKey, options?: TOptions) => translation.t(key, options)
    }
}

export const Trans = <
    Key extends ParseKeys<Ns, TOpt, KPrefix>,
    Ns extends Namespace = _DefaultNamespace,
    TOpt extends TOptions = Record<string, unknown>,
    KPrefix = undefined,
    E = HTMLProps<HTMLDivElement>,
    I18NKey extends TKey | undefined = undefined
>({
    i18nKey,
    ...props
}: Omit<TransProps<Key, Ns, TOpt, KPrefix, E>, 'i18nKey'> & {i18nKey: I18NKey}) => (
    <I18nTrans {...props} i18nKey={i18nKey} />
)

const i18n = {...i18next, t: (key: TKey, options?: TOptions) => i18next.t(key, options)}

export default i18n
