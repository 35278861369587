import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {FileSearch02Icon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'

export const WalkthroughInspectionCompletedStatusDescription: FC<{
    assignedFreelancer: string | undefined
    assignmentDate: Assignment['to_shoot_at'] | undefined
}> = ({assignedFreelancer, assignmentDate}) => {
    const {t} = useTranslation()

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <FileSearch02Icon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:walkthrough:inspection_completed_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:completed_paragraph', {
                        freelancer: assignedFreelancer,
                        feedbackDate: formatLocaleDate(dayjs(assignmentDate).add(8, 'days'))
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

WalkthroughInspectionCompletedStatusDescription.displayName = 'WalkthroughInspectionCompletedStatusDescription'
