import {ServiceType} from '@/types/commons.ts'
import {AcceptanceStep, AcceptServiceStepField, AcceptServiceSubStep} from '@/features/service-acceptance-flow/types.ts'

export const AcceptanceStepsConfigByService = {
    //to handle the acceptance flow that comes from the manual requests
    acceptManualRequest: {
        home: {
            modal_title: 'service_accept:accept:home:modal_title',
            title: 'service_accept:accept:home:title',
            subtitle: 'service_accept:accept:home:subtitle',
            primary_cta: 'service_accept:accept:home:primary_cta'
        },
        matterport: {
            modal_title: 'service_accept:accept:matterport:modal_title',
            title: 'service_accept:accept:matterport:title',
            subtitle: 'service_accept:accept:matterport:subtitle',
            primary_cta: 'service_accept:accept:matterport:primary_cta'
        },
        experience_photo: {
            modal_title: 'service_accept:accept:home:modal_title',
            title: 'service_accept:accept:home:title',
            subtitle: 'service_accept:accept:home:subtitle',
            primary_cta: 'service_accept:accept:home:primary_cta'
        },
        experience_video: {
            modal_title: 'service_accept:accept:experience_video:modal_title',
            title: 'service_accept:accept:experience_video:title',
            subtitle: 'service_accept:accept:experience_video:subtitle',
            primary_cta: 'service_accept:accept:experience_video:primary_cta'
        },
        walkthrough: {
            modal_title: 'service_accept:accept:walkthrough:modal_title',
            title: 'service_accept:accept:walkthrough:title',
            subtitle: 'service_accept:accept:walkthrough:subtitle',
            primary_cta: 'service_accept:accept:walkthrough:primary_cta'
        }
    },
    // Service to Accept step divided by services
    accept: {
        home: {
            modal_title: 'service_accept:accept:home:modal_title',
            title: 'service_accept:accept:home:title',
            subtitle: 'service_accept:accept:home:subtitle',
            primary_cta: 'service_accept:accept:home:primary_cta'
        },
        matterport: {
            modal_title: 'service_accept:accept:matterport:modal_title',
            title: 'service_accept:accept:matterport:title',
            subtitle: 'service_accept:accept:matterport:subtitle',
            primary_cta: 'service_accept:accept:matterport:primary_cta'
        },
        experience_photo: {
            modal_title: 'service_accept:accept:home:modal_title',
            title: 'service_accept:accept:home:title',
            subtitle: 'service_accept:accept:home:subtitle',
            primary_cta: 'service_accept:accept:home:primary_cta'
        },
        experience_video: {
            modal_title: 'service_accept:accept:experience_video:modal_title',
            title: 'service_accept:accept:experience_video:title',
            subtitle: 'service_accept:accept:experience_video:subtitle',
            primary_cta: 'service_accept:accept:experience_video:primary_cta'
        },
        walkthrough: {
            modal_title: 'service_accept:accept:walkthrough:modal_title',
            title: 'service_accept:accept:walkthrough:title',
            subtitle: 'service_accept:accept:walkthrough:subtitle',
            primary_cta: 'service_accept:accept:walkthrough:primary_cta'
        }
    },

    // Service Accepted step divided by services
    accepted: {
        home: {
            modal_title: 'service_accept:accepted:home:modal_title',
            title: 'service_accept:accepted:home:title',
            subtitle: 'service_accept:accepted:home:subtitle',
            primary_cta: 'commons:get_started'
        },
        matterport: {
            modal_title: 'service_accept:accepted:matterport:modal_title',
            title: 'service_accept:accepted:matterport:title',
            subtitle: 'service_accept:accepted:matterport:subtitle',
            primary_cta: 'commons:get_started'
        },
        walkthrough: {
            modal_title: 'service_accept:accepted:walkthrough:modal_title',
            title: 'service_accept:accepted:walkthrough:title',
            subtitle: 'service_accept:accepted:walkthrough:subtitle',
            primary_cta: 'commons:get_started'
        },
        experience_photo: {
            modal_title: 'service_accept:accepted:home:modal_title',
            title: 'service_accept:accepted:home:title',
            subtitle: 'service_accept:accepted:home:subtitle',
            primary_cta: 'commons:get_started'
        },
        experience_video: {
            modal_title: 'service_accept:accepted:experience_video:modal_title',
            title: 'service_accept:accepted:experience_video:title',
            subtitle: 'service_accept:accepted:experience_video:subtitle',
            primary_cta: 'commons:get_started'
        }
    },
    // Service SMS step divided by services
    sms: {
        generic: {
            base: {
                title: 'service_accept:sms:base:title'
            },
            editing: {
                title: 'service_accept:sms:editing:title',
                subtitle: 'service_accept:sms:editing:subtitle',
                field_label: 'service_accept:sms:editing:field_label'
            },
            pending_confirm: {
                title: 'service_accept:sms:pending_confirm:title',
                subtitle: 'service_accept:sms:pending_confirm:subtitle',
                field_label: 'service_accept:sms:pending_confirm:field_label',
                field_help: 'service_accept:sms:pending_confirm:field_help'
            }
        },
        home: {
            modal_title: 'service_accept:sms:home:modal_title',
            title: 'commons:enable_text_notifications',
            subtitle: 'service_accept:sms:common:subtitle',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        },
        matterport: {
            modal_title: 'service_accept:sms:matterport:modal_title',
            title: 'commons:enable_text_notifications',
            subtitle: 'service_accept:sms:common:subtitle',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        },
        walkthrough: {
            modal_title: 'service_accept:sms:walkthrough:modal_title',
            title: 'commons:enable_text_notifications',
            subtitle: 'service_accept:sms:common:subtitle',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        },
        experience_photo: {
            modal_title: 'service_accept:sms:home:modal_title',
            title: 'commons:enable_text_notifications',
            subtitle: 'service_accept:sms:common:subtitle',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        },
        experience_video: {
            modal_title: 'service_accept:sms:experience_video:modal_title',
            title: 'commons:enable_text_notifications',
            subtitle: 'service_accept:sms:common:subtitle',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        },
        coAlarm: {
            modal_title: 'commons:enable_notifications',
            title: 'commons:enable_text_notifications',
            subtitle: 'coAlarm:receive_notifications',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        }
    },

    // Service Email step divided by services
    email: {
        generic: {
            base: {
                title: 'service_accept:email:base:title'
            },
            editing: {
                title: 'service_accept:email:editing:title',
                subtitle: 'service_accept:email:editing:subtitle',
                field_label: 'service_accept:email:editing:field_label'
            },
            pending_confirm: {
                title: 'service_accept:email:pending_confirm:title',
                subtitle: 'service_accept:email:pending_confirm:subtitle',
                field_label: 'service_accept:email:pending_confirm:field_label',
                field_help: 'service_accept:email:pending_confirm:field_help'
            }
        },
        home: {
            modal_title: 'service_accept:email:home:modal_title',
            title: 'service_accept:email:common:title',
            subtitle: 'service_accept:email:common:subtitle',
            primary_cta: 'service_accept:email:common:primary_cta'
        },
        experience_photo: {
            modal_title: 'service_accept:email:home:modal_title',
            title: 'service_accept:email:common:title',
            subtitle: 'service_accept:email:common:subtitle',
            primary_cta: 'service_accept:email:common:primary_cta'
        },
        matterport: {
            modal_title: 'service_accept:email:matterport:modal_title',
            title: 'service_accept:email:common:title',
            subtitle: 'service_accept:email:common:subtitle',
            primary_cta: 'service_accept:email:common:primary_cta'
        },
        walkthrough: {
            modal_title: 'service_accept:email:walkthrough:modal_title',
            title: 'service_accept:email:common:title',
            subtitle: 'service_accept:email:common:subtitle',
            primary_cta: 'service_accept:email:common:primary_cta'
        },
        experience_video: {
            modal_title: 'service_accept:email:experience_video:modal_title',
            title: 'service_accept:email:common:title',
            subtitle: 'service_accept:email:common:subtitle',
            primary_cta: 'service_accept:email:common:primary_cta'
        },
        coAlarm: {
            modal_title: 'commons:enable_notifications',
            title: 'service_accept:email:common:title',
            subtitle: 'coAlarm:receive_email',
            primary_cta: 'commons:enable_text_notifications',
            secondary_cta: 'service_accept:sms:common:secondary_cta'
        }
    }
} as const satisfies Record<
    Exclude<AcceptanceStep, 'portrait' | 'photo_preparation_1' | 'photo_preparation_2'>,
    | Partial<Record<ServiceType | 'coAlarm', Partial<AcceptServiceStepField>>>
    | Partial<Record<'generic', Partial<AcceptServiceSubStep>>>
>

/**
 * maskPhoneNumber
 * @param phoneNumber
 */
export const maskPhoneNumber = (phoneNumber: string) => {
    const phoneNumberWithoutSpaces = phoneNumber.replace(/ /g, '') // Remove all spaces
    const prefix = phoneNumberWithoutSpaces.slice(0, 5) // Get the first 3 digits
    const suffix = phoneNumberWithoutSpaces.slice(phoneNumberWithoutSpaces.length - 2) // Get the last 2 digits
    return `${prefix}*****${suffix}` // Combine the masked digits
}

export const serviceTypeToTermsOfService = {
    home: 'photo-video',
    matterport: 'photo-video',
    experience_video: 'photo-video',
    experience_photo: 'photo-video',
    walkthrough: 'walkthrough'
} as const satisfies Record<ServiceType, string>
