import {FC, Fragment, useMemo} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledCategoryRequests} from './style'
import {HomeCategoryRequests, RemappedRequests} from '../types'
import {useCategories} from '../services/queries/useCategories'
import {CategoryRequestContentItem} from './CategoryRequestContentItem'
import {Divider} from '@components/ui/divider/Divider'

interface CategoryRequestsContentProps {
    requests: HomeCategoryRequests[]
}

export const CategoryRequestsContent: FC<CategoryRequestsContentProps> = ({requests}) => {
    const {data: categories} = useCategories()

    const remappedRequests = useMemo(
        () =>
            requests.reduce(
                (acc, val) => ({
                    ...acc,
                    [val.batch]: {
                        created_at: val.created_at,
                        status: val.status,
                        action: val.action,
                        requests: requests
                            .filter(request => request.batch === val.batch)
                            .map(requestedCategory => ({
                                ...categories.find(category => category.id === requestedCategory.home_category_id),
                                status: requestedCategory.status
                            }))
                    }
                }),
                {} as Record<string, RemappedRequests>
            ),
        [requests, categories]
    )

    return (
        <Flexbox direction="column" gap={2}>
            {Object.keys(remappedRequests).map((batch, index) => (
                <Fragment key={batch}>
                    <StyledCategoryRequests width={'100%'} direction="column" gap={3}>
                        {remappedRequests[batch].requests.length > 0 && (
                            <CategoryRequestContentItem
                                label={remappedRequests[batch].action}
                                date={remappedRequests[batch].created_at}
                                requests={remappedRequests[batch].requests}
                            />
                        )}
                    </StyledCategoryRequests>

                    {index + 1 < Object.keys(remappedRequests).length && <Divider direction="horizontal" />}
                </Fragment>
            ))}
        </Flexbox>
    )
}
