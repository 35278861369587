import styled, {css} from 'styled-components'

export const StyledFooter = styled.div(
    ({theme: {spacing, palette}}) => css`
        position: sticky;
        bottom: 0;
        padding: ${spacing * 2}px ${spacing * 4}px ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4.5}px;
        background-color: ${palette.neutral.white};
        z-index: 2;
    `
)
