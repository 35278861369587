import {RefObject} from 'react'
import {generatePath} from 'react-router-dom'
import Table from '@/components/commons/table/Table'
import {CoAlarmListingRemappedRequest} from '@/features/co-alarm/types'
import {breakpoints} from '@/theme/mediaQueries'
import {useTranslation} from '@/translations/i18n.tsx'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {EmptyResultsBox} from '@/components/commons/empty-results-box/EmptyResultsBox'
import {ButtonLink} from '@components/ui/button-link/ButtonLink'
import {
    StyledCoAlarmButtonsCellWrapper,
    StyledCoAlarmListingStatus,
    StyledCoAlarmListingStatusCell,
    StyledListingCell,
    StyledListingCellTextWrapper
} from '@/features/co-alarm/components/co-alarm-listings-table/style'
import {
    ListingThumbnailImage,
    ListingThumbnailName,
    ListingThumbnailParagraph
} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'
import {routes} from '@/utilities/constants'
import dayjs from 'dayjs'
import {coAlarmStatusToIconLabel} from '@/features/co-alarm/utils'

interface CoAlarmListingsTable {
    data: CoAlarmListingRemappedRequest[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchRef: RefObject<HTMLInputElement> | null
    onChangePage: () => void
    onResetSearch: (inputRef: RefObject<HTMLInputElement>) => void
    searchValue?: string
}

export const CoAlarmListingsTable = ({
    data,
    isChangingPage,
    isError,
    isLoading,
    searchRef,
    onChangePage,
    onResetSearch,
    searchValue
}: CoAlarmListingsTable) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.m

    return (
        <Table
            columns={[
                {
                    label: t('commons:listing'),
                    width: '36%',
                    cellRender: listing => (
                        <StyledListingCell gap={4} title={listing.name}>
                            <ListingThumbnailImage src={listing.image_url} alt={listing.name} />
                            <StyledListingCellTextWrapper direction="column">
                                <ListingThumbnailName>
                                    <a href={`https://www.airbnb.com/rooms/${listing.id}`} target="_blank">
                                        {listing.name}
                                    </a>
                                </ListingThumbnailName>
                                <ListingThumbnailParagraph>{`${listing?.city ?? '-'}, ${
                                    listing?.state ?? '-'
                                }`}</ListingThumbnailParagraph>
                            </StyledListingCellTextWrapper>
                        </StyledListingCell>
                    )
                },
                {
                    label: t('commons:status'),
                    width: '32%',
                    cellRender: listing => {
                        const {label, icon} = coAlarmStatusToIconLabel[listing.co_alarm.status]
                        return (
                            <StyledCoAlarmListingStatusCell gap={2.5}>
                                {icon}
                                <StyledCoAlarmListingStatus>{t(label)}</StyledCoAlarmListingStatus>
                            </StyledCoAlarmListingStatusCell>
                        )
                    }
                },
                {
                    label: t('commons:last_update'),
                    width: '18%',
                    cellRender: listing =>
                        listing.co_alarm?.request?.updated_at
                            ? dayjs(listing.co_alarm.request.updated_at).format('LLL')
                            : '-'
                },
                {
                    label: '',
                    width: '14%',
                    alignment: 'right',
                    cellRender: listing => (
                        <StyledCoAlarmButtonsCellWrapper direction="column" align="center">
                            {listing?.co_alarm?.status == 'eligible' || listing?.co_alarm?.status == 'ineligible' ? (
                                <ButtonLink
                                    size="sm"
                                    variant="tertiary"
                                    target="_blank"
                                    shape="text"
                                    to={`https://www.airbnb.com/resources/hosting-homes/a/reducing-carbon-monoxide-exposure-in-your-space-510`}
                                >
                                    {t('commons:learn_more')}
                                </ButtonLink>
                            ) : (
                                listing.co_alarm.request?.id &&
                                listing.co_alarm.status != 'installed' && (
                                    <ButtonLink
                                        size="sm"
                                        variant="secondary"
                                        to={generatePath(routes.CO_ALARM_REQUEST_DETAILS.path, {
                                            listingId: listing.id,
                                            requestId: listing.co_alarm.request.id
                                        })}
                                    >
                                        {t('commons:details')}
                                    </ButtonLink>
                                )
                            )}
                        </StyledCoAlarmButtonsCellWrapper>
                    )
                }
            ]}
            isMobile={isMobile}
            data={data}
            isChangingPage={isChangingPage}
            isError={isError}
            isLoading={isLoading}
            onChangePage={onChangePage}
            searchValue={!!searchValue}
            emptyStateComponent={
                <EmptyResultsBox
                    title={t('commons:empty_listings_results')}
                    subtitle={t('coAlarm:dashboard:empty_listings_results_subtitle')}
                    width={'100%'}
                    customContent={
                        <ButtonLink to={`https://www.airbnb.com/hosting/listings`} target="_blank">
                            {t('commons:your_active_listings')}
                        </ButtonLink>
                    }
                />
            }
            emptySearchStateComponent={
                <EmptyResultsBox
                    onResetCb={() => searchRef && onResetSearch(searchRef)}
                    title={t('commons:no_results_found')}
                    subtitle={t('commons:no_results_listings_message', {value: searchValue})}
                    width="100%"
                />
            }
        />
    )
}
