import {StyledAcceptStepHeaderCloseButton} from '@/features/service-acceptance-flow/components/style.ts'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {useMemo} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {ChevronLeftIcon, XCloseIcon} from '@components/ui/icon'
import {useNotificationPermission} from '@/features/otp-flow/services/queries/useNotificationPermission.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {AcceptanceModalHeader} from '@/features/service-acceptance-flow/components/AcceptanceModalHeader.tsx'
import {AcceptanceModalStepsIndicator} from '@/features/service-acceptance-flow/components/AcceptanceModalStepsIndicator.tsx'
import {EmailNotifyConfirmationStep} from '@/features/notifications-settings/components/EmailNotifyConfirmationStep.tsx'

export const ServiceConfirmEmailStep = () => {
    const {t} = useTranslation()
    //otp store
    const service = useOtpStore(state => state.service)
    const optProcessStep = useOtpStore(state => state.step)
    const changeOptProcessStep = useOtpStore(state => state.changeStep)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    //accept store
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const changeAcceptModalStep = useServiceAcceptStore(state => state.changeStep)
    const portraitEnabled = useServiceAcceptStore(state => state.portraitEnabled)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    //utils
    const canSeeSmsSettings = service?.can_see_sms_settings
    //notification accept toggle
    const {isPending} = useNotificationPermission()

    const handleBackAction = () => {
        switch (optProcessStep) {
            case 'pending_confirmation':
                changeOptProcessStep('edit', true)
                break
            case 'edit':
                changeOptProcessStep('base', false)
                break
            default:
                changeAcceptModalStep('sms')
        }
    }

    //modal composition utils
    const modalHeaderTitle = useMemo(() => {
        if (coAlarmRequest) {
            return t(AcceptanceStepsConfigByService['email']['coAlarm'].modal_title)
        } else if (service) {
            return t(AcceptanceStepsConfigByService['email'][serviceType].modal_title)
        } else {
            return ''
        }
    }, [service, coAlarmRequest])

    return (
        <>
            <AcceptanceModalHeader
                actionIcon={
                    canSeeSmsSettings ? (
                        <StyledAcceptStepHeaderCloseButton
                            disabled={isPending}
                            size="lg"
                            shape="square"
                            variant="ghost"
                            onClick={handleBackAction}
                        >
                            <ChevronLeftIcon />
                        </StyledAcceptStepHeaderCloseButton>
                    ) : (
                        <StyledAcceptStepHeaderCloseButton
                            disabled={isPending}
                            size="lg"
                            shape="square"
                            variant="ghost"
                            onClick={() => acceptServiceModalToggle()}
                        >
                            <XCloseIcon />
                        </StyledAcceptStepHeaderCloseButton>
                    )
                }
                portraitEnabled={portraitEnabled}
                title={modalHeaderTitle}
                subtitle={t('service_accept:common:step_indicator', {current: 1, total: 2})}
            />
            <AcceptanceModalStepsIndicator portraitEnabled={portraitEnabled} />

            <EmailNotifyConfirmationStep />
        </>
    )
}

ServiceConfirmEmailStep.displayName = 'ServiceConfirmEmailStep'
