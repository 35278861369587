import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {envVars} from '@/envVars.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {MapWrapper} from './style.ts'

type Coordinates = {
    lat: number
    lng: number
}

interface MapProps {
    center: Coordinates
    markerPosition: Coordinates
}

const mapStyle = {
    height: '300px',
    width: '100%'
}

export const Map = ({center, markerPosition}: MapProps) => {
    const {t} = useTranslation()
    const {isLoaded} = useJsApiLoader({
        id: '__googleMapsScriptId',
        googleMapsApiKey: envVars.VITE_GOOGLE_API_KEY
    }) //needed fot the Marker correct positioning, removable in the future

    return (
        <MapWrapper direction="column" width="100%">
            <p>{t('coAlarm:smokeForm:map_preview')}</p>
            {isLoaded && (
                <GoogleMap center={center} zoom={18} mapContainerStyle={mapStyle}>
                    <Marker position={markerPosition} />
                </GoogleMap>
            )}
        </MapWrapper>
    )
}

Map.displayName = 'Map'
