import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-host-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {Dispatch, FC, SetStateAction} from 'react'
import {QuizStep} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'
import {UseQuizAnswerResponse} from '@/features/experiences-host-video/services/useQuizAnswer.ts'
import {raise} from '@utilities/helpers.ts'
import {UseMutationResult} from '@tanstack/react-query'

const optionToTranslationKey = {
    '1_edited_video': 'experiences_host_video:quiz:upload:1_edited_video',
    '20_40_short_clips': 'experiences_host_video:quiz:upload:20_40_short_clips',
    '5_photos': 'experiences_host_video:quiz:upload:5_photos'
} as const satisfies Record<string, TKey>

interface QuizModalUploadStepProps {
    quiz: Quiz
    setStep: Dispatch<SetStateAction<QuizStep>>
    quizAnswerMutation: UseMutationResult<UseQuizAnswerResponse, unknown, {questionId: number}, unknown>
}
export const QuizModalUploadStep: FC<QuizModalUploadStepProps> = ({quiz, setStep, quizAnswerMutation}) => {
    const {t} = useTranslation()
    const oneEditedVideoQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'what_upload' &&
                question.question_answer.answer.key_name == '1_edited_video'
        ) ?? raise('oneEditedVideoQuestion is missing')
    const twentyFortyClipsQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'what_upload' &&
                question.question_answer.answer.key_name == '20_40_short_videos'
        ) ?? raise('twentyFortyClipsQuestion is missing')
    const fivePhotos =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'what_upload' &&
                question.question_answer.answer.key_name == '5_photos'
        ) ?? raise('fivePhotos is missing')

    return (
        <>
            <ExperiencesHostVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>{t('experiences_host_video:quiz:upload:question')}</QuizModalQuestion>
                    <QuizModalQuestionHint>{t('experiences_host_video:quiz:tap_option')}</QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['1_edited_video'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    oneEditedVideoQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="1_edited_video"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: oneEditedVideoQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != oneEditedVideoQuestion.question_answer.id
                        }
                        checked={
                            quizAnswerMutation.data?.question_answer_id == oneEditedVideoQuestion.question_answer.id
                        }
                    />
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['20_40_short_clips'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    twentyFortyClipsQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="20_40_short_clips"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: twentyFortyClipsQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != twentyFortyClipsQuestion.question_answer.id
                        }
                        checked={
                            quizAnswerMutation.data?.question_answer_id == twentyFortyClipsQuestion.question_answer.id
                        }
                    />
                    <AnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['5_photos'])}
                                {quizAnswerMutation.data?.question_answer_id == fivePhotos.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="5_photos"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: fivePhotos.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != fivePhotos.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == fivePhotos.question_answer.id}
                    />
                </Flexbox>
                {quizAnswerMutation.isSuccess && (
                    <QuizModalAnswerResponse
                        variant={quizAnswerMutation.data.is_correct ? 'success' : 'danger'}
                        title={t(
                            `experiences_host_video:quiz:${
                                quizAnswerMutation.data.is_correct ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_host_video:quiz:upload:description')}
                    />
                )}
            </ExperiencesHostVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 0, 0, 0]}>
                <Button
                    disabled={!quizAnswerMutation.isSuccess}
                    onClick={() => {
                        setStep('hold_camera')
                        quizAnswerMutation.reset()
                    }}
                >
                    {t('experiences_host_video:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
