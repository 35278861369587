import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Mail01Icon} from '@components/ui/icon'
import {FC, useState} from 'react'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {httpClickAction} from '@/features/service-actions/services/actions.http.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

interface KeyElementsVideoActionProps {
    actionId: ServiceAction['id']
    isCompleted: boolean
    freelancerEmail?: string
}
export const KeyElementsVideoAction: FC<KeyElementsVideoActionProps> = ({actionId, isCompleted, freelancerEmail}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const [isClicked, setIsClicked] = useState(isCompleted)

    const clickAction = () => {
        window.open(`mailto:${freelancerEmail}`)
        httpClickAction({actionId, ...urlParams})
        setIsClicked(true)
    }

    return (
        <ActionCard
            onClick={isClicked ? undefined : clickAction}
            isCompleted={isClicked}
            icon={Mail01Icon}
            paragraph={t('service_details:actions:ket_elements_video:paragraph')}
            title={t('service_details:actions:ket_elements_video:title')}
        />
    )
}
