import {envVars} from '@/envVars'
import {Coordinates} from '@/features/services/types'
import {useState} from 'react'
import {useLoadScript} from '@/hooks/useLoadScript'

interface UseAddressGeocodingResult {
    addressLatLng: Coordinates | undefined
    addressFormat: google.maps.GeocoderResult | undefined
    retrieveLatLng: (address: string) => Promise<void>
    retrieveStructured: (placeId: string) => Promise<void>
    resetGeocoding: () => void
}

interface UseAddressGeocodingOptions {
    onLatLngFound?: (value: Coordinates) => void
    onStructuredAddressFound?: (value: google.maps.GeocoderResult) => void
}

const useAddressGeocoding = ({
    onLatLngFound,
    onStructuredAddressFound
}: UseAddressGeocodingOptions): UseAddressGeocodingResult => {
    const [addressLatLng, setAddressLatLng] = useState<Coordinates | undefined>()
    const [addressFormat, setAddressFormat] = useState<google.maps.GeocoderResult | undefined>()
    useLoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${envVars.VITE_GOOGLE_API_KEY}&libraries=places&language=en`,
        {
            removeOnUnmount: true
        }
    )

    const retrieveLatLng = async (address: string) => {
        const geocoder = new google.maps.Geocoder()
        await geocoder.geocode({address}, (results, status) => {
            if (status == 'OK' && results) {
                setAddressLatLng({
                    latitude: results[0].geometry.location.lat().toString(),
                    longitude: results[0].geometry.location.lng().toString()
                })

                onLatLngFound?.({
                    latitude: results[0].geometry.location.lat().toString(),
                    longitude: results[0].geometry.location.lng().toString()
                })
            }
        })
    }

    const retrieveStructured = async (placeId: string) => {
        const geocoder = new google.maps.Geocoder()
        await geocoder.geocode({placeId}, (results, status) => {
            if (status == 'OK' && results) {
                setAddressFormat(results[0])
                onStructuredAddressFound?.(results[0])
            }
        })
    }

    const resetGeocoding = () => {
        setAddressLatLng(undefined)
        setAddressFormat(undefined)
    }

    return {
        addressLatLng,
        addressFormat,
        retrieveLatLng,
        retrieveStructured,
        resetGeocoding
    }
}

export default useAddressGeocoding
