import {Assignment, Quote, TaskMedia, TaskResource, TaskStatus} from '@/features/service/types.ts'
import {ServiceType} from '@/types/commons.ts'
import i18next from 'i18next'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {ReactElement} from 'react'
import {HomePendingStatusDescription} from '@/features/service/components/status-description/HomePendingStatusDescription.tsx'
import {HomeAssignedSharedDatesStatusDescription} from '@/features/service/components/status-description/HomeAssignedSharedDatesStatusDescription.tsx'
import {HomeAssignedDeclinedDatesStatusDescription} from '@/features/service/components/status-description/HomeAssignedDeclinedDatesStatusDescription.tsx'
import {HomeAssignedNoDatesStatusDescription} from '@/features/service/components/status-description/HomeAssignedNoDatesStatusDescription.tsx'
import {HomeAssignedUnresponsiveStatusDescription} from '@/features/service/components/status-description/HomeAssignedUnresponsiveStatusDescription.tsx'
import {HomeScheduledStatusDescription} from '@/features/service/components/status-description/HomeScheduledStatusDescription.tsx'
import {HomeAssignedNotReadyStatusDescription} from '@/features/service/components/status-description/HomeAssignedNotReadyStatusDescription.tsx'
import {HomePhotoshootCompletedStatusDescription} from '@/features/service/components/status-description/HomePhotoshootCompletedStatusDescription.tsx'
import {HomeCompletedStatusDescription} from '@/features/service/components/status-description/HomeCompletedStatusDescription.tsx'
import {HomeCanceledStatusDescription} from './components/status-description/HomeCanceledStatusDescription'
import {HomePhotographerCanceledStatusDescription} from './components/status-description/HomePhotographerCanceledStatusDescription'
import {HomeNoPhotographerStatusDescription} from '@/features/service/components/status-description/HomeNoPhotographerStatusDescription.tsx'
import {HomeAdminCanceledStatusDescription} from '@/features/service/components/status-description/HomeAdminCanceledStatusDescription.tsx'
import {WalkthroughCompletedStatusDescription} from '@/features/service/components/status-description/WalkthroughCompletedStatusDescription.tsx'
import {WalkthroughCanceledStatusDescription} from '@/features/service/components/status-description/WalkthroughCanceledStatusDescription.tsx'
import {WalkthroughConsultantCanceledStatusDescription} from '@/features/service/components/status-description/WalkthroughConsultantCanceledStatusDescription.tsx'
import {WalkthroughAdminCanceledStatusDescription} from '@/features/service/components/status-description/WalkthroughAdminCanceledStatusDescription.tsx'
import {WalkthroughNoConsultantStatusDescription} from '@/features/service/components/status-description/WalkthroughNoConsultantStatusDescription.tsx'
import {WalkthroughInspectionCompletedStatusDescription} from '@/features/service/components/status-description/WalkthroughInspectionCompletedStatusDescription.tsx'
import {WalkthroughScheduledStatusDescription} from '@/features/service/components/status-description/WalkthroughScheduledStatusDescription.tsx'
import {WalkthroughAssignedNotReadyStatusDescription} from '@/features/service/components/status-description/WalkthroughAssignedNotReadyStatusDescription.tsx'
import {WalkthroughAssignedUnresponsiveStatusDescription} from '@/features/service/components/status-description/WalkthroughAssignedUnresponsiveStatusDescription.tsx'
import {WalkthroughAssignedNoDatesStatusDescription} from '@/features/service/components/status-description/WalkthroughAssignedNoDatesStatusDescription.tsx'
import {WalkthroughAssignedDeclinedDatesStatusDescription} from '@/features/service/components/status-description/WalkthroughAssignedDeclinedDatesStatusDescription.tsx'
import {WalkthroughAssignedSharedDatesStatusDescription} from '@/features/service/components/status-description/WalkthroughAssignedSharedDatesStatusDescription.tsx'
import {WalkthroughPendingStatusDescription} from '@/features/service/components/status-description/WalkthroughPendingStatusDescription.tsx'
import {MatterportPendingStatusDescription} from '@/features/service/components/status-description/MatterportPendingStatusDescription.tsx'
import {MatterportAssignedSharedDatesStatusDescription} from '@/features/service/components/status-description/MatterportAssignedSharedDatesStatusDescription.tsx'
import {MatterportAssignedNoDatesStatusDescription} from '@/features/service/components/status-description/MatterportAssignedNoDatesStatusDescription.tsx'
import {MatterportAssignedUnresponsiveStatusDescription} from '@/features/service/components/status-description/MatterportAssignedUnresponsiveStatusDescription.tsx'
import {MatterportAssignedNotReadyStatusDescription} from '@/features/service/components/status-description/MatterportAssignedNotReadyStatusDescription.tsx'
import {MatterportAssignedDeclinedDatesStatusDescription} from '@/features/service/components/status-description/MatterportAssignedDeclinedDatesStatusDescription.tsx'
import {MatterportScheduledStatusDescription} from '@/features/service/components/status-description/MatterportScheduledStatusDescription.tsx'
import {MatterportCompletedStatusDescription} from '@/features/service/components/status-description/MatterportCompletedStatusDescription.tsx'
import {MatterportCanceledStatusDescription} from '@/features/service/components/status-description/MatterportCanceledStatusDescription.tsx'
import {MatterportTechnicianCanceledStatusDescription} from '@/features/service/components/status-description/MatterportTechnicianCanceledStatusDescription.tsx'
import {MatterportAdminCanceledStatusDescription} from '@/features/service/components/status-description/MatterportAdminCanceledStatusDescription.tsx'
import {MatterportNoTechnicianStatusDescription} from '@/features/service/components/status-description/MatterportNoTechnicianStatusDescription.tsx'
import {ExperienceVideoPendingStatusDescription} from '@/features/service/components/status-description/ExperienceVideoPendingStatusDescription.tsx'
import {ExperienceVideoAssignedSharedDatesStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAssignedSharedDatesStatusDescription.tsx'
import {ExperienceVideoAssignedDeclinedDatesStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAssignedDeclinedDatesStatusDescription.tsx'
import {ExperienceVideoAssignedNoDatesStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAssignedNoDatesStatusDescription.tsx'
import {ExperienceVideoAssignedUnresponsiveStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAssignedUnresponsiveStatusDescription.tsx'
import {ExperienceVideoAssignedNotReadyStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAssignedNotReadyStatusDescription.tsx'
import {ExperienceVideoScheduledStatusDescription} from '@/features/service/components/status-description/ExperienceVideoScheduledStatusDescription.tsx'
import {ExperienceVideoVideoshootCompletedStatusDescription} from '@/features/service/components/status-description/ExperienceVideoVideoshootCompletedStatusDescription.tsx'
import {ExperienceVideoCompletedStatusDescription} from '@/features/service/components/status-description/ExperienceVideoCompletedStatusDescription.tsx'
import {ExperienceVideoCanceledStatusDescription} from '@/features/service/components/status-description/ExperienceVideoCanceledStatusDescription.tsx'
import {ExperienceVideoVideographerCanceledStatusDescription} from '@/features/service/components/status-description/ExperienceVideoVideographerCanceledStatusDescription.tsx'
import {ExperienceVideoAdminCanceledStatusDescription} from '@/features/service/components/status-description/ExperienceVideoAdminCanceledStatusDescription.tsx'
import {ExperienceVideoNoVideographerStatusDescription} from '@/features/service/components/status-description/ExperienceVideoNoVideographerStatusDescription.tsx'
import {HomePendingSharedDatesStatusDescription} from '@/features/service/components/status-description/HomePendingSharedDatesStatusDescription.tsx'
import {StepCardProps, StepStatus} from '@/components/commons/step-card/StepCard'
import {ExperienceVideoCancellationRequestedStatusDescription} from '@/features/service/components/status-description/ExperienceVideoCancellationRequestedStatusDescription.tsx'
import {WalkthroughCancellationRequestedStatusDescription} from '@/features/service/components/status-description/WalkthroughCancellationRequestedStatusDescription.tsx'
import {MatterportCancellationRequestedStatusDescription} from '@/features/service/components/status-description/MatterportCancellationRequestedStatusDescription.tsx'
import {HomeCancellationRequestedStatusDescription} from '@/features/service/components/status-description/HomeCancellationRequestedStatusDescription.tsx'

export const serviceTypeToServiceSubtitle = {
    home: 'commons:photoshoot',
    experience_video: 'commons:experience_video',
    experience_photo: 'commons:experience_photo',
    matterport: 'commons:matterport',
    walkthrough: 'commons:walkthrough'
} as const satisfies Record<ServiceType, string>

//Steps
const stepsTitlesTKeys = {
    experience_photo: {
        step1: {
            completed: 'service_details:steps:home:step1_completed_title'
        },
        step2: {
            active: 'service_details:steps:home:step2_active_title',
            completed: 'service_details:steps:home:step2_completed_title',
            error: 'service_details:steps:home:step2_active_title'
        },
        step3: {
            active: 'service_details:steps:home:step3_active_title',
            completed: 'service_details:steps:home:step3_completed_title',
            inactive: 'service_details:steps:home:step3_active_title'
        },
        step4: {
            active: 'service_details:steps:home:step4_active_title',
            completed: 'service_details:steps:home:step4_completed_title',
            inactive: 'service_details:steps:home:step4_active_title'
        },
        step5: {
            active: 'service_details:steps:home:step5_active_title',
            completed: 'service_details:steps:home:step5_completed_title',
            inactive: 'service_details:steps:home:step5_active_title'
        }
    },
    experience_video: {
        step1: {
            completed: 'service_details:steps:home:step1_completed_title'
        },
        step2: {
            active: 'service_details:steps:experience_video:step2_active_title',
            completed: 'service_details:steps:experience_video:step2_completed_title',
            error: 'service_details:steps:experience_video:step2_active_title'
        },
        step3: {
            active: 'service_details:steps:experience_video:step3_active_title',
            completed: 'service_details:steps:experience_video:step3_completed_title',
            inactive: 'service_details:steps:experience_video:step3_active_title'
        },
        step4: {
            active: 'service_details:steps:experience_video:step4_active_title',
            completed: 'service_details:steps:experience_video:step4_completed_title',
            inactive: 'service_details:steps:experience_video:step4_active_title'
        },
        step5: {
            active: 'service_details:steps:experience_video:step5_active_title',
            completed: 'service_details:steps:experience_video:step5_completed_title',
            inactive: 'service_details:steps:experience_video:step5_active_title'
        }
    },
    home: {
        step1: {
            completed: 'service_details:steps:home:step1_completed_title'
        },
        step2: {
            active: 'service_details:steps:home:step2_active_title',
            completed: 'service_details:steps:home:step2_completed_title',
            error: 'service_details:steps:home:step2_active_title'
        },
        step3: {
            active: 'service_details:steps:home:step3_active_title',
            completed: 'service_details:steps:home:step3_completed_title',
            inactive: 'service_details:steps:home:step3_active_title'
        },
        step4: {
            active: 'service_details:steps:home:step4_active_title',
            completed: 'service_details:steps:home:step4_completed_title',
            inactive: 'service_details:steps:home:step4_active_title'
        },
        step5: {
            active: 'service_details:steps:home:step5_active_title',
            completed: 'service_details:steps:home:step5_completed_title',
            inactive: 'service_details:steps:home:step5_active_title'
        }
    },
    matterport: {
        step1: {
            completed: 'service_details:steps:home:step1_completed_title'
        },
        step2: {
            active: 'service_details:steps:matterport:step2_active_title',
            completed: 'service_details:steps:matterport:step2_completed_title',
            error: 'service_details:steps:matterport:step2_active_title'
        },
        step3: {
            active: 'service_details:steps:matterport:step3_active_title',
            completed: 'service_details:steps:matterport:step3_completed_title',
            inactive: 'service_details:steps:matterport:step3_active_title'
        },
        step4: {
            active: 'service_details:steps:matterport:step4_active_title',
            completed: 'service_details:steps:matterport:step4_completed_title',
            inactive: 'service_details:steps:matterport:step4_active_title'
        },
        step5: {
            active: 'service_details:steps:matterport:step5_active_title',
            completed: 'service_details:steps:matterport:step5_completed_title',
            inactive: 'service_details:steps:matterport:step5_active_title'
        }
    },
    walkthrough: {
        step1: {
            completed: 'service_details:steps:home:step1_completed_title'
        },
        step2: {
            active: 'service_details:steps:walkthrough:step2_active_title',
            completed: 'service_details:steps:walkthrough:step2_completed_title',
            error: 'service_details:steps:walkthrough:step2_active_title'
        },
        step3: {
            active: 'service_details:steps:walkthrough:step3_active_title',
            completed: 'service_details:steps:walkthrough:step3_completed_title',
            inactive: 'service_details:steps:walkthrough:step3_active_title'
        },
        step4: {
            active: 'service_details:steps:walkthrough:step4_active_title',
            completed: 'service_details:steps:walkthrough:step4_completed_title',
            inactive: 'service_details:steps:walkthrough:step4_active_title'
        },
        step5: {
            active: 'service_details:steps:walkthrough:step5_active_title',
            completed: 'service_details:steps:walkthrough:step5_completed_title',
            inactive: 'service_details:steps:walkthrough:step5_active_title'
        }
    }
} as const satisfies Record<
    ServiceType,
    Record<'step1' | 'step2' | 'step3' | 'step4' | 'step5', Partial<Record<StepStatus, string>>>
>

type getStepsOptions = {
    assignedFreelancer?: string
    assignmentStatus?: Assignment['status']
    quoteAcceptedAt: Quote['accepted_at']
    postProdStatus?: Assignment['task_status']['post_prod_status']
    quoteStatus: Quote['quote_status']
    serviceType: ServiceType
    taskStatus?: Assignment['task_status']['status']
    assignmentDate?: Assignment['to_shoot_at']
}
export const getSteps = ({
    assignedFreelancer,
    assignmentStatus,
    postProdStatus,
    quoteAcceptedAt,
    quoteStatus,
    serviceType,
    taskStatus,
    assignmentDate
}: getStepsOptions): Array<StepCardProps & {isCurrent: boolean}> => {
    const formattedQuoteAcceptedAt = quoteAcceptedAt ? formatLocaleDate(quoteAcceptedAt) : '-'
    const formattedToShootAt = assignmentDate ? formatLocaleDate(assignmentDate) : '-'

    if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
                subtitle: formattedQuoteAcceptedAt
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step2.completed),
                subtitle: assignedFreelancer || '-'
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step3.completed),
                subtitle: formattedToShootAt
            },
            {
                number: 4,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step4.completed),
                subtitle: i18next.t('commons:done')
            },
            {
                number: 5,
                isCurrent: true,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step5.completed),
                subtitle: i18next.t('commons:done')
            }
        ]
    }
    if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'auto_cancelled') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
                subtitle: formattedQuoteAcceptedAt
            },
            {
                number: 2,
                isCurrent: true,
                status: 'error',
                title: i18next.t(stepsTitlesTKeys[serviceType].step2.error),
                subtitle: ''
            },
            {
                number: 3,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step3.inactive),
                subtitle: ''
            },
            {
                number: 4,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step4.inactive),
                subtitle: ''
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step5.inactive),
                subtitle: ''
            }
        ]
    }
    if (taskStatus == 'accepted') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
                subtitle: formattedQuoteAcceptedAt
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step2.completed),
                subtitle: assignedFreelancer || '-'
            },
            {
                number: 3,
                isCurrent: true,
                status: 'active',
                title: i18next.t(stepsTitlesTKeys[serviceType].step3.active),
                subtitle: i18next.t('service_details:in_progress')
            },
            {
                number: 4,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step4.inactive),
                subtitle: ''
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step5.inactive),
                subtitle: ''
            }
        ]
    }
    if (taskStatus == 'scheduled') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
                subtitle: formattedQuoteAcceptedAt
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step2.completed),
                subtitle: assignedFreelancer || '-'
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step3.completed),
                subtitle: formattedToShootAt
            },
            {
                number: 4,
                isCurrent: true,
                status: 'active',
                title: i18next.t(stepsTitlesTKeys[serviceType].step4.active),
                subtitle: i18next.t('service_details:in_progress')
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(stepsTitlesTKeys[serviceType].step5.inactive),
                subtitle: ''
            }
        ]
    }
    if (
        taskStatus == 'pending_assets' ||
        taskStatus == 'needs_more_assets' ||
        taskStatus == 'assets_in_review' ||
        taskStatus == 'pending_payment'
    ) {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
                subtitle: formattedQuoteAcceptedAt
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step2.completed),
                subtitle: assignedFreelancer || '-'
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step3.completed),
                subtitle: formattedToShootAt
            },
            {
                number: 4,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(stepsTitlesTKeys[serviceType].step4.completed),
                subtitle: i18next.t('commons:done')
            },
            {
                number: 5,
                isCurrent: true,
                status: 'active',
                title: i18next.t(stepsTitlesTKeys[serviceType].step5.active),
                subtitle: i18next.t('service_details:in_progress')
            }
        ]
    }

    return [
        {
            number: 1,
            isCurrent: false,
            status: 'completed',
            title: i18next.t(stepsTitlesTKeys[serviceType].step1.completed),
            subtitle: formattedQuoteAcceptedAt
        },
        {
            number: 2,
            isCurrent: true,
            status: 'active',
            title: i18next.t(stepsTitlesTKeys[serviceType].step2.active),
            subtitle: i18next.t('service_details:in_progress')
        },
        {
            number: 3,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(stepsTitlesTKeys[serviceType].step3.inactive),
            subtitle: ''
        },
        {
            number: 4,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(stepsTitlesTKeys[serviceType].step4.inactive),
            subtitle: ''
        },
        {
            number: 5,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(stepsTitlesTKeys[serviceType].step5.inactive),
            subtitle: ''
        }
    ]
}

//Descriptions
type ServiceStepsDescriptionsOptions = {
    addedNewImages: boolean
    assignedFreelancer?: string
    assignmentDate?: Assignment['to_shoot_at']
    assignmentStatus?: Assignment['status']
    cancellationRequestedAt?: Assignment['cancellation_requested_at']
    coordinationStatus?: Assignment['task_status']['coordination_status']
    hasSharedDates: boolean
    hostUnresponsiveAt?: Assignment['host_unresponsive_at']
    isFeedbackSent: boolean
    manualCoordinationAt?: Assignment['manual_coordination_at']
    postProdStatus?: TaskStatus['post_prod_status']
    quoteStatus: Quote['quote_status']
    reminderAt?: Assignment['reminder_at']
    taskCategory: TaskResource['name']
    taskMedia: TaskMedia['name']
    taskStatus?: TaskStatus['status']
}
export const serviceStepsDescriptions = {
    experience_photo: ({
        assignmentDate,
        assignedFreelancer,
        assignmentStatus,
        cancellationRequestedAt,
        hasSharedDates,
        hostUnresponsiveAt,
        isFeedbackSent,
        manualCoordinationAt,
        postProdStatus,
        quoteStatus,
        reminderAt,
        taskStatus,
        coordinationStatus
    }) => {
        if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
            return <HomeCompletedStatusDescription isFeedbackSent={isFeedbackSent} />
        }
        if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'declined') {
            return <HomePhotographerCanceledStatusDescription />
        }
        if (taskStatus == 'auto_cancelled') {
            return <HomeAdminCanceledStatusDescription />
        }
        if (assignmentStatus == 'auto_cancelled') {
            return <HomeNoPhotographerStatusDescription />
        }
        if (quoteStatus == 'cancelled') {
            return <HomeCanceledStatusDescription />
        }
        if (
            taskStatus == 'pending_assets' ||
            taskStatus == 'needs_more_assets' ||
            taskStatus == 'assets_in_review' ||
            taskStatus == 'pending_payment'
        ) {
            return <HomePhotoshootCompletedStatusDescription assignmentDate={assignmentDate} />
        }
        if (taskStatus == 'scheduled') {
            return (
                <HomeScheduledStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_not_ready') {
            return <HomeAssignedNotReadyStatusDescription reminderAt={reminderAt} />
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_unresponsive') {
            return (
                <HomeAssignedUnresponsiveStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    hostUnresponsiveAt={hostUnresponsiveAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'cancellation_requested') {
            return (
                <HomeCancellationRequestedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    cancellationRequestedAt={cancellationRequestedAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'manual_coordination' && manualCoordinationAt) {
            return <HomeAssignedDeclinedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (
            taskStatus == 'accepted' &&
            (coordinationStatus == 'manual_coordination' ||
                coordinationStatus == 'host_contacted' ||
                coordinationStatus == 'overdue_host_contacted')
        ) {
            return <HomeAssignedNoDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (taskStatus == 'accepted') {
            return <HomeAssignedSharedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (hasSharedDates) {
            return <HomePendingSharedDatesStatusDescription />
        }
        return <HomePendingStatusDescription />
    },
    experience_video: ({
        assignedFreelancer,
        assignmentDate,
        assignmentStatus,
        cancellationRequestedAt,
        coordinationStatus,
        hostUnresponsiveAt,
        manualCoordinationAt,
        postProdStatus,
        quoteStatus,
        reminderAt,
        taskCategory,
        taskMedia,
        taskStatus
    }) => {
        if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
            return <ExperienceVideoCompletedStatusDescription />
        }
        if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'declined') {
            return <ExperienceVideoVideographerCanceledStatusDescription />
        }
        if (taskStatus == 'auto_cancelled') {
            return <ExperienceVideoAdminCanceledStatusDescription />
        }
        if (assignmentStatus == 'auto_cancelled') {
            return <ExperienceVideoNoVideographerStatusDescription />
        }
        if (quoteStatus == 'cancelled') {
            return <ExperienceVideoCanceledStatusDescription />
        }
        if (
            taskStatus == 'pending_assets' ||
            taskStatus == 'needs_more_assets' ||
            taskStatus == 'assets_in_review' ||
            taskStatus == 'pending_payment'
        ) {
            return <ExperienceVideoVideoshootCompletedStatusDescription assignmentDate={assignmentDate} />
        }
        if (taskStatus == 'scheduled') {
            return (
                <ExperienceVideoScheduledStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                    taskMedia={taskMedia}
                    taskCategory={taskCategory}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_not_ready') {
            return (
                <ExperienceVideoAssignedNotReadyStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    reminderAt={reminderAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_unresponsive') {
            return (
                <ExperienceVideoAssignedUnresponsiveStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    hostUnresponsiveAt={hostUnresponsiveAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'cancellation_requested') {
            return (
                <ExperienceVideoCancellationRequestedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    cancellationRequestedAt={cancellationRequestedAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'manual_coordination' && manualCoordinationAt) {
            return <ExperienceVideoAssignedDeclinedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (
            taskStatus == 'accepted' &&
            (coordinationStatus == 'manual_coordination' ||
                coordinationStatus == 'host_contacted' ||
                coordinationStatus == 'overdue_host_contacted')
        ) {
            return <ExperienceVideoAssignedNoDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (taskStatus == 'accepted') {
            return <ExperienceVideoAssignedSharedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        return <ExperienceVideoPendingStatusDescription />
    },
    home: ({
        assignmentDate,
        assignedFreelancer,
        assignmentStatus,
        cancellationRequestedAt,
        hasSharedDates,
        hostUnresponsiveAt,
        isFeedbackSent,
        manualCoordinationAt,
        postProdStatus,
        quoteStatus,
        reminderAt,
        taskStatus,
        coordinationStatus
    }) => {
        if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
            return <HomeCompletedStatusDescription isFeedbackSent={isFeedbackSent} />
        }
        if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'declined') {
            return <HomePhotographerCanceledStatusDescription />
        }
        if (taskStatus == 'auto_cancelled') {
            return <HomeAdminCanceledStatusDescription />
        }
        if (assignmentStatus == 'auto_cancelled') {
            return <HomeNoPhotographerStatusDescription />
        }
        if (quoteStatus == 'cancelled') {
            return <HomeCanceledStatusDescription />
        }
        if (
            taskStatus == 'pending_assets' ||
            taskStatus == 'needs_more_assets' ||
            taskStatus == 'assets_in_review' ||
            taskStatus == 'pending_payment'
        ) {
            return <HomePhotoshootCompletedStatusDescription assignmentDate={assignmentDate} />
        }
        if (taskStatus == 'scheduled') {
            return (
                <HomeScheduledStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_not_ready') {
            return <HomeAssignedNotReadyStatusDescription reminderAt={reminderAt} />
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_unresponsive') {
            return (
                <HomeAssignedUnresponsiveStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    hostUnresponsiveAt={hostUnresponsiveAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'cancellation_requested') {
            return (
                <HomeCancellationRequestedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    cancellationRequestedAt={cancellationRequestedAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'manual_coordination' && manualCoordinationAt) {
            return <HomeAssignedDeclinedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (
            taskStatus == 'accepted' &&
            (coordinationStatus == 'manual_coordination' ||
                coordinationStatus == 'host_contacted' ||
                coordinationStatus == 'overdue_host_contacted')
        ) {
            return <HomeAssignedNoDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (taskStatus == 'accepted') {
            return <HomeAssignedSharedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (hasSharedDates) {
            return <HomePendingSharedDatesStatusDescription />
        }
        return <HomePendingStatusDescription />
    },
    matterport: ({
        addedNewImages,
        assignmentStatus,
        assignedFreelancer,
        assignmentDate,
        cancellationRequestedAt,
        coordinationStatus,
        hostUnresponsiveAt,
        manualCoordinationAt,
        postProdStatus,
        quoteStatus,
        reminderAt,
        taskStatus
    }) => {
        if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
            return <MatterportCompletedStatusDescription hasRequestedChanges={addedNewImages} />
        }
        if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'declined') {
            return <MatterportTechnicianCanceledStatusDescription />
        }
        if (taskStatus == 'auto_cancelled') {
            return <MatterportAdminCanceledStatusDescription />
        }
        if (assignmentStatus == 'auto_cancelled') {
            return <MatterportNoTechnicianStatusDescription />
        }
        if (quoteStatus == 'cancelled') {
            return <MatterportCanceledStatusDescription />
        }
        if (
            taskStatus == 'pending_assets' ||
            taskStatus == 'needs_more_assets' ||
            taskStatus == 'assets_in_review' ||
            taskStatus == 'pending_payment'
        ) {
            return <HomePhotoshootCompletedStatusDescription assignmentDate={assignmentDate} />
        }
        if (taskStatus == 'scheduled') {
            return <MatterportScheduledStatusDescription assignmentDate={assignmentDate} />
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_not_ready') {
            return <MatterportAssignedNotReadyStatusDescription reminderAt={reminderAt} />
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_unresponsive') {
            return (
                <MatterportAssignedUnresponsiveStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    hostUnresponsiveAt={hostUnresponsiveAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'cancellation_requested') {
            return (
                <MatterportCancellationRequestedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    cancellationRequestedAt={cancellationRequestedAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'manual_coordination' && manualCoordinationAt) {
            return <MatterportAssignedDeclinedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (
            taskStatus == 'accepted' &&
            (coordinationStatus == 'manual_coordination' ||
                coordinationStatus == 'host_contacted' ||
                coordinationStatus == 'overdue_host_contacted')
        ) {
            return <MatterportAssignedNoDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (taskStatus == 'accepted') {
            return <MatterportAssignedSharedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        return <MatterportPendingStatusDescription />
    },
    walkthrough: ({
        assignmentDate,
        assignedFreelancer,
        assignmentStatus,
        cancellationRequestedAt,
        hostUnresponsiveAt,
        manualCoordinationAt,
        postProdStatus,
        quoteStatus,
        reminderAt,
        taskStatus,
        coordinationStatus
    }) => {
        if (quoteStatus == 'completed' || (taskStatus == 'pending_payment' && postProdStatus == 'upload_completed')) {
            return (
                <WalkthroughCompletedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                />
            )
        }
        if (assignmentStatus == 'soft_cancelled' || assignmentStatus == 'declined') {
            return <WalkthroughConsultantCanceledStatusDescription />
        }
        if (taskStatus == 'auto_cancelled') {
            return <WalkthroughAdminCanceledStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (assignmentStatus == 'auto_cancelled') {
            return <WalkthroughNoConsultantStatusDescription />
        }
        if (quoteStatus == 'cancelled') {
            return <WalkthroughCanceledStatusDescription />
        }
        if (
            taskStatus == 'pending_assets' ||
            taskStatus == 'needs_more_assets' ||
            taskStatus == 'assets_in_review' ||
            taskStatus == 'pending_payment'
        ) {
            return (
                <WalkthroughInspectionCompletedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                />
            )
        }
        if (taskStatus == 'scheduled') {
            return (
                <WalkthroughScheduledStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    assignmentDate={assignmentDate}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_not_ready') {
            return (
                <WalkthroughAssignedNotReadyStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    reminderAt={reminderAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'host_unresponsive') {
            return (
                <WalkthroughAssignedUnresponsiveStatusDescription
                    hostUnresponsiveAt={hostUnresponsiveAt}
                    assignedFreelancer={assignedFreelancer}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'cancellation_requested') {
            return (
                <WalkthroughCancellationRequestedStatusDescription
                    assignedFreelancer={assignedFreelancer}
                    cancellationRequestedAt={cancellationRequestedAt}
                />
            )
        }
        if (taskStatus == 'accepted' && coordinationStatus == 'manual_coordination' && manualCoordinationAt) {
            return <WalkthroughAssignedDeclinedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (
            taskStatus == 'accepted' &&
            (coordinationStatus == 'manual_coordination' ||
                coordinationStatus == 'host_contacted' ||
                coordinationStatus == 'overdue_host_contacted')
        ) {
            return <WalkthroughAssignedNoDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        if (taskStatus == 'accepted') {
            return <WalkthroughAssignedSharedDatesStatusDescription assignedFreelancer={assignedFreelancer} />
        }
        return <WalkthroughPendingStatusDescription />
    }
} as const satisfies Record<ServiceType, (options: ServiceStepsDescriptionsOptions) => ReactElement>

export const TASK_CATEGORIES = {
    ECH: 'ECH'
}
